var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if (options.sponsor && options.sponsor === 'dealeron') {
        shimUtils.moveDealerOnAppFrame(options);
    }

    return options;
};
