var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    //temporary fix for 826 goschToyota. sponsorShim usually disabled, but needs to be enabled to test new site in staging for foxdealersites
    if(options.dealerId===826){
        return options;
    }

    var vehicleOverviewLabel = document.querySelectorAll('.vehicle--specs__list dt');
    var vehicleOverviewValue = document.querySelectorAll('.vehicle--specs__list dd');

    var overviewArrLabel = Array.prototype.map.call(
        vehicleOverviewLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArrValue = Array.prototype.map.call(
        vehicleOverviewValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    if (overviewArrLabel.length && overviewArrValue.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArrLabel.filter(function(row, index, array) {
            var label = row.textContent.trim().replace(replaceRegex, '').toLowerCase();
            var value = overviewArrValue[index];

            switch (label) {
                case 'mileage':
                    data.mileage = value.textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'stock':
                    data.stock = value.textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'vin':
                    data.vin = value.textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                default:
                    break;
            }
            return true;
        });
    }

    if(!data.mileage){
        data.mileage = document.querySelector('.miles:nth-child(2)')
            || document.querySelector('div#vehicle-miles .ui-block-b');
    }

    if(!data.vin){
        data.vin = document.querySelector('.vin')
            || document.querySelector('div#vehicle-vin .ui-block-b');
    }

    if(!data.stock){
        data.stock = document.querySelector('.stock')
            || document.querySelector('div#vehicle-stock .ui-block-b');
    }

    data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-name')
        || document.querySelector('.vehicle-detail-name')
        || document.querySelector('.paragon-text');

    data.retailPrice = document.querySelector('.vehicle-price')
        || document.querySelector('div#vehicle-price .ui-block-b')
        || document.querySelector('.price--msrp .value');

    data.listedPrice = document.querySelector('.sales-price')
        || document.querySelector('div#sale-price .ui-block-b');

    data.dealerSavings = document.querySelector('.vehicle-savings')
        || document.querySelector('div#vehicle-savings .ui-block-b');

    data.vehicleStatus = document.URL;

    data.img = document.querySelector('.tabpanel-photos img')
        || document.querySelector('.vehicle-photo-detail-container img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result){
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            var vin = data.vin;
            if(vin.textContent){
                vin = vin.textContent.split(' ').filter(function(n){ return n != '' });
                if(vin.length>1){
                    vin = vin[1];
                }else{
                    vin = vin[0];
                }
            }
            result.vin = vin.trim();
        },
        stock: function(data, result) {
            var stock = data.stock;
            if(stock.textContent){
                var stock = data.stock.textContent.split(' ').filter(function(n){ return n != '' });
                if(stock.length>1){
                    result.stock = stock[1];
                }else{
                    result.stock = stock[0];
                }
            }
            result.stock = stock.trim();
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus.indexOf('new')>-1) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
