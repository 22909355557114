var shimUtils = require('../utils');

module.exports = function(options) {

    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverviewLabel = document.querySelectorAll('.specWrap .specTitle');
    var vehicleOverviewValue = document.querySelectorAll('.specWrap .specAnswer');
    var pricingTableLabel = [];
    var pricingTableValue = [];

    var priceOneLabel = document.querySelector('.priceOne .priceLabel');
    var priceOneValue = document.querySelector('.priceOne .price');
    var priceTwoLabel = document.querySelector('.priceTwo .priceLabel');
    var priceTwoValue = document.querySelector('.priceTwo .price');

    if(priceOneLabel && priceOneValue)
        pricingTableLabel.push(priceOneLabel);
        pricingTableValue.push(priceOneValue);

    if(priceTwoLabel && priceOneValue)
        pricingTableLabel.push(priceTwoLabel);
        pricingTableValue.push(priceTwoValue);

    var overviewLabelArr = Array.prototype.map.call(
        vehicleOverviewLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewValueArr = Array.prototype.map.call(
        vehicleOverviewValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    var pricingLabelArr = Array.prototype.map.call(
        pricingTableLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var pricingValueArr = Array.prototype.map.call(
        pricingTableValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewLabelData = [];
    var overviewValueData = [];

    if(overviewLabelArr.length && overviewValueArr.length && pricingLabelArr.length && pricingValueArr.length){
        overviewLabelData = overviewLabelData.concat(overviewLabelArr);
        overviewLabelData = overviewLabelData.concat(pricingLabelArr);

        overviewValueData = overviewValueData.concat(overviewValueArr);
        overviewValueData = overviewValueData.concat(pricingValueArr);
    }else if(!overviewLabelArr.length && !overviewValueArr.length && pricingLabelArr.length && pricingValueArr.length){
        overviewLabelData = overviewLabelData.concat(pricingLabelArr);

        overviewValueData = overviewValueData.concat(pricingValueArr);
    }else if(overviewLabelArr.length && overviewValueArr.length && !pricingLabelArr.length && !pricingValueArr.length){
        overviewLabelData = overviewLabelData.concat(overviewLabelArr);

        overviewValueData = overviewValueData.concat(overviewValueArr);
    }

    if (overviewLabelData.length && overviewValueData.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewLabelData.filter(function(row, index, array) {
            var label = row.textContent.trim().replace(replaceRegex, '').toLowerCase();

            switch (label) {
                case 'vin':
                    data.vin = overviewValueData[index].textContent.trim().replace(replaceRegex, '');
                    break;

                case 'stock':
                    data.stock = overviewValueData[index].textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'mileage':
                    data.mileage = overviewValueData[index].textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'msrp':
                case 'retail price':
                    data.retailPrice = overviewValueData[index].textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'sale price':
                case 'sales price':
                    data.listedPrice = overviewValueData[index].textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                default:
                    break;
            }
            return true;
        });
    };

    data.vehicleStatus = document.querySelector(".status");
    data.year = document.querySelector(".vehicleYear");
    data.make = document.querySelector(".make");
    data.model = document.querySelector(".model");
    data.trim = document.querySelector(".trim");
    data.img = document.querySelector('#mediaWrapper img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = shimUtils.getInteger(data.year);
        },
        make: function(data, result) {
            result.make = data.make.textContent.trim();
        },
        model: function(data, result) {
            result.model = data.model.textContent.trim();
        },
        trim: function(data, result) {
            result.trim = data.trim.textContent.trim();
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.textContent.toLowerCase();

            if (vehicleStatus==="new") {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
