var shimUtils = require('../utils');
var magic = require('../magiccollector');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        dealerSavings : '',
        incentiveSavings : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    //get listedPrice & retailPrice
    //based on available elements
    data.retailPrice = document.querySelector('.price-msrp .price-value');
    data.listedPrice = document.querySelector('.price-eprice .price-value');

    //get year, make, model, trim
    data.year = document.querySelector('span[itemprop=name]');
    data.make = document.querySelector('span[itemprop=name] span[itemprop=brand]');
    data.model = document.querySelector('span[itemprop=name] span[itemprop=model]');
    data.trim = document.querySelector('span[itemprop=name] .trim');

    //get status
    data.vehicleStatus = location.href.toLowerCase();

    //get mileage
    data.mileage = data.vin = data.stock = document.querySelector('.vehicle-details .details');

    //get img
    data.img = document.querySelector('.galleria img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            data.year = data.year.childNodes[0].textContent.trim();
            result.year = parseInt(data.year);
        },
        make : function(data, result) {
            result.make = data.make.textContent.trim();
        },
        model : function(data, result) {
            result.model = data.model.textContent.trim();
        },
        trim : function(data, result) {
            result.trim = data.trim.textContent.trim();
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            if(status.search(/new/) === -1) {
                result.vehicleStatus = 0;
            } else {
                result.vehicleStatus = 1;
            }
        },
        mileage : function(data, result) {
            result.mileage = magic.getMileage(data.mileage);
        },
        vin : function(data, result) {
            result.vin = magic.getVin(data.vin);
        },
        stock : function(data, result) {
            result.stock = magic.getStock(data.stock);
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
