var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var cm = window.ContextManager;
    var platform;

    if (cm && typeof cm.getDesktopOrMobile == 'function') {
        platform = cm.getDesktopOrMobile();

        if (platform === 'mobile') {
            options.appendTo = "#flex-Text-1485293552742";
        }
    }

    return options;
}
