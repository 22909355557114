//shim:fusionzone
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    var href = location.href;

    //get status
    data.vehicleStatus = location.pathname.toLowerCase().search(/\bnew\b/g);

    if (href.search(/mobile/) == -1) { //desktop

        var pricingTable = document.querySelectorAll('.vdp-vehicle-pricing .row .column .row .columns');
        if(!pricingTable.length){
            pricingTable = document.querySelectorAll('.large-6 .row .columns');
        };
        var vehicleOverview = document.querySelectorAll('.vdp-vehicle-details li');

        if (pricingTable.length > 0) {
            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector1 = pricingArr.filter(function(row, index, array) {
                if (array[index].children[0].className==='left') {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'msrp':
                        case 'retail':
                        case 'kbb value':
                        case 'kbb market value':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'you save':
                        case 'savings':
                            data.dealerSavings = array[index].children[1];
                            break;

                        case 'internet special':
                        case 'sale price':
                        case 'price':
                            data.listedPrice = array[index].children[1];
                            break;

                        default:
                    }
                } else if (array[index].children.length !== 0 && !array[index].children[0].className==='left') {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'msrp':
                        case 'kbb market value':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'you save':
                            data.dealerSavings = array[index].children[1];
                            break;

                        case 'internet special':
                        case 'sale price':
                        case 'price':
                            data.listedPrice = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        } else {
            data.listedPrice = document.querySelector('#toolbox div[align="center"] b .value') ||
                document.querySelector('#toolbox .incentivesSalePrice span') ||
                document.getElementById('#toolbox [style="text-align:center"] .enow');

            data.retailPrice = document.querySelector('#toolbox div[align="center"] .value') ||
                document.querySelector('#toolbox .incentivesMsrp span');
        }

        if (vehicleOverview.length > 0) {
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector2 = overviewArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var vehicleInfoCleansedRow = array[index].textContent.trim().toLowerCase().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '');
                    var vehicleInfo;
                    if (vehicleInfoCleansedRow.indexOf(':') > -1) {
                        vehicleInfo = vehicleInfoCleansedRow.split(':');
                    } else {
                        vehicleInfo = vehicleInfoCleansedRow.split(' ');
                    }

                    var label = vehicleInfo[0].trim();

                    switch (label) {
                        case 'mileage':
                            data.mileage = vehicleInfo[1];
                            break;

                        case 'vin':
                            data.vin = vehicleInfo[1].toUpperCase();
                            break;

                        case 'stock no':
                        case 'stock':
                            data.stock = vehicleInfo[1];
                            break;

                        default:
                    }
                }
                return true;
            });

        } else {

            //get vin
            data.vin = document.querySelector('#info_column1 li:first-child');

            //get stock
            data.stock = document.querySelector('#info_column1 li:nth-child(2)');

            //get mileage
            data.mileage = document.querySelector('#info_column1 li:nth-child(3)');

        }

        //get year, make, model, trim
        data.year = data.make = data.model = data.trim = document.querySelector('#title h2') ||
            document.querySelector('.vehicle-title h1');

        //get img
        data.img = document.querySelector('#photo_one img') ||
            document.querySelector('.rg-image img');

    } else if (href.search(/mobile/) > 0) { //mobile

        //get listedPrice
        data.listedPrice = data.retailPrice = document.querySelector('div[role="main"]:last-child ul:first-child li.ui-first-child span');

        //get year, make, model, trim
        data.year = data.make = data.model = data.trim = document.querySelector('.dtitle p b');

        //get mileage, vin, stock
        data.vin = data.stock = data.mileage = document.querySelector('div[role="main"]:last-child ul:first-child li.ui-last-child');

        //get img
        data.img = document.getElementById('large') || document.querySelector('.center img');


    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //defiine tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        mileage: function(data, result) {
            // fix for mwo-857 - mileage has labels on desktop for used vehicles
            // appears that both mobile and desktop has potential of mileage label
            var mileage;

            if (!data.mileage.textContent) {
                mileage = data.mileage;
            } else {
                mileage = data.mileage.textContent.trim().toLowerCase();
            }

            if (mileage.toLowerCase().search(/mileage/g) !== -1) { // did not find mileage label
                mileage = mileage.split(' ')[mileage.split(' ').indexOf('mileage:') + 1];
                mileage = mileage.replace(/\D/g, '');
            }

            result.mileage = parseInt(mileage, 10);
        },
        vin: function(data, result) {
            var vin = '';
            if (!data.vin.textContent) {
                vin = data.vin;
            } else {
                vin = data.vin.textContent.trim();
            }

            if (href.search(/mobile/) == -1) { //desktop
                if (vin.indexOf(':') > -1) {
                    result.vin = vin.split(':')[1];
                }
            } else { //mobile
                vin = vin.split(' ')[vin.indexOf('VIN:') + 1];
            }

            result.vin = result.vin ? result.vin.trim() : vin.trim();
        },
        stock: function(data, result) {
            var stock = '';
            if (!data.stock.textContent) {
                stock = data.stock;
            } else {
                stock = data.stock.textContent.trim();
            }

            if (href.search(/mobile/) == -1) { //desktop
                if (stock.indexOf(':') > -1) {
                    stock = stock.split(':')[1];
                }
                result.stock = stock.trim();
            } else { //mobile
                stock = stock.toLowerCase().split(' ');
                var stockLocation = stock.indexOf('stock');
                if (stock[stockLocation + 1].indexOf(':') > -1) {
                    stock = stock[stockLocation + 2];
                } else {
                    stock = stock[stockLocation + 1];
                };
                result.stock = stock.trim();
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set prependTo
    options.prependTo = '#vdetails div[data-role="content"]';

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
