// interfaces/types
import type { WidgetEvent } from '../../utils/widgetEvent';
import type { IAccelerateWindow } from '../../models/IAccelerateWindow';
import type { SdpWidgetInitialization } from '../types/initializationInterfaces';
import type { SaveOffer, SaveOfferResults } from '../types/saveOfferTypes';

// utils
import { LoggerBase, IWidgetNewRelicEvent } from '../../utils/logger';

declare let window: IAccelerateWindow;

export class SDPLogger extends LoggerBase {
    protected readonly WIDGET_STARTED_EVENT: WidgetEvent = 'opensdp_started';
    protected readonly WIDGET_FINISHED_EVENT: WidgetEvent = 'opensdp_finished';
    protected readonly WIDGET_DURATION: WidgetEvent = 'opensdp_open_modal_duration';
    protected readonly SAVE_OFFER_STARTED_EVENT: WidgetEvent = 'opensdp_save_offer_started';
    protected readonly SAVE_OFFER_FINISHED_EVENT: WidgetEvent = 'opensdp_save_offer_finished';
    protected readonly SAVE_OFFER_DURATION: WidgetEvent = 'opensdp_save_offer_duration';
    protected readonly LOAD_SDP_STARTED_EVENT: WidgetEvent = 'opensdp_sdp_load_started';
    protected readonly LOAD_SDP_FINISHED_EVENT: WidgetEvent = 'opensdp_sdp_load_finished';
    protected readonly LOAD_SDP_DURATION: WidgetEvent = 'opensdp_sdp_load_duration';

    constructor() {
        super('AccelerateSDPWidgetLogger');
    }

    public static getLogger(): SDPLogger {
        let logger: SDPLogger;

        if (window.sdpWidgetLogger) {
            // Checks if logger already initialized
            logger = window.sdpWidgetLogger;
        } else {
            logger = new SDPLogger();
            window.sdpWidgetLogger = logger; // for global access
        }

        return logger;
    }

    public static clearCache(): void {
        window.sdpWidgetLogger = undefined;
    }

    public updateContext(options: { context: SdpWidgetInitialization }) {
        const { context } = options;
        const { offer, isMobile, version, hooks } = context;
        const { dealer, vehicle } = (offer || {}) as SaveOffer;
        const { vin, connectionId, sponsor: sponsorResults, dealerId: resultsDealerId } = (offer || {}) as SaveOfferResults;
        const { id: dealerId, sponsor: sponsorDealer } = dealer || {};
        const sponsor = sponsorDealer || sponsorResults || 'ddc';

        super.updateContext({
            context: {
                dealerId: dealerId ? dealerId.toString() : resultsDealerId?.toString?.(),
                vehicleData: {
                    vdpUrl: vehicle?.urls?.vdpUrl,
                    ownerId: dealer?.ownerId,
                    sponsor
                },
                version,
                websiteProviderId: sponsor,
                hooks,
                isMobile,
                vin,
                connectionId
            }
        });
    }

    protected publishDurationsToNewRelic(eventMap: { [key: string]: IWidgetNewRelicEvent }) {
        super.publishDurationsToNewRelic(eventMap);

        const saveStarted = eventMap[this.SAVE_OFFER_STARTED_EVENT];
        const saveFinished = eventMap[this.SAVE_OFFER_FINISHED_EVENT];
        this.publishDurationEvents(this.SAVE_OFFER_DURATION, saveStarted, saveFinished);

        const loadStarted = eventMap[this.LOAD_SDP_STARTED_EVENT];
        const loadFinished = eventMap[this.LOAD_SDP_FINISHED_EVENT];
        this.publishDurationEvents(this.LOAD_SDP_DURATION, loadStarted, loadFinished);
    }
}
