var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        model: '',
        trim: ''
    };

    if (!options.model) {
        data.model = data.trim = document.querySelector('.vdp-veh-title') || document.querySelector('.vdp .vdp-heading');

        //logging
        shimUtils.callValueQuerySelector(data, options);

        //define tasks to get data
        var tasks = {
            model: function(data, result) {
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            },
            trim: function(data, result) {
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            }
        };

        //call continue to get data
        options = shimUtils.continue(data, tasks, options);
    }

    return options;
};
