// Commenting out shim for now.  Need to figure out how to structure this with VIN site as well
// This is for the FordDirect store but is breaking the widget on VIN.
// Need to send deals to different stores based on the make
var convertDealerId = require('./utils/odanielgroup.utils');

var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    var vehicleOverviewLabel = document.querySelectorAll('.dl-horizontal dt');
    var vehicleOverviewValue = document.querySelectorAll('.dl-horizontal dd');

    if(vehicleOverviewLabel.length===0){
        var vehicleOverview = document.querySelector('.vehicle-info');
    }

    var pricingTable = document.getElementById('pricing') || '';

    if(pricingTable){
        pricingTable = pricingTable.querySelectorAll('div');
    }

    var overviewArrLabel = Array.prototype.map.call(
        vehicleOverviewLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArrValue = Array.prototype.map.call(
        vehicleOverviewValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewPrice = Array.prototype.map.call(
        pricingTable,
        function(obj, i, arr) {
            return obj;
        }
    );

    if (overviewArrLabel.length && overviewArrValue.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArrLabel.filter(function(row, index, array) {

            var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'vin':
                    data.vin = overviewArrValue[index].textContent.replace(replaceRegex, '').toLowerCase().trim();
                    break;

                case 'stock id':
                    data.stock = overviewArrValue[index].textContent.replace(replaceRegex, '').toLowerCase().trim();
                    break;

                case 'mileage':
                    data.mileage = overviewArrValue[index].textContent.replace(replaceRegex, '').toLowerCase().trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if (overviewPrice.length) {

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewPrice.filter(function(row, index, array) {

            var hasStrike = row.querySelector('.strike');
            var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().trim().split(':');
            var label = labelAndValue[0];
            if(hasStrike){
                var value = hasStrike.textContent.replace(replaceRegex, '').toLowerCase().trim();
            }else{
                var value = labelAndValue[1];
            }

            switch (label.trim()) {
                case 'msrp':
                    data.retailPrice = value.trim();
                    break;

                default:
                    break;

            }
            return true;
        });

    }

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('[itemprop="price"]')
    }

    data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-details .vehicle-header');

    data.vehicleStatus = document.URL;

    var img = document.getElementById('image-viewer') || '';
    if(img){
        data.img = img.querySelector('img');
    }

    var year = document.getElementById('year_car') || '';
    if(year){
        data.year = year;
    };
    var make = document.getElementById('car_make') || '';
    if(make){
        data.make = make;
    };
    var model = document.getElementById('car_model') || '';
    if(model){
        data.model = model;
    };
    var trim = document.getElementById('car_trims') || '';
    if(trim){
        data.trim = trim;
    };
    var price = document.getElementById('car_price') || '';
    if(price){
        data.listedPrice = price;
    };
    var vehicleStatus = document.getElementById('inv_type') || '';
    if(vehicleStatus){
        data.vehicleStatus = vehicleStatus;
    };
    var vin = document.getElementById('metrics_vin') || '';
    if(vin){
        data.vin = vin;
    };


    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if(data.listedPrice.value){
                data.listedPrice = data.listedPrice.value;
            }
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            if(data.year.value){
                result.year = parseInt(data.year.value, 10);
            }else{
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            }
        },
        make: function(data, result) {
            if(data.make.value){
                result.make = data.make.value.trim();
            }else{
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            }
        },
        model: function(data, result) {
            if(data.model.value){
                result.model = data.model.value.trim();
            }else{
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }
        },
        trim: function(data, result) {
            if(data.trim.value){
                result.trim = data.trim.value.trim();
            }else{
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if(data.vin.value){
                data.vin = data.vin.value.trim();
            };
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            if(data.vehicleStatus.value){
                data.vehicleStatus = data.vehicleStatus.value;
            };
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus.indexOf('new')>-1){
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    options = convertDealerId(options);

    return options;

};
