/**
 * MMD-1654: Selfhosted dealer site with many MMD IDs.  Need to replicate the shim across all dealerIds.
 * ALERT: When changing this shim, be sure to test/change all the following IDs as well
 * IDs: 807, 809, 810, 811, 812, 818, 819
 */

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        retailPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    var vehicleDetails = document.querySelectorAll('table.car-detail-table tbody tr');

    if (vehicleDetails.length > 0 ) {
        var detailsArr = Array.prototype.map.call(
            vehicleDetails,
            function(obj, i, arr) {
                return obj;
            }
        );

        var collector = detailsArr.filter(function(row, index, array) {
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/\s/g,'').toLowerCase();
            switch(label) {
                case 'price':
                    data.listedPrice = array[index].children[1];
                    break;
                case 'msrp':
                    data.retailPrice = array[index].children[1];
                    break;
                case 'year':
                    data.year = array[index].children[1];
                    break;
                case 'make':
                    data.make = array[index].children[1];
                    break;
                case 'model':
                    data.model = array[index].children[1];
                    break;
                case 'trim':
                    data.trim = array[index].children[1];
                    break;
                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;
                case 'stock':
                    data.stock = array[index].children[1];
                    break;
                case 'vin':
                    data.vin = array[index].children[1];
                    break;
            }
            return true;
        });
    }

    data.vehicleStatus = location.pathname.toLowerCase();

    data.img = document.querySelector('div#car-carousel .carousel-inner .item img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = data.listedPrice.textContent;
        },
        retailPrice: function(data, result) {
            result.retailPrice = data.retailPrice.textContent;
        },
        year: function(data, result) {
            result.year = data.year.textContent;
        },
        make: function(data, result) {
            result.make = data.make.textContent;
        },
        model: function(data, result) {
            result.model = data.model.textContent;
        },
        trim: function(data, result) {
            result.trim = data.trim.textContent;
        },
        mileage: function(data, result) {
            result.mileage = data.mileage.textContent;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if(status && status.search(/new/g)) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        vin: function(data, result) {
            result.vin = data.vin.textContent;
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
