var shimUtils = require('./utils');

var magic = module.exports = {
    containsLetterAndNumber: function (input) {
        var letter = /[a-zA-Z]/;
        var number = /[0-9]/;
        if(input.match(letter) && input.match(number))
            return true;
        else return false;
    },
    //Automagically find VIN on the page without a selector (works well)
    getVinNoSelector: function () {
        var selector = document.getElementsByTagName("html");
        if(selector.innerText)
            selector = selector[0].innerText;
        else if(selector.textContent)
            selector = selector[0].textContent;
        //using regex to match a 17 digit vin number
        var vin = selector.match(/[^ioq\s\W]{17}/igm);

        if (vin === null) return '';
        else if(vin.length == 1) return vin[0];
        else{
            //if more than one vin is found. narrow down result. could use more work
            for(var i=0; i < vin.length; i++){
                //vin must contain one letter and number.
                if(magic.containsLetterAndNumber(vin[i])){
                    return vin[i];
                }
            }
        }
        return '';
    },

    getVin: function (selector) {
        //using regex to match a 17 digit vin number
        var regex  = /[^ioq\s\W]{17}/igm;
        var vin;

        if(typeof selector === 'object'){
            if(selector.innerText)
                vin = selector.innerText;
            else if(selector.textContent)
                vin = selector.textContent
            if(vin)
                vin = vin.match(regex);
        }

        if (vin === null) return '';
        else if(vin.length === 1) return vin[0];
        else{
            //if more than one vin is found. narrow down result. could use more work
            for(var i=0; i < vin.length; i++){
                //vin must contain one letter and number.
                if(magic.containsLetterAndNumber(vin[i])){
                    return vin[i];
                }
            }
        }

        return magic.getVinNoSelector();
    },

    getYear: function (vin) {
        //can we use this if date is wrong on clients computer?
        //only an issue right now because date goes up to 2030 but we can go to 2027
        //year list will need to be updated in 2027 to include new years.
        var date = new Date();
        var year = date.getFullYear();

        switch (vin.substr(9,1)) {
            case '1':
                return 2001;
            case '2':
                return 2002;
            case '3':
                return 2003;
            case '4':
                return 2004;
            case '5':
                return 2005;
            case '6':
                return 2006;
            case '7':
                return 2007;
            case '8':
                return 2008;
            case '9':
                return 2009;
            case 'A':
                return 2010;
            case 'B':
                return 2011;
            case 'C':
                return 2012;
            case 'D':
                return 2013;
            case 'E':
                return 2014;
            case 'F':
                return 2015;
            case 'G':
                return 2016;
            case 'H':
                return 2017;
            case 'J':
                return 2018;
            case 'K':
                return 2019;
            case 'L':
                return 2020;
            case 'M':
                return 2021;
            case 'N':
                return 2022;
            case 'P':
                return 2023;
            case 'R':
                return 2024;
            case 'S':
                return 2025;
            case 'T':
                return 2026;
            case 'V':
                return 2027;
            case 'W':
                year = year >= 2028 ? 2028 : 1998;
                break;
            case 'X':
                year = year >= 2029 ? 2029 : 1999;
                break;
            case 'Y':
                year = year >= 2030 ? 2030 : 2000;
                break;
        }

        return year;
    },

    getMake: function (selector) {

        var regex = /(make)([\s\S]*?)([\w-\.]+)(([\s])(benz|martin|rover|romeo|royce))?/ig;
        var make;

        if(typeof selector === 'object'){
            if(selector.innerText)
                make = selector.innerText;
            else if(selector.textContent)
                make = selector.textContent;
            if(make)
                make = make.match(regex);
        }

        if(make) {
            make = make[0].replace(/make/gi,'').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim();
        }
        else return '';

        return make;
    },
    //Need to fix for "Model Code: XXXX"
    getModel: function (selector) {

        var regex = /(model)([\s\S]*?)([\w-\.]+)/ig;
        var model;

        if(typeof selector === 'object'){
            if(selector.innerText)
                model = selector.innerText;
            else if(selector.textContent)
                model = selector.textContent;
            if(model)
                model = model.match(regex);
        }

        //remove "model code"
        if(model.length > 1){
            var modelArr = model.filter(function(element, index, array){
                if(element.toLowerCase().indexOf('model code') > -1){
                    return false;
                }
                return true;
            });
            model = modelArr;
        }

        if(model) {
            model = model[0].replace(/model/gi,'').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim();
        }
        else return '';

        return model;
    },

    getTrim: function (selector) {

        var regex = /(trim)([\s\S]*?)([\w-\.]+)/ig;
        var trim;

        if(typeof selector === 'object'){
            if(selector.innerText)
                trim = selector.innerText;
            else if(selector.textContent)
                trim = selector.textContent;
            if(trim)
                trim = trim.match(regex);
        }

        if(trim) {
            trim = trim[0].replace(/trim/gi,'').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim();
        }
        else return '';

        return trim;
    },
    //currently assumes model and trim are one word. we can easily concatenate the
    //rest of the end of the array trim if we want trim to be more than one word.
    //biggest issue is models with more than one word.
    getMMT: function (data) {
        var arr = ["make","model","trim"];
        //assuming data.make has entire make model trim in title;
        var cleanTitle = shimUtils.removeLocationFromVehicleTitle(data.make);
        //clean make/model/trim after we grab selector
        data.make = data.model = data.trim = '';
        var yearIndex = cleanTitle.indexOf(data.year);

        //assumes title is formatted (year make model trim), start title from make.
        if(yearIndex > -1 ){
            cleanTitle = cleanTitle.substr(yearIndex+4);
        }
        //else if no year assuming make/model/trim starts at beginning of string
        var regex = /([\w-\.]+)(([\s])(benz|martin|rover|romeo|royce))?/ig;
        cleanTitle = cleanTitle.match(regex);

        for(var i=0; i<cleanTitle.length; i++){
            //break once title doesn't have a make/model/trim
            if(i >= arr.length) break;
            //trim is rest of the array
            if(arr[i] === "trim"){
                data[arr[i]] = cleanTitle.slice(2).toString().replace(/,/g,' ');
            }
            else data[arr[i]] = cleanTitle[i];
        }

        return data;
    },
    //edge case. "mileage: 23,023 miles stock: 123a34" passes because the first item gets returned.
    getMileage: function (selector) {

        var regex = /(mileage|odometer|miles)([\s\S]*?)\d+\s/ig;
        var mileage;

        if(typeof selector === 'object'){
            if(selector.innerText)
                mileage = selector.innerText;
            else if(selector.textContent)
                mileage = selector.textContent;
            if(mileage)
                mileage = mileage.match(regex);
        }

        if(mileage) {
            mileage = mileage[0].replace(/(mileage|odometer)/gi,'').replace(/[&\/\\#,+()$~%'":*?<>{}]/gi, '').trim();
        }
        else return '';

        return mileage;
    },

    //If the table has words "Stock photos", it'll fail. In the case that we want it to find stock automagically, we can make sure the item
    //contains a special symbol such as ": or "#:"
    getStock: function (selector) {

        var regex = /(stock|stk)([\s]*no(\.)?)?([\s\S]*?)(\w+)/ig;
        var stock;

        if(typeof selector === 'object'){
            if(selector.innerText)
                stock = selector.innerText;
            else if(selector.textContent)
                stock = selector.textContent;
            if(stock)
                stock = stock.match(regex);
        }

        if(stock){
            stock = stock[0].replace(/(stock|stk)([\s]*no(\.)?)?/gi,'').replace(/\W+/gi,'');
        }
        return stock;
    },

    getVehicleStatus: function() {
        //1 for new, 0 for used
        var url = document.URL;
        if(url.search(/new/gi) >= 0) return 'new';
        else return 'used';
    },
    //Have not coded for selector. Wanted to try to automagically find image. Currently not in use.
    getImages: function() {
        //check for alt tags with same attributes as car for sale
        //check for carousel images
        var img = document.querySelector(".jcarousel img") || document.querySelector(".carousel img") || document.querySelector(".gallery img");

        if(img !== null) return img.src;
        //check that theres thumb_ in the image name and remove it to get the full image.
        return '';
    },
};
