
/*	
* DOM tag data attributes to object.
*
* @param {dom}, the element to pull attributes from.
*/
var camelCase = require('./hyphenToCamel');

module.exports = function(el) {
	var a, attrs, _i, _len, _ref;
    
    attrs 	= {};
    _ref	= (el)? el.attributes:[];
    
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
		a = _ref[_i];
		if (a.name.indexOf('data-') === -1) {
			continue;
		}
		attrs[camelCase(a.name.replace("data-", ""))] = a.value;
    }
    return attrs;
}