//Promax
var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.getElementById('infolist') || document.querySelector('.mod-vehicle-info .row');
    var vehicleOverviewLabel = '';
    var vehicleOverviewValue = '';

    if(vehicleOverview){
        vehicleOverviewLabel = vehicleOverview.querySelectorAll('.infolabel');
        if(vehicleOverviewLabel.length === 0){
            vehicleOverviewLabel = vehicleOverview.querySelectorAll('.title');
        }
        vehicleOverviewValue = vehicleOverview.querySelectorAll('.infoinfo');
        if(vehicleOverviewValue.length === 0){
            vehicleOverviewValue = vehicleOverview.querySelectorAll('.value');
        }
    }
    var pricingTable = document.getElementById('infoprice') || '';
    if(pricingTable){
        pricingTable = pricingTable.querySelectorAll('.info-price-line');
    }

    var overviewArrLabel = Array.prototype.map.call(
        vehicleOverviewLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArrValue = Array.prototype.map.call(
        vehicleOverviewValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewPrice = Array.prototype.map.call(
        pricingTable,
        function(obj, i, arr) {
            return obj;
        }
    );

    var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;
    var siteOptionsCollector = [];

    if (overviewArrLabel.length && overviewArrValue.length) {

        siteOptionsCollector = overviewArrLabel.filter(function(row, index, array) {

            var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {

                case 'mileage':
                    data.mileage = overviewArrValue[index].textContent.replace(replaceRegex, '').trim();
                    break;

                case 'stock':
                    data.stock = overviewArrValue[index].textContent.replace(replaceRegex, '').trim();
                    break;

                case 'vin':
                    data.vin = overviewArrValue[index].textContent.replace(replaceRegex, '').trim();
                    break;

                case 'type':
                    data.vehicleStatus = overviewArrValue[index].textContent.replace(replaceRegex, '').trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if (overviewPrice.length) {

        replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        siteOptionsCollector = overviewPrice.filter(function(row, index, array) {

            var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().split(':');
            var label = labelAndValue[0].trim();
            var value = labelAndValue[1].trim();

            switch (label) {
                case 'internet price':
                    data.listedPrice = row.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'kelley blue book value':
                    data.retailPrice = row.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'msrp':
                    data.retailPrice = row.textContent.replace(replaceRegex, '').trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('.price');
    }

    if(!data.retailPrice){
        data.retailPrice = document.querySelector('.price-old');
    }

    var img = document.getElementById('photomain') || '';
    if(img){
        data.img = img.querySelector('img');
    }

    if(!data.vehicleStatus){
        data.vehicleStatus = document.querySelector('.vehiclepreview .vehiclepreviewdata div:nth-child(2) .wsefvalue');
    }

    data.year = data.make = data.model = data.trim = document.getElementById('infobase')
        || document.querySelector('.vehicle-title');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            if(data.vehicleStatus.textContent){
                data.vehicleStatus = data.vehicleStatus.textContent;
            }
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus === 'new') {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
