var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
      mileage : '',
      stock : '',
      model: '',
      trim: '',
    };

    if(document.URL.search(/mobi/gi) > -1){
        //Miles and Stock both contained in this one div.
        data.mileage = data.stock = document.querySelector('div.itemBootomInfo');
    }
    else {
        //get mileagae
        var mileageLabelArr = document.querySelectorAll('#vehicleinfodesc .DescriptionColumnLabel');
        var mileageValueArr = document.querySelectorAll('#vehicleinfodesc .DescriptionColumnContent');
        var label = '';

        for(var i=0; i < mileageLabelArr; i++){
            label = mileageLabelArr[i].textContent.toLowerCase();
            if(label.indexOf('mileage') > -1 && mileageValueArr.length > i){
                data.mileage = mileageValueArr[i];
                break;
            }
        }
        //get stock
        var stockArr = document.querySelectorAll('.vehicleNumbers .floatRight div');
        for(i=0; i < stockArr.length; i++){
            label = stockArr[i].textContent.toLowerCase();
            if(label.indexOf('stock') > -1){
                data.stock = stockArr[i];
                break;
            }
        }

        if(!options.model){
            data.model = document.querySelector('div#breadCrumbContent li:nth-child(4) .link')
                || document.querySelector('.middleModelAndDescTop');
        }else {
            data.model = options.model;
        }

        //get trim
        data.trim = document.querySelector('div#DetailVehicleDescription div.fs18');

        //logging
        shimUtils.callValueQuerySelector(data,options);
    }

    //define tasks to get data
    var tasks = {
        mileage : function(data,result){
            var mileage = data.mileage.innerText
            mileage = mileage.match(/(miles)([\s\S]*?)\d+\s/gi);

            if(mileage){
                mileage = mileage[0].replace(/(miles)([\s\S]*?)/gi,'').replace(/\D+/g,'');
            }
            else{
                mileage = data.mileage.textContent.replace(/,/g,'');
            }

            result.mileage = parseInt(mileage);
        },
        stock : function(data,result){
            var stock = data.stock.innerText;
            stock = stock.match(/(stock|stk)([\s]*no(\.)?)?([\s\S]*?)(\w+)/ig);

            if(stock){
                stock = stock[0].replace(/(stock|stk)([\s]*no(\.)?)?([\s\S]*?)/gi,'').replace(/\W+/g,'');
            }
            else{
                stock = data.stock.textContent.split(':')[1].trim();
            }

            result.stock = stock;
        },
        model: function(data,result){
            if(data.model.split(' ').length>1){
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }else {
                result.model = options.model;
            }
        },
        trim: function(data,result){
            result.trim = data.trim.textContent.trim();
        }

    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
