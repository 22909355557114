// MWO-2071 - This shim makes a lot of assumptions given this is only used
// for a single site and only used vehicles.  May need re-work if need to be
// used across more sites or vehicleStatuses.

var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    // Collecting specs for both desktop and mobile
    // Desktop: '#vehicle_details table tr'
    // Mobile: '.vdp_details table tr'
    var vehicleSpecs = document.querySelectorAll('#vehicle_details table tr, .vdp_details table tr');

    if (vehicleSpecs) {
        var vehicleSpecsArr = [];

        vehicleSpecsArr = Array.prototype.map.call(
            vehicleSpecs,
            function(obj, i, arr) {
                return obj;
            }
        );

        if (vehicleSpecsArr.length) {
            var siteOptionsCollector = vehicleSpecsArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
                    switch (label) {
                        case 'price':
                            data.listedPrice = array[index].children[1];
                            break;

                        case 'stock number':
                            data.stock = array[index].children[1];
                            break;

                        case 'mileage':
                            data.mileage = array[index].children[1];
                            break;

                        case 'vin':
                            data.vin = array[index].children[1];
                            break;

                        case 'year':
                            data.year = array[index].children[1];
                            break;

                        case 'make':
                            data.make = array[index].children[1];
                            break;

                        case 'model':
                            data.model = array[index].children[1];
                            break;

                    }
                }
            });
        }
    }

    // get img
    // Desktop: '#large_image'
    // Mobile: '.vdp_image img'
    data.img = document.querySelector('#large_image, .vdp_image img');

    // get year, make, model, trim if not already set
    // on mobile, ymm can be set from the vehicleSpecs table
    // Desktop: 'div#vehicle_focus h1'
    // Mobile: 'h2.autos_title'
    var title = document.querySelector('div#vehicle_focus h1, h2.autos_title');

    if(!data.year) {
        data.year = title;
    }

    if(!data.make) {
        data.make = title;
    }

    if(!data.model) {
        data.model = title;
    }

    if(!data.trim) {
        data.trim = title;
    }

    // get vehicleStatus
    // Setting this to used because sponsor currently only has used inventory
    // Mobile site does not clearly have vehicleStatus on the page so it
    // currently simplifies the shim.
    data.vehicleStatus = 'used';

    // logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data,result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year : function(data,result) {
            if (data.year.nodeName === 'H1' || data.year.nodeName === 'H2') {
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            } else if (data.year.nodeName === 'TD') {
                // assume collected from vehicleSpecs table
                result.year = data.year.textContent;
            }
        },
        make : function(data,result) {
            if (data.make.nodeName === 'H1' || data.make.nodeName === 'H2') {
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            } else if (data.make.nodeName === 'TD') {
                // assume collected from vehicleSpecs table
                result.make = data.make.textContent;
            }
        },
        model : function(data,result) {
            if (data.model.nodeName === 'H1' || data.model.nodeName === 'H2') {
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            } else if (data.model.nodeName === 'TD') {
                // assume collected from vehicleSpecs table
                result.model = data.model.textContent;
            }
        },
        trim : function(data, result) {
            if (data.trim.nodeName === 'H1' || data.trim.nodeName === 'H2') {
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            } else if (data.trim.nodeName === 'TD') {
                // assume collected from vehicleSpecs table
                result.trim = data.trim.textContent;
            }
        },
        mileage : function(data,result){
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus : function(data,result){
            // Assume vehicleStatus equals 'used'
            // so far all sites have been used only
            // not setting new for now until needed
            var status = data.vehicleStatus;
            if (status === 'used') {
                result.vehicleStatus = 0;
            }
        },
        vin : function(data,result){
            var vin = data.vin;
            result.vin = vin.textContent.trim();
        },
        stock : function(data, result){
            var stock = data.stock;
            result.stock = stock.textContent.trim();
        },
        img : function(data,result){
            result.img = data.img.src;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data,tasks,options);

    //set URL
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    return options;
};
