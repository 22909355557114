var shimUtils = require('../utils');

module.exports = function(options) {

    var listedPrice = document.querySelector('.pricing .stackedConditionalFinal');

    if(listedPrice) {
        listedPrice = listedPrice.textContent.replace(/\D/g, '');
        options.listedPrice = parseInt(listedPrice, 10);
    }

    return options;

}
