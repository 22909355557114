// interfaces/types
import type { FetchOfferDealXg } from '../types/fetchOfferInterfaces';

// utils
import { checkRequiredFieldsAnd } from './utils';

/**
 * Is the data of type FetchOfferDealXg
 * @param data Object to check
 */
export function isFetchOfferDealXg(data: any): data is FetchOfferDealXg {
    return data && checkRequiredFieldsAnd(data, ['dealXgId']);
}
