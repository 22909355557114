var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

   var data = {
       listedPrice: '',
       retailPrice: ''
   };

   var pricingTable = document.querySelectorAll('.srpVehiclePricing li');

   var pricingArr = Array.prototype.map.call(
       pricingTable,
       function(obj,i,arr){
           return obj;
       }
   );

   var siteOptionsArr = pricingArr;

   var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
       if(array[index].children.length > 1) {
           var label =  array[index].children[0].textContent.trim().toLowerCase().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
           switch(label) {

               case 'list price':
                   data.listedPrice = array[index].children[1];
                   break;

               default:
           }
       }
       return true;
   });

   if(!data.retailPrice){
       data.retailPrice = document.querySelector('.srpVehiclePricing .msrpPrice')
   }



    //logging
   shimUtils.callValueQuerySelector(data, options);

    //define tasks to get Data
    var tasks = {
        retailPrice: function(data, result){
            result.retailPrice = shimUtils.getInteger(data.retailPrice, 10);
        },
        listedPrice: function(data, result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice, 10);
        }
    };

    options = shimUtils.continue(data,tasks,options);

    return options;

};
