export const validateSchema = (options: any = {}) => {
    // validate mileage
    // remove all non digits if it is a string
    // and parse it
    if (options.mileage && typeof options.mileage == 'string') {
        options.mileage = options.mileage.replace(/\D/g, '');
        options.mileage = parseInt(options.mileage, 10);
    }

    // validate listedPrice
    // remove all non digits if it is a string
    // and parse it
    if (options.listedPrice && typeof options.listedPrice == 'string') {
        options.listedPrice = options.listedPrice.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        options.listedPrice = parseInt(options.listedPrice, 10);
    }

    // validate retailPrice
    // remove all non digits if it is a string
    // and parse it
    if (options.retailPrice && typeof options.retailPrice == 'string') {
        options.retailPrice = options.retailPrice.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        options.retailPrice = parseInt(options.retailPrice, 10);
    }

    // validate dealerId
    // some dealers incorrectly pass an array for dealerid
    // extract first element if it is an array
    if (options.dealerId && options.dealerId.constructor === Array) {
        options.dealerId = options.dealerId[0];
    }

    // validate vehicleStatus
    // some dealers incorrectly pass an array for vehicleStatus
    // extract first element if it is an array
    if (options.vehicleStatus && options.vehicleStatus.constructor === Array) {
        options.vehicleStatus = options.vehicleStatus[0];
    }

    // validate sponsor
    // some dealers pass sponsor with curly braces
    // remove them
    if (options.sponsor && (options.sponsor.indexOf('{') != -1 || options.sponsor.indexOf('}') != -1)) {
        options.sponsor = options.sponsor.replace(/[{}]/g, '');
    }

    // validate stock
    if (options.stock && options.stock.constructor === String && options.stock.indexOf('$') != -1) {
        delete options.stock;
    }

    // validate trim
    if (options.trim && options.trim.constructor === String && options.trim.indexOf('$') != -1) {
        delete options.trim;
    }

    // validate vin
    if (options.vin && options.vin.indexOf(' ') != -1) {
        delete options.vin;
        console.log({
            msg: 'Unable to collect vin from dealer site'
        });
    }

    if ((!options.listedPrice || options.listedPrice == ' ') && (!options.retailPrice || options.retailPrice === ' ')) {
        throw new Error('Listed Price and Retail Price are not set. Accelerate Experience will not load!');
    }
    // MWO-127: rules to ensure widget shows as much as possible.
    // Executing this logic after all other validation has occured
    if (!options.listedPrice || options.listedPrice == ' ') {
        options.listedPrice = options.retailPrice;
    }

    if (!options.retailPrice || options.retailPrice === ' ') {
        delete options.retailPrice;
    }

    if (
        !options.condition &&
        (!options.vehicleStatus || options.vehicleStatus == ' ') &&
        options.mileage &&
        options.mileage > 500
    ) {
        options.vehicleStatus = 0;
    }

    if (!options.mileage || options.mileage == ' ' || options.mileage < 1) {
        options.mileage = 1;
    }

    if (!options.certifiedStatus) {
        options.certifiedStatus = false;
    }

    // MMD-5234 - Normalizing vehicle details url instead of having so many options
    if (options.url) {
        options.vehicleUrl = options.url;
        delete options.url;
    } else {
        options.vehicleUrl = document.URL;
    }

    // validate listingCode
    if (options.vehicleLocationId) {
        options.listingCode = options.vehicleLocationId;
    } else if (options.refId && ['atc', 'kbb'].indexOf(options.sponsor) !== -1) {
        options.listingCode = options.refId;
    }

    if (!options.condition && !options.vehicleStatus) {
        // this is for AMSI and some dealers passing data in the wrong field
        options.vehicleStatus = options.vehicleCondition;
    }

    return options;
};
