'use strict';
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    // grab data from vehicle overview table
	var pricingTable = document.querySelector('.mainPriceContainer');

    if(pricingTable){

        if(pricingTable){
            var pricingTableLabel = [];
            var pricingTableValue = [];

            //pulls out and separates labels and values
            for(var i=0;i<pricingTable.children.length;i++){
                if(pricingTable.children[i].classList[0].toLowerCase().indexOf('label')>-1){
                    pricingTableLabel.push(pricingTable.children[i]);
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('value')>-1){
                    pricingTableValue.push(pricingTable.children[i]);
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('text')>-1){
                    pricingTableValue.push(pricingTable.children[i]);
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('originalpricecontainer')>-1){
                    pricingTableLabel.push(pricingTable.children[i].children[0]);
                    pricingTableValue.push(pricingTable.children[i].children[1]);
                }
            }

            var pricingLabelArr = Array.prototype.map.call(
                pricingTableLabel,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var pricingValueArr = Array.prototype.map.call(
                pricingTableValue,
                function(obj, i, arr){
                    return obj;
                }
            );

            var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
                var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                var value = pricingValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                switch(label) {
                    case 'tamaroff family price':
                        data.listedPrice = value;
                        break;

                    default:
                }
                return true;
            });
        }
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = parseInt(data.listedPrice.replace(/[^0-9.]/g, ''),10);
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
