var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        dealerSavings : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    //get listedPrice and retailPrice
    var pricingTable = document.querySelectorAll('.price-wrap .price');
    var vehicleOverview = document.querySelectorAll('.detail-fields-prices .fields.pull-left .field');

    var overviewData = [];

    if(pricingTable.length){
        var overviewPrice = Array.prototype.map.call(
            pricingTable,
            function(obj, i, arr) {
                return obj;
            }
        );
    }

    if(vehicleOverview.length){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );
    }

    if(overviewPrice && overviewArr){
        overviewData = overviewArr.concat(overviewPrice);
    } else if(!overviewPrice && overviewArr){
        overviewData = overviewArr;
    } else if(overviewPrice && !overviewArr){
        overviewData = overviewPrice;
    };

    if(overviewData.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        overviewData = overviewData.concat(overviewPrice);

        var siteOptionsCollector = overviewData.filter(function(row, index, array) {
            if (row.children[0]) {
                var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock number':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'msrp':
                    case 'price':
                        data.retailPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'sale price':
                        data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'dealer discount':
                        data.dealerSavings = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    //get year, make, model, trim
    data.vehicleStatus = data.year = data.make = data.model = data.trim = document.querySelector('.title-row h4.pull-left');

    //get img
    data.img = document.querySelector('#detail-media .item img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings : function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year : function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus : function(data, result) {
            var title = shimUtils.titleTrimmer(data.vehicleStatus);
            result.vehicleStatus = title.status;
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock : function(data, result) {
            result.stock = data.stock;
        },
        vin : function(data, result) {
            result.vin = data.vin;
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
