var shimUtils = require('../utils');

module.exports = function(options) {

    var domain = location.href;

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        incentiveSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    if (domain.indexOf('m.')===-1) { //desktop
        // grab data from vehicle overview table
        var vehicleOverview = document.querySelectorAll('.vehicle-details li');
        if(!vehicleOverview.length)
            vehicleOverview = document.querySelectorAll('#vehicleTopLineInfo li');
        var pricingTable = document.querySelectorAll('.pricing div');

        //autorevo shim

        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var overviewPrice = Array.prototype.map.call(
            pricingTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var overviewData = [];

        if (overviewArr.length && overviewPrice.length) {
            overviewData = overviewArr.concat(overviewPrice);
        } else if (overviewArr.length && !overviewPrice.length) {
            overviewData = overviewArr;
        } else if (!overviewArr.length && overviewPrice.length) {
            overviewData = overviewPrice;
        };

        if (overviewData.length) {

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;
            var replaceRegex2 = /[&\/\\#,+()$~%'"*?<>{}]/g;

            var siteOptionsCollector = overviewData.filter(function(row, index, array) {
                if (row.children[1]) {
                    var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();
                    switch (label) {
                        case 'vin':
                            data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'stock':
                            data.stock = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'mileage':
                            data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'our price':
                            data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        default:
                            break;
                    }
                } else if (row.children[0] && !row.children[1]){
                    var labelAndValue = row.textContent.replace(replaceRegex2, '').toLowerCase().trim().split(':');
                    var label = labelAndValue[0];
                    var value = labelAndValue[1];
                    switch (label.trim()) {
                        case 'mileage':
                            data.mileage = value.trim();
                            break;

                        case 'vin':
                            data.vin = value.trim();
                            break;

                        case 'stock':
                            data.stock = value.trim();
                            break;

                        default:
                            break;
                    }
                }
                return true;
            });
        }
        if(document.querySelector('.vehicle-crumbs')){
            data.vehicleStatus = document.querySelector('.vehicle-crumbs');
        }

        if(!data.listedPrice)
            data.listedPrice = document.querySelector('#vehicleMainPriceRow');

        data.img = document.querySelector('.main-photo img')
            || document.querySelector('img#detailMainPhoto');
        data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-title')
            || document.querySelector('#vehicleDetailTitle');
    } else { //mobile
        data.listedPrice = document.querySelectorAll('.ui-block-b')[0];

        data.year = data.make = data.model = data.year = document.querySelector('div[data-role="content"] h2');

        data.mileage = document.querySelectorAll(".ui-block-b")[1];

        data.vin = document.querySelectorAll(".ui-block-b")[4];

        data.stock = document.querySelectorAll(".ui-block-b")[5];

        data.img = document.querySelector(".photo a img")
            || document.querySelector('#detailMainPhoto');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if(data.vin.textContent)
                data.vin = data.vin.textContent;
            result.vin = data.vin;
        },
        stock: function(data, result) {
            if(data.stock.textContent)
                data.stock = data.stock.textContent;
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            if(data.vehicleStatus.textContent){
                if(data.vehicleStatus.textContent.toLowerCase().indexOf('new')>-1){
                    result.vehicleStatus = 1
                }
            }else{
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;
    options.isShimmed = true;

    return options;
};
