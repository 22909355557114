var shimUtils = require('../../utils');

/**
 * Cobalt has a couple different templates for their sites.
 * This template type is determine by running ContextManager.getPageLayout() on the console
 */
var stdShim = require('./shim.std');
var flexShim = require('./shim.flex');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        incentiveSavings: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    /**
     * ContextManager is an object that is found on most all Cobalt sites
     * Will use it to collect the template and possibly other data from the site
     */
    var cm = window.ContextManager;

    // array of shims organized by the template type that is collected by the
    // ContextManager below
    var shims = {
        std: stdShim,
        flex: flexShim
    };

    // Check that ContextManager exists and the PageLayout function exists
    // which is the basis to determine which cobalt shim to execute
    if(cm && typeof (cm.getPageLayout) === 'function') {
        var pageLayout = cm.getPageLayout().split('_')[1].toLowerCase();

        // Cobalt mobile sites have a differnt identifier for pageLayout.  Need to
        // take into account mobile and apply correct shim (std vs flex)
        // Using isFlex to determine which layout to load
        // 2015.11.02 - std sites don't current have widget installed Mobile
        if(pageLayout === "mobile") {
            pageLayout = (cm.isFlex) ? 'flex' : 'std';
        }

        // execute the shim and pass data, options and ContextManager as arguments
        shims[pageLayout]({
            data: data,
            options: options,
            cm: cm
        });
    } else {
        // default to flex layout since majority of the sites use it
        // TODO: maybe use a dealer map to identify the template instead of pageLayout
        shims.flex({
            data: data,
            options: options,
            cm: cm
        });
    }

    options.isShimmed = true;

    //set url
    options.url = document.URL;

    // TODO: This should probably be based on the shim template that executes
    // set container to append widget on mobile
    if (!!document.querySelector('a[href="#top"]')) {
        options.appendTo = ".Inventory_Details_Vehicle_Glance";
    }

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
};
