import { IAccelerateWindow } from '../models/IAccelerateWindow';
import * as qs from 'qs';
import { getScriptConfig } from '../utils';
import { prepareDataFromLegacyObjects } from '../bootstrap_utils';
import { ICADigitalRetailObject } from 'lib/models/ICADigitalRetailObject';
/**
 * Use this service to interact with the VDP page
 * Common tasks - add scripts
 * @export
 * @class VdpService
 */
export class VdpService {
    private root: IAccelerateWindow;
    constructor(accelerateWindow: IAccelerateWindow) {
        this.root = accelerateWindow;
    }
    /**
     * VDP url
     *
     * @readonly
     * @memberof VdpService
     */
    public get url() {
        return this.root.location.href;
    }
    /**
     * Should add a script to VDP Page
     *
     * @memberof VdpService
     */
    /**
     *
     * url of the script to be added to VDP
     * @param {string} url
     * @memberof VdpService
     */
    public addScript(url: string, elementId: string) {
        const doc = this.root.document;
        const customScript = doc.createElement('script');
        customScript.setAttribute('src', url);
        customScript.setAttribute('id', elementId);
        customScript.async = true;
        doc.body.appendChild(customScript);
    }

    public getQueryParams() {
        let queryParams = {};
        const queryString = this.root.location.search;
        if (queryString) {
            queryParams = qs.parse(queryString.substr(1));
        }

        return queryParams;
    }

    public getQueryParam(name: string) {
        let param;
        const params = this.getQueryParams();
        if (params[name]) {
            param = params[name];
        }
        return param;
    }
    /**
     * Returns Accelerate Window where Widget needs to load
     *
     * @memberof VdpService
     */
    public getAccelerateWindow = () => {
        return this.root;
    };

    /**
     * Returns Vdp Document
     *
     * @memberof VdpService
     */
    public getDocument = () => {
        return this.getAccelerateWindow().document;
    };

    /**
     * Returns Current Script
     *
     * @memberof VdpService
     */
    public getCurrentScript = () => {
        return this.getDocument().currentScript;
    };

    public getCADigitalRetailObject = (): ICADigitalRetailObject => {
        return this.getAccelerateWindow().mmd || this.getAccelerateWindow().CADigitalRetailObject || {};
    };

    public getBootstrapData = (): ICADigitalRetailObject => {
        let bootStrapData;

        // Prepare data from page for Bootstrapping Digital Retailing
        const CADigitalRetailObject: ICADigitalRetailObject = this.getCADigitalRetailObject();
        const scriptAttrObject: any = getScriptConfig();
        const version = CADigitalRetailObject.version || scriptAttrObject.version;

        const bootStrapInputData = {
            CADigitalRetailObject,
            scriptAttrObject,
            version
        };

        // This new version seeks to have a single object that widget-js creates and passes
        // data to any client it bootstraps.  The shape of the data will always be the same
        // internally
        if (this.isAccelerate(version)) {
            // Always prefer to use CADRO
            bootStrapData = CADigitalRetailObject;
        } else {
            // TODO: Need to deprecate reading from legacy objects
            bootStrapData = prepareDataFromLegacyObjects(bootStrapInputData) as ICADigitalRetailObject;
        }

        return bootStrapData;
    };

    public isAccelerate = (version: string) => {
        return version >= '5.0.0';
    };
}
