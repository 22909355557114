var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var vehicleStatusOnPage = document.querySelector('link[itemprop="itemCondition"]');
    if(vehicleStatusOnPage){
        vehicleStatusOnPage = (vehicleStatusOnPage.href.toLowerCase().indexOf('used')) > -1 ? 0 : 1;
    }
    var vehicleStatus = !!options.vehicleStatus ? options.vehicleStatus : vehicleStatusOnPage;

    var stockOnPage = document.querySelector('.vehiclenumber span:nth-child(3)');
    if(stockOnPage!==null){
        stockOnPage = !!(stockOnPage.textContent.indexOf('STOCK')>-1) ? stockOnPage.textContent.split(':')[1].trim() : '';
    }
    var stock = !!options.stock ? options.stock : stockOnPage;

    var listedPriceOnPage = document.querySelector('.realPriceText');
    listedPriceOnPage = !!listedPriceOnPage ? shimUtils.getInteger(listedPriceOnPage) : '';
    var listedPrice = !!options.listedPrice ? options.listedPrice%10 : listedPriceOnPage%10;

            if(stock && vehicleStatus===1){

                stock = stock.toLowerCase().trim().charAt(0);

                switch (stock) {
                    case 't':
                        options.dealerId = 975;
                        break;

                    case 'z':
                        options.dealerId = 1177;
                        break;

                    case 'm':
                        options.dealerId = 1068;
                        break;

                    case 'b':
                        options.dealerId = 1173;
                        break;

                    default:
                        options.dealerId = 967;
                        break;
                }
            }else if(listedPrice && vehicleStatus === 0){
                switch (listedPrice) {
                    case 1:
                        options.dealerId = 975;
                        break;

                    case 2:
                        options.dealerId = 1068;
                        break;

                    case 3:
                        options.dealerId = 1173;
                        break;

                    case 4:
                        options.dealerId = 1177;
                        break;

                    default:
                        options.dealerId = 967;
                        break;
                }
            };

    return options;
};
