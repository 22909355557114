var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '0',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.page-heading');

    data.img = document.querySelector('.thumbnail img');

    data.listedPrice = document.querySelector('.content h4');

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.table-responsive .table tbody tr');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );


    if (overviewArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children[1]) {
                var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'miles':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock number':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = data.vehicleStatus;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
