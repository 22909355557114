var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var location = document.querySelector('.contact-info .vcard .adr .postal-code');

    if(location) {
        location = location.textContent;

        if(location=='84070') {
            options.dealerId = 1584;
        }
    }

    return options;

}
