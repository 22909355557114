var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: ''
    };

    //remove erroneously set price from options
    options.listedPrice = '';

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.pricing .clearfix');

    if (vehicleOverview.length) {

        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children[0] && row.children[0].children[0]) {
                var label = row.children[0].children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'msrp':
                        data.retailPrice = row.children[0].children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'price':
                        data.listedPrice = row.children[0].children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if(data.listedPrice) {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            }
        },
        retailPrice: function(data, result) {
            if(data.retailPrice) {
                result.retailPrice = shimUtils.getInteger(data.retailPrice);
            }
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
