module.exports = {
    test: require('./test'),
    atc: require('./atc'),
    autodealertech: require('./autodealertech'),
    autojockey: require('./autojockey'),
    automanager: require('./automanager'),
    autoonemedia: require('./autoonemedia'),
    autorevo: require('./autorevo'),
    autorevolution: require('./autorevolution'),
    autosearchtech: require('./autosearchtech'),
    clickmotive: require('./clickmotive'),
    cobalt: require('./cobalt'),
    ddc: require('./ddc'),
    dealeractive: require('./dealeractive'),
    dealershipids: require('./dealershipids'),
    dealercar: require('./dealercar'),
    dealereprocess: require('./dealereprocess'),
    dealerfire: require('./dealerfire'),
    dealerinspire: require('./dealerinspire'),
    dealerlab: require('./dealerlab'),
    dealeron: require('./dealeron'),
    dealerpeak: require('./dealerpeak'),
    dealersocket: require('./dealersocket'),
    dealertrack: require('./dealertrack'),
    dealerx: require('./dealerx'),
    dealerzoom: require('./dealerzoom'),
    dom360: require('./dom360'),
    dominion: require('./dominion'),
    ebizautos: require('./ebizautos'),
    ecarlist: require('./ecarlist'),
    eleadsdigital: require('./eleadsdigital'),
    freemanmotors: require('./freemanmotors'),
    foxdealer: require('./foxdealer'),
    fusionzone: require('./fusionzone'),
    granttech: require('./granttech'),
    gsmarketing: require('./gsmarketing'),
    jazel: require('./jazel'),
    kbb: require('./kbb'),
    "kbb-par": require('./kbb-par'),
    lttf: require('./lttf'),
    motortrak: require('./motortrak'),
    motorwebs: require('./motorwebs'),
    nakedlime: require('./nakedlime'),
    nehmedia: require('./nehmedia'),
    promax: require('./promax'),
    remora: require('./remora'),
    searchoptics: require('./searchoptics'),
    slipstream: require('./slipstream'),
    sokalmedia: require('./sokalmedia'),
    webxloo: require('./webxloo'),
    worlddealer: require('./worlddealer'),
    vin: require('./vin')
};
