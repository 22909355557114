// WTD-255
// Send deals to different stores based on first element of vin

var convertDealerId = require('./utils/glocknerAutoGroup.utils');

module.exports = function(options) {
    options = options || {};

    options.dealerId = convertDealerId.convertDealerId(options);

    return options;
}
