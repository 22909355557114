var shimUtils = require('../utils');
var selectn = require('selectn');
var extend = require('lodash/extend');

module.exports = function (options) {

    options = options || {};

    var hooks = {
        onTrack: function(eventName, eventAction, data) {
            console.log('GA Example - onTrackHook: EventName: ' + eventName + ' EventAction: ' + eventAction + ' CustomDimensionData: ' + JSON.stringify(data));
        }
    }

    options = extend(options, {  hooks: hooks });

    return options;
}
