var convertDealerId = require('./utils/butlergroup.utils');

module.exports = function(options) {

    options = options || {};

    options = convertDealerId(options);

    return options;
}
