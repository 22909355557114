/**
 * Cobalt Template Shim for Flex sites
 * This template type is determine by running ContextManager.getPageLayout() on the console
 * The value will be 'VehicleDetails_flex_{something}'
 * Majority of Cobalt sites use this template type
 */

 var shimUtils = require('../../utils');

module.exports = function(opts) {

    var data = opts.data;
    var options = opts.options;
    var cm = opts.cm;

    var pricingSpecs = document.querySelectorAll('.mathboxWidget .cblt-content .cblt-list .offer-item');

    if (pricingSpecs.length) {

        var pricingSpecsArr = [];

        if (pricingSpecs.length) {
            pricingSpecsArr = Array.prototype.map.call(
                pricingSpecs,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        var optionsArr = pricingSpecsArr;

        if (optionsArr.length) {
            var siteOptionsCollector = optionsArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var label = array[index].children[0].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}\n0-9]/g, '').toLowerCase().trim();
                    switch (label) {
                        case 'msrp':
                        case 'total msrp':
                        case 'retail price':
                        case 'total msrp before offers':
                        case 'kbb book value':
                        case 'kbb retail price':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'internet price':
                        case 'price after all offers':
                        case 'price':
                        case 'sale price':
                        case 'dw price':
                        case 'market value':
                        case 'upfront price':
                            data.listedPrice = array[index].children[1];
                            break;

                        case 'rebates':
                        case 'less than kbb value':
                        case 'savings':
                        case '2015 model year end':
                        case '2015 model year end 1':
                            data.dealerSavings = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        }
    }

    if (!data.listedPrice) {
        data.listedPrice = document.querySelector('.price') ||
            document.querySelector('.sortedPrice_container .featuredPrice span');
    }

    if (!data.retailPrice) {
        if(data.listedPrice && data.listedPrice.children.length > 2) {
            if(data.listedPrice.children[0].textContent.toLowerCase().indexOf('msrp')>-1) {
                data.retailPrice = data.listedPrice;
            }
        } else {
            data.retailPrice = document.querySelector('.sortedPrice_container .retailPrice');
        }
    }


    if (pricingSpecs.length === 0) {
        //get listedPrice
        // mwo-282 - Listed price was incorrect on desktop
        // remove the selector by className - commenting out for now
        //var price = document.getElementsByClassName('highlight special')[0] || document.querySelector('.price .highlight');
        var priceCont = document.querySelectorAll('.cblt-list .price');
        //in case there is no price container
        if (priceCont.length < 1) {
            data.listedPrice = document.querySelector('.price [itemprop="price"]');
        }

        switch (priceCont.length) {
            case 1:
                data.listedPrice = document.querySelector('.price .highlight') || priceCont[0];
                data.retailPrice = priceCont[0];
                break;
            case 2:
                data.listedPrice = priceCont[1] || document.querySelector('.price .highlight');
                data.retailPrice = priceCont[0];
                break;
            case 3:
                data.listedPrice = priceCont[2] || document.querySelector('.price .highlight');
                data.retailPrice = priceCont[0];
                break;
        }

        data.incentiveSavings = document.querySelector('.savings .mathbox-price');
    }

    //set vehicleStatus
    data.vehicleStatus = document.querySelector('span[itemprop="itemCondition"]');

    if(!data.vehicleStatus){
        data.vehicleStatus = document.URL;
    }

    if(cm){
        if(cm.hasOwnProperty('vehicle')){
            //set year
            if(cm.vehicle.hasOwnProperty('year')){
                data.year = cm.vehicle.year;
            }

            //set make
            if(cm.vehicle.hasOwnProperty('make')){
                data.make = cm.vehicle.make;
            }

            //set model
            if(cm.vehicle.hasOwnProperty('model')){
                data.model = cm.vehicle.model;
            }

            //set trim
            if(cm.vehicle.hasOwnProperty('trim')){
                data.trim = cm.vehicle.trim;
            }
            //set vin
            if(cm.vehicle.hasOwnProperty('vin')){
                data.vin = cm.vehicle.vin;
            }

            // set retailPrice
            if (!data.retailPrice && cm.vehicle.hasOwnProperty('msrp')) {
                data.retailPrice = cm.vehicle.msrp;
            }

            // set listedPrice
            if (!data.listedPrice && cm.vehicle.hasOwnProperty('internetPrice')) {
                data.listedPrice = cm.vehicle.internetPrice;
            }

            // set stock
            if (!data.stock && cm.vehicle.hasOwnProperty('stockNumber')) {
                data.stock = cm.vehicle.stockNumber;
            }

            // set vehicleStatus
            if (!data.vehicleStatus && cm.vehicle.hasOwnProperty('category')) {
                data.vehicleStatus = cm.vehicle.category;
            }

            // set mileage
            if (!data.mileage && cm.vehicle.hasOwnProperty('miles')) {
                data.mileage = cm.vehicle.miles;
            }

            // set img
            if (!data.img && cm.vehicle.hasOwnProperty('photoUrl')) {
                data.img = cm.vehicle.photoUrl;
            }
        }
        if(cm.hasOwnProperty('vin')){
            data.vin = cm.vin;
        }
    }


    //set vin
    if(cm && cm.vin){
        data.vin = cm.vin
    }else if(cm && cm.vehicle && cm.vehicle.vin){
        data.vin = cm.vehicle.vin;
    }

    var vehicleDetails = document.querySelectorAll('ul.subInfo li');

    if(vehicleDetails.length) {
        var vehicleDetailsArr = Array.prototype.map.call(
            vehicleDetails,
            function(obj, i, arr) {
                return obj;
            }
        );

        var vehicleDetailsCollector = vehicleDetailsArr.filter(function(row, index, array) {
            var labelAndValue = array[index].textContent.replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().trim().split(':');
            var label = labelAndValue[0].trim();
            var value = labelAndValue[1].trim();

            switch (label) {

                case 'vin':
                    data.vin = value;
                    break;

                case 'stock':
                case 'stock number':
                    data.stock = value;
                    break;

                default:
            }
            return true;
        });

    }

    //set mileage
    if(!data.mileage) {
        data.mileage = document.querySelector('.vehicleInfo .even span[title*="Miles"]') || document.querySelector('.vehicleInfo .odd span[title*="Miles"]');
    }

    //get img
    if(!data.img) {
        data.img = document.querySelectorAll('.photo-container .slides img')[0] || document.querySelector('div#vehicleLargePhoto img');
    }

    //for mobile flex sites that do not fit other shims
    var vehicleOverview = document.querySelectorAll('dl#specsContainer dt, dl#specsContainer dd');

    if(vehicleOverview.length){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            var specs = array[index].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
            switch (specs) {
                case 'price':
                    data.listedPrice = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                case 'msrp':
                    data.retailPrice = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                case 'trim':
                    data.trim = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                case 'stock number':
                    data.stock = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                case 'vin':
                    data.vin = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                case 'mileage':
                    data.mileage = array[index+1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/(\r\n|\n|\r)/gm,"").toLowerCase().trim().split(' ').filter(function(n){ return n != '' }).join(' ');
                    break;

                default:
            }
        return true;
        });
    }

    if (!data.incentiveSavings) {
        delete data.incentiveSavings;
    }

    if (!data.dealerSavings) {
        delete data.dealerSavings;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            if(status.textContent){
                status = status.textContent;
            }
            status = status.trim().toLowerCase();
            result.vehicleStatus = status.search(/\b(certified|used|pre owned|pre-owned)\b/g) === -1 ? 1 : 0;
        },
        year: function(data, result) {
            result.year = shimUtils.getInteger(data.year);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            var stock = data.stock;
            if(stock.textContent){
                stock = stock.textContent;
            }
            if (stock.indexOf(':') > -1) {
                result.stock = stock.split(':')[1].trim();
            } else {
                result.stock = stock.trim();
            }
        },
        img: function(data, result) {
            if (typeof (data.img) === 'string') {
                result.img = data.img;
            } else {
                result.img = data.img.getAttribute('data-original')
                    || data.img.src;
            }
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
