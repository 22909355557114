
//MWO-389
//dealer is passing 1 for all vehicleStatus
//change used to 0

var shimUtils = require('../utils');

module.exports = function(options) {

	options = options || {};

	var data = {
		vehicleStatus : '',
	}

	data.vehicleStatus = document.querySelector('link[itemprop="itemCondition"]');

	//logging
	shimUtils.callValueQuerySelector(data,options);

	var tasks = {
		vehicleStatus : function(data,result){
            var status = data.vehicleStatus.getAttribute('href');
			result.vehicleStatus = (status.indexOf('Used') > 0) ? 0 : 1;
		}
	}

	//call continue to get data
	options = shimUtils.continue(data, tasks, options);

	return options;
}
