var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: '',
    };

    var vehicleOverview = document.querySelectorAll('.container .row div .col-md-4');

    if (vehicleOverview.length > 0) {
        var vehicleOverviewText = [];
        for (var i = 0; i < vehicleOverview.length; i++) {
            vehicleOverviewText.push(vehicleOverview[i].textContent.replace(/^\s+/, '').replace(/\s+$/, '').split(/\n/));
        }
        vehicleOverview = [];
        for (i = 0; i < vehicleOverviewText.length; i++) {
            for (var x = 0; x < vehicleOverviewText[i].length; x++) {
                vehicleOverview.push(vehicleOverviewText[i][x].trim());
            };
        };
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            var labelAndValue = array[index].replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
            var label = labelAndValue[0];
            var value = labelAndValue[1];
            switch (label) {
                case 'mileage':
                    data.mileage = value;
                    break;

                case 'vin':
                    data.vin = value;
                    break;

                default:
            }
            return true;
        });
    } else {

        //get vin
        data.vin = document.querySelector('table.detailswhite2 tr:first-child td a[href*="vin"]');

        //get mileage
        data.mileage = document.querySelector('table.detailswhite2 tr:nth-child(2) td:nth-child(2) b');
    }

    if (!data.stock) {
        //get stock
        data.stock = document.querySelector('table.detailswhite2 tr:first-child td:nth-child(2) b');
    }

    //get year, make, model and trim from title
    data.year = data.make = data.model = data.trim = document.querySelector('table td[style*="height:70px"] font b')
        || document.querySelector('table td[style*="height: 70px"] font b')
        || document.querySelector('.page-header h1');


    //get listedPrice
    data.listedPrice = document.querySelector('table td[style*="height: 50px"] font b')
        || document.querySelector('table td[style*="height:50px"] font b')
        || document.querySelector('.container .row div h1 .label-primary');

    //get status
    data.vehicleStatus = 1;

    //get img
    data.img = document.querySelector('div#mmainphoto img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus: function(data, result) {
            if (result.mileage > 100) {
                result.vehicleStatus = 0;
            } else {
                result.vehicleStatus = data.vehicleStatus;
            }
        },
        vin: function(data, result) {
            if (data.vin.textContent) {
                data.vin = data.vin.textContent;
            }
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }

    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
