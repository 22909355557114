/**
 * MWO-2391: Refactor into a dealer utility
 * Utility currently overrides the dealerId when necessary
 * and disable load when not match approved sites / locations
 */
var shimUtils = require('../../utils');

var convertDealerId = function(options) {
    options = options || {};

    // collection the car location from the site
    // Desktop: '.innerContactInfo li.PhoneNumber span.phonenumber'
    // Mobile: 'a#autophone'
    var phoneNumberElem = document.querySelector('.innerContactInfo li.PhoneNumber span.phonenumber, a#autophone');

    // Assume that one of these elements will be on the page
    var isComplete = shimUtils.isComplete();
    if (!phoneNumberElem && !options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    if (phoneNumberElem) {
        var phoneNumber = '';

        // Extract and Sanitize Phone Number
        if (phoneNumberElem.nodeName === 'A') { // Element from mobile
            phoneNumber = phoneNumberElem.href.split(':')[1];
        } else if (phoneNumberElem.nodeName === 'SPAN') {   // Element from desktop
            phoneNumber = phoneNumberElem.textContent
                .replace(/[&\/\\#,+()$~%'":*?<>{}-\s]/g, '')
                .trim();
        }

        // Perform logic to override dealerId or set to not load
        if (phoneNumber === '3178630029') {
            // Butler Kia of Fishers
            options.dealerId = 439;
        } else if (phoneNumber === '3172511441') {
            // Butler Kia
            options.dealerId = 440;
        } else if (phoneNumber === '3178484200') {
            // Butler of Hyundai
            options.dealerId = 441;
        } else {
            options.disableLoad = true;
        }
    }

    return options;

};

 module.exports = convertDealerId;
