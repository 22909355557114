var shimUtils = require('../../utils');

/**
 * ebizautos has a couple templates they are using now.  Updated theme is slowly being rolled out
 * however the team is still using the legacy template as dealers have an option to keep both sites...
 * weird I know.
 */
var legacyShim = require('./shim.legacy');
var updatedShim = require('./shim.updated');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        incentiveSavings: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    // select the div for the legacy page identification
    var legacyPageWrapper = document.querySelector('div#SiteWrapper');

    if(legacyPageWrapper || location.href.search(/mobi/g) > -1) {
        legacyShim({
            data: data,
            options: options
        });
    } else {
        updatedShim({
            data: data,
            options: options
        });
    }

    options.isShimmed = true;

    options.url = document.URL;

    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    return options;
};
