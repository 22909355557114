var shimUtils = require('../utils');

module.exports = function (options) {

    options = options || {};

    //542,301,152,732 creates a custom object (params) with the vehicle list data
    var customDealerObject = (typeof window.params !== 'undefined') ? window.params : null;

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //get url
    options.url = document.URL;
    options.isShimmed = true;

    //custom dealer vehicle data object
    if(customDealerObject){
        for(var key in window.params){
            if(key === 'status'){
                data.vehicleStatus = window.params[key].toString();
            }else if(key in data){
                data[key] = window.params[key];
            }
        }
    }else{
        //scraping from the web page
        var vehicleData =  document.querySelector('.vehicleInfo')
                    || document.getElementById('VehicleDetails');


        if(vehicleData){
            var itHasChildrens = typeof vehicleData.childElements != 'undefined' ? false : true;

            var specs = Array.prototype.map.call(itHasChildrens ?
                                                vehicleData.children:
                                                vehicleData.childElements() , function(obj, ind, arr){

                                                var _obj = obj.textContent
                                                            .toLowerCase()
                                                            .replace(/(\r\n|\n|\r)|\s{2,}/gm, '')
                                                return _obj;
            });

            var dealerPriceNames = {'listed':true, 'list':true, 'tlc':true, 'internet':true };
            var dealerRetailNames      = {'msrp':true, 'retail':true};

            var specsDataObj = specs.reduce(function(obj, value, idx){

              var _n = value.split(':');

              var key = _n[0].replace(/\W/g, ' ').trim().split(' ')[0];
              var newValue = _n[1];

              if(key in data || dealerRetailNames[key] || dealerPriceNames[key]){
                if(dealerRetailNames[key]){
                    data.retailPrice = newValue;
                }else if(dealerPriceNames[key]){
                    data.listedPrice = newValue;
                }else{
                    data[key] = newValue;
                }
              }

              return obj;
            }, {});
        }



        //get retailPrice
        data.retailPrice = data.retailPrice;
        data.listedPrice = data.listedPrice || document.getElementById('DetailLabel_PromisePrice') || '';

        //get status
        data.vehicleStatus = data.vehicleStatus || (location.pathname.toLowerCase().search(/\bnew\b/gi) >= 0 ? "1" : "0")
            || document.getElementById('wmxheading')
            || document.getElementsByTagName('h1')[0];

        //get vin on 732
        data.vin = data.vin ? data.vin : ((typeof vehicleVIN !== 'undefined') ? window.vehicleVIN : '');

        //get year on 540
        data.year = data.year ? data.year : ((typeof vehicleYear !== 'undefined') ? window.vehicleYear : '');

        //get img
        data.img = data.img || document.querySelector('.thumbnailNavItem img')
                || document.querySelector('.mainshow img');
    }

    if(!data.retailPrice){

        data.retailPrice = document.querySelector('.inventoryPrice .inventoryPriceField:first-child .vehiclePriceDisplay')
                    || document.querySelector('#price .price')
                    || document.querySelector('.markupContentWrapper table tbody tr td:nth-child(2)')
                    || document.querySelector('span[itemprop="price"]')
                    || document.querySelector('.InventoryPage .row-a-1 .tableRow .contentContainer:nth-child(3) span[style="font-size:18px;"]:last-child')
                    || document.querySelector('.InventoryPage .row-a-1 .tableRow .contentContainer:nth-child(3) span[style="font-size:16pt;"] span');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            var listedPrice = data.listedPrice;
            result.listedPrice = parseInt((typeof listedPrice === 'string')
                ? listedPrice.replace(/[^0-9.]/g, '')
                : (typeof listedPrice ==='object') ? listedPrice.textContent.replace(/[,$]/g, '') : listedPrice, 10);
        },
        retailPrice : function(data, result) {
            var retailPrice = data.retailPrice;
            result.retailPrice =  parseInt((typeof retailPrice === 'string')
                ? retailPrice.replace(/[^0-9.]/g, '')
                : (typeof retailPrice === 'object') ? data.retailPrice.textContent.replace(/[,$]/g, '') : retailPrice, 10);
        },
        year : function(data, result) {
            var year = data.year;
            result.year = parseInt(year, 10);
        },
        make : function(data, result) {
            var make = data.make;
            result.make = make;
        },
        model : function(data, result) {
            var model = data.model;
            result.model = model;
        },
        trim : function(data, result) {
            var trim = data.trim;
            result.trim = trim;
        },
        vehicleStatus : function(data, result) {
            result.vehicleStatus = (typeof data.vehicleStatus === 'object') ?
            (data.vehicleStatus.textContent.search(/\bnew\b/gi) >= 0 ? 1 : 0) :
            parseInt(data.vehicleStatus, 10);
        },
        mileage : function(data, result) {
            var mileage = (data.mileage !== '') ? data.mileage : 0;
            result.mileage = parseInt((mileage.constructor.name === 'String')
                ? mileage.replace(/[^0-9.]/g, '')
                : mileage, 10);
        },
        vin : function(data, result) {
            var vin = data.vin;
            result.vin = vin;
        },
        stock : function(data, result) {
            var stock = data.stock;
            result.stock = stock;
        },
        img : function(data, result) {
            result.img = (data.img && data.img.src) ? data.img.src : data.img;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    // set appendTo
    // 2015.09.11 - Need to append for NakedLime globally as web provider does not have ability to place MMD div
    options.appendTo = ".nested.placeholder.elementStyle_TabStrip1.placeholderElement.placeholderControl.control";

    return options;
};
