import { EXTERNAL_TRACK, PIXALL_ANALYTICS, REMEMBERED_TRADE, ANALYTICS_RELAY } from './messageContexts';

export const encodeMessage = (payload: any) => JSON.stringify(payload);

export const decodeMessage = (message: string): any => {
    try {
        return JSON.parse(message);
    } catch (err) {}
    return null;
};

export const isValidPixallAnalyticsMessage = (message?: { context?: string }) => message && message.context === PIXALL_ANALYTICS;
export const isValidExternalTrackMessage = (message?: { context?: string }) => message && message.context === EXTERNAL_TRACK;
export const isValidGeneralMesssage = (message?: { context?: string }) => message && message.context === ANALYTICS_RELAY;
export const isValidRememberedTradeMessage = (message?: { context?: string }) => message && message.context === REMEMBERED_TRADE;
export const isValidMessage = (message?: { context?: string }) =>
    isValidPixallAnalyticsMessage(message) ||
    isValidExternalTrackMessage(message) ||
    isValidGeneralMesssage(message) ||
    isValidRememberedTradeMessage(message);

export const log = (message: string, enableLogging = false, ...optionalParams: any[]) => {
    if (enableLogging) {
        console.log(`IN SDP WIDGET: ${message}`, ...optionalParams);
    }
};

export const alwaysLog = (message: string) => {
    log(message, true);
};
