module.exports = function(options) {

    options = options || {};


    var dealerId = options.dealerId;
    var dealerName = document.querySelector('[name="description"]');

    if(dealerName){
        dealerName = dealerName.getAttribute('content');
        if(dealerName){
            dealerName = dealerName.toLowerCase().indexOf('buckeye ford in sidney');

            if(dealerName > -1){
                options.dealerId = 824;
            }
        }
    }

    return options;
}
