var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    var pricingTableValue = document.querySelectorAll('.inventory-detail-pricing .pricing li .price');
    var pricingTableLabel = document.querySelectorAll('.inventory-detail-pricing .pricing li .label');

    if (pricingTableValue.length > 0 && pricingTableLabel.length > 0 ) {
        var pricingValueArr = Array.prototype.map.call(
            pricingTableValue,
            function(obj, i, arr) {
                return obj;
            }
        );

        var pricingLabelArr = Array.prototype.map.call(
            pricingTableLabel,
            function(obj, i, arr) {
                return obj;
            }
        );

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = pricingValueArr.filter(function(row, index, array) {
            var label = pricingLabelArr[index].textContent.trim().replace(replaceRegex, '').toLowerCase();
            switch(label) {
                case 'dealer discount price':
                    data.listedPrice = array[index];
                    break;
            }
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = data.listedPrice.textContent.trim();
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
