module.exports = function(options) {

    options = options || {};

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //set shimmed true
    options.isShimmed = true;

    return options;

}
