var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var phoneNumber = document.querySelector('.phone1 .value');

            if(phoneNumber){

                phoneNumber = phoneNumber.textContent;

                switch (phoneNumber) {
                    case '(855) 519-8884':
                        options.dealerId = 639;
                        break;

                    case '(855) 519-9722':
                        options.dealerId = 640;
                        break;

                    case '(855) 520-0947':
                        options.dealerId = 641;
                        break;

                    case '(888) 210-2131':
                        options.dealerId = 642;
                        break;

                    case '(855) 519-9728':
                        options.dealerId = 643;
                        break;

                    case '(877) 860-2057':
                        options.dealerId = 644;
                        break;

                    case '(844) 810-0214':
                        options.dealerId = 645;
                        break;

                    case '(888) 808-0463':
                        options.dealerId = 646;
                        break;

                    default:
                        options.dealerId = 411;
                        break;
                }
            };

    return options;
};
