var valueQuerySelector = module.exports = {
    isComplete: function(){
        var result = false;

        if(
            document.readyState === "complete" ||
            document.readyState === "loaded" ||
            (document.readyState === "interactive" && document.body && !document.body.doScroll)
        ){
            result = true;
        };

        return result;
    },
    valueQuerySelectorNew: function(query, options, searchValue, required) {
        var isComplete = this.isComplete();

        if (!query && (!options.wasDeferred && !isComplete)) {
            options.isDeferred = true;
        } else if (!query && options.wasDeferred && required) {
            options.hasError = true;
            options.error = {
                message: searchValue + ' has an error and cannot be collected'
            };
        }

        return query;
    },
    valueQuerySelector: function(query, options, searchValue) {
        var isComplete = this.isComplete();

        if (!query && !options.wasDeferred && !isComplete) {
            options.isDeferred = true;
        } else if (!query && options.wasDeferred) {
            options.hasError = true;
            options.error = {
                message: searchValue + ' has an error and cannot be collected'
            };
        }

        return query;
    },
    callValueQuerySelector: function(data, options) {
        var nonRequired = ['retailPrice', 'listedPrice', 'img', 'trim', 'mileage', 'stock', 'incentiveSavings', 'dealerSavings', 'certifiedStatus'];
        for (var d in data) {
            if (nonRequired.indexOf(d) > -1) {
                data[d] = this.valueQuerySelectorNew(data[d], options, d, false);
            } else {
                data[d] = this.valueQuerySelectorNew(data[d], options, d, true);
            }
        }
    }
};
