var shimUtils = require('../utils');
var magiccollector = require('../magiccollector');

module.exports = function(options) {

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.Txt-YMM h1')
        || document.querySelector('.Txt-YMM');

    data.mileage = document.querySelector('.Vehicle-Overview .Table-Specs tr:nth-child(2) td');
    if(!data.mileage && !!document.querySelectorAll('.Txt-Price')[1]){
        data.mileage = document.querySelectorAll('.Txt-Price')[1];
    };
    data.stock =  document.querySelector('.Content-Background .Content-Background .Table-Specs tr:nth-child(2) td:nth-child(2)');
    if(!data.stock && !!document.querySelectorAll('.Txt-Price')[0]){
        data.stock = document.querySelectorAll('.Txt-Price')[0];
    };
    data.vin =  document.querySelector('.Content-Background .Content-Background .Table-Specs tr:nth-child(1) td:nth-child(2)')
        || document.querySelector('.vin-no');

    //pull vin from carfax url if data.vin doesn't contain vin
    if(data.vin && data.vin.textContent){
        if(!!data.vin.textContent.trim()=='&nbsp;' || data.vin.textContent.trim()===''){
            data.vin = document.querySelector('.Content-Background .Bt-CarFaxReport');
        };
    }

    data.img = document.querySelector('.Picture-Frame-640 img')
        || document.querySelector('.picture-inv img');

    data.listedPrice = document.querySelector('.Txt-Price');
    if(!!document.querySelectorAll('.Txt-Price')[2]){
        data.listedPrice = document.querySelectorAll('.Txt-Price')[2];
    };

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            if(data.mileage.textContent){
                var regex = /\d+,?\d+(\s+)?(miles)/ig;
                data.mileage = data.mileage.textContent.replace('\n','').match(regex)[0];
            }
            result.mileage = shimUtils.getInteger(data.mileage);

        },
        vin: function(data, result) {
            var vin;
            if(data.vin.textContent.indexOf(':')>-1){
                vin = data.vin.textContent.split(':')[1];
            }else if(data.vin.href){
                vin = data.vin.href.split('vin=')[1]
            }
            result.vin = vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.split(':')[1].trim();
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
