/**
 * This function replaces the need for the lodash.isEmpty call. The purpose
 * is to determine if the object passed in is empty or not.
 *
 * Note that this is a minimalist implementation. Object inheritance may impact the result of this logic.
 * @param o - An object to test.
 * @returns boolean
 */
function isEmpty(o: any) {
    return !(o && Object.keys(o).length > 0);
}

export { isEmpty };
