module.exports = function(options) {

    options = options || {};
    var location = null;

    location = document.querySelector('.dealer-name');

    if(location !== null) {
        location = location.textContent.toLowerCase().replace(/\s/g,'');

        switch(location) {
            case 'moritzkiaft.worth':
                options.dealerId = 1020;
                break;

            case 'moritzkiahurst':
                options.dealerId = 1019;
                break;

            case 'moritzkiaalliance':
                options.dealerId = 1154;
                break;

        }
    }

    return options;
}
