var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

    var data = {
       listedPrice: '',
       retailPrice: ''
    };

    var pricingTable = document.querySelectorAll('.Inventory_Details_Title .price');

    if(pricingTable.length > 0) {
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj,i,arr){
                return obj;
        });

        var siteOptionsArr = pricingArr;

        var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
            if(array[index].children.length !== 0) {
                var labelAndValue =  array[index].textContent.toLowerCase().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').split(':'),
                label = labelAndValue[0].trim(),
                value = labelAndValue[1].trim();

                switch(label) {

                    case 'wilde price':
                        data.listedPrice = value;
                        break;

                    case 'price':
                        data.listedPrice = value;
                        break;

                    default:
                }
            }
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get Data
    var tasks = {
        retailPrice: function(data, result){
            result.retailPrice = parseInt(data.retailPrice,10);
        },
        listedPrice: function(data, result){
            result.listedPrice = parseInt(data.listedPrice,10);
        }
    };

    options = shimUtils.continue(data,tasks,options);

    options.isShimmed = true;

    return options;

};
