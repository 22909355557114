//get correct listedPrice
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
      listedPrice : '',
    }

    //get listedPrice
    data.listedPrice = document.querySelector('.pricing .initial span')
                    || document.querySelector('.singlePrice .single');

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
      listedPrice : function(data, result){
       result.listedPrice = shimUtils.getInteger(data.listedPrice);
      }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
