//comp used to determine which shim to use
//  id : {
//    dealer: true/false,
//    sponsor: true/false
//  }

module.exports = {
    38: {
        dealer: false,
        sponsor: true
    },
    99: {
        dealer: true,
        sponsor: false
    },
    132: {
        dealer: false,
        sponsor: false
    },
    133: {
        dealer: false,
        sponsor: false
    },
    134: {
        dealer: false,
        sponsor: false
    },
    141: {
        dealer: true,
        sponsor: false
    },
    155: {
        dealer: true,
        sponsor: false
    },
    180: {
        dealer: true,
        sponsor: false
    },
    187: {
        dealer: true,
        sponsor: false
    },
    188: {
        dealer: true,
        sponsor: false
    },
    212: {
        dealer: true,
        sponsor: false
    },
    214: {
        dealer: true,
        sponsor: false
    },
    267: {
        dealer: true,
        sponsor: false
    },
    327: {
        dealer: true,
        sponsor: false
    },
    339: {
        dealer: false,
        sponsor: false
    },
    344: {
        dealer: true,
        sponsor: false
    },
    357: {
        dealer: true,
        sponsor: false
    },
    358: {
        dealer: true,
        sponsor: false
    },
    394: {
        dealer: false,
        sponsor: false
    },
    403: {
        dealer: true,
        sponsor: false
    },
    439: {
        dealer: true,
        sponsor: false
    },
    440: {
        dealer: true,
        sponsor: false
    },
    441: {
        dealer: true,
        sponsor: false
    },
    444: {
        dealer: false,
        sponsor: true
    },
    454: {
        dealer: true,
        sponsor: false
    },
    456: {
        dealer: true,
        sponsor: false
    },
    464: {
        dealer: true,
        sponsor: false
    },
    466: {
        dealer: true,
        sponsor: false
    },
    498: {
        dealer: true,
        sponsor: false
    },
    508: {
        dealer: true,
        sponsor: false
    },
    524: {
        dealer: true,
        sponsor: false
    },
    544: {
        dealer: true,
        sponsor: false
    },
    549: {
        dealer: true,
        sponsor: false
    },
    555: {
        dealer: true,
        sponsor: false
    },
    600: {
        dealer: true,
        sponsor: false
    },
    688: {
        dealer: true,
        sponsor: false
    },
    714: {
        dealer: true,
        sponsor: false
    },
    728: {
        dealer: true,
        sponsor: false
    },
    736: {
        dealer: false,
        sponsor: false
    },
    745: {
        dealer: true,
        sponsor: true
    },
    746: {
        dealer: true,
        sponsor: true
    },
    753: {
        dealer: true,
        sponsor: false
    },
    787: {
        dealer: true,
        sponsor: false
    },
    798: {
        dealer: true,
        sponsor: false
    },
    807: {
        dealer: true,
        sponsor: false
    },
    809: {
        dealer: true,
        sponsor: false
    },
    810: {
        dealer: true,
        sponsor: false
    },
    811: {
        dealer: true,
        sponsor: false
    },
    812: {
        dealer: true,
        sponsor: false
    },
    818: {
        dealer: true,
        sponsor: false
    },
    826: {
        // Site is using Provider implementation through widgetData.
        // Only enable dealer if we need to override.
        dealer: false,
        sponsor: true
    },
    858: {
        dealer: true,
        sponsor: false
    },
    867: {
        dealer: true,
        sponsor: false
    },
    876: {
        dealer: true,
        sponsor: false
    },
    889: {
        dealer: true,
        sponsor: false
    },
    903: {
        dealer: true,
        sponsor: false
    },
    916: {
        dealer: true,
        sponsor: false
    },
    923: {
        dealer: false,
        sponsor: false
    },
    973: {
        dealer: true,
        sponsor: false
    },
    1030: {
        dealer: true,
        sponsor: false
    },
    1046: {
        dealer: true,
        sponsor: false
    },
    1059: {
        dealer: true,
        sponsor: false
    },
    1109: {
        dealer: true,
        sponsor: false
    },
    1110: {
        dealer: true,
        sponsor: false
    },
    1604: {
        dealer: true,
        sponsor: false
    },
    1616: {
        dealer: true,
        sponsor: false
    },
    1637: {
        dealer: true,
        sponsor: false
    },
    1661: { // Bob Ruth Ford - New Remora layout so no sponsor
        dealer: true,
        sponsor: false
    },
    1720: {
        dealer: true,
        sponsor: false
    }
};
