module.exports = function(options) {

    options = options || {};

    if(options.vehicleStatus === 1 && options.retailPrice) {
        options.listedPrice = options.retailPrice;
    }

    return options;

}
