var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        mileage : '',
        vehicleStatus : ''
    };

    var vehicleOverview = document.querySelectorAll('.vehicleInfo li');
    var pricingTable = document.querySelectorAll('.mathboxWidget li .offer-item');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj,i,arr){
            return obj;
        }
    );

    var pricingArr = Array.prototype.map.call(
        pricingTable,
        function(obj,i,arr){
            return obj;
        }
    );

    var siteOptionsArr = overviewArr.concat(pricingArr);

    var siteOptionsCollector = siteOptionsArr.filter(function(row,index,array){
        if(array[index].children.length !== 0){
            var label = array[index].querySelector('span:nth-child(1)').textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch(label){
                case 'price':
                    data.retailPrice = array[index].querySelector('span:nth-child(2)');
                    break;

                case 'sale price':
                    data.listedPrice = array[index].querySelector('span:nth-child(2)');
                    break;

                case 'mileage':
                    data.mileage = array[index].querySelector('span:nth-child(3)');
                    break;

                default:
            }
        }
        return true;
    });

    if(data.listedPrice === ''){
        data.listedPrice = document.querySelector('span[itemprop="price"]');
    }

    //set vehicleStatus
    data.vehicleStatus = document.querySelector('span[itemprop="itemCondition"]');

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data,result){
            var listedPrice = data.listedPrice.textContent.replace(/\D/g, '');
            result.listedPrice = parseInt(listedPrice);
        },
        retailPrice: function(data,result){
            var retailPrice = data.retailPrice.textContent.replace(/\D/g, '');
            result.retailPrice = parseInt(retailPrice);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            status = status.textContent.trim().toLowerCase();
            result.vehicleStatus = (status === 'certified' || status === 'used') ? 0 : 1;
        },
        mileage: function(data,result){
            var mileage = data.mileage.textContent.replace(/\D/g, '');
            result.mileage = parseInt(mileage);
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    // need to set options.isShimmed to true so that island.cobalt.js is not loaded
    options.isShimmed = true;

    return options;

};
