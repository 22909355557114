var shimUtils = require('../utils');

module.exports = function (options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    if (options.vehicleStatus == '1') {
        data.listedPrice = document.querySelector('.retailValue .price');

        // logging
        shimUtils.callValueQuerySelector(data, options);

        // define tasks to get data
        var tasks = {
            listedPrice: function (data, result) {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            }
        }

        // call continue to get data
        options = shimUtils.continue(data, tasks, options);
    }

    return options;
}
