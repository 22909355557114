// MWO-2008
// Need to send deals to different stores based on the
// phone number.

module.exports = function(options) {

    options = options || {};

    var phoneNumber = document.querySelector('.detailPhone');

    if(phoneNumber){
        phoneNumber = phoneNumber.textContent;
        
        if(phoneNumber === "888-769-4862") {
            options.dealerId = 872;
        }
    }

    return options;
};
