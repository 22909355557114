//shim:dealerfire
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    var vehicleOverview = document.querySelectorAll('.info-line .info-line-tr');
    if(vehicleOverview.length<1){
        vehicleOverview = document.querySelectorAll('.mod-vehicle-info .row ul li');
        if(vehicleOverview.length<1){
            vehicleOverview = document.querySelectorAll('.mod-vehicle-info .row .specification-item')
            if(vehicleOverview.length<1){
                vehicleOverview = document.querySelectorAll('.iui_vehicle table tbody:nth-child(1) tr');
                if(vehicleOverview.length<1){
                    vehicleOverview = document.querySelectorAll('#vitalsContainer li');
                    if(vehicleOverview.length<1){
                         vehicleOverview = document.querySelectorAll('.mod-vehicle-info li');    
                    }
                }
            }
        }
    }

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    var siteOptionsArr = overviewArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
        if(array[index].children.length === 1 && array[index].textContent.indexOf(':')>-1) {
            var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
            var label = labelAndValue[0];
            var value = labelAndValue[1];

            switch(label.trim()) {

                case 'vin':
                    data.vin = value;
                    break;

                case 'mileage':
                    data.mileage = value;
                    break;

                case 'stock':
                    data.stock = value;
                    break;

                default:
            }
        } else if(array[index].children.length > 1){
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch(label.trim()) {

                case 'vin':
                    data.vin = array[index].children[1];
                    break;

                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;

                case 'stock':
                    data.stock = array[index].children[1];
                    break;

                default:
            }
        }
        return true;
    });

    var breadcrumb = document.querySelectorAll('.breadcrumb span');

    if(breadcrumb.length>0){
        var breadcrumbArr = Array.prototype.map.call(
            breadcrumb,
            function(obj, i , arr){
                return obj;
            }
        );

        //get vehicle status
        var vehicleStatusCollector = breadcrumbArr.map(function(row, index, array){
            var breadcrumbElement = row.textContent.trim().toLowerCase();
            return (!breadcrumbElement.search(/new/g)) ? 1 : 0;
        }).reduce(function(prev, next){
            return prev + next;
        },0);

        data.vehicleStatus = vehicleStatusCollector ? '1' : '0';
    }

    if(!data.vehicleStatus){
        data.vehicleStatus = document.URL;
    }

    //get listedPrice
    data.listedPrice = document.querySelector('.price-block .buy-it-now-price')
        || document.querySelector('.price-selling .price-value')
        || document.querySelector('.price-block .selling-price')
        || document.querySelector('.price-block .price')
        || document.querySelector('.price_line_1')
        || document.querySelector('.ecl_vehicle_price .ecl_vehicle_price_pri h3')
        || document.querySelector('.price-block .price-value');

    //get retailPrice
    data.retailPrice = document.querySelector('.price-block .vdp-numbers') || document.querySelector('.price-block .price-old');

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.product-sidebar.right-side h1[itemprop="name"]') || document.querySelector('.col-md-7 .vehicle-title')
    || document.getElementById('carTitle')
    || document.querySelector('.iui_vehicle li b')
    || document.querySelector('#vehicle_title')
    || '';

    //get stock if not already gotten
    if(!data.stock){
        data.stock = document.querySelector('.product-sidebar.right-side h3')
        || document.querySelector('.vitalsColumnLeftBottom');
    }

    if(!data.mileage){
        var mileage = document.querySelector('#vitalsInfo tr:nth-child(1) .vitalsColumnRight');
        if(mileage){
            if(mileage.textContent.indexOf('ileage')>-1){
                data.mileage = mileage;
            }
        }
    }

    if(!data.vin){
        data.vin = document.querySelector('.vitalsLink');
    }

    //get img
    data.img = document.querySelector('.product-main .photo-gallery .images li.active')
        || document.querySelector('.photo-gallery')
        || document.getElementById('player')
        || document.querySelector('.mod-vehicle-gallery img')
        || '';

    if(data.img){
        data.img = data.img.querySelector('img');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice : function(data, result) {
            if(data.listedPrice.textContent.indexOf('price')<-1){
                result.listedPrice =  shimUtils.getInteger(data.listedPrice.nextSibling.nextSibling);
            }else{
                result.listedPrice = parseInt(data.listedPrice.textContent.replace(/\D/g,''),10);
            }
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus : function(data, result) {
            if(isNaN(data.vehicleStatus)){
                if(data.vehicleStatus.indexOf('new')>-1){
                    data.vehicleStatus = 1;
                }else{
                    data.vehicleStatus = 0;
                }
            }
            result.vehicleStatus = parseInt(data.vehicleStatus);
        },
        vin : function(data, result) {
            if(data.vin.textContent)
                data.vin = data.vin.textContent;
            result.vin = data.vin.trim();
        },
        stock : function(data, result) {
            var stock = data.stock;
            if(stock.textContent)
                stock = stock.textContent;
            if(stock.indexOf('#')>-1){
                result.stock = stock.split('#')[1].trim();
            }else if(stock.toLowerCase().indexOf('engine')>-1){
                stock = stock.toLowerCase().replace(/(\r\n|\n|\r)/gm," ").split(' ').filter(function(n){ return n != '' });
                stock = stock.filter(function(str) { return /\S/.test(str);});
                result.stock = stock.splice(stock.length-1,1)[0].trim();
            }else{
                result.stock = stock.trim();
            }
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        img : function(data, result) {
            var img = data.img;
            result.img =img.src;
        }
    }

    //get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;


}
