

module.exports = function(options) {
    
    options = options || {};
    
    if(options.stock && options.vehicleStatus === 0 && (options.stock[0] === "N" || options.stock[0] === "G")) {
        options.forceHideWidget = {
            msg : "Widget not allowed to show on this VDP. Stock " + options.stock + " not allowed."
        }
    }
    
    return options;
}