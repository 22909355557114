// interfaces/types
import type {
    FetchOfferDealXgResults,
    SaveOffer,
    SaveOfferResults,
    VerifyResults,
    VerifySaveOfferResults
} from './types/saveOfferTypes';
import type { SdpWidgetInitialization } from './types/initializationInterfaces';
import type { SDPWidgetHooks } from './types/SDPWidgetHooks';

// utils
import { getSDPUrl, SDPUrlOpts } from './getSdpUrl';
import { SDPFrame } from './sdpFrame';
import { SDPLogger } from './utils/sdpLogger';

type CallAndReturnSaveOfferResult = {
    saveOfferResults: SaveOfferResults;
    verify: VerifyResults;
};

export type VerifySaveOfferAsyncFnResult = VerifySaveOfferResults | FetchOfferDealXgResults;

export const hasVerifyResults = (
    saveOfferResultsOrFetchOfferDealXg: VerifySaveOfferAsyncFnResult
): saveOfferResultsOrFetchOfferDealXg is VerifySaveOfferResults => {
    const valAsFetchOfferDealXgResults = saveOfferResultsOrFetchOfferDealXg as FetchOfferDealXgResults;
    return !valAsFetchOfferDealXgResults.offer.dealer?.dealerId;
};

const callAndReturnSaveOffer = async (
    verifySaveOfferAsyncFn: () => Promise<VerifySaveOfferAsyncFnResult>,
    iframeManager: SDPFrame,
    logger: SDPLogger,
    hooks: SDPWidgetHooks
): Promise<CallAndReturnSaveOfferResult> => {
    let verifySaveOfferResult: VerifySaveOfferAsyncFnResult;
    let dnaAccountId: string | undefined;
    try {
        logger.logEvent({
            event: 'opensdp_save_offer_started'
        });

        iframeManager.showProgress();

        verifySaveOfferResult = await verifySaveOfferAsyncFn();
        if (hasVerifyResults(verifySaveOfferResult)) {
            const { verify } = verifySaveOfferResult;
            dnaAccountId = verify?.dnaAccountId;
            logger.addToContext({
                dealerId: verify?.dealerId,
                isRedesignEnabled: verify?.isRedesignEnabled
            });
        } else {
            dnaAccountId = verifySaveOfferResult.offer.dealer?.dnaAccountId;
            logger.addToContext({
                dealerId: verifySaveOfferResult.offer.dealer?.dealerId
            });
        }

        logger.logEvent({
            event: 'opensdp_save_offer_finished'
        });
    } catch (errorSaveOffer) {
        iframeManager.hide();
        logger.error(errorSaveOffer, 'opensdp_save_offer_failed');
        throw errorSaveOffer;
    }

    // if we get here, then saving the offer worked
    const { verify } = verifySaveOfferResult as VerifySaveOfferResults;

    // in the case there is no verifyResponse.result property look at the ok property
    if (verify && (!verify.result || !verify.ok)) {
        iframeManager.hide();
        const err = Error(`OpenSDP Widget configuration data error: ${JSON.stringify(verify)}`);
        logger.error(err, 'verify-no-experience');
        throw err;
    }

    if (hooks.onSuccessfulDealSetup) {
        hooks.onSuccessfulDealSetup({
            dnaAccountId
        });
    }

    // only set the offer if we are in experience deal starter
    if (verify?.experience === 'dealStarter') {
        return {
            saveOfferResults: verifySaveOfferResult.offer,
            verify
        };
    } else if (!verify) {
        // fetchDealXg offer will not have verification
        return {
            saveOfferResults: verifySaveOfferResult.offer,
            verify
        };
    } else {
        return {
            saveOfferResults: undefined,
            verify
        };
    }
};

/**
 * Orchestrate the process of calling the SDP
 * @param context info about the SDP initialization
 * @param iframeManager class instance that manages iframe visibility / status
 * @param saveOfferResultsOrVerifySaveOfferFn Either an ISaveOfferResults object which has vin/connectionId or a function that will create the offer
 */
export const openSdp = async (
    context: SdpWidgetInitialization,
    iframeManager: SDPFrame,
    saveOfferResultsOrVerifySaveOfferFn: SaveOfferResults | (() => Promise<VerifySaveOfferResults>)
): Promise<void> => {
    const logger = SDPLogger.getLogger();

    // confirm we have a context before continuing
    if (!context) {
        logger.error('cannot open SDP without context');
        return;
    }
    logger.createNewVdpVisit();
    logger.updateContext({ context });
    logger.logEvent({ event: 'opensdp_started' });

    let saveOfferResults: SaveOfferResults;
    let verifyResults: VerifyResults;

    try {
        if (typeof saveOfferResultsOrVerifySaveOfferFn === 'function') {
            const callAndReturnSaveOfferResult = await callAndReturnSaveOffer(
                saveOfferResultsOrVerifySaveOfferFn,
                iframeManager,
                logger,
                context.hooks || {}
            );
            saveOfferResults = callAndReturnSaveOfferResult.saveOfferResults;
            verifyResults = callAndReturnSaveOfferResult.verify;
        } else {
            saveOfferResults = saveOfferResultsOrVerifySaveOfferFn;
        }

        if (saveOfferResults) {
            try {
                logger.logEvent({
                    event: 'opensdp_open_modal_started'
                });

                // need sponsor here if it does not come back from API so we use the right domain
                const savedOffer = context.offer as SaveOffer;
                if (!saveOfferResults.sponsor && savedOffer && savedOffer.dealer) {
                    saveOfferResults.sponsor = savedOffer.dealer.sponsor;
                }
                const source = savedOffer?.source;
                const opts: SDPUrlOpts = {
                    offerInfo: saveOfferResults,
                    isMobile: context.isMobile,
                    verify: verifyResults,
                    route: context.route,
                    referringSource: context.pixall?.referringSource,
                    source,
                    context
                };

                const url = await getSDPUrl(opts);

                iframeManager.show(url);

                logger.logEvent({
                    event: 'opensdp_finished'
                });
            } catch (errorOpenModal) {
                logger.error(errorOpenModal, 'opensdp_open_modal_failed');
                throw errorOpenModal;
            }
        } else {
            iframeManager.hide();
        }
    } catch (errorVerify) {
        iframeManager.hide();
        logger.error(errorVerify, 'opensdp_failed');
        throw errorVerify;
    }
};
