var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        make: '',
        model: ''
    };

    if (document.URL.indexOf('new') > -1) {
        shimUtils.moveDealerOnAppFrame(options);
    }

    data.make = document.querySelector('meta[itemprop=manufacturer]');
    data.model = document.querySelector('meta[itemprop=model]');

    options.trim = '';

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        make: function(data, result) {
            if(data.make && data.make.getAttribute('content')){
                result.make = data.make.getAttribute('content')
            }
        },
        model: function(data, result) {
            if(data.model && data.model.getAttribute('content')){
                result.model = data.model.getAttribute('content')
            }
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

};
