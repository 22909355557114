import * as Cookies from 'js-cookie';
import * as scrappy from '../../shims/Scrappy';
import { validateSchema } from './validator';
import { ICADigitalRetailObject } from '../models/ICADigitalRetailObject';
import { IDcaData } from '../models/IDcaData';
import { isEmpty } from './isEmptyPolyfill';
import { Logger } from '../utils/logger';
declare const window: any;
function mapVehicleStatus(vehicleStatus: any, certifiedStatus: any) {
    const validVehicleStatusList = ['new', 'used', 'certified', 'certified used', 'n', 'u'];

    if (typeof vehicleStatus === 'string' && validVehicleStatusList.indexOf(vehicleStatus.toLocaleLowerCase()) >= 0) {
        const result = vehicleStatus.toLocaleLowerCase();

        switch (result) {
            case 'n':
                return 'new';
            case 'u':
                return 'used';
            case 'certified used':
                return 'certified';
            default:
                return result;
        }
    }

    if (vehicleStatus == 1) {
        return 'new';
    } else if (vehicleStatus == 0) {
        return certifiedStatus ? 'certified' : 'used';
    } else {
        return 'used';
    }
}
export const getVehicleStatus = (sponsor: string, scrapedData: any = {}) => {
    const { condition, vehicleStatus, certifiedStatus } = scrapedData;
    let status;

    status = vehicleStatus || condition;
    status = mapVehicleStatus(status, certifiedStatus);

    return status;
};

const getMetadata = (scrapedData: any) => {
    if (!scrapedData) {
        return null;
    }
    /*
        Please note the code below replaces the need for lodash.pick.
        This code will destructure the object and then use reassemble to
        create a new instance of the subset. Object assign here removes the
        need for lodash.extend.
     */
    const { interiorColor, exteriorColor, uuid, preference, options, packages, stockNumber } = scrapedData;

    const extendedVehicleData = {
        interiorColor,
        exteriorColor,
        uuid,
        preference,
        options,
        packages,
        stockNumber
    };
    const additionalData = { ...scrapedData?.additionalData };
    const clickPath = additionalData?.campaignData?.['cms.ClickPath'];

    if (clickPath !== undefined) {
        delete additionalData.campaignData['cms.ClickPath'];
        additionalData.campaignData.cmsClickPath = clickPath;
    }

    return {
        vehicle: extendedVehicleData,
        additionalData
    };
};

const formatData = (scrapedData: any): IDcaData => {
    let data: IDcaData = {
        config: {
            test_name: scrapedData.test_name
        },
        vehicle: {
            imageUrl: scrapedData.imgUrl || scrapedData.img || '',
            listedPrice: scrapedData.listedPrice,
            make: scrapedData.make,
            mileage: scrapedData.mileage,
            model: scrapedData.model,
            retailPrice: scrapedData.retailPrice,
            styleId: scrapedData.chromeStyleId,
            stock: scrapedData.stockNumber || scrapedData.stock,
            url: scrapedData.url,
            vin: scrapedData.vin,
            vehicleUrl: (scrapedData.vehicleUrl || '').replace(/\u0023mmd-widget/g, ''),
            year: scrapedData.year,
            trim: scrapedData.trim
        },
        dealer: {
            id: scrapedData.dealerId, // attributed to Dealer Id
            listingCode: scrapedData.listingCode,
            refId: scrapedData.refId, // Attributed to ATC Account Id (or Gearbox Owner Id)
            dnaAccountId: scrapedData.ownerId, // Currently have ownerId as dnaAccountId
            sponsor: scrapedData.sponsor,
            ddcAccountId: scrapedData.ddcAccountId,
            vdpTrackingPhoneNumber: scrapedData.vdpTrackingPhoneNumber
        },
        cookies: {
            pixallId: scrapedData.cookies ? scrapedData.cookies.pixallId : '',
            zipCode: scrapedData.zipCode
        }
    };

    if (scrapedData?.hooks) {
        data = { ...data, ...{ hooks: scrapedData.hooks } };
    }

    data.metaData = getMetadata(scrapedData);
    data.metaData.vdpTrackingPhoneNumber = scrapedData.vdpTrackingPhoneNumber;
    data.vehicle.vehicleStatus = getVehicleStatus(data.dealer && data.dealer.sponsor, scrapedData);
    const screenWidth = screen.width || window.innerWidth;

    if (scrapedData.hasOwnProperty('isMobile')) {
        data.config.isMobile = scrapedData.isMobile;
    } else if (screenWidth < 768) {
        data.config.isMobile = true;
    } else {
        data.config.isMobile = false;
    }

    if (scrapedData.hasOwnProperty('target')) {
        data.dealer.target = scrapedData.target;
    }

    return data;
};

const getPixallId = () => {
    let pixallId = null;
    const pixallCookie =
        Cookies.get('ddc_abc_cache') ||
        Cookies.get('abc') ||
        Cookies.get('pxa_id') ||
        Cookies.get('pixall_abc') ||
        Cookies.get('pxa_realid');
    if (pixallCookie) {
        pixallId = pixallCookie;
    }
    return pixallId;
};

// This function may change the input data.
const setPixallId = (data, pixallId) => {
    if (data.cookies && data.cookies.pixallId) {
        return;
    } else if (data.cookies) {
        data.cookies.pixallId = pixallId;
    } else {
        data.cookies = { pixallId };
    }
};

const executeDCA = (bootstrapData: ICADigitalRetailObject): Promise<IDcaData> => {
    return new Promise<IDcaData>(async (resolve, reject) => {
        try {
            const logger = Logger.getLogger();
            logger.logEvent({ event: 'dca_started' });
            const opts: any = {
                id: bootstrapData.dealerId,
                sponsor: bootstrapData.websiteProviderId,
                ddcAccountId: bootstrapData.accountId,
                version: bootstrapData.version,
                pixallId: getPixallId(),
                zipCode: Cookies.get('ZipCode'),
                data: {
                    dealerDataIsland: bootstrapData.vehicleData || {},
                    additionalData: bootstrapData.additionalData || {}
                }
            };
            logger.logEvent({ event: 'scrappy_started' });

            scrappy(opts, (err: any, data: any) => {
                logger.logEvent({ event: 'scrappy_finished' });
                if (err) {
                    throw err;
                }

                if (isEmpty(data)) {
                    throw Error('DCA returned an empty object');
                }

                if (data.disableLoad) {
                    throw Error('Widget is disabled for the dealer: disableLoad flag is set to true.');
                }

                data.refId = bootstrapData.refId; // Add ownerId to data
                // [US121447] Check the vehicleData to see if ownerId has a value. If yes, it is the listing code;
                // if not, use the accountId as the listing code. For ATC/KBB, this listingCode property
                // is not used in dealstarter to pass the value to DTM, ownerId is used instead.
                let listingCode = bootstrapData.vehicleData ? bootstrapData.vehicleData.ownerId : '';
                if (!listingCode) {
                    listingCode = bootstrapData.accountId;
                }
                data.listingCode = listingCode;

                data.vehicleLocationId = bootstrapData.vehicleLocationId; // Add vehicleLocationId to listingCode
                const normalizedData: IDcaData = validateSchema(data);
                const formattedData: IDcaData = formatData(normalizedData);
                if (isEmpty(formattedData.dealer.dnaAccountId)) {
                    formattedData.dealer.dnaAccountId =
                        (bootstrapData.vehicleData && bootstrapData.vehicleData.ownerId) ||
                        bootstrapData.accountId ||
                        bootstrapData.dnaAccountId;
                }

                setPixallId(formattedData, opts.pixallId);
                logger.logEvent({ event: 'dca_finished' });
                resolve(formattedData);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export { executeDCA, mapVehicleStatus, setPixallId, getPixallId };
