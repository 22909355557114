var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    //
    var vehicleOverview = document.querySelectorAll('.ui-collapsible-set .ui-collapsible .ui-collapsible-content .dataTable tbody tr');
    if(!vehicleOverview.length){
        vehicleOverview = document.querySelectorAll('table#specifiation_table tbody tr');
    }

    var pricingTable = document.querySelectorAll('div#details_vehicle_specification .vehicle_price');

    if (vehicleOverview) {
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (array[index].children.length !== 0) {
                var label = array[index].children[0].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim().toLowerCase();
                switch (label) {

                    case 'mileage':
                        data.mileage = array[index].children[1];
                        break;

                    case 'stock number':
                    case 'stock no':
                        data.stock = array[index].children[1];
                        break;

                    case 'vin':
                        data.vin = array[index].children[1];
                        break;

                    default:
                }
            }
            return true;
        });
    }

    if (pricingTable) {
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
            if (array[index].children.length !== 0) {
                var className = array[index].children[0].getAttribute('class').trim().toLowerCase();
                switch (className) {
                    case 'vdp_eprice':
                        data.listedPrice = array[index].children[0];
                        break;

                    default:
                }
            }
            return true;
        });
    }

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('.price');
    }
    //get m m t
    data.year = data.make = data.model = data.trim = document.querySelector('div#details_vehicle_specification h1')
        || document.querySelector('.page-title');

    //get vehicleStatus
    data.vehicleStatus = location.pathname;

    //get img
    data.img = document.querySelector('div#details_left .content img')

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = parseInt(data.listedPrice.textContent.replace(/\D/g, ''), 10);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },

        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = parseInt(data.mileage.textContent.replace(/\D/g, ''), 10);
        },
        vin: function(data, result) {
            result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        vehicleStatus: function(data, result) {
            if (data.vehicleStatus.toLowerCase().search(/used/g) !== -1) {
                result.vehicleStatus = 0;
            } else {
                result.vehicleStatus = 1;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
