var moveAppFrame = module.exports = {
    isElementHidden: function(el) {
        var style = window.getComputedStyle(el);
        return (style.display === 'none');
    },
    removeContainers: function() {
        //dealer has more than one widget container on responsive page
        // remove the containers that are hidden so widget loads in
        // visible one.
        var containers = document.querySelectorAll('#mmd-widget');

        for(var i = 0; i < containers.length; i++) {
            if(containers[i].offsetParent === null) {
                containers[i].parentElement.removeChild(containers[i]);
            }
        }
    },
    moveDealerOnAppFrame: function(options) {
        var desktopParent = document.getElementsByClassName('vehicleMileage')[0].nextSibling.nextSibling;
        var target = null;
        var app = null;
        var skipMoving = false;

        // Get list of targets
        var widgetContainers = document.querySelectorAll('#mmd-widget');

        // If only one continue as normal
        if (widgetContainers.length <= 1) {
            return options
        };


        for (var i = 0; i < widgetContainers.length; i++) {
            var $el = widgetContainers[i];
            var hasFrame = false;

            // Set app
            if ($el.hasChildNodes() && !app) {
                hasFrame = true;
                app = $el.childNodes[0];
            }

            // Check if current target is hidden
            if (this.isElementHidden($el.parentNode.parentNode) ||
                this.isElementHidden($el.parentNode.parentNode.parentNode)) {
                continue;
            }

            if (app && i) {
                desktopParent.appendChild($el.appendChild(app));
                skipMoving = true;
                break;
            }

            // if not hidden then current is target and
            // if it has frame, continue as normal
            if (hasFrame) {
                skipMoving = true;
                break;
            }

            // if this is the target with no frame set target.
            i === 0 ? target = desktopParent : target = $el;
        }

        // break out if continue as normal
        if (skipMoving) {
            return options
        };

        // move app to target.
        target.appendChild(app);
    },
    moveDDCAppFrame: function() {
        //remove extra containers that are hidden
        var containers = document.querySelectorAll('#mmd-widget');
        var app = null;

        for (var i = 0; i < containers.length; i++) {

            if (containers.length <= 1) {
                break;
            }

            var container = containers[i];
            var parent3 = container.parentNode.parentNode.parentNode;
            var parent5 = container.parentNode.parentNode.parentNode.parentNode.parentNode;
            var hasFrame = false;

            //set app
            if (container.hasChildNodes() && !app) {
                hasFrame = true;
                app = container.childNodes[0];
            }

            //check if current target is hidden
            //mobile container is nested deeper than desktop
            //check for main container class from known levels
            if ((parent3.className.indexOf('inventory-detail-pricing') > -1 && this.isElementHidden(parent3)) || (parent5.className.indexOf('inventory-detail-pricing') > -1 && this.isElementHidden(parent5))) {
                container.parentNode.removeChild(container);
            } else {
                container.appendChild(app);
                break;
            }

        }
    }
};
