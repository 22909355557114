import * as qs from 'qs';

/**
 * Gets querystring params into an object
 */
export const getQueryParams = (): { [key: string]: string | string[] } => {
    let queryParams = {};
    const queryString = window.location.search;
    if (queryString) {
        queryParams = qs.parse(queryString.substr(1));
    }

    return queryParams;
};

export const getQueryStringFirst = (qs: string | string[]): string => {
    if (Array.isArray(qs)) {
        return qs.length > 0 ? qs[0] : undefined;
    }

    return qs;
};

/**
 * Add a value to a qs value like enableToggles
 * @param value string or array of the existing values
 * @param toAdd value to add
 */
export const addToQueryParam = (value: any, toAdd?: string): string => {
    if (value) {
        let result = typeof value === 'string' ? value : value.join(',');

        if (toAdd) {
            if (result) {
                result = `${result},${toAdd}`;
            } else {
                result = toAdd;
            }
        }
        return result;
    }

    // if there is no value, just return the string
    return toAdd;
};
