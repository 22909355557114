
//MWO-300
//get use stock number to identify the correct dealer

module.exports = function(options) {

    options = options || {};

    // MWO-300: Need to change dealerId to sister store if stock number begins with T or U
    // This is VIN so stock number is passed to loadWidget call.  
    // Grab stock number from options and check first character
    var stock = options.stock;
    var vehicleStatus = options.vehicleStatus;

    if(vehicleStatus == 0 && stock && (stock.charAt(0) === 'P' || stock.charAt(0) === 'F')) {
        options.dealerId = 187;
    }

    return options;
}
