var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    shimUtils.moveDealerOnAppFrame(options);

    return options;
};
