var shimUtils = require('../utils');

//clickmotive

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    //get year, make, model, trim
    var pageTitle = document.querySelector('.page-title .title') || document.querySelector('.vehicle-title');

    var vehicleVin = null;
    var vehicleStockNumber = null;

    vehicleVin = document.querySelector('[class="vehicle-vin"]');
    vehicleStockNumber = document.querySelector('[class="stock-number-field"]');

    if(pageTitle){
        data.year = data.make = data.model = data.trim = pageTitle;
    }

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.inner-container .title-blurb .blurb-list-item');
    if(vehicleOverview.length===0){
        vehicleOverview = document.querySelectorAll('div#jqt div:nth-child(3) ul li');
    };

    vehicleOverview = !vehicleOverview.length ? document.querySelectorAll('.vehicle-detail-list span') : vehicleOverview;

    var pricingTableLabel = document.querySelectorAll('.discount-pricing dt');
    var pricingTableValue = document.querySelectorAll('.discount-pricing dd');

    if (vehicleOverview.length > 0) {
        var overviewArr = Array.prototype.map.call(
        vehicleOverview,

        function(obj, i, arr) {
            return obj;
        });


        var vehicleOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.textContent.indexOf(':') > -1) {
                var labelAndValue = row.textContent.replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().trim().split(':');
                var label = labelAndValue[0];
                var value = labelAndValue[1];
                switch (label) {
                    case 'department':
                        data.vehicleStatus = value;
                        break;

                    case 'year':
                        data.year = value;
                        break;

                    case 'make':
                        data.make = value;
                        break;

                    case 'model':
                        data.model = value;
                        break;

                    case 'mileage':
                        data.mileage = value;
                        break;

                    case 'stock':
                    case 'stock number':
                        data.stock = value;
                        break;

                    case 'vin':
                        data.vin = value;
                        break;

                    default:
                }
            }
            return true;
        });
    }

    if (pricingTableLabel.length > 0 && pricingTableValue.length > 0) {
        var pricingLabelArr = Array.prototype.map.call(
        pricingTableLabel,

        function(obj, i, arr) {
            return obj;
        });

        var pricingValueArr = Array.prototype.map.call(
        pricingTableValue,

        function(obj, i, arr) {
            return obj;
        });
        //750 - odgen lincoln is applying a loyalty price that doesn't apply to all customers
        //We are getting the price without the owner loyalty
        //604 - Williamsburgford we are pulling price without the Ford Credit Retail Bonus Customer Cash
        var pricingOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            var value = pricingValueArr[index];

            switch (label) {
                case 'atchley price':
                case 'dave sinclair eprice':
                case 'fred martin best value':
                case 'ogden e-price':
                //case 'price':
                case 'az plan price':
                case 'sale price':
                case 'selling price':
                case 'discount price':
                case 'internet price':
                case 'internet sale price':
                case 'holiday sale price':
                case 'buy now':
                case 'fiesta price':
                case 'buy now for':
                case 'our list price after rebates':
                   if(!data.listedPrice){
                        data.listedPrice = value;
                    }else{
                        data.retailPrice = value;
                    }
                    break;

                case 'msrp':
                case 'm.s.r.p.':
                case 'price':
                case 'market value':
                    data.retailPrice = value;
                    break;

                default:
            }
            return true;
        });
    }

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('div#jqt h2')
        || data.retailPrice;
        data.listedPrice = !document.querySelector('[name="price"]') ? data.listedPrice : document.querySelector('[name="price"]');
    }

    if(document.querySelector('.vehicle-price-base-price')) {
        data.retailPrice = document.querySelector('.vehicle-price-base-price');
    };

    if(!data.vehicleStatus){
        data.vehicleStatus = location.pathname.toLowerCase().search(/\bnew\b/g);
    }
    if(!data.year){
        data.year = document.querySelector('[name="year"]');
    }
    if(!data.make){
        data.make = document.querySelector('[name="make"]');
    }
    if(!data.model){
        data.model = document.querySelector('[name="model"]');
    }
    if(!data.trim){
        data.trim = document.querySelector('[name="trim"]');
    }
    if(!data.stock){
        data.stock = !vehicleStockNumber ? document.querySelector('[name="stock_number"]') : vehicleStockNumber;
    }
    if(!data.vin){
        data.vin = !vehicleVin ? document.querySelector('[name="vin"]') : vehicleVin;
    }
    if(!data.img){
        data.img = document.querySelector('.image-1 img');
        if(document.querySelectorAll('.hero-shot-frame').length){
            data.img = document.querySelectorAll('.hero-shot-frame')[0].children[0];
        }
        if(!data.img){
            data.img = document.querySelector('img#picture');
        }
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            var listedPrice = !data.listedPrice.value ? data.listedPrice : data.listedPrice.value;

            if(listedPrice && listedPrice.innerHTML){
                if (listedPrice && listedPrice.innerHTML.indexOf('sup') > -1) {
                    listedPrice = listedPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                    result.listedPrice = parseInt(listedPrice, 10);
                };
            }else if(listedPrice && listedPrice.textContent){
                if(listedPrice && listedPrice.textContent.toLowerCase().indexOf('price')>-1){
                    listedPrice = listedPrice.textContent.toLowerCase().split(':')[1];
                    result.listedPrice = shimUtils.getInteger(listedPrice);
                }
            } else {
                result.listedPrice = shimUtils.getInteger(listedPrice);
            }
        },
        retailPrice: function(data, result) {
            var retailPrice = !data.retailPrice.value ? data.retailPrice : data.retailPrice.value;
            if(retailPrice.innerHTML){
                if (retailPrice.innerHTML.indexOf('sup') > -1) {
                    retailPrice = retailPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                    result.retailPrice = parseInt(retailPrice, 10);
                }
            } else {
                result.retailPrice = shimUtils.getInteger(retailPrice);
            }
        },
        year: function(data, result) {
            var year = !data.year.value ? data.year : data.year.value;
            if(!year.textContent){
                result.year = shimUtils.getInteger(year);
            }else{
                year = year.textContent.trim().split(' ');
                //When vehicleStatus is the first string on the title
                if(parseInt(year[0],10)){
                    result.year = parseInt(year[0], 10);
                }else{
                    result.year = parseInt(year[2], 10);
                }
            }
        },
        make: function(data, result) {
            var make = !data.make.value ? data.make : data.make.value;
            if(!make.textContent){
                result.make = make.trim();
            }else{
                make = make.textContent.trim().split(' ');
                //When vehicleStatus is the first string on the title
                if(parseInt(data.year.textContent.trim().split(' ').shift(),10)){
                    result.make = make.splice(1, 1)[0];
                }else{
                    result.make = make[3];
                }
            }
        },
        model: function(data, result) {
            var model = !data.model.value ? data.model : data.model.value;
            if(!model.textContent){
                result.model = model.trim();
            }else{
                model = model.textContent.trim().split(' ');
                if(parseInt(data.year.textContent.trim().split(' ').shift(),10)){
                    result.model = model.splice(2, 1)[0];
                }else{
                    result.model = model[4];
                }
            }
        },
        trim: function(data, result) {
            var trim = !data.trim.value ? data.trim : data.trim.value;
            if(!trim.textContent){
                result.trim = trim.trim();
            }else{
                trim = trim.textContent.trim().split(' ');
                if(parseInt(data.year.textContent.trim().split(' ').shift(),10)){
                    result.trim = trim.splice(3, trim.length).join(' ');
                }else{
                    result.trim = trim.splice(5, trim.length).join(' ');
                }
            }
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            if(isNaN(status)){
                if(status.indexOf('new')>-1){
                    status = 1;
                }else{
                    status = 0;
                }
            }
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        mileage: function(data, result) {
            result.mileage = parseInt(data.mileage, 10);
        },
        vin: function(data, result) {
            var vin = !data.vin.value ? data.vin : data.vin.value;
            result.vin = vin.trim();
        },
        stock: function(data, result) {
            var stock = !data.stock.value ? data.stock : data.stock.value;
            stock = !data.stock.textContent ? stock : data.stock.textContent;
            result.stock = stock.trim();
        },
        img: function(data, result) {
            if(data.img.nodeName === "HTMLDivElement"){
                result.img = data.img.children[0].src;
            }else{
                result.img = data.img.src;
            }
        }
    };

    //call continue to get data
    options = shimUtils.continue (data, tasks, options);

    //set isShimmed
    options.isShimmed = true;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

};
