var shimUtils = require('../../utils');

/**
 * DealerOn has a couple different templates for their sites.
 * This template type is determine by running ContextManager.getPageLayout() on the console
 */
var desktopShimOne = require('./desktop.shim.one');
var desktopShimTwo = require('./desktop.shim.two');
var desktopShimThree = require('./desktop.shim.three');

var mobileShimOne = require('./mobile.shim.one');


module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        incentiveSavings: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    if (options.version >= '5.0.0') {
        var target = null;

        // Get list of targets
        var widgetContainers = document.querySelectorAll('#mmd-widget');

        // If only one continue as normal
        if (widgetContainers.length > 1) {
            findContainerLoop: for (var i = 0; i < widgetContainers.length; i++) {
                var $el = widgetContainers[i];
        
                if (shimUtils.isVisible($el)) {
                    target = $el;
                    break findContainerLoop;
                }
            }

            options.target = target;
        }

        return options;
    }

    //querySelectors for the template types
    var mainDiv_template1 = document.querySelectorAll('#content');
    var mainDiv_template2 = document.querySelectorAll('#wrapper');

    //get internet address
    var href = location.href.toLowerCase();

    // array of shims organized by the template type
    var shims = {
        desktopOne: desktopShimOne,
        desktopTwo: desktopShimTwo,
        desktopThree: desktopShimThree,
        mobileOne: mobileShimOne
    };

    //get vehicleStatus
    data.vehicleStatus = (href.search(/used/));

    if (mainDiv_template1.length) {
        shims.desktopOne({
            data: data,
            options: options
        });
    } else if (mainDiv_template2.length) {
        shims.desktopTwo({
            data: data,
            options: options
        });
    } else if (href.search(/mobi/) > 0) {
        shims.mobileOne({
            data: data,
            options: options
        });
    } else {
        shims.desktopThree({
            data: data,
            options: options
        });
    };

    options.isShimmed = true;

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
};
