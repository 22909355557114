/**
 * DealerOn first desktop shim
 */

var shimUtils = require('../../utils');

module.exports = function(opts) {

    var data = opts.data;
    var options = opts.options;

    //get listedPrice and retailPrice
    var pricingTable = document.querySelectorAll('.priceBlockResponsiveVdp li');
    if(pricingTable.length>0){
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
            if(array[index].children.length > 1) {
                var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                switch(label.trim()) {

                    case 'msrp':
                    case 'retail price':
                    case 'market list price':
                        data.retailPrice = array[index].children[1];
                        break;

                    case 'final price':
                    case 'ehrlichs price':
                    case 'garys price':
                    case 'internet price':
                    case 'mike shaw price':
                    case 'pure car price':
                    case 'hoover country price':
                    case 'price':
                    case 'preferred sale price':
                    case 'make your best offer':
                    case 'mvp price':
                        data.listedPrice = array[index].children[1];
                        break;

                    default:
                }
            }
            return true;
        });
    }else{
        var internetPrice = document.querySelector('.srpVehiclePricing .internetPrice span[itemprop="price"]') || document.querySelector('.srpVehiclePricing .internetPrice .pull-right');
        var finalPrice = document.querySelector('.srpVehiclePricing .finalPrice span[itemprop="price"]');
        data.retailPrice = document.querySelector('.srpVehiclePricing .msrpPrice .strike') || document.querySelector('.srpVehiclePricing span[itemprop="price"]') || document.querySelector('.srpVehiclePricing .msrpPrice span');

        data.incentiveSavings = document.querySelector('.srpVehiclePricing .savePrice');

        //set listedPrice
        //based on available element
        if (!finalPrice && internetPrice) {
            data.listedPrice = internetPrice;
        } else if (!internetPrice && !finalPrice) {
            data.listedPrice = data.retailPrice
        } else {
            data.listedPrice = finalPrice;
        }
    }

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.vehicleTitle span[itemprop="name"]') || document.querySelector('.vehicleTitleContainer .vehicleTitle') || document.querySelector('.vdp-vehicle-title .h4');;

    //get mileage
    data.mileage = document.querySelector('#mileageValue span') || document.querySelector('.vdp-vehicle-title li:nth-child(4)');

    //get vin
    data.vin = document.querySelector('.vinDisplay span') || document.querySelector('.vdp-vehicle-title li:nth-child(1)');

    //get stock
    data.stock = document.querySelector('.stockDisplay span') || document.querySelector('.vdp-vehicle-title li:nth-child(2)');;

    //get img
    data.img = document.querySelector('#vehicleImgLarge .item img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        year: function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make: function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model: function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.model = model.splice(2, 1)[0];
        },
        trim: function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status >= 0) ? 0 : 1;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock: function(data, result) {
            var stock = '';
            if((typeof data.stock) === 'string') {
                stock = data.stock;
            }
            else stock = data.stock.textContent;

            if (stock.search(/:/g) > -1) {
                result.stock = stock.split(':')[1].trim();
            } else {
                result.stock = stock.trim();
            }
        },
        vin: function(data, result) {
            var vin = '';
            if((typeof data.vin) === 'string') {
                vin = data.vin;
            }
            else vin = data.vin.textContent;

            if (vin.search(/:/g) > -1) {
                result.vin = vin.split(':')[1].trim();
            } else {
                result.vin = vin.trim();
            }
        },
        img: function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
    };

    // call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
