//get correct price

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.sponsor === 'vin') {
        var data = {
          retailPrice : '',
          listedPrice : '',
        };

        data.listedPrice = document.querySelector('.pricing .final span')
                    || document.querySelector('.pricing .single span');

        //set listedPrice and retailPrice
        data.retailPrice = document.querySelector('.pricing .initial span');

        //logging
        shimUtils.callValueQuerySelector(data,options);

        //define tasks to get data
        var tasks = {
          listedPrice : function(data, result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
          },
          retailPrice : function(data,result){
            result.retailPrice = shimUtils.getInteger(data.retailPrice);

          }
        }

        //call continue to get data
        options = shimUtils.continue(data, tasks, options);
    }

    return options;

}
