//KBB Report DCA

module.exports = function(options) {

	options = options || {};

	//get listedPrice & retailPrice
	var price = document.querySelector('.kbb-vehicle-detail-price .price');
	var basePrice = document.querySelector('.summary-box .base-price-row .price');

	//make listedPrice 0 if no price
	//so onHidingFunction runs
	if(!price) {
		options.listedPrice = 0;
	} else {
		price = price.textContent.replace(/\D/g, '');
		options.listedPrice = parseInt(price, 10);
	}

	if(!!basePrice) {
		basePrice = basePrice.textContent.replace(/\D/g, '');
		options.retailPrice = parseInt(basePrice, 10);
	}

	//get year, make, model
	var ymm = document.querySelector('.kbb-vehicle-detail-desc .kbb-vehicle-detail-ymm');
	ymm = ymm.textContent.split(' ');

		//get year
		var year = ymm.shift();
		options.year = parseInt(year, 10);

		//get make
		options.make = ymm.shift();

		//get model
		options.model = ymm.join(' ').trim();

	//get trim
	var trim = document.querySelector('.kbb-vehicle-detail-desc .kbb-vehicle-detail-series');
	options.trim = trim.textContent.trim().replace(/\n/g, '');

	//use first heading in vehicle details section
	//to get vehicle status
	var headingDetails = document.querySelector('.summary-box .heading-label').textContent.toLowerCase();

	if(headingDetails.search(/odometer/) != -1) {
		options.vehicleStatus = 0;
	} else {
		options.vehicleStatus = 1;
	}

	//get mileage
	var mileageLabel = document.querySelector('.summary-section-vehicle-details .summary-box .heading-label').textContent;
	if(mileageLabel == "Odometer") {
		var mileage = document.querySelector('.summary-section-vehicle-details .summary-box .h1');
		mileage = mileage.textContent.replace(/\D/g, '');
		options.mileage = parseInt(mileage, 10);
	} else {
		options.mileage = 1;
	}

	//get vin
	var vin = document.querySelector('.KeyDetails li:nth-child(2) .KeyDetailsItem');
	options.vin = vin.textContent;

	//get stock
	var stock = document.querySelector('.KeyDetails li:nth-child(3) .KeyDetailsItem');
	options.stock = stock.textContent;

	//get img
	var img = document.querySelector('.kbb-vehicle-detail-photo img');
	options.img = img.src;

	//get url
	options.url = document.URL;

	//set loaded function
	if(options.handlers.hasOwnProperty('onLoadedFunction')) {
		options.onLoadedFunction = options.handlers.onLoadedFunction;
	} else {
		options.onLoadedFunction = function() { console.log('widget loaded!'); }
	}

	//set hiding function
	if(options.handlers.hasOwnProperty('onHidingFunction')) {
		options.onHidingFunction = options.handlers.onHidingFunction;
	} else {
		options.onHidingFunction = function() { console.log('widget not showing!'); }
	}

	return options;

}
