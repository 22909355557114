var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //only display on page if "Presented by Stephen Wade chrysler jeep dodge" exists on page
    var stephenWade = document.getElementById('printInfo') || document.querySelector('.dealer-info h4:nth-child(1)');

    if(stephenWade && stephenWade.textContent.trim().toLowerCase()!=='presented by stephen wade chrysler jeep dodge'){
        options.disableLoad = true;
    }

    return options;
};