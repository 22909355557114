var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    //get listedPrice
    data.listedPrice = document.querySelector('.mdet2-premidd-price')
        || document.querySelector('.mdet-price')
        || document.querySelector('.mdet2-Des-pr2');

    //get year
    data.year = data.make = data.model = data.trim = document.querySelector('.mdet2-Des-name')
        || document.querySelector('.msiMB-br-link');

    //get stock
    data.stock = document.querySelector('.mdet2-Des-table tr:nth-child(5) td:nth-child(2)')
        || document.querySelector('.mdet-stock');

    var vehicleOverview = document.querySelectorAll('.mdet-specefic .mdet-st-bor div');

    if(vehicleOverview.length){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );
        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children.length<3) {
                var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().trim().split(':');
                var label = labelAndValue[0].trim();
                var value = labelAndValue[1];

                switch (label) {
                    case 'vin':
                        data.vin = value;
                        break;

                    case 'odometer':
                        data.mileage = value;
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    //get mileage
    if(!data.mileage){
        data.mileage = document.querySelector('.mdet2-Des-table tr:nth-child(2) td:nth-child(2)');
    }

    //get vin
    if(!data.vin){
        data.vin = document.querySelector('.mdet2-Des-table tr:nth-child(6) td:nth-child(2)');
    }

    //get img
    data.img = document.querySelector('.mdet-img-prew-1 img')
        || document.querySelector('.minv-img img');


    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to collect data
    var tasks = {
        listedPrice : function(data, result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year : function(data, result){
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data,result){
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data,result){
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data,result){
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage : function(data,result){
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock : function(data,result){
            if(data.stock.textContent){
                data.stock = data.stock.textContent;
            };
            if(data.stock.indexOf('#')>-1){
                data.stock = data.stock.split('#')[1];
            };
            result.stock = data.stock.trim();
        },
        vin : function(data,result){
            if(data.vin.textContent){
                data.vin = data.vin.textContent;
            };
            result.vin = data.vin.trim();
        },
        img : function(data,result){
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data,tasks,options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction =  function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    return options;
}
