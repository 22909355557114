
//hotfix to switch dealer id 425 to 421
//if the domain is gallerygroup

module.exports = function(options) {

	if(location.host === "www.gallerygroup.com") {
		options.dealerId = 421;
	}

	return options;

}
