var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    if(location.href.search(/mobi/g) === -1) {

        //get listed and retail prices
        data.retailPrice = document.querySelector('.price-table .PriceStrikethrough')
                        || document.querySelector('.price-table .price-display-value.fBold ');
            
        data.listedPrice = document.querySelector('div[itemprop="price"] span');

        //get year, make, model
        data.year = data.make = data.model = document.querySelector('h1[itemprop="name"] .middleModelAndDescTop');

        //get trim
        data.trim = document.querySelector('#DetailVehicleDescription .fs18');

        //get status
        data.vehicleStatus = document.querySelector('#vehicleinfodesc .DescriptionColumnContent');

        //get mileage
        data.mileage = document.querySelector('#vehicleinfodesc .DescriptionColumnContent:nth-child(11)');

        //get stock
        data.stock = document.querySelector('.vehicleNumbers .floatRight div:not([class="mgb5"])');

        //get vin
        data.vin = document.querySelector('.vehicleNumbers .floatRight .mgb5');

        //get img
        data.img = document.querySelector('#PhotoViewerTab1Content img');

       
    } else {

       //get listed and retail prices
        data.listedPrice = document.querySelector('.priceContainer span:not([class*="PriceStrikethrough"])');
            
        data.retailPrice = document.querySelector('.priceContainer .PriceStrikethrough');

        //get year, make, model
        data.year = data.make = data.model = document.querySelector('.vehicleTitle.attention');

        //get trim
        data.trim = document.querySelector('.vehicleSubTitle');

        //get status
        data.vehicleStatus = document.querySelector('.itemConditionInfo');

        //get mileage and stock
        data.mileage = data.stock = document.querySelector('.itemBootomInfo');

        //get vin
        data.vin = document.querySelector('.vehicleDetailSection .vehicleDetailItem:nth-child(6) .detail')
                        || document.querySelector('.vehicleDetailSection .vehicleDetailItem:nth-child(5) .detail');;

        //get img
        data.img = document.querySelector('#mainImgCenter img');
    }

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
       listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().replace(/\n/g, ' ').split(' ');
            result.make = make.splice(1, 1)[0].trim();
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().replace(/\n/g, ' ').split(' ');
            result.model = model.splice(2, model.length).join(' ').trim();
        },
        trim : function(data, result) {
            var trim = data.trim;
            result.trim = trim.textContent.trim();
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus.textContent.toLowerCase();
            result.vehicleStatus = (status.search(/used/g) > -1) ? 0 : 1;
        },
        mileage : function(data, result) {
            if(location.href.search(/mobi/g) == -1) { //desktop
                result.mileage = shimUtils.getInteger(data.mileage);
            } else {
                var mileage = data.mileage;
                mileage = mileage.textContent.split('Stock')[0].replace(/\D/g, '');
                result.mileage = parseInt(mileage, 10);
            }
        },
        stock : function(data, result) {
            if(location.href.search(/mobi/g) == -1) { //desktop
                var stock = data.stock;
                result.stock = stock.querySelector('span').nextSibling.textContent.trim();
            } else {
                var stock = data.stock;
                result.stock = stock.textContent.split(':')[2].trim();
            }
        },
        vin : function(data, result) {
            if(location.href.search(/mobi/g) == -1) { //desktop
                var vin = data.vin;
                result.vin = vin.querySelector('span').nextSibling.textContent.trim();
            } else {
                result.vin = data.vin.textContent.trim();
            }
        },
        img : function(data, result) {
            result.img = data.img.src;
        } 
    }

    //call continue to get data
    options = shimUtils.continue(data,tasks,options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    options.isShimmed = true;


    return options;
}
