var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        retailPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    //get listedPrice
    data.listedPrice = document.querySelector('.single-stacked-price h3')
        || document.querySelector('.asking-price .strike-through');

    data.retailPrice = document.querySelector('.double-stacked-price');

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('h2[itemprop="name"]');

    //get vehicleStatus
    data.vehicleStatus = document.querySelector('.vehicle-data span[itemprop="itemCondition"]');

    //get mileage'
    data.mileage = document.querySelector('.vehicle-data tr:nth-child(6) td:nth-child(1) span');

    //get vin
    data.vin = document.querySelector('.vehicle-data .vdp-vin');

    //get stock
    data.stock = document.querySelector('.vehicle-data span[itemprop="productID"]');

    //get imageViewer
    data.img = document.querySelector('.vdp-vehicle-image');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.textContent.trim().toLowerCase();
            result.vehicleStatus = (status === 'pre-owned' || status === 'used') ? 0 : 1;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            var vin = data.vin.textContent.trim();
            result.vin = vin;
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
}
