
//dealer has more than one widget container on responsive page
//remove the containers that are hidden so widget loads in
//visible one.

var shimUtils = require('../utils');

module.exports = function(options) {
   
   options = options || {};

   shimUtils.moveDealerOnAppFrame(options);

   return options;
	
}
