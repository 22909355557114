//autodealertech
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    data.listedPrice = document.querySelector('.car-detail-heading .auto-price span');

    data.retailPrice = document.querySelector('.car-detail-heading .auto-price em');

    data.stock = document.querySelector('.car-detail-heading .auto-text span');

    // grab data from vehicle overview table
    var vehicleOverviewLabel = document.querySelectorAll('.auto-specifications .specifications-info li span');

    var vehicleOverviewValue = document.querySelectorAll('.auto-specifications .specifications-info li strong');

    if(vehicleOverviewLabel.length>0 && vehicleOverviewValue.length>0){
        var overviewLabelArr = Array.prototype.map.call(
            vehicleOverviewLabel,
            function(obj,i,arr){
                return obj;
            }
        );

        var overviewValueArr = Array.prototype.map.call(
            vehicleOverviewValue,
            function(obj,i,arr){
                return obj;
            }
        );

        var siteOptionsCollector = overviewLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~:%'"*?<>{}]/g, '').toLowerCase().trim();
            var value = overviewValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase()
            switch(label) {
                case 'year':
                    data.year = value;
                    break;

                case 'make':
                    data.make = value;
                    break;

                case 'model':
                    data.model = value;
                    break;

                case 'trim':
                    data.trim = value;
                    break;

                case 'mileage':
                    data.mileage = value;
                    break;

                case 'vin':
                    data.vin = value;
                    break;

                case 'condition':
                    data.vehicleStatus = value;

                default:
            }
            return true;
        });
    }

    data.img = document.querySelector('.main-section .page-section .page-section img');

    options.isDeferred = false;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            result.year = data.year.trim();
        },
        make : function(data, result) {
            result.make = data.make.trim();
        },
        model : function(data, result) {
            result.model = data.model.trim();
        },
        trim : function(data, result) {
            result.trim = data.trim.trim();
        },
        vehicleStatus : function(data, result) {
            if(data.vehicleStatus === 'new'){
                result.vehicleStatus = 1;
            }
            else {
                 result.vehicleStatus = 0;
            }
        },
        vin : function(data, result) {
            result.vin = data.vin.trim();
        },
        stock : function(data, result) {
            var stockArr = data.stock.textContent.split('#');
            if(stockArr.length > 1){
                result.stock = stockArr[1];
            }
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;


}
