/**
 * MMD-1654: Selfhosted dealer site with many MMD IDs.  Need to replicate the shim across all dealerIds.
 * ALERT: When changing this shim, be sure to test/change all the following IDs as well
 * IDs: 807, 809, 810, 811, 812, 818, 819
 */

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        retailPrice: '',
        dealerSavings: '',
        incentiveSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    var vehicleDetails = document.querySelectorAll('table.car-detail-table tbody tr');

    if (vehicleDetails.length > 0 ) {
        var detailsArr = Array.prototype.map.call(
            vehicleDetails,
            function(obj, i, arr) {
                return obj;
            }
        );

        var collector = detailsArr.filter(function(row, index, array) {
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/\s/g,'').toLowerCase();
            switch(label) {
                case 'price':
                    data.listedPrice = array[index].children[1];
                    break;
                case 'msrp':
                    data.retailPrice = array[index].children[1];
                    break;
                case 'year':
                    data.year = array[index].children[1];
                    break;
                case 'make':
                    data.make = array[index].children[1];
                    break;
                case 'model':
                    data.model = array[index].children[1];
                    break;
                case 'trim':
                    data.trim = array[index].children[1];
                    break;
                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;
                case 'stock':
                    data.stock = array[index].children[1];
                    break;
                case 'vin':
                    data.vin = array[index].children[1];
                    break;
            }
            return true;
        });
    }

    data.vehicleStatus = location.pathname.toLowerCase();

    data.img = document.querySelector('div#car-carousel .carousel-inner .item img');

    //grab from mmd.widgetData if on page
    if(window.mmd.widgetData) {
        var widgetData = window.mmd.widgetData;

        data.listedPrice = widgetData.listedPrice ? widgetData.listedPrice : data.listedPrice;

        data.retailPrice = widgetData.retailPrice ? widgetData.retailPrice : data.retailPrice;

        data.dealerSavings = widgetData.dealerSavings ? widgetData.dealerSavings : data.dealerSavings;

        data.img = widgetData.img ? widgetData.img : data.img;

        data.incentiveSavings = widgetData.incentiveSavings ? widgetData.incentiveSavings : data.incentiveSavings;

        data.make = widgetData.make ? widgetData.make : data.make;

        data.mileage = widgetData.mileage ? widgetData.mileage : data.mileage;

        data.model = widgetData.model ? widgetData.model : data.model;

        data.vin = widgetData.vin ? widgetData.vin : data.vin;

        data.year = widgetData.year ? widgetData.year : data.year;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            data.listedPrice = data.listedPrice.textContent ? data.listedPrice.textContent : data.listedPrice;
            result.listedPrice = data.listedPrice;
        },
        retailPrice: function(data, result) {
            data.retailPrice = data.retailPrice.textContent ? data.retailPrice.textContent : data.retailPrice;
            result.retailPrice = data.retailPrice;
        },
        dealerSavings: function(data, result) {
            data.dealerSavings = data.dealerSavings.textContent ? data.dealerSavings.textContent : data.dealerSavings;
            result.dealerSavings = data.dealerSavings;
        },
        incentiveSavings: function(data,result) {
            data.incentiveSavings = data.incentiveSavings.textContent ? data.incentiveSavings.textContent : data.incentiveSavings;
            result.incentiveSavings = data.incentiveSavings;
        },
        year: function(data, result) {
            data.year = data.year.textContent ? data.year.textContent : data.year;
            result.year = data.year;
        },
        make: function(data, result) {
            data.make = data.make.textContent ? data.make.textContent : data.make;
            result.make = data.make;
        },
        model: function(data, result) {
            data.model = data.model.textContent ? data.model.textContent : data.model;
            result.model = data.model;
        },
        trim: function(data, result) {
            data.trim = data.trim.textContent ? data.trim.textContent : data.trim;
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            data.mileage = data.mileage.textContent ? data.mileage.textContent : data.mileage;
            result.mileage = data.mileage;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if(status && status.search(/new/g)) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        vin: function(data, result) {
            data.vin = data.vin.textContent ? data.vin.textContent : data.vin;
            result.vin = data.vin;
        },
        stock: function(data, result) {
            data.stock = data.stock.textContent ? data.stock.textContent : data.stock;
            result.stock = data.stock;
        },
        img: function(data, result) {
            data.img = data.img.src ? data.img.src : data.img;
            result.img = data.img;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
