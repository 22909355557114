var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice : '',
        listedPrice : '',
        mileage : '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.sub-header .vehicle-title-header .vehicle-detail-list span');
	
    var overviewArr = Array.prototype.map.call(vehicleOverview, 
        function(obj, i, arr){
            return obj.textContent.toLowerCase().trim().split(/\n/);
        }
    );


	var siteOptionsArr = overviewArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array){
		
        if(row.length >1) {

	        var label = row[0].replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim();
            var value = row[1].trim();

			if(label in data) {
                data[label] = value;
            }
		}

        return true;
    });

    var mobileCheck = document.querySelector('.ddc-icon-menu.ddc-icon.ddc-nav-icon');

    var href = location.href.toLowerCase();
    
    //get listedPrice, retailPrice and img
    if(!mobileCheck) { //desktop

        data.retailPrice = document.querySelector('.msrp .price')
                    || document.querySelector('.discount-pricing .vehicle-price-base-price') 
                    || document.querySelector('.vehicle-price-default-price');
        data.listedPrice = document.querySelector('.final-price .price')
                    || document.querySelector('.discount-pricing .vehicle-price-discounted-price') 
                    || document.querySelector('.vehicle-price-default-price');

        data.img = document.querySelector('.ddc-content.inventory-detail-media-tabs .imageViewer .photo') 
                || document.querySelector('.hero-shot-frame img');

    } else { //mobile

        if(href.search(/new/g) != -1) {
            data.listedPrice = document.querySelector('.pricing.multiple-prices .askingPrice .h4.price');
            data.retailPrice = document.querySelector('.pricing.multiple-prices .msrp .h4.price');
        } else if(href.search(/used/g) > 0) {
            data.listedPrice = document.querySelector('.pricing.multiple-prices .final-price .h4.price');
        }

        data.img = document.querySelector('.slide img:first-child');
    }

	//logging
	shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {

        listedPrice : function(data, result) {
            var listedPrice = data.listedPrice;
            if(listedPrice.innerHTML.indexOf('sup') > -1) {
                listedPrice = listedPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                result.listedPrice = parseInt(listedPrice, 10);
            } else {
                result.listedPrice = shimUtils.getInteger(listedPrice);
            }
        },
        
        retailPrice : function(data, result) {
            var retailPrice = data.retailPrice;
            if(retailPrice.innerHTML.indexOf('sup') > -1) {
                retailPrice = retailPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                result.retailPrice = parseInt(retailPrice, 10);
            } else {
                result.retailPrice = shimUtils.getInteger(retailPrice);
            }
        },
     
        mileage: function(data, result) {
			if(data.mileage) {
				result.mileage = parseInt(data.mileage);
			}
        },
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    options.isShimmed = true;

    return options;
}
