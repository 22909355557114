'use strict';

var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

   var data = {
       year: '',
       make: '',
       model: '',
       trim: '',
       listedPrice: '',
       retailPrice: '',
       vehicleStatus: '',
       mileage: '',
       vin: '',
       stock: '',
       img: ''
   };

   data.year = document.querySelector('[itemprop="releaseDate"]');
   data.make = document.querySelector('[itemprop="manufacturer"]');
   data.model = document.querySelector('[itemprop="model"]');
   data.trim = document.querySelector('[itemprop="trim"]');

   var vehicleOverview = document.querySelectorAll('.subInfo li');
   var vehicleOverview2 = document.querySelectorAll('.vehicleInfo li');
   var pricingTable = document.querySelectorAll('.mathboxWidget li');

   if(vehicleOverview.length>0){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj,i,arr){
                return obj;
            }
        );
   };

   if(vehicleOverview2.length>0){
        var overviewArr2 = Array.prototype.map.call(
            vehicleOverview2,
            function(obj,i,arr){
                return obj;
            }
        );
   };

   if(pricingTable.length>0){
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj,i,arr){
                return obj;
            }
        );
   }

    if(pricingArr || overviewArr || overviewArr2){
        if(overviewArr && overviewArr2){
            var overviewArr = overviewArr.concat(overviewArr2);
        }else if(!overviewArr && overviewArr2){
            var overviewArr = overviewArr2;
        }
        if(pricingArr && overviewArr){
            var siteOptionsArr = overviewArr.concat(pricingArr);
        }else if(pricingArr && !overviewArr){
            var siteOptionsArr = pricingTable;
        }else if(!pricingArr && overviewArr){
            var siteOptionsArr = overviewArr;
        }

        var siteOptionsCollector = siteOptionsArr.filter(function(row,index,array){
        if(array[index].children.length !== 0){
            var label = array[index].querySelector('span:nth-child(1)').textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
            switch(label){
                case 'msrp':
                    data.retailPrice = array[index];
                    break;

                case 'mileage':
                    data.mileage = array[index];
                    break;

                case 'sale price':
                    data.listedPrice = array[index];
                    break;

                case 'vin':
                    data.vin = array[index];
                    break;

                case 'stock':
                    data.stock = array[index];
                    break;

                default:
            }
        }
        return true;
        });
    }

    //set retailPrice if not already Set
    if(!data.retailPrice){
        data.retailPrice = document.querySelector('.offer-item span:nth-child(2)');
    }

    //set listedPrice if not already set
    if(!data.listedPrice){
        data.listedPrice = document.querySelector('.salePrice span:nth-child(2)');
    }

    //set vehicleStatus
    data.vehicleStatus = document.querySelector('[itemprop="itemCondition"]');

    //set img
    data.img = document.querySelector('.flex-active-slide img');

    //logging
	shimUtils.callValueQuerySelector(data, options);

    //define tasks to get Data
    var tasks = {
        year: function(data, result){
            result.year = shimUtils.getInteger(data.year,10);
        },
        make: function(data, result){
            result.make = data.make.textContent.trim();
        },
        model: function(data, result){
            result.model = data.model.textContent.trim();
        },
        trim: function(data, result){
            result.trim = data.trim.textContent.trim();
        },
        mileage: function(data, result){
            if(data.mileage.textContent){
                var mileage = data.mileage.textContent.split(' ').filter(function(str) {return /\S/.test(str);}).map(function(s) { return s.trim() }).join(' ').replace(/\D/g, '');
            }else{
                mileage = data.mileage;
            }
            result.mileage = parseInt(mileage,10);
        },
        retailPrice: function(data, result){
            var retailPrice = data.retailPrice.textContent.split(' ').filter(function(str) {return /\S/.test(str);}).map(function(s) { return s.trim() }).join(' ').replace(/\D/g, '');
            result.retailPrice = parseInt(retailPrice,10);
        },
        listedPrice: function(data, result){
            var listedPrice = data.listedPrice.textContent.split(' ').filter(function(str) {return /\S/.test(str);}).map(function(s) { return s.trim() }).join(' ').replace(/\D/g, '');
            result.listedPrice = parseInt(listedPrice,10);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            status = status.textContent.trim().toLowerCase();
            result.vehicleStatus = (status === 'new') ? 1 : 0;
        },
        vin: function(data, result){
            var vin = data.vin.textContent.split(':')[1];
            result.vin = vin.trim();
        },
        stock: function(data, result){
            var stock = data.stock.textContent.split(':')[1];
            result.stock = stock.trim();
        },
        img: function(data, result){
            result.img = data.img.getAttribute('data-original');
        }
    };

    options = shimUtils.continue(data,tasks,options);

    options.isShimmed = true;

    return options;

};
