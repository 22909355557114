var shimUtils = require('../../utils');

module.exports = function(opts) {

    options = opts || {};

    var data = opts.data;
    var options = opts.options;

    if(location.href.search(/mobi/g) === -1) {

        var pricingTable = document.querySelectorAll('.price-box-detail .price-table tbody td');

        if(pricingTable.length){
            var overviewPrice = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

            var siteOptionsCollector = overviewPrice.filter(function(row, index, array) {
                if (row.children[0]) {
                    var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

                    switch (label) {
                        case 'nada retail price':
                        case 'retail price':
                            data.retailPrice = row.children[1].textContent.replace(replaceRegex, '').trim();
                            break;

                        case 'asking price':
                        case 'internet price':
                        case 'internet special price':
                        case 'no-haggle price':
                            data.listedPrice = row.children[1].textContent.replace(replaceRegex, '').trim();
                            break;

                        case 'my savings':
                        case 'dealer discount':
                            data.dealerSavings = row.children[1].textContent.replace(replaceRegex, '').trim();
                            break;

                        default:
                            break;
                    }

                    if( row.children[0].children[0]) {
                        var label = row.children[0].children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

                        switch (label) {
                            case 'msrp':
                                data.retailPrice = row.children[0].children[1].textContent.replace(replaceRegex, '').trim();
                                break;

                            default:
                                break;
                        }
                    }
                }
                return true;
            });
        }

        if(!data.listedPrice){
            data.listedPrice = document.querySelector('div[itemprop="price"] span')
                || document.querySelector('.price-display-value-highlight-f16')
                || document.querySelector('.price-table .price-display-value-highlight');
        }
        //get listed and retail prices
        if(!data.retailPrice && data.listedPrice){
            data.retailPrice = document.querySelector('.price-table .PriceStrikethrough')
                || document.querySelector('.price-table .price-display-value.fBold ');
        }

        //get year, make, model
        data.year = data.make = data.model = document.querySelector('h1[itemprop="name"] .middleModelAndDescTop');

        //get trim
        data.trim = document.querySelector('#DetailVehicleDescription .fs18');

        //get status
        data.vehicleStatus = document.querySelector('#vehicleinfodesc .DescriptionColumnContent');

        //get mileage
        data.mileage = document.querySelector('#vehicleinfodesc .DescriptionColumnContent:nth-child(11)');

        //get stock
        data.stock = document.querySelector('.vehicleNumbers .floatRight div:not([class="mgb5"])');

        //get vin
        data.vin = document.querySelector('.vehicleNumbers .floatRight .mgb5');

        //get img
        data.img = document.querySelector('#PhotoViewerTab1Content img');

    } else {

        var vehicleOverview = document.querySelectorAll('.vehicleDetailSection .vehicleDetailItem');
        var pricingTable = document.querySelectorAll('.priceBox .priceContainer');
        var overviewArr = [];
        var vehicleArr = [];
        var pricingArr = [];

        if (vehicleOverview.length) {
            vehicleArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (pricingTable.length) {
            pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (vehicleArr.length && pricingArr.length){
            overviewArr = vehicleArr.concat(pricingArr)
        } else if (vehicleArr.length && !pricingArr.length) {
            overviewArr = vehicleArr;
        } else if (!vehicleArr.length && pricingArr.length) {
            overviewArr = pricingArr;
        };

        if(overviewArr.length){
            overviewArr = Array.prototype.map.call(
                overviewArr,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().split(':');
                var label = labelAndValue[0].trim();
                var value = labelAndValue[1];

                switch (label) {
                    case 'vin':
                        data.vin = value.trim();
                        break;

                    case 'nada retail price':
                    case 'retail price':
                        data.retailPrice = value.trim();
                        break;

                    case 'asking price':
                    case 'internet price':
                    case 'no-haggle price':
                        data.listedPrice = value.trim();
                        break;

                    case 'my savings':
                    case 'dealer discount':
                        data.dealerSavings = value.trim();
                        break;

                    default:
                        break;
                }
                return true;
            });
        }

        //get listed and retail prices
        if(!data.listedPrice) {
            data.listedPrice = document.querySelector('.priceContainer span:not([class*="PriceStrikethrough"])');
        }

        if(!data.retailPrice) {
            data.retailPrice = document.querySelector('.priceContainer .PriceStrikethrough');
        }

        //get year, make, model
        data.year = data.make = data.model = document.querySelector('.vehicleTitle.attention');

        //get trim
        data.trim = document.querySelector('.vehicleSubTitle');

        //get status
        data.vehicleStatus = document.querySelector('.itemConditionInfo');

        //get mileage and stock
        data.mileage = data.stock = document.querySelector('.itemBootomInfo');

        //get vin
        if(!data.vin){
            data.vin = document.querySelector('.vehicleDetailSection .vehicleDetailItem:nth-child(6) .detail')
                || document.querySelector('.vehicleDetailSection .vehicleDetailItem:nth-child(5) .detail');
        }

        //get img
        data.img = document.querySelector('#mainImgCenter img')
            || document.querySelector('#imageViewer img');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            if(data.retailPrice.textContent){
                data.retailPrice = data.retailPrice.textContent
            }
            //if phone number is in location, do not parse
            if(data.retailPrice.indexOf('-')===-1){
                result.retailPrice = shimUtils.getInteger(data.retailPrice);
            }
        },
        dealerSavings : function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().replace(/\n/g, ' ').split(' ');
            result.make = make.splice(1, 1)[0].trim();
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().replace(/\n/g, ' ').split(' ');
            result.model = model.splice(2, model.length).join(' ').trim();
        },
        trim : function(data, result) {
            var trim = data.trim;
            result.trim = trim.textContent.trim();
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus.textContent.toLowerCase();
            result.vehicleStatus = (status.search(/used/g) > -1) ? 0 : 1;
        },
        mileage : function(data, result) {
            if(location.href.search(/mobi/g) == -1) { //desktop
                result.mileage = shimUtils.getInteger(data.mileage);
            } else {
                var mileage = data.mileage;
                mileage = mileage.textContent.split('Stock')[0].replace(/\D/g, '');
                result.mileage = parseInt(mileage, 10);
            }
        },
        stock : function(data, result) {
            if(location.href.search(/mobi/g) == -1) { //desktop
                var stock = data.stock;
                result.stock = stock.querySelector('span').nextSibling.textContent.trim();
            } else {
                var stock = data.stock;
                if(stock.textContent){
                    stock = stock.textContent.split(':');
                    if(stock.length>2){
                        stock = stock[2].trim();
                    } else {
                        stock = stock[1].trim();
                    }
                    result.stock = stock;
                } else {
                    result.stock = stock.trim();
                }
            }
        },
        vin : function(data, result) {
            var vin = data.vin;
            if(location.href.search(/mobi/g) == -1) { //desktop
                result.vin = vin.querySelector('span').nextSibling.textContent.trim();
            } else {
                if(vin.textContent){
                    data.vin = data.vin.textContent;
                }
                result.vin = data.vin.trim();
            }
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
