

module.exports = function(options) {

   options = options || {};

   //mwo-204
   //set container to prepend widget on mobile
   if(!!document.querySelector('a[href="#top"]')) {
      options.prependTo = "#flex-Inventory_Details_Math_Box-1397768140156";
   }

   return options;

}
