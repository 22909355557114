var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice : ''
    };

    data.listedPrice = document.querySelector('.internetPrice .price');

    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
