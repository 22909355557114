var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var location = document.querySelector('[itemprop="address"] [itemprop="name"]')

    if(location){
        location = location.textContent.toLowerCase().trim();

        switch (location) {
            case 'coral springs kia':
                options.dealerId = 1104;
                break;

            case 'coral springs honda':
                options.dealerId = 1100;
                break;

            case 'coral springs nissan':
                options.dealerId = 1101;
                break;

            default:
                options.dealerId = 1105;
                break;
        }
    }

    return options;
};
