var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: '',
    };

    //get retailPrice
    data.retailPrice = document.querySelector('.pricing_msrp .pricing-value') || document.querySelector('.pricing-zone .pricing-value');

    //get listedPrice
    data.listedPrice = document.querySelector('.pricing_new .pricing-value') 
        || document.querySelector('.pricing_fmccrebate .pricing-value') 
        || document.querySelector('.display_price_row .display_price_value')
        || data.retailPrice;

    //get vehicleStatus
    data.vehicleStatus = document.URL;

    //get year, make, model ,trim
    data.year = data.make = data.model = data.trim = document.querySelector('#details-main-second .title-zone')
        || document.querySelector('.auto4-current-vehicle-name')
        || document.querySelector('.vehicle-title h1');

    //get mileage, vin, stock and determine status
    var vehicleOverview = document.querySelectorAll('.overview .row');
    if(vehicleOverview.length < 1){
        vehicleOverview = document.querySelectorAll('.cc2 .pv1');
    }

    var overviewData = [];

    if (vehicleOverview.length) {
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        if (overviewArr.length) {
            overviewData = overviewArr;
        }
    }

    if (overviewData.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewData.filter(function(row, index, array) {

            var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

            switch (label.trim()) {
                case 'mileage':
                    data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                    break;

                case 'stock':
                    data.stock = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                    break;

                case 'vin':
                    data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                    break;

                default:
                    break;

            }
            return true;
        });
    }


    //get img
    data.img = document.querySelector('.gallery-thumbnails .photos img') || document.querySelector('.gallery-active-image-container a img.gallery-active-image');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make: function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model: function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim: function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus.toLowerCase().indexOf('new');
            if (status > -1) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        mileage: function(data, result) {
            result.mileage = parseInt(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
