import * as _ from 'underscore';
import * as getDataAttributes from '../getDataAttributes';

export const getScriptConfig = () => {
    const $script = document.getElementById('mmd-widget-script') || document.getElementById('makemydeal') || null;
    let scriptConfig: any = $script ? getDataAttributes($script) : {};
    scriptConfig = _.mapObject(scriptConfig, (value, index, list) => {
        return _.isString(value) ? value.toLowerCase().trim() : value;
    });

    // HACK - DDC passed chromeId instead of chromeStyleId; mapping to chromeStyleId if it exists
    if (scriptConfig && scriptConfig.chromeId) {
        scriptConfig.chromeStyleId = scriptConfig.chromeId;
        delete scriptConfig.chromeId;
    }

    return scriptConfig;
};

export const scrollTo = (element) => {
    window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop
    });
};
