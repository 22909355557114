
module.exports = function(options) {
	console.log('scrap for test sponsor.');

	options = options || {};

	// example set of data that needs to be collected from the dealer website
	// get listedPrice
	// options.listedPrice

	// get retailPrice
	// options.retailPrice

	// get vehicleStatus
	// options.vehicleStatus

	// get vin
	// options.vin

	// get stock
	// options.stock

	// get mileage
	// options.mileage

	// get year
	// options.year

	// get make
	// options.make

	// get model
	// options.model

	// get trim
	// options.trim

	// get url
	// options.url = document.URL

	//set loaded function
	//options.onLoadedFunction = function() { console.log("widget loaded"); };

	//set hiding function
	//options.onHidingFunction = function() { console.log("widget not showing"); };

	// get img
	// options.img

	// set appendTo
	// options.appendTo

	return options;
};
