// MWO-2048
// Need to send deals to different stores based on the make

module.exports = function(options) {

    options = options || {};

    if(options.vehicleStatus == 1 && options.make.toLowerCase() === 'hyundai'){
            options.dealerId = 902;
    }

    return options;
};
