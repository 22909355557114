var shimUtils = require('../utils');
var selectn = require('selectn');

module.exports = function (options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    var vehicleStatus = selectn('vehicleStatus', options);
    var internetPrice = selectn('internetPrice', window.dataLayer[0].vehicles[0]);
    var retailPrice = selectn('retailPrice', options);

    if (vehicleStatus == '1') {
        data.listedPrice = retailPrice || internetPrice;

        // logging
        shimUtils.callValueQuerySelector(data, options);

        // define tasks to get data
        var tasks = {
            listedPrice: function (data, result) {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            }
        }

        // call continue to get data
        options = shimUtils.continue(data, tasks, options);
    }

    return options;
}
