var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.widget-content .vehicle-info-items li')
    var pricingTable = document.querySelectorAll('.vsrch_pricing li');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewPrice = Array.prototype.map.call(
        pricingTable,
        function(obj, i, arr) {
            return obj;
        }
    );

    var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;
    var siteOptionsCollector = [];

    if (overviewArr.length) {

        siteOptionsCollector = overviewArr.filter(function(row, index, array) {

            var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'mileage':
                    data.mileage = row.innerHTML.replace(/<label[^)]*\/label>/g, "").replace(replaceRegex, '').trim();
                    break;
                case 'vin':
                    data.vin = row.innerHTML.replace(/<label[^)]*\/label>/g, "").replace(replaceRegex, '').trim();
                    break;

                case 'stock':
                    data.stock = row.innerHTML.replace(/<label[^)]*\/label>/g, "").replace(replaceRegex, '').trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if (overviewPrice.length) {

        siteOptionsCollector = overviewPrice.filter(function(row, index, array) {

            var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'msrp':
                case 'was':
                    data.retailPrice = row.children[1].textContent.replace(replaceRegex, '').trim();
                    break;
                case 'lowest price':
                case 'now':
                    data.listedPrice = row.children[1].textContent.replace(replaceRegex, '').trim();
                    break;

                case 'savings -':
                    data.dealerSavings = row.children[1].textContent.replace(replaceRegex, '').trim();
                    break;

                case 'savings':
                    data.dealerSavings = row.children[1].textContent.replace(replaceRegex, '').trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    var img = document.getElementById('detailPic1') || '';
    if(img){
        data.img = img.querySelector('img');
    }

    var title = document.getElementById('resp_vdp_main') || '';
    if(title){
        data.year = data.make = data.model = data.trim = title.querySelector('h2');
    }

    data.vehicleStatus = document.URL;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus.indexOf('new')) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
