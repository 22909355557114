var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        dealerSavings: '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    //get listedPrice
    data.listedPrice = document.querySelector('.wrap-content-detail .mid-detail .left-detail .retail-price b')
        || document.querySelector('.headingprice .etrprice .eprice')
        || document.querySelector('.net-price span');

    data.dealerSavings = document.querySelector('div#rebatetotals2')
        || document.querySelector('.yousave span');

    //get retailPrice
    data.retailPrice = document.querySelector('.retail-price b')
        || document.querySelector('#mmd-msrp')
        || document.querySelector('.headingprice .heading td:last-child');

    //get stock
    data.stock = document.querySelector('.headingtopprice .headingtoppriceleft');

    //get status, year, make, model & trim
    data.vehicleStatus = data.year = data.make = data.model = data.trim = document.querySelector('.headingtoppricetitle .vdptitle');

    //get vin
    data.vin = document.querySelector('.headingtopprice .headingtoppriceright');

    //get img
    data.img = document.querySelector('.thumb_images a:nth-child(2) img')
            || document.querySelector('#carpic');

    var vehicleOverview = document.querySelectorAll('.wrap-summarylist .summarylist li');

    if(vehicleOverview.length) {
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children[0]) {
                var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });

    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        dealerSavings : function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        retailPrice : function(data, result) {
			result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
			var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus : function(data, result) {
            var title = shimUtils.titleTrimmer(data.vehicleStatus);
            result.vehicleStatus = title.status;
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin : function(data, result) {
            var vin = data.vin;
            result.vin = vin.textContent.split(":")[1].trim();
        },
        stock : function(data, result) {
            var stock = data.stock;
            result.stock = stock.textContent.split(":")[1].trim();
        },
        img : function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
