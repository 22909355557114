var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

   //options.listedPrice = options.retailPrice;
   
    var data = {
       listedPrice: ''
    };
    
    if(options.vehicleStatus === 1) {
         data.listedPrice = document.querySelector('.invoicePrice .price');
    }
    else {
        data.listedPrice = document.querySelector('.internetPrice');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get Data
    var tasks = {
        listedPrice: function(data, result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        }
    };

    options = shimUtils.continue(data,tasks,options);

    options.isShimmed = true;
    

    return options;

};
