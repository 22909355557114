// interfaces/types
import type { ICADigitalRetailObject, IWidgetHookError, IWidgetHooks } from '../models/ICADigitalRetailObject';
import type { SdpWidgetInitialization } from './types/initializationInterfaces';
import {
    SaveOffer,
    SaveOfferDealer,
    SaveOfferPayment,
    SaveOfferResults,
    SaveOfferShopper,
    SaveOfferVehicleData,
    OfferTypes,
    SPSource
} from './types/saveOfferTypes';

// utils
import { getVehicleStatus } from '../widget_modules/dca.accelerate';

export const stringOrNumberToNumber = (value?: string | number): number => {
    if (value && typeof value == 'string') {
        value = value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        value = parseInt(value, 10);
        return isNaN(value) ? undefined : value;
    }

    return value as number;
};

export const stringOrNumberToString = (value?: string | number): string => {
    return value?.toString?.();
};

export const getListingCode = (cadr: ICADigitalRetailObject): string => {
    // [US121447] Check the vehicleData to see if ownerId has a value. If yes, it is the listing code;
    // if not, use the accountId as the listing code. For ATC/KBB, this listingCode property
    // is not used in dealstarter to pass the value to DTM, ownerId is used instead.
    return cadr.vehicleData?.ownerId ?? cadr.accountId;
};

export const createNewHooks = (
    cadr: ICADigitalRetailObject,
    startDigitalRetailing?: (cadr: ICADigitalRetailObject) => void
): IWidgetHooks => {
    // create a shallow copy of hooks so we do not step on the existing ones
    const hooks = {
        ...(cadr.hooks || {})
    };

    // if the startDigitalRetailing function is here, then create hooks here
    // that use the existing hooks and can open the original widget
    // by creating the copy above, we will not step on the original
    // cadr object's hooks when we need to open it.
    if (typeof startDigitalRetailing === 'function') {
        // so DR only opens once
        let startDigitalRetailingCalled = false;

        // replace the onHidingFunction for when the window closes
        const existingHidingFunction = hooks.onHidingFunction;
        hooks.onHidingFunction = () => {
            if (typeof existingHidingFunction === 'function') {
                existingHidingFunction();
            }

            if (!startDigitalRetailingCalled) {
                startDigitalRetailingCalled = true;
                startDigitalRetailing(cadr);
            }
        };

        const existingOnLoadedFunction = hooks.onLoadedFunction;

        hooks.onLoadedFunction = (info?: IWidgetHookError) => {
            if (typeof existingOnLoadedFunction === 'function') {
                existingOnLoadedFunction(info);
            }

            // only open when an error occurs and we haven't opened it yet
            if (info && !startDigitalRetailingCalled) {
                startDigitalRetailingCalled = true;
                startDigitalRetailing(cadr);
            }
        };
    }

    return hooks;
};

export const convertCadrToSdpInit = (
    cadr: ICADigitalRetailObject,
    qs?: { [key: string]: string },
    startDigitalRetailing?: (cadr: ICADigitalRetailObject) => void
): SdpWidgetInitialization => {
    // go through using the QS params and the cadr to setup the init object

    const { vehicleData, shopper: shopperCadr, isMobile, websiteProviderId, dealerId } = cadr;
    const { connectionId, vin = vehicleData?.vin, sponsor = websiteProviderId, dsSource, fn, ln, email, dealXgId, dealXgVersion } =
        qs || {};

    // create a new hooks object so we do not ruin the existing one with the replacement hooks.
    const hooks = createNewHooks(cadr, startDigitalRetailing);

    // if we have a connection id and vin, we will be able to open the offer
    if (connectionId && vin) {
        const offer: SaveOfferResults = {
            connectionId,
            vin,
            sponsor,
            dealerId: stringOrNumberToNumber(dealerId)
        };

        // open an existing offer
        return {
            hooks,
            version: '1.0.0',
            isMobile,
            offer
        };
    } else if (dealXgId && dealXgVersion) {
        const offer = {
            dealXgId,
            dealXgVersion
        };

        return {
            hooks,
            version: '1.0.0',
            isMobile,
            offer
        };
    } else if (dealXgId) {
        const offer = {
            dealXgId
        };

        return {
            hooks,
            version: '1.0.0',
            isMobile,
            offer
        };
    } else {
        // we need to pull everything from the cadr
        const { dealerId: id, accountId: dnaAccountId } = cadr;
        const dealer: SaveOfferDealer = {
            dnaAccountId: dnaAccountId ? dnaAccountId : undefined,
            id: stringOrNumberToNumber(id),
            sponsor,
            ownerId: stringOrNumberToNumber(vehicleData.ownerId)?.toString?.()
        };

        const {
            listedPrice,
            mileage,
            condition,
            vehicleStatus,
            certifiedStatus,
            retailPrice,
            year,
            chromeStyleId,
            exteriorColor,
            interiorColor,
            listingCode,
            make,
            model,
            stock,
            trim,
            vin,
            uuid,
            vdpUrl,
            imgUrl,
            vehicleUrl
        } = vehicleData;

        const vehicle: SaveOfferVehicleData = {
            listedPrice: stringOrNumberToNumber(listedPrice),
            mileage,
            condition: getVehicleStatus(sponsor, {
                condition,
                vehicleStatus,
                certifiedStatus
            }),
            retailPrice,
            year: stringOrNumberToNumber(year),
            chromeStyleId: stringOrNumberToString(chromeStyleId),
            exteriorColor,
            interiorColor,
            listingCode: listingCode ? stringOrNumberToString(listingCode) : getListingCode(cadr),
            make,
            model,
            stock,
            trim,
            vin,
            uuid,
            urls: {
                vdpUrl,
                imageUrl: decodeURIComponent(imgUrl),
                vehicleUrl
            }
        };

        const payment: SaveOfferPayment = {
            selectedOfferType: shopperCadr?.defaultOfferType ? (shopperCadr.defaultOfferType as OfferTypes) : undefined,
            creditScore: shopperCadr?.creditScoreLow
        };

        // get the source
        let source: SPSource = 'SPONSOR';
        if (dsSource && dsSource.toLowerCase() === 'vin') {
            source = 'VIN';
        }

        // if there is a fn, ln or email on the qs, make a shopper object
        const shopper: SaveOfferShopper = {
            firstName: fn,
            lastName: ln,
            email
        };

        // CADR does not define shopper data firstname, lastname, comment or email
        const offer: SaveOffer = {
            vehicle,
            dealer,
            payment,
            shopper,
            source
        };

        return {
            hooks,
            version: '1.0.0',
            isMobile,
            offer
        };
    }
};
