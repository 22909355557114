//dealer 1182
'use strict';
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.listedPrice && options.retailPrice && options.vehicleStatus === 1){
        options.listedPrice = options.retailPrice;
    }

    return options;
};