
//this logic is for dealer {}
//replace as necessary


module.exports = function(options) {

	console.log("scrape for 38");
	return options;
}
