//ddc shim
'use strict';

var shimUtils = require('../utils');


//supports DDC's most popular template
//all other templates that fail go in dealer shims
module.exports = function(options) {

    options = options || {};

    //defer from get go
    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    //move appframe for dealers with multiple containers
    shimUtils.moveDDCAppFrame();

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        incentiveSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    //get listedPrice & retailPrice
    //based on available elements
    var msrp = document.querySelector('.msrp .price') ||
        document.querySelector('.askingPrice .price') ||
        document.querySelector('.customMsrp .price') ||
        document.querySelector('.fnPromo .msrp');
    var retailValue = document.querySelector('.retailValue .price') ||
        document.querySelector('.salePrice .price');
    var finalPrice = document.querySelector('.final-price .price');
    var internetPrice = document.querySelector('.internetPrice .price') ||
        document.querySelector('.invoicePrice .price') ||
        document.querySelector('.askingPrice .price');
    var isNotShowingListedPrice = document.querySelector('.please-call');
    var vehicleDetails = document.querySelector('.groupedVehicleDetails');
    var isCPO = false;

    if (options.version === '5.0.0') {
        var isCPO = location.href.search(/certified/gi) > -1;
        if (isCPO) {
            options.certifiedStatus = true;
        } else if (options.condition === 'certified'){
            options.condition = 'used'; 
        }

        // TODO: remove once we can provision tracking phone number from admin
        var phoneNumber = document.querySelector('[data-phone-ref=SALES]');

        try {
            options.vdpTrackingPhoneNumber =  typeof phoneNumber === 'object' ?  phoneNumber.textContent.trim() : '';
        }catch(error){ }
        
        return options;
    }
    

    if (internetPrice && !finalPrice) {
        data.listedPrice = internetPrice;
    } else if ((internetPrice && finalPrice) && (Number(shimUtils.getInteger(internetPrice)) > Number(shimUtils.getInteger(finalPrice)))) {
        data.listedPrice = internetPrice;
    } else {
        data.listedPrice = finalPrice;
    }

    if (!msrp && retailValue) {
        data.retailPrice = retailValue;
    } else {
        data.retailPrice = msrp;
    }

    if (isNotShowingListedPrice) {
        if (isNotShowingListedPrice.querySelectorAll('span:nth-child(2)')[0].textContent.toLowerCase() === 'outlet price' && isNotShowingListedPrice.querySelectorAll('span:nth-child(1)')[0].textContent.toLowerCase() !== 'please call') {
            data.retailPrice = internetPrice;
        }
    } else {
        data.retailPrice = data.retailPrice || internetPrice;
    }



    //get dealer dealerSavings
    data.dealerSavings = document.querySelector('.abSub .price');

    //get incentive
    data.incentiveSavings = document.querySelector('.rebate .price') ||
        document.querySelector('.stackedConditionalRebate .price') ||
        document.querySelector('.stackedRebate.discount .price') ||
        data.dealerSavings;

    if (data.incentiveSavings == data.dealerSavings) {
        delete data.dealerSavings
    }

    // MWO-2367 - Use dataLayer to get some data if available
    // make sure array exists before going into subset of it.
    if (window.dataLayer && window.dataLayer[0] && window.dataLayer[0].vehicles && window.dataLayer[0].vehicles[0]) {
        var siteOptions = window.dataLayer[0].vehicles[0];

        data.year = siteOptions.modelYear ? siteOptions.modelYear : data.year;
        data.make = siteOptions.make ? siteOptions.make : data.make;
        data.model = siteOptions.model ? siteOptions.model : data.model;
        data.trim = siteOptions.trim ? siteOptions.trim : data.trim;
        data.vehicleStatus = siteOptions.inventoryType ? siteOptions.inventoryType : data.vehicleStatus;
        data.mileage = siteOptions.odometer ? siteOptions.odometer : data.mileage;
        data.vin = siteOptions.vin ? siteOptions.vin : data.vin;

    }

    var title = document.querySelector('.content-page-title h1') ||
        document.querySelector('h1.ddc-page-title') ||
        document.querySelector('title');

    if (!data.year) {
        data.year = title;
    }

    if (!data.make) {
        data.make = title;
    }

    if (!data.model) {
        data.model = title;
    }

    if (!data.trim) {
        data.trim = title;
    }

    if (!data.vehicleStatus) {
        data.vehicleStatus = location.href.toLowerCase();
    }

    if (!data.mileage) {
        data.mileage = document.querySelector('.quick-specs .odometer .value') ||
            document.querySelector('#overview .odometer .value');
    }

    if (!data.vin) {
        data.vin = document.querySelector('.quick-specs .vin .value') ||
            document.querySelector('#overview .vin .value') ||
            document.querySelector('input[name="vin"]');
    }

    //get stock
    data.stock = document.querySelector('.quick-specs .stockNumber .value') ||
        document.querySelector('#overview .stockNumber .value') ||
        document.querySelector('.details .stockNumber .value');

    //get img
    data.img = document.querySelector('.slider li[index="0"] img') ||
        document.querySelector('#photos .imageViewer img') ||
        document.querySelector('.slide img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        year: function(data, result) {
            if (data.year.nodeType) {
                var year = data.year;
                year = ((data.year.constructor.name === "String") ? data.year.split(' ')[1] : data.year.textContent.trim().split(' ').shift());
                year = parseInt(year, 10);
                //some headings have new/used so check
                if (isNaN(year)) {
                    result.yearNext = true; //set yearNext to tell others that new/used is at front
                    year = parseInt(data.year.textContent.trim().split(' ').filter(
                        function(n) { return n != ""; })[1], 10);
                }
                result.year = year;
            } else {
                result.year = data.year;
            }
        },
        make: function(data, result) {
            if (data.make.nodeType) {
                var make = data.make;
                if (make.constructor.name !== 'String') {
                    make = make.textContent.trim().split(' ').filter(
                        function(n) { return n != ""; });
                    if (result.yearNext) {
                        if (make[2] + " " + make[3] == "Land Rover") {
                            make = make.splice(2, 2).join(" ");
                        } else {
                            make = make.splice(2, 1)[0];
                        }
                    } else {
                        if (make[1] + " " + make[2] == "Land Rover") {
                            make = make.splice(1, 2).join(" ");
                        } else {
                            make = make.splice(1, 1)[0];
                        }
                    }
                } else {
                    make = data.make.split(' ')[2];
                }
                result.make = make;
            } else {
                result.make = data.make;
            }
        },
        model: function(data, result) {
            if (data.model.nodeType) {
                var model = data.model.constructor.name === 'String' ? data.model : shimUtils.removeLocationFromVehicleTitle(data.model);
                //Need to fix this logic to make it clear and rewrite a case when the maker is more than 1 word.
                if (model.search(/land rover/gi) >= 0) {
                    model = model.split(' ').filter(
                        function(n) { return n != ""; });
                    if (result.yearNext) {
                        if (model[2] + " " + model[3] == "Land Rover") {
                            model = model.splice(4, 1)[0];
                        } else {
                            model = model.splice(3, 1)[0];
                        }
                    } else {
                        if (model[1] + " " + model[2] == "Land Rover") {
                            model = model.splice(3, 1)[0];
                        } else {
                            model = model.splice(2, 1)[0];
                        }
                    }
                } else {
                    if (model.search(/\b(used|new)\b/gi) >= 0) {
                        model = model.split(' ')[3];
                    } else {
                        model = model.split(' ')[2];
                    }

                }
                result.model = model;
            } else {
                result.model = data.model;
            }
        },
        trim: function(data, result) {
            if (data.trim.nodeType) {
                var trim = data.trim.constructor.name === 'String' ? data.trim : shimUtils.removeLocationFromVehicleTitle(data.trim);

                if (trim.search(/land rover/gi) >= 0) {
                    trim = trim.split(' ').filter(
                        function(n) { return n != ""; });
                    if (result.yearNext) {
                        if (trim[1] + " " + trim[2] == "Land Rover") {
                            trim = trim.splice(5, trim.length).join(' ');
                        } else {
                            trim = trim.splice(4, trim.length).join(' ');
                        }
                    } else {
                        if (trim[1] + " " + trim[2] == "Land Rover") {
                            trim = trim.splice(4, trim.length).join(' ');
                        } else {
                            trim = trim.splice(3, trim.length).join(' ');
                        }
                    }
                } else {
                    trim = trim.split(' ');
                    if (trim.length > 4) {
                        trim = trim[4];
                    } else {
                        trim = '';
                    }
                }
                result.trim = trim;
            } else {
                result.trim = data.trim;
            }
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            if (status.search(/new/) === -1) {
                result.vehicleStatus = 0;
            } else {
                result.vehicleStatus = 1;
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if (data.vin.nodeType) {
                var vin = data.vin;
                if (vin.nodeName === 'INPUT') {
                    result.vin = vin.value;
                } else {
                    result.vin = vin.textContent.trim();
                }
            } else {
                result.vin = data.vin;
            }
        },
        stock: function(data, result) {
            var stock = data.stock;
            result.stock = stock.textContent.trim();
        },
        img: function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //set isShimmed
    options.isShimmed = true;

    //change dealerid from 533 to 611 if dealer is kings of car loans
    if (location.href.search(/\bkingsofcarloans\b/g) >= 0) {
        options.dealerId = 611;
    }

    return options;

}