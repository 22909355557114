//get correct price

var shimUtils = require('../utils');

module.exports = function(options) {
    
    options = options || {};

    var data = {
        listedPrice: ''
    };

    var listedPrice = document.querySelector('.FinanceDetais span')|| document.querySelector('.pricing .single span');
    //FinanceDetais clearfix 

    if(listedPrice){
        data.listedPrice = listedPrice;
    }

    shimUtils.valueQuerySelector(data, options);
    
    var tasks = {
        listedPrice: function(data, result){
            result.listedPrice = data.listedPrice.textContent.replace(/\D/g, '');
            result.listedPrice = parseInt(result.listedPrice, 10);
        }
    };

    options = shimUtils.continue(data,tasks,options);
    
    return options;
    
}