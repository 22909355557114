var shimUtils = require('../utils');

module.exports = function(options){
    options = options || {};

    var data = {
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    /** MWO-949 - VIN was not correct passing to widgetapp
     ** Current shim is just for zoomzauto; may need to update for additional dealers
     ** Updating to pull table of options and compare against the label
     **/

    var vehicleSpecifics = document.querySelectorAll('.specifics-container .row div');
    var vehicleSpecificsArr = Array.prototype.map.call(
        vehicleSpecifics,
        function(obj, i, arr) {
            return obj;
        }
    );

    var vehicleSpecificsCollector = vehicleSpecificsArr.filter(function(row, index, array) {
        if(array[index].children.length === 0) {  // No elements to collect so return false
            // maybe set data.hasError since there is nothing to collect on the page
            // will alert us to an error that we may need to fix
            return false;
        } else {
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch(label) {
                case 'stock number':
                    data.stock = array[index].children[1];
                    break;

                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;

                case 'vin':
                    data.vin = array[index].children[1];
                    break;

                default:
            }
        }
    });

    data.listedPrice = document.querySelector('.showroomvalue') || document.querySelector('.internetvalue');

    data.year = data.make = data.model = data.trim = document.querySelector('.pagetitle')
        || document.querySelector('.vehicle-heading');

    data.img = document.getElementById('AM_imgMainPhoto');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data,result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year : function(data, result){
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result){
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result){
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result){
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage : function(data,result){
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus : function(data, result){
            // TODO: MWO-847 - Currently zoomzauto is the only dealer using this shim
            // Since zoomzauto is only a used car dealer, defaulting to 0 for now.
            result.vehicleStatus = 0;
        },
        stock : function(data, result){
            var stock = data.stock;
            result.stock = stock.textContent.trim();
        },
        vin : function(data, result){
            var vin = data.vin;
            result.vin = vin.textContent.trim();
        },
        img : function(data, result){
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set URL
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function(){
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function(){
        console.log("widget not showing");
    };

    return options;

}
