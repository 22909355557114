//shim:1720
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    data.listedPrice = document.querySelector('.internetPrice .price') || document.querySelector('.retailValue .price');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
