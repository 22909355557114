
module.exports = function(options) {

	options = options || {};

    var martin = "martin.mercedesdealer";
    var host = location.host;

    if(host.indexOf(martin)>-1)
        options.dealerId = 721;

	return options;

}
