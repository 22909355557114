//shim:dealereprocess
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //get all selectors
    //new approach

    var host = location.href.indexOf('m.') > -1;

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    if (host) { //mobile

        //get listedPrice
        data.listedPrice = document.querySelector('#region_2 .vehicle-prices dl dd:last-child');

        //get retailPrice
        data.retailPrice = document.querySelector('#region_2 .vehicle-prices dl dd:nth-child(2)');

        //get stock
        data.stock = document.getElementById('Stock');

        //get mileage
        data.mileage = document.getElementById('Mileage');

        //get vin
        data.vin = document.getElementById('Vin') || document.querySelector("[itemprop='vehicleIdentificationNumber']");

        //get img
        data.img = document.querySelector('.vehicle-photo img');


    } else {


        var pricingLabelTable = document.querySelectorAll('.veh_pricing_container dt');

        var pricingValueTable = document.querySelectorAll('.veh_pricing_container dd');

        var pricingLabelArr = Array.prototype.map.call(
            pricingLabelTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var pricingValueArr = Array.prototype.map.call(
            pricingValueTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        if (pricingLabelArr.length) {

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

            var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {

                var label = row.textContent.trim().replace(replaceRegex, '').toLowerCase();

                var value = pricingValueArr[index].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'showroom price':
                    case 'internet price':
                    case 'grayson price':
                    case 'value price':
                    case 'your price':
                    case 'special offer':
                    case 'harbor advantage price':
                    case 'our best price':
                    case 'family deal price':
                        data.listedPrice = value;
                        break;

                    case 'internet special':
                    case 'msrp':
                        data.retailPrice = value;
                        break;

                    case 'dealer discount':
                    case 'you save':
                        data.dealerSavings = value;
                        break;

                    default:
                        break;

                }
                return true;
            });
        }

        var vehicleOverview = document.querySelectorAll('.details-overview_table tbody tr');

        if (vehicleOverview.length > 0) {

            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0 && array[index].children[0].textContent.indexOf('price') < 0) {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
                    switch (label) {
                        case 'mileage':
                            data.mileage = array[index].children[1];
                            break;

                        case 'vin':
                            data.vin = array[index].children[1];
                            break;

                        case 'stock':
                            data.stock = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        }

        //get listedPrice
        if(!data.listedPrice){
            data.listedPrice = document.querySelector('.veh_pricing_container .veh_pricing_inner_container dl dd:nth-child(10)') ||
                document.querySelector('#region_2 .details-price dl dd:last-child') ||
                document.querySelector('.veh_pricing_container .veh_pricing_inner_container dl dd:nth-child(10)') ||
                document.querySelector('.vehicle_price.price_tp-selling');
        }

        if(!data.retailPrice){
            //get retailPrice
            data.retailPrice = document.querySelector('#region_2 .details-price dl dd:nth-child(2)') ||
                document.querySelector('.veh_pricing_container .veh_pricing_inner_container dl dd:nth-child(2)');
        }

        //get stock
        if(!data.stock) {
            data.stock = document.querySelector('#details_table-Stock .details-overview_data') ||
            document.querySelector('.details-overview_table tr:nth-child(2) td:nth-child(2)') ||
            document.querySelector("[itemprop='sku']");
        }

        //get mileage
        if(!data.mileage) {
            data.mileage = document.querySelector('#details_table-Mileage .details-overview_data') ||
            document.querySelector('.details-overview_table tr:nth-child(6) td:nth-child(2)');
        }

        //get vin
        if(!data.vin) {
            data.vin = document.querySelector('#details_table-VIN .details-overview_data') ||
            document.querySelector('.details-overview_table tr:nth-child(3) td:nth-child(2)') || document.querySelector("[itemprop='vehicleIdentificationNumber']");
        }

        //get img
        data.img = document.querySelector('#details-media_box img') ||
            document.querySelector('.fallback_enabled_img') || document.querySelector('.preview_vehicle_image_item');

    }

    //get status, year, make, model, trim
    data.vehicleStatus = data.year = data.make = data.model = data.trim = document.querySelector('#details-header h1') ||
        document.querySelector('.vdp_vehicle_title_container h1') || document.querySelector('.srp_vehicle_title_container h2 a') ||
        document.querySelector('#content .heading-h1');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            status = status.textContent.trim().split(' ');
            result.vehicleStatus = (status.shift().toLowerCase() === 'new') ? 1 : 0;
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            if (data.mileage.textContent) {
                result.mileage = shimUtils.getInteger(data.mileage);
            }
        },
        vin: function(data, result) {
            if (data.vin.textContent) {
                result.vin = data.vin.textContent.trim();
            } else {
                result.vin = data.vin.content.trim();
            }
        },
        stock: function(data, result) {
            if (data.stock.textContent) {
                result.stock = data.stock.textContent.trim();
            } else {
                result.stock = data.stock.content.trim();
            }

        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
