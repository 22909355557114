// MWO-1276: Dealer has requested widget not load on the site.
// Only remove this flag if we have been requested to add back by dealer
// This site may show up on reports as receiving no dealer site traffic but that is valid.
// This is an ATC-only account

module.exports = function(options) {

    options = options || {};

    if(options.sponsor === 'vin') {
    	options.disableLoad = true;
    }

    return options;

}
