var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //get listedPrice & retailPrice
    var msrp = document.querySelector('dd.vehicle-price-base-price');
    var finalPrice = document.querySelector('dd.vehicle-price-default-price');
    var finalPrice2 = document.querySelector('dd.vehicle-price-discounted-price');
    
    //get year, make, model, trim
    var ymmt = document.querySelector('.vehicle-title-header .vehicle-title');
    
    //get mileage
    var keys = document.querySelectorAll('.vehicle-detail-list span dt');
    var values = document.querySelectorAll('.vehicle-detail-list span dd');
    
    //get vin
    var vin = document.querySelector('input[name="VIN"]');
    
    //get stock
    var stock = document.querySelector('dd.stock-number-field');
    
    //get img
    var img = document.querySelector('.vehicle-thumbnails a img');
    
    //logging
    if(!finalPrice && !finalPrice2) {
        finalPrice = shimUtils.valueQuerySelector(finalPrice, options, 'listedPrice');
    }
    ymmt = shimUtils.valueQuerySelector(ymmt, options, 'year-make-model-trim');
    keys = shimUtils.valueQuerySelector(keys, options, 'detail-section-labels');
    values = shimUtils.valueQuerySelector(values, options, 'details-section-values');
    vin = shimUtils.valueQuerySelector(vin, options, 'vin');
    img = shimUtils.valueQuerySelector(img, options, 'image');
    
    if((!finalPrice && !finalPrice2)
      || !ymmt
      || !keys
      || !values
      || !vin
      || !img ) {
        return options;
    }

    if(!!msrp) {
        msrp = msrp.innerHTML.split('<')[0].replace(/\D/g, '');
        options.retailPrice = parseInt(msrp, 10);
    }

    if(!!finalPrice) {
        finalPrice = finalPrice.textContent.replace(/\D/g, '');
    } else if(!!finalPrice2 && !finalPrice) {
        finalPrice = finalPrice2.textContent.replace(/\D/g, '');
    }

    options.listedPrice = parseInt(finalPrice, 10);

   
    ymmt = ymmt.textContent.trim().split(' ');

        //get year
        var year = ymmt.shift();
        options.year = parseInt(year, 10);

        //get make
        options.make = ymmt.shift();

        //get model
        options.model = ymmt.shift();

        //get trim
        if(ymmt.length > 0) {
            options.trim = ymmt.join(' ').trim();
        } else {
            options.trim = ' ';
        }

    //get vehicleStatus
    var urlPath = location.pathname.toLowerCase();

    if(urlPath.search(/\bnew\b/g) >=0) {
        options.vehicleStatus = 1;
    } else {
        options.vehicleStatus = 0;
    }

    //set mileage
    var details = {};

    for (var i = 0; i < keys.length; i++) {
        var key = keys[i].textContent.toLowerCase().replace(/:/g, '').trim();
        var value = values[i].textContent.trim();
        details[key] = value;
    }

    if(details.mileage) {
        options.mileage = parseInt(details.mileage, 10);
    } else {
        options.mileage = 1;
    }

    //set vin
    options.vin = vin.value;

    //set stock
    options.stock = stock.textContent;
    
    //set img
    options.img = img.src;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //set url
    options.url = document.URL;

    return options;

}
