var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.listedPrice) {
        if(options.retailPrice){

            options.listedPrice = options.retailPrice;

        }else{

            var data = {
                listedPrice : ''
            };


            //get retailPrice as listedPrice
            data.listedPrice = document.querySelector('.msrp .price');



            shimUtils.callValueQuerySelector(data, options);

            //define tasks to get data
            var tasks = {
                listedPrice : function(data, result) {
                    result.listedPrice = shimUtils.getInteger(data.listedPrice,10);
                }
            };

            //call continue to get data
            options = shimUtils.continue(data, tasks, options);

        }
    }

    return options;
};
