var shimUtils = require('../../utils');

module.exports = function(opts) {

    options = opts || {};

    var data = opts.data;
    var options = opts.options;


    data.listedPrice = document.querySelector('#vdp-price .ebiz-price-link')
                    ||  document.querySelector('#vdp-price h4');

    var vehicleOverview = document.querySelectorAll('#tab-details tbody tr');

    if (vehicleOverview.length > 0) {

        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (array[index].children.length !== 0 && array[index].children[0].textContent.indexOf('price') < 0) {
                var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
                switch (label) {
                    case 'miles':
                        data.mileage = array[index].children[1];
                        break;

                    case 'vin':
                        data.vin = array[index].children[1];
                        break;

                    case 'stock':
                        data.stock = array[index].children[1];
                        break;

                    default:
                }
            }
            return true;
        });
    }

    data.year = data.make = data.model = document.querySelector('.ebiz-vdp-title');

    data.trim = document.querySelector('.ebiz-vdp-subtitle');

    //get status
    data.vehicleStatus = location.pathname.search(/new/g);

    data.img = document.querySelector('.swiper-container div[data-swiper-slide-index="0"] img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var trim = data.trim;
            if(trim.textContent){
                trim = trim.textContent.trim();
            }
            result.trim = trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);

        },
        vin: function(data, result) {
            var vin = data.vin;
            if(vin.textContent){
                vin = vin.textContent;
            }

            result.vin = vin;
        },
        stock: function(data, result) {
            var stock = data.stock;
            if(stock.textContent){
                stock = stock.textContent;
            };

            result.stock = stock
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
