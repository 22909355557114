//shim:1661
var shimUtils = require('../utils');
var extend = require('lodash/extendWith');
var selectn = require('selectn');

module.exports = function(options) {

    options = options || {};

    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    function isJSON(str) {
        try {
            var obj = JSON.parse(str);
            if (obj && typeof obj === 'object') {
                return true;
            }
        } catch (err) {}
        return false;
    }

    var conditionMap = {
        'NewCondition': 'new',
        'UsedCondition': 'used'
    };

    // Remora has ld+json script blocks on the page
    // Using script block json data to get majority of vehicle data
    // Note: querySelectorAll returns NodeList (not Array)
    // url: https://developer.mozilla.org/en-US/docs/Web/API/NodeList
    var ldScriptTags = document.querySelectorAll('script[type="application/ld+json"]');

    var ldObject;
    for(var i = 0; i < ldScriptTags.length; i++) {
        if (isJSON(ldScriptTags[i].text)) {
            var ldJSON = JSON.parse(ldScriptTags[i].text);
            ldObject = extend(ldObject, ldJSON, function (objValue, srcValue, key, obj, src) {
                return (selectn(key, obj)) ? objValue : srcValue;
            });
        }
    }

    data.year = selectn('vehicleModelDate', ldObject);
    data.make = selectn('manufacturer', ldObject);
    data.model = selectn('model', ldObject);
    data.trim = selectn('vehicleConfiguration', ldObject);
    data.vehicleStatus = conditionMap[selectn('itemCondition', ldObject)];
    data.mileage = selectn('mileageFromOdometer',ldObject);
    data.vin = selectn('vehicleIdentificationNumber', ldObject);
    data.stock = selectn('productID', ldObject);

    data.listedPrice = selectn('offers.price', ldObject);

    if (data.vehicleStatus === 'new') {
        data.retailPrice = document.querySelector('.veh-pricing .veh-price-block .amount.msrp');
    }

    data.img = selectn('image', ldObject);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if(status.toLowerCase().indexOf('new') > -1){
                result.vehicleStatus = 1;
            }
            else result.vehicleStatus = 0;
        },
        year: function(data, result) {
            result.year = data.year;
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        img: function(data, result) {
            result.img = data.img;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
