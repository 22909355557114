var getInteger = module.exports = {

    sanitizeInteger : function(selector) {
        if(!!selector.textContent || selector.textContent == ''){
            selector = selector.textContent;
        };

        if(typeof selector === 'number') {
            selector = selector.toString();
        };

        if(selector.toLowerCase().indexOf('please call')>-1){
            selector = '';
        };

        if(selector==='') {
            return selector;
        };

        if(selector.indexOf('$')>-1) {
            //grab first price occurence
            selector = selector.split('$')[1];
        };

        selector = selector.replace(/[^0-9.]/g, '');

        return selector;
    },

    getInteger : function(selector) {
        if(!!selector){
            selector = this.sanitizeInteger(selector);
        };

        return selector;
    }

};
