var shimUtils = require('../utils');

module.exports = function(options) {

	options = options || {};

    //only display on page if "Presented by Stephen Wade Toyota" exists on page
    var stephenWadeToyota = document.getElementById('printInfo') || document.querySelector('.dealer-info h4:nth-child(1)');

    if(stephenWadeToyota && stephenWadeToyota.textContent.trim().toLowerCase()!=='presented by stephen wade toyota'){
        options.disableLoad = true;
    }

	return options;
};
