//1653.js
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var validStartStock = ["PMC","PME","PMF"];
    var validEndStock = ["MA","MB"];

    if(options.stock && options.vehicleStatus === 0) {
        var stock = options.stock;

        if(stock.length > 2){
            var first3 = stock.substr(0,3);
            var last2 = stock.substr(stock.length-2,2);
            
            if(!(validStartStock.indexOf(first3) > -1 || validEndStock.indexOf(last2) > -1)) {
                options.disableLoad = true;
            }
        }
    }


    return options;

};
