

module.exports = function(options) {
   
   options = options || {};
   
   if(options.vehicleStatus && options.vehicleStatus == 1) {
      options.mileage = 1;
   }
   
   return options;
   
}