var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

   var data = {
       listedPrice: '',
       retailPrice: ''
   };

   var pricingTable = document.querySelectorAll('[itemprop="offers"]');

   var pricingArr = Array.prototype.map.call(
       pricingTable,
       function(obj,i,arr){
           return obj;
       }
   );

   var siteOptionsArr = pricingArr;

   var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
       if(array[index].children.length !== 0) {
           var label =  array[index].children[0].textContent.trim().toLowerCase().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
           switch(label) {

               case 'msrp':
                   data.retailPrice = array[index];
                   break;

                case 'price':
                    data.listedPrice = array[index];
                    break;

               default:
           }
       }
       return true;
   });


    //logging
   shimUtils.callValueQuerySelector(data, options);

    //define tasks to get Data
    var tasks = {
        retailPrice: function(data, result){
            result.retailPrice = parseInt(data.retailPrice.textContent.split(':')[1].replace(/\D+/g, ''),10);
        },
        listedPrice: function(data, result){
            result.listedPrice = parseInt(data.listedPrice.textContent.split(':')[1].replace(/\D+/g, ''),10);
        }
    };

    options = shimUtils.continue(data,tasks,options);

    if(document.querySelector('dl#specsContainer')){
        options.appendTo = "dl#specsContainer";;
    }
    
    return options;

};
