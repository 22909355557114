var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.make && options.vehicleStatus===1){
        switch (options.make.toLowerCase().trim()) {
            case 'audi':
                options.dealerId = 1211;
                break;

            case 'porsche':
                options.dealerId = 1214;
                break;

            case 'volkswagen':
                options.dealerId = 1216;
                break;

            case 'mercedes-benz':
                options.dealerId = 1217;
                break;

            default:
                options.dealerId = 1203;
                break;
        }
    }else{
        //if used set Id to this number
        options.dealerId = options.vehicleStatus === 0 ? 1216 : options.dealerId;
    }

    return options;
};
