/**
 * DealerOn first desktop shim
 */

var shimUtils = require('../../utils');

module.exports = function(opts) {

    var data = opts.data;
    var options = opts.options;

    data.year = data.make = data.model = data.trim = document.querySelector('.ddc-page-title');

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.inventory-detail-quick-specs .quick-specs li');
    var pricingTable = document.querySelectorAll('.pricing .clearfix span');

    data.img = document.querySelector('.imageViewer img');

    if(vehicleOverview.length>0 || pricingTable.length>0){

        if(vehicleOverview.length>0){
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if(array[index].children.length !== 0) {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch(label.trim()) {
                        case 'mileage':
                            data.mileage = array[index].children[1];
                            break;

                        case 'stock':
                            data.stock = array[index].children[1];
                            break;

                        case 'vin':
                            data.vin = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        }

        if(pricingTable.length>0){
            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
                if(array[index].children.length > 1) {
                    var label = array[index].children[1].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch(label.trim()) {

                        case 'msrp':
                            data.retailPrice = array[index].children[0];
                            break;

                        case 'zeigler price':
                        case 'price':
                            data.listedPrice = array[index].children[0];
                            break;

                        default:
                    }
                }
                return true;
            });
        }

    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        year: function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make: function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model: function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.model = model.splice(2, 1)[0];
        },
        trim: function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status >= 0) ? 0 : 1;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock: function(data, result) {
            var stock = data.stock.textContent;
            if (stock.search(/:/g) > -1) {
                result.stock = stock.split(':')[1].trim();
            } else {
                result.stock = stock.trim();
            }
        },
        vin: function(data, result) {
            var vin = data.vin.textContent;
            if (vin.search(/:/g) > -1) {
                result.vin = vin.split(':')[1].trim();
            } else {
                result.vin = vin.trim();
            }
        },
        img: function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
    };

    // call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
