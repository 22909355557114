// interfaces/types
import type { SdpWidgetInitialization } from '../types/initializationInterfaces';

// utils
import { isFetchOfferDealXg } from './fetchOfferDealXg';
import { isSaveOffer, isSaveOfferResults } from './saveOffer';
import { checkRequiredFieldsAnd } from './utils';

export function isSdpWidgetInitialization(data: any): data is SdpWidgetInitialization {
    const fieldsExist = checkRequiredFieldsAnd(data, ['offer', 'version']);
    return fieldsExist && (isSaveOffer(data.offer) || isFetchOfferDealXg(data.offer) || isSaveOfferResults(data.offer));
}

export function isDealerInitialization(data: any): boolean {
    return data?.offer?.source === 'VIN';
}

export function isValidDealerInitialization(data: any): boolean {
    const shopper = data?.offer?.shopper;
    if (!shopper) {
        return false;
    }
    const { firstName, lastName, email } = shopper;
    return firstName && lastName && email;
}
