var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '0',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.img = document.querySelector('img#home_img1');
    data.listedPrice = document.querySelector('.inventory-detailed-internet-price')
        || document.querySelector('.price_line_1')
        || document.querySelector('.ecl_vehicle_price_pri');

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.inventory-detailed-details .datatable dl');
    if(!vehicleOverview.length){
        vehicleOverview = document.querySelectorAll('table#vitalsInfo td');
        if(!vehicleOverview.length){
            vehicleOverview = document.querySelectorAll('li table tbody tr');
        }
    }

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );


    if (overviewArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children[1]) {
                var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase();

                switch (label.trim()) {
                    case 'type':
                        data.vehicleStatus = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'year':
                        data.year = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'make':
                        data.make = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'model':
                        data.model = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'trim':
                        data.trim = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            } else {
                if(row.innerHTML.indexOf('<strong>')>-1){
                    var labelAndValue = row.innerHTML.trim().toLowerCase().split('</strong>');
                    var label = labelAndValue[0].replace(/<[^>]*>/g, "");
                    var value = labelAndValue[1];

                    switch (label.replace(replaceRegex, '')) {

                        case 'year':
                            data.year = value.replace(replaceRegex, '');
                            break;

                        case 'make':
                            data.make = value.replace(replaceRegex, '');
                            break;

                        case 'model':
                            data.model = value.replace(replaceRegex, '');
                            break;

                        case 'mileage':
                            data.mileage = value.replace(replaceRegex, '');
                            break;

                        default:
                            break;
                    }

                    data.vin = document.querySelector('.vitalsLink');

                    data.stock = document.querySelector('table#vitalsInfo .last .vitalsColumnLeftBottom');
                    if(data.stock){
                        data.stock = data.stock.innerHTML.trim().toLowerCase().split('</strong>')[2];
                    }
                }
            }
            return true;
        });
        if(!data.year){
            data.year = document.querySelector('.iui_vehicle li b');
        }

        if(!data.make){
            data.make = document.querySelector('.iui_vehicle li b');
        }

        if(!data.model){
            data.model = document.querySelector('.iui_vehicle li b');
        }

        if(!data.trim){
            data.trim = document.querySelector('.iui_vehicle li b');
        }
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            if(data.year.textContent){
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            }else{
                result.year = parseInt(data.year, 10);
            }
        },
        make: function(data, result) {
            if(data.make.textContent){
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            }else{
                result.make = data.make;
            }
        },
        model: function(data, result) {
            if(data.model.textContent){
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }else{
                result.model = data.model;
            }
        },
        trim: function(data, result) {
            if(data.trim){
                if(data.trim.textContent){
                    var title = shimUtils.titleTrimmer(data.trim);
                    result.trim = title.trim;
                }else{
                    result.trim = data.trim;
                }
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if(data.vin.textContent){
                data.vin = data.vin.textContent.trim();
            }
            result.vin = data.vin;
        },
        stock: function(data, result) {
            var stock = data.stock.replace(/\s/g, "");
            result.stock = stock;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus === 'new') {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
