import { DS_SP_INIT_ACK } from './messageContexts';
import {
    alwaysLog,
    log,
    decodeMessage,
    encodeMessage,
    isValidGeneralMesssage,
    isValidMessage,
    isValidPixallAnalyticsMessage,
    isValidRememberedTradeMessage
} from './utils';

export type onTrackFunction = (eventName: string, eventAction: string, data: any) => void;
export type ackReceivedFunction = (success: boolean) => void;

export class AnalyticsRelay {
    protected _contentWindow: Window;
    protected readonly HOSTING_PAGE_DOMAIN = '*';

    /* The retry timeouts are as follows:
    - it first tries to establish communication between widget and SP after ANALYTICS_RELAY_INIT_TIMEOUT (2s).
    - if the first try fails, it attempts again after ANALYTICS_RELAY_RETRY_TIMEOUT (2s).
    - it attempts this 9 more times, which totals 20 seconds, and then gives up.
    */
    protected readonly ANALYTICS_RELAY_INIT_TIMEOUT = 2000;
    protected readonly ANALYTICS_RELAY_RETRY_COUNT = 9;
    protected readonly ANALYTICS_RELAY_RETRY_TIMEOUT = 2000;
    protected readonly ANALYTICS_RELAY_ACK_TIMEOUT = 100;
    protected retriesRemaining = 0;
    public receivedSpAckMessage = false;
    public ackReceived: ackReceivedFunction;
    public onTrack?: onTrackFunction;

    public send = (payload: any): void => {
        const msg = encodeMessage(payload);
        log(`sending message with payload: ${msg}`);
        this._contentWindow.postMessage(msg, this.HOSTING_PAGE_DOMAIN);
    };

    public receive = (event: any): void => {
        alwaysLog('SDP Widget received post message in analyticsRelay');

        if (!event || !event.data) {
            return;
        }

        const rawMessage = event.data;
        const obj = decodeMessage(rawMessage);

        if (!isValidMessage(obj)) {
            return;
        }

        log('processing valid analytics context message');
        alwaysLog('SDP Widget received valid post message in analyticsRelay');

        if (isValidGeneralMesssage(obj)) {
            const { message: contextMessage } = obj;
            if (contextMessage === DS_SP_INIT_ACK) {
                log('received shopper platform ack message for analytics relay');
                this.receivedSpAckMessage = true;
                this.ackReceived?.(true);
            } else {
                alwaysLog(`Unexpected analytics relay general message received: ${contextMessage}`);
            }
        } else if (isValidPixallAnalyticsMessage(obj)) {
            // post message this through to the referrer so it can be handled by pixall
            log('posting to PIXALL', undefined, obj.message);
            window.postMessage(obj.message, document.location.origin);
            // this.send(obj.message, document.location.origin);
        } else if (isValidRememberedTradeMessage(obj)) {
            // save the remembered trade to localstorage
            // TODO: Implement RememberedTradeMessage
        } else if (obj.message && this.onTrack) {
            const { eventAction, eventData, eventName } = obj.message;
            if (eventName) {
                this.onTrack(eventName, eventAction, eventData);
            }
        }
    };

    public init = (iframe: HTMLIFrameElement, ackReceived: ackReceivedFunction, onTrack?: onTrackFunction): void => {
        // setup frame and tracking function
        this._contentWindow = iframe.contentWindow;
        this.ackReceived = ackReceived;
        this.onTrack = onTrack;

        this.addMessageEventListener();
        alwaysLog('DS initializing shopper platform link for analyticsRelay');

        this.retriesRemaining = this.ANALYTICS_RELAY_RETRY_COUNT;
        setTimeout(() => {
            this.tryToInitSpLink(this.ANALYTICS_RELAY_RETRY_TIMEOUT);
        }, this.ANALYTICS_RELAY_INIT_TIMEOUT);
    };

    public close = (): void => {
        this.removeMessageEventListener();

        // clear the settings
        this._contentWindow = undefined;
        this.onTrack = undefined;
    };

    private tryToInitSpLink = (timeoutToUse: number) => {
        log('sending message to IFrame to initiate communication');
        this.send({
            sourceId: 'MAIN',
            targetId: 'SP-MAIN',
            context: 'ANALYTICS',
            message: {
                type: 'Init Analytics',
                description: ''
            }
        });

        if (this.retriesRemaining <= 0) {
            alwaysLog(
                `Giving up trying to establish communication for analytics relay after ${this.ANALYTICS_RELAY_RETRY_COUNT} retries.`
            );
            this.ackReceived?.(false);
        } else {
            this.retriesRemaining--;
            setTimeout(() => {
                // after a short timeout (say 100ms) check to see whether we've received the acknowledge message from
                // shopper platform
                if (!this.receivedSpAckMessage) {
                    log(`no ack message from shopper platform - retrying after ${this.ANALYTICS_RELAY_ACK_TIMEOUT} timeout`);
                    setTimeout(() => {
                        // after the requested interval (minus the short timeout above)
                        this.tryToInitSpLink(timeoutToUse);
                    }, timeoutToUse - this.ANALYTICS_RELAY_ACK_TIMEOUT);
                }
            }, this.ANALYTICS_RELAY_ACK_TIMEOUT);
        }
    };

    /* istanbul ignore next */
    private getEventInfo = (): { addEvent: string; msg: string; removeEvent: string } => {
        if (window.addEventListener) {
            return {
                addEvent: 'addEventListener',
                msg: 'message',
                removeEvent: 'removeEventListener'
            };
        }

        return {
            addEvent: 'attachEvent',
            msg: 'onmessage',
            removeEvent: 'detachEvent'
        };
    };

    private addMessageEventListener = () => {
        alwaysLog('sdp widget set up event listener for analyticsRelay');
        const info = this.getEventInfo();
        const eventer = window[info.addEvent];
        eventer(info.msg, this.receive);
        log('addMessageEventListener');
    };

    private removeMessageEventListener = () => {
        alwaysLog('sdp widget close event listener for analyticsRelay');
        const info = this.getEventInfo();
        const eventer = window[info.removeEvent];
        eventer(info.msg, this.receive);
        log('removeMessageEventListener');
    };
}
