var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.listedPrice) {
        if(options.retailPrice){
            options.listedPrice = options.retailPrice;
        }
    }

    return options;
};
