var shimUtils = require('../utils');
//871

module.exports = function(options) {

    options = options || {};

    if(options.vehicleStatus && options.vehicleStatus === 1) {
        if(options.retailPrice){

            options.listedPrice = options.retailPrice;

        }else{

            var data = {
                retailPrice : ''
            };


            //get retailPrice as listedPrice
            data.retailPrice = document.querySelector('.asking-price .strike-through');



            shimUtils.callValueQuerySelector(data, options);

            //define tasks to get data
            var tasks = {
                retailPrice : function(data, result) {
                    var retailPrice = data.retailPrice;
                    result.retailPrice = shimUtils.getInteger(retailPrice);
                }
            };

            //call continue to get data
            options = shimUtils.continue(data, tasks, options);

        }
    }

    return options;
};
