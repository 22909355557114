var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var vehicleStatus = options.vehicleStatus;
    if(vehicleStatus){
        if(vehicleStatus===1){
            if(options.stock){
                var stock = options.stock;
            }else{
                var stock = document.querySelector('.stockNumber .value').textContent;
            }
            if(stock){

                stock = stock.toLowerCase().trim().split('')[0];

                switch (stock) {
                    case 'h':
                        options.dealerId = 834;
                        break;

                    case 't':
                        options.dealerId = 835;
                        break;

                    case 'f':
                        options.dealerId = 839;
                        break;

                    default:
                        options.dealerId = 836;
                        break;
                }
            }
        }
    };


    return options;
};
