//get correct prices for dealer
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
      retailPrice : '',
      listedPrice : '',
    }

    //remove extra containers that are hidden
    var parentContainers = [document.querySelector('.vi-tablet'), document.querySelector('.vi-mobile')];
    var app = null;
    var appSet = false;

    for(var i = 0; i < parentContainers.length; i++) {

        var parentContainer = parentContainers[i];
        var hasFrame = false;
        var containers = parentContainer.querySelectorAll('#mmd-widget');

        for(var j = 0; j < containers.length; j++) {

            var container = containers[j];

            //set app
            if(container.hasChildNodes() && !app) {
                hasFrame = true;
                app = container.childNodes[0];
            }

            //check if current target is hidden
            if(shimUtils.isElementHidden(parentContainer)) {
                container.parentNode.removeChild(container);
            } else {
                //append app to apt container if windo width is b/w 768 & 991
                if(window.innerWidth >= 768 && window.innerWidth <= 991) {
                    containers[1].appendChild(app);
                    break;
                }
                container.appendChild(app);
                appSet = true;
                break;
            }
        }


        if(appSet) {
            break;
        }

    }


    data.retailPrice = document.querySelector('.price-list .msrp .dollars')
            || document.querySelector('.price-list .bookretail .dollars');
    data.listedPrice = document.querySelector('.price-list .final .dollars');



    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
      listedPrice : function(data,result){
        result.listedPrice = shimUtils.getInteger(data.listedPrice);
      },
      retailPrice : function(data,result){
        result.retailPrice = shimUtils.getInteger(data.retailPrice);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
