//KBB Report DCA

module.exports = function(options) {

	options = options || {};

	//get vehicleCondition
	var title = document.querySelector('.primary-vehicle-title');
	if (title) {
		var isCPO = title.innerHTML.indexOf('Certified') > -1;
		if (options.condition === 'used' && isCPO) options.condition = 'certified'; 
		options.certifiedStatus = isCPO;
	}

	// TODO: remove once we can provision tracking phone number from admin
	var phoneNumber = document.querySelector('[data-birf-cmp="kbb_call"]');
	try {
		options.vdpTrackingPhoneNumber =  typeof phoneNumber === 'object' ? phoneNumber.textContent.trim() : '';
	}catch(error){ }

	return options;

}
