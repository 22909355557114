// MWO-2034
// Need to send deals to different stores based on the make
var convertDealerId = require('./utils/odanielgroup.utils');

module.exports = function(options) {

    options = options || {};

    options = convertDealerId(options);

    return options;
};
