var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '0',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    if(document.URL.indexOf('mobile')>-1){ //mobile
        data.year = data.make = data.model = data.trim = document.querySelector('table tbody tr td strong');
        data.img = document.querySelector('.carImg');

        var vehicleOverview = document.querySelector('table tbody tr:nth-child(3) td');

        if(vehicleOverview){
            vehicleOverview = vehicleOverview.innerHTML.split('<br>');

            function transformHTML(value){
                var transformedValue = value.replace(/<\/?[^>]+(>|$)/g, "");
                return transformedValue;
            }

            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            )

            overviewArr = overviewArr.map(transformHTML);

            var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;
            var siteOptionsCollector = [];

            siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if(row.indexOf(':')>-1){
                    var labelAndValue = row.trim().replace(replaceRegex, '').toLowerCase().split(':');
                    var label = labelAndValue[0];
                    var value = labelAndValue[1];

                    switch (label.trim()) {
                        case 'price':
                            data.listedPrice = value.trim();
                            break;

                        case 'mileage':
                            data.mileage = value.trim();
                            break;

                        case 'vin':
                            data.vin = value.trim();
                            break;

                        case 'stockno':
                            data.stock = value.trim();
                            break;

                        default:
                            break;

                    }
                    return true;
                }
            });
        }

    }else{ //desktop

        // grab data from vehicle overview table
        var vehicleOverviewOne = document.querySelectorAll('.greyStripe');
        var vehicleOverviewTwo = document.querySelectorAll('.whiteStripe');
        var overviewArrOne = Array.prototype.map.call(
            vehicleOverviewOne,
            function(obj, i, arr) {
                return obj;
            }
        );

        var overviewArrTwo = Array.prototype.map.call(
            vehicleOverviewTwo,
            function(obj, i, arr) {
                return obj;
            }
        );

        if(overviewArrOne && overviewArrTwo)
            var overviewArr = overviewArrOne.concat(overviewArrTwo);

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;
        var siteOptionsCollector = [];

        if (overviewArr) {

            siteOptionsCollector = overviewArr.filter(function(row, index, array) {

                var labelAndValue = row.textContent.trim().replace(replaceRegex, '').toLowerCase().split(':');
                var label = labelAndValue[0];
                var value = labelAndValue[1];

                switch (label.trim()) {
                    case 'mileage':
                        data.mileage = value.trim();
                        break;

                    case 'v.i.n.':
                        data.vin = value.trim();
                        break;

                    case 'stock no.':
                        data.stock = value.trim();
                        break;

                    case 'internet price':
                        data.listedPrice = value.trim();
                        break;

                    default:
                        break;

                }
                return true;
            });
        }

        data.year = data.make = data.model = data.trim = document.querySelector('.detailsHeader span:nth-child(1)');

        data.img = document.querySelector('.carImg');

    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
