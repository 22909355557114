//shim:1702
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus: ''
    };

    //get status, year, make, model & trim
    data.vehicleStatus = document.URL
    data.year = data.make = data.model = document.querySelector('.wrap-vdptitle .vdptitle');
    data.trim = document.querySelector('.wrap-vdptitle .vdptitle2');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        year : function(data, result) {
            var title = data.year.textContent.trim().split(/\s/g);
            if(title.length >= 3){
                result.year = title[0];
            }
        },
        make : function(data, result) {
            var title = data.make.textContent.trim().split(/\s/g);
            if(title.length >= 3){
                result.make = title[1];
            }
        },
        model : function(data, result) {
            var title = data.model.textContent.trim().split(/\s/g);
            if(title.length >= 3){
                result.model = title[2];
            }
        },
        trim : function(data, result) {
            result.trim = data.trim.textContent;
        },
        vehicleStatus : function(data, result) {
            if(data.vehicleStatus.indexOf('new') >= 0){
                result.vehicleStatus = 1;
            }
            else result.vehicleStatus = 0;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
