var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        model: ''
    };


    if(!options.model && !!options.trim) {
        if(options.trim==='Montana SV6') {
            data.model = options.trim;
            options.trim = '';
        }
    } else {
        data.model = options.model;
    }


    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {

        model: function(data, result) {
            result.model = data.model ? data.model : options.model;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    options.isShimmed = true;

    return options;
};
