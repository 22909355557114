import axios, { AxiosRequestConfig } from 'axios';
import { Subject } from 'rxjs';
import { bufferTime, filter } from 'rxjs/operators';
export class NewRelicClient {
    private environment: string;
    private API_KEY: string;
    private URL: string;
    private DEBUG_ENABLED: boolean = false;
    private BUFFER_TIME = 500;
    /**
     * Sink for incoming events
     * @private
     * @type {Subject<INewRelicEvent>}
     * @memberof NewRelicClient
     */
    private eventSink: Subject<INewRelicEvent>;
    constructor(options: { environment: string; API_KEY: string; URL: string }) {
        const { environment, API_KEY, URL } = options || { environment: 'development', API_KEY: '', URL: '' };
        this.eventSink = new Subject();
        this.environment = environment;
        this.API_KEY = API_KEY;
        this.URL = URL;

        if (this.environment == 'dev') {
            this.DEBUG_ENABLED = true;
            this.log('New Relic DEBUG_ENABLED');
        }
        this.setupEventPublisher();
    }

    public publishEvent(event: INewRelicEvent) {
        this.eventSink.next(event);
    }
    /**
     * The publisher listens to event sink and buffers the events for BUFFER_TIME
     * before publishing to New Relic
     * @private
     * @memberof NewRelicClient
     */
    private setupEventPublisher() {
        this.eventSink
            .pipe(
                bufferTime(this.BUFFER_TIME),
                filter((list) => list.length > 0)
            )
            .subscribe((list) => {
                this.httpPostToNewRelic(
                    list.map((event) => {
                        return { ...event, environment: this.environment };
                    })
                );
            });
    }
    private log(data: any) {
        if (this.DEBUG_ENABLED) {
            console.log(data);
        }
    }
    private httpPostToNewRelic(eventList: INewRelicEvent[]) {
        const options: AxiosRequestConfig = {
            headers: {
                'X-Insert-Key': this.API_KEY,
                'Content-Type': 'application/json'
            }
        };

        axios
            .post(this.URL, eventList, options)
            .then((response: any) => {
                if (response.status !== 200) {
                    const msg: any = `Failed to send custom attributes: ${response}`;
                    this.log(response);
                }
            })
            .catch((error: any) => {
                this.log(error);
            });
    }
}

export interface INewRelicEvent {
    // Unique identifier
    eventType: string;
    timestamp?: number;
    environment?: string;
}
