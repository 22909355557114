/**
 * Cobalt Template Shim for Standard sites
 * This template type is determine by running ContextManager.getPageLayout() on the console
 * The value will be 'VehicleDetails_std'
 * Dealers currently using this template: 836, 853
 */

 var shimUtils = require('../../utils');

module.exports = function(opts) {

    var data = opts.data;
    var options = opts.options;
    var cm = opts.cm;

    var vehicleSpecs = document.querySelectorAll('#detailTabsContainer #overview_content .specifications li');
    var pricingSpecs = document.querySelectorAll('#detailTabsContainer #overview_content .sortedPrice_container li');
    var specialPricingSpecs = document.querySelectorAll('#detailTabsContainer #overview_content .specials ul');

    if(pricingSpecs.length === 0) {
        pricingSpecs = document.querySelectorAll('.cash-price li');
    }

    if (vehicleSpecs.length || pricingSpecs.length || specialPricingSpecs.length) {

        var vehicleSpecsArr = [];
        var pricingSpecsArr = [];
        var specialPricingSpecsArr = [];

        if (vehicleSpecs.length) {
            vehicleSpecsArr = Array.prototype.map.call(
                vehicleSpecs,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (pricingSpecs.length) {
            pricingSpecsArr = Array.prototype.map.call(
                pricingSpecs,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (specialPricingSpecs.length) {
            specialPricingSpecsArr = Array.prototype.map.call(
                specialPricingSpecs,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        var optionsArr = vehicleSpecsArr.concat(pricingSpecsArr).concat(specialPricingSpecsArr);

        if (optionsArr.length) {
            var siteOptionsCollector = optionsArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
                    switch (label) {
                        case 'total msrp before offers':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'price after all offers':
                            data.listedPrice = array[index].children[1];
                            break;

                        case 'msrp':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'price':
                            data.retailPrice = array[index].children[1];
                            break;

                        case 'internet price':
                            data.listedPrice = array[index].children[1];
                            break;

                        case 'live market price':
                            data.listedPrice = array[index].children[1];
                            break;

                        case 'stock number':
                            data.stock = array[index].children[1];
                            break;

                        case 'vin':
                            data.vin = array[index].children[1];
                            break;

                        case 'mileage':
                            data.mileage = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        }
    }

    //set vehicleStatus
    data.vehicleStatus = document.querySelector('.title .category');

    //set year
    if(cm){
        if(cm.hasOwnProperty('vehicle')){
            if(cm.vehicle.hasOwnProperty('year')){
                data.year = cm.vehicle.year;
            }

            //set make
            if(cm.vehicle.hasOwnProperty('make')){
                data.make = cm.vehicle.make;
            }

            //set model
            if(cm.vehicle.hasOwnProperty('model')){
                data.model = cm.vehicle.model;
            }

            //set trim
            if(cm.vehicle.hasOwnProperty('trim')){
                data.trim = cm.vehicle.trim;
            }
        }
        //set vin
        if(cm.hasOwnProperty('vin')){
            data.vin = cm.vin;
        }
    }

    //get img
    data.img = document.querySelector('#media_placeholder img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        incentiveSavings: function(data, result) {

            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            status = status.textContent.trim().toLowerCase();
            result.vehicleStatus = status.search(/\b(certified|used|pre owned)\b/g) === -1 ? 1 : 0;
        },
        year: function(data, result) {
            result.year = shimUtils.getInteger(data.year);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            var stock = data.stock;
            if (stock.textContent.indexOf(':') > -1) {
                result.stock = stock.textContent.split(':')[1].trim();
            } else {
                result.stock = stock.textContent.trim();
            }
        },
        img: function(data, result) {
            result.img = data.img.getAttribute('data-original') ||
                data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
