var shimUtils = require('../utils');

module.exports = function(options) {

	options = options || {};

    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
		mileage : '',
        vehicleStatus : '',
        stock : '',
        vin : '',
        img : '',
    };

    //get retailPrice
	data.retailPrice = document.querySelector('.price');

	//get year, make, model, trim
	data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-title');

    var vehicleOverview = document.querySelectorAll('.specification li');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    var siteOptionsArr = overviewArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
        if(array[index].children.length !== 0) {
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch(label) {


                case 'vin ':
                    data.vin = array[index].children[1];
                    break;

                case 'vin':
                    data.vin = array[index].children[1];
                    break;

                case 'stock':
                    data.stock = array[index].children[1];
                    break;

                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;

                case 'type':
                    data.vehicleStatus = array[index].children[1];
                    break;

                default:
            }
        }
        return true;
    });

	//get img
	data.img = document.querySelector('.images img');

	//logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var year = data.year.textContent.trim().split(' ');
            result.year = year.shift();
        },
        make : function(data, result) {
            var make = data.make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model : function(data, result) {
            var model = data.model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim.textContent.replace(/[&\/\\#,+()$~%'"*?<>{}\u00ae]/g, '').trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
		mileage : function(data, result){
			var mileage = data.mileage;
			result.mileage = shimUtils.getInteger(mileage);
		},
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            if(status.textContent.toLowerCase().trim()=='new'){
                result.vehicleStatus = 1;
            }else{
                result.vehicleStatus = 0;
            }
        },
        vin : function(data, result) {
            var vin = data.vin;
            result.vin = vin.textContent.trim();
        },
        stock : function(data, result) {
            var stock = data.stock;
            result.stock = stock.textContent.trim();
        },
        img : function(data, result) {
            var img = data.img;
            result.img =img.src;
        }
    }

    //get data
    options = shimUtils.continue(data, tasks, options);

	//set url
	options.url = document.URL;

	//set loaded function
	options.onLoadedFunction = function() { console.log("widget loaded"); };

	//set hiding function
	options.onHidingFunction = function() { console.log("widget not showing"); };

	return options;


}
