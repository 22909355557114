//prevent vdps without a P stock from showing widget

module.exports = function(options) {

    options = options || {};

    if(options.stock.indexOf('P') != 0) {
        options.disableLoad = true;
    }

    return options;

}
