var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    data.listedPrice = document.querySelector('span.salePrice strong');

    if (data.listedPrice) {
        var tasks = {
            listedPrice: function(data, result) {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            }
        };
    
        options = shimUtils.continue(data, tasks, options);
    }

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    //set isShimmed
    options.isShimmed = true;

    return options;

}
