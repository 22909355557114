/**
 * Does the object contain the required fields
 * @param data Data to check for required fields
 * @param fields List of required fields
 */
export const checkRequiredFieldsAnd = (data: any, fields?: string[]): boolean => {
    if (!fields || fields.length === 0) {
        return true; // no required fields
    }

    return fields.every((field) => field in data);
};

/**
 * Does the object contain one or more of the required fields
 * @param data Data to check for required fields
 * @param fields List of required fields
 */
export const checkRequiredFieldsOr = (data: any, fields?: string[]): boolean => {
    if (!fields || fields.length === 0) {
        return true; // no required fields
    }

    return fields.some((field) => field in data);
};

export const isEmailLandingRoutes = (route) => {
    const emailLandingRoutes = {
        'shopper-platform': 'shopper-platform',
        dashboardDealSummaryModal: 'dashboardDealSummaryModal',
        credit: 'credit'
    };

    return emailLandingRoutes[route] !== undefined;
};
