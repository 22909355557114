
//hotfix to switch dealer id 271 to 268
//if the domain is gallerygroup

module.exports = function(options) {

	if(location.host === "www.parkwaymotorcars.com" || location.host === "www.parkwaycadillacvalencia.com") {
		options.dealerId = 268;
	}

	return options;

}
