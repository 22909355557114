module.exports = {
    38: require('./38'),
    92: require('./92'),
    99: require('./99'),
    103: require('./103'),
    141: require('./141'),
    154: require('./154'),
    155: require('./155'),
    159: require('./159'),
    180: require('./180'),
    187: require('./187'),
    188: require('./188'),
    210: require('./210'),
    212: require('./212'),
    214: require('./214'),
    234: require('./234'),
    236: require('./236'),
    241: require('./241'),
    254: require('./254'),
    267: require('./267'),
    271: require('./271'),
    278: require('./278'),
    275: require('./275'),
    281: require('./281'),
    290: require('./290'),
    302: require('./302'),
    304: require('./304'),
    327: require('./327'),
    339: require('./339'),
    344: require('./344'),
    354: require('./354'),
    355: require('./355'),
    356: require('./356'),
    357: require('./357'),
    358: require('./358'),
    372: require('./372'),
    385: require('./385'),
    411: require('./411'),
    425: require('./425'),
    430: require('./430'),
    439: require('./439'),
    403: require('./403'),
    440: require('./440'),
    441: require('./441'),
    444: require('./444'),
    447: require('./447'),
    454: require('./454'),
    456: require('./456'),
    464: require('./464'),
    466: require('./466'),
    471: require('./471'),
    473: require('./473'),
    480: require('./480'),
    481: require('./481'),
    498: require('./498'),
    503: require('./503'),
    508: require('./508'),
    511: require('./511'),
    513: require('./513'),
    518: require('./518'),
    521: require('./521'),
    528: require('./528'),
    529: require('./529'),
    530: require('./530'),
    532: require('./532'),
    544: require('./544'),
    549: require('./549'),
    550: require('./550'),
    555: require('./555'),
    559: require('./559'),
    600: require('./600'),
    603: require('./603'),
    614: require('./614'),
    617: require('./617'),
    618: require('./618'),
    619: require('./619'),
    621: require('./621'),
    625: require('./625'),
    655: require('./655'),
    658: require('./658'),
    675: require('./675'),
    676: require('./676'),
    679: require('./679'),
    688: require('./688'),
    700: require('./700'),
    711: require('./711'),
    714: require('./714'),
    727: require('./727'),
    728: require('./728'),
    731: require('./731'),
    736: require('./736'),
    743: require('./743'),
    745: require('./745'),
    746: require('./745'),
    753: require('./753'),
    771: require('./771'),
    773: require('./773'),
    787: require('./787'),
    798: require('./798'),
    807: require('./807'),
    809: require('./809'),
    810: require('./810'),
    811: require('./811'),
    812: require('./812'),
    818: require('./818'),
    819: require('./819'),
    829: require('./829'),
    831: require('./831'),
    834: require('./834'),
    835: require('./835'),
    836: require('./836'),
    837: require('./837'),
    839: require('./839'),
    849: require('./849'),
    852: require('./852'),
    857: require('./857'),
    867: require('./867'),
    858: require('./858'),
    870: require('./870'),
    871: require('./871'),
    873: require('./873'),
    876: require('./876'),
    879: require('./879'),
    884: require('./884'),
    886: require('./886'),
    887: require('./887'),
    889: require('./889'),
    901: require('./901'),
    903: require('./903'),
    908: require('./908'),
    911: require('./911'),
    916: require('./916'),
    921: require('./921'),
    943: require('./943'),
    960: require('./960'),
    967: require('./967'),
    973: require('./973'),
    1020: require('./1020'),
    1021: require('./1021'),
    1030: require('./1030'),
    1036: require('./1036'),
    1046: require('./1046'),
    1059: require('./1059'),
    1061: require('./1061'),
    1105: require('./1105'),
    1107: require('./1107'),
    1109: require('./1109'),
    1110: require('./1110'),
    1147: require('./1147'),
    1154: require('./1154'),
    1162: require('./1162'),
    1182: require('./1182'),
    1183: require('./1183'),
    1184: require('./1184'),
    1191: require('./1191'),
    1203: require('./1203'),
    1214: require('./1214'),
    1227: require('./1227'),
    1228: require('./1228'),
    1238: require('./1238'),
    1239: require('./1239'),
    1299: require('./1299'),
    1564: require('./1564'),
    1583: require('./1583'),
    1604: require('./1604'),
    1616: require('./1616'),
    1634: require('./1634'),
    1635: require('./1635'),
    1637: require('./1637'),
    1640: require('./1640'),
    1645: require('./1645'),
    1648: require('./1648'),
    1653: require('./1653'),
    1661: require('./1661'),
    1666: require('./1666'),
    1702: require('./1702'),
    1720: require('./1720'),
    1736: require('./1736'),
    1751: require('./1751'),
    1755: require('./1755'),
    1774: require('./1774'),
    1783: require('./1783'),
    1799: require('./1799'),
    1803: require('./1803'),
    1819: require('./1819'),
    1823: require('./1823'),
    1832: require('./1832'),
    1834: require('./1834'),
    1835: require('./1835'),
    1842: require('./1842'),
    1853: require('./1853'),
    1857: require('./1857'),
    1863: require('./1863'),
    1870: require('./1870'),
    1892: require('./1892'),
    1903: require('./1903'),
    1908: require('./1908'),
    2039: require('./2039'),
    2087: require('./2087')
};
