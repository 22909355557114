var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.year = data.make = data.model = data.trim = document.querySelector('[itemprop="name"]');

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.well .cutoff');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );


    if (overviewArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {

            var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().trim().split(':');
            var label = labelAndValue[0].trim();
            var value = labelAndValue[1];

            switch (label) {
                case 'mileage':
                    data.mileage = value;
                    break;

                case 'stock':
                    data.stock = value;
                    break;

                case 'vin':
                    data.vin = value;
                    break;

                case 'condition':
                    data.vehicleStatus = value;
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    var img = document.getElementById('photoLoader') || '';
    if(img){
        data.img = img.querySelector('img');
    }

    data.listedPrice = document.querySelector('[itemprop="price"]');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus === 'new') {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            if(data.img.nodeName === 'A'){
                result.img = data.img.href;
            }else{
                result.img = data.img.src;
            }

        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
