'use strict';
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //defer from get go
    var isComplete = shimUtils.isComplete();
    if (!options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    var href = location.href.toLowerCase();

    data.year = data.make = data.model = data.trim = document.querySelector('.vehicleTitle [itemprop="name"]')
    || document.getElementById('detailsBlock').querySelector('[itemprop=name]');

    data.vehicleStatus = href.search(/new/g);

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.srpVehicleDetails li');
    var pricingTable = document.querySelectorAll('.srpVehiclePricing li')

    if(vehicleOverview.length>0 || pricingTable.length>0){
        if(vehicleOverview.length>0){
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if(pricingTable.length>0){
            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if(overviewArr && pricingArr){
            var siteOptionsArr = overviewArr.concat(pricingArr);
        }else if(overviewArr && !pricingArr){
            var siteOptionsArr = overviewArr;
        }else if(!overviewArr && pricingArr){
            var siteOptionsArr = pricingArr;
        }

        if(siteOptionsArr){
            var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
                if(array[index].textContent.indexOf(':') > -1) {
                    var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
                    var label = labelAndValue[0].trim();
                    var value = labelAndValue[1].trim();
                    switch(label) {
                        case 'mileage':
                            data.mileage = value;
                            break;

                        case 'your price':
                            data.listedPrice = value;
                            break;

                        case 'msrp':
                            data.retailPrice = value;
                            break;

                        case 'vin':
                            data.vin = value;
                            break;

                        case 'vin ':
                            data.vin = value;
                            break;

                        case 'stock':
                            data.stock = value;
                            break;

                        case 'stock ':
                            data.stock = value;
                            break;

                        default:
                    }
                }
                return true;
            });
        }
    }

    if(data.retailPrice===''){
        data.retailPrice = document.querySelector('.msrpPrice span:nth-child(2)');
    }

    if(data.listedPrice===''){
        data.listedPrice = document.querySelector('.internetPrice span:nth-child(2)') ||
            document.querySelector('span[itemprop="price"]');
    }

    if(data.mileage===''){
        data.mileage = document.getElementById('mileageValue') || '';
    }

    if(data.stock===''){
        data.stock = document.getElementById('stockNumber');
    }

    if(data.vin===''){
        data.vin = document.getElementById('VIN');
    }

    data.img = document.getElementById('car_pic') || document.querySelector('.carousel-inner img') || '';

	//logging
	shimUtils.callValueQuerySelector(data, options);

	//define tasks to get data
	var tasks = {
		listedPrice : function(data, result) {
            var listedPrice = data.listedPrice;
            if(!!listedPrice.textContent){
                listedPrice = listedPrice.textContent;
            }
            result.listedPrice = parseInt(listedPrice.replace(/\D/g, ''),10);

        },
        retailPrice : function(data, result) {
            var retailPrice = data.retailPrice;
            if(!!retailPrice.textContent){
                retailPrice = retailPrice.textContent;
            }
            result.retailPrice = parseInt(retailPrice.replace(/\D/g, ''),10);

		},
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1,1)[0];
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ');
            result.model = model.splice(2,1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ');
            result.trim = trim.splice(3,trim.length).join(' ');
        },
        vehicleStatus : function(data, result) {
            var vehicleStatus = data.vehicleStatus;
            result.vehicleStatus = (vehicleStatus > -1) ? 1 : 0;
        },
        mileage : function(data, result) {
            var mileage = data.mileage;
            if(!!mileage.textContent){
                mileage = mileage.textContent;
            }
            result.mileage = parseInt(mileage.replace(/\D/g, ''),10);
        },
        vin : function(data, result) {
            var vin = data.vin;
            if(!!vin.textContent){
                vin = vin.textContent;
            }
            if(vin.indexOf(':')>-1){
                vin = vin.split(':')[1];
            }
            result.vin = vin.trim();
        },
        stock : function(data, result) {
            var stock = data.stock;
            if(!!stock.textContent){
                stock = stock.textContent;
            }
            if(stock.indexOf(':')>-1){
                stock = stock.split(':')[1];
            }
            result.stock = stock.trim();
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
	}

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //set url
    options.url = document.url;

    return options;
}
