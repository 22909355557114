var shimUtils = require('../utils');
// 464

module.exports = function(options) {

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.inventory-detailed-section .container .data .row');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    if (overviewArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.textContent.indexOf(':')>-1) {
                var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().split(':');
                var label = labelAndValue[0];
                var value = labelAndValue[1];

                switch (label.trim()) {
                    case 'vin':
                        data.vin = value.trim();
                        break;

                    case 'stock':
                        data.stock = value.trim();
                        break;

                    default:
                        break;
                }
            }
            return true;
        });

        //get img
        data.img = document.querySelector('.inventory-detailed-section .container .row:nth-child(3) .frame a');
        data.year = data.make = data.model = data.trim = document.querySelector('.inventory-detailed-section .container .row:nth-child(2) div:nth-child(1) h2');
        data.listedPrice = document.querySelector('.inventory-detailed-section .container .row:nth-child(2) div:nth-child(2) h2');
        data.mileage = document.querySelector('.inventory-detailed-section .container .row:nth-child(5) .col-lg-4:nth-child(2) .row:nth-child(1) div:nth-child(2) span');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        img: function(data, result) {
            result.img = data.img.href;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
