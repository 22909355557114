var titleTrimmer = module.exports = {

    // MOVE THIS TO TITLETRIMMER
    /* Sanitizes vehicle title description removing the location
       We are assuming the location is at the end of the title string
       shortly after the vehicle trim following this pattern:
       [<vehicleStatus> Year Make    Model Trim     location]
       <New>            2015 Lincoln MKC   FWD 4DR  for sale OR near OR in OR | San Francisco, CA
    */
    removeLocationFromVehicleTitle: function(selector) {
        var title = !!selector.textContent ? selector.textContent.trim() : selector.trim();
        var unnecessaryStringsOnTitle = title.search(/for sale|\bin\b|\bat\b|\bnear\b/gi);
        if(title.indexOf('|')>0 && unnecessaryStringsOnTitle===-1) {
            unnecessaryStringsOnTitle = title.indexOf('|');
        };
        return (unnecessaryStringsOnTitle >= 0) ? title.substring(0, unnecessaryStringsOnTitle).trim() : title;
    },

    trimExcess: function(data) {
        if (data.textContent) {
            data = data.textContent;
        };

        var unnecessaryStringsOnTitle = /(?:^|\W)\bcar\b|\bcars\b|\bdetails\b|\bview\b|\bvehicle\b|internet|\bspecial\b|\bbrilliance auto a brilliannt decision\b|\brear entertainment\b|\bfind\b|\brare\b|\b3rd row seating\b|\bcarfax\b|\binventory\b|--|[|&\/\\#,+()!$~%'":*?<>{}](?:$|\W)/ig;

        data = data.replace(unnecessaryStringsOnTitle,'');
        data = data.replace(/[\t\n\r:]/gm, " ").trim().split(' ').filter(function(n) {
            return n != ''
        });

        return data;
    },
    checkStatus: function(data) { //TODO : Remove when parseVehicleDescription is connected to API
        //remove certified, used, new, and preowned from beginning of title if there
        var status = '';
        var titleElement = '';
        var titleTrimmer = data.filter(function(row, index, array) {
            var titleElement = data[index].trim();
            switch (titleElement.toLowerCase()) {

                case 'new':
                    status = 1;
                    data[index] = '';
                    break;

                case 'used':
                case 'pre-owned':
                case 'certified':
                    status = 0;
                    data[index] = '';
                    break;

                default:
            }
            return true;
        });
        data = data.filter(function(n) {
            return n != ''
        });
        return [data, status];
    },
    checkYear: function(data) {
        // Check if year exists
        var length = data.length;
        var year = '';

        if (!isNaN(data[0]) && data[0].trim().length == 4) {
            year = parseInt(data[0], 10);
            data.splice(0, 1);
        };

        return [data, year];
    },
    checkMake: function(data) {
        // Check if make exists
        // TODO: have length work for longer makes

        var makeLength = 1;
        var make = '';

        var compoundMakes = [
            'land rover',
            'mercedes benz',
            'aston martin',
            'alfa romeo',
            'rolls royce'
        ];

        //if beginning is super assume make is not in title due to odanford
        switch (data[0].toLowerCase()) {
            case 'super':
            case 'f-150':
            case 'f-250':
            case 'f-350':
            case 'f-450':
            case 'f-550':
            case 'f-650':
            case 'f-750':
            case 'transit':
                make = 'ford';
                break;

        }

        if (data[0].toLowerCase() == 'super') {
            data = data.join(' ')
        } else {
            var title = data.join(' ');
            for(var index in compoundMakes) {
                if(title.toLowerCase().indexOf(compoundMakes[index]) !== -1) {
                    makeLength = compoundMakes[index].split(' ').length;
                }
            }
            title = title.split(' ');
            make = title.splice(0, makeLength).join(' ');
            data = title.join(' ')
        };

        return [data, make];
    },
    checkModel: function(data) {
        // Check if compound model name; returns model element with correct length;
        //TODO: Expand past F-250 like cars
        //TODO: see if can combine super duty into nested array
        var modelLength = 1; //Length is assumed to be one
        var model = '';
        var title = data;

        var compoundModels = [
            'super duty f-150',
            'super duty f-250',
            'super duty f-350',
            'super duty f-450',
            'super duty f-550',
            'super duty f-650',
            'super duty f-750',
            '1 series',
            '2 series',
            '3 series',
            '4 series',
            '5 series',
            '6 series',
            '7 series',
            '8 series',
            '9 series',
            'grand cherokee',
            'grand caravan',
            'range rover'
        ];


        for (var index in compoundModels) {
            if (title.toLowerCase().indexOf(compoundModels[index]) !== -1) {
                //get length of model
                modelLength = compoundModels[index].split(' ').length;
            }
        }
        title = title.split(' ');
        model = title.splice(0, modelLength).join(' ');
        data = title.join(' ')
        return [data, model];
    },
    titleTrimmer: function(data) {
        var status;
        var year;
        var make;
        var model;
        var trim;

        //Remove unnecessary parts of the title;
        data = this.removeLocationFromVehicleTitle(data);

        data = this.trimExcess(data);

        data = this.checkStatus(data);
        status = data[1];
        data = data[0];

        data = this.checkYear(data);
        year = data[1];
        data = data[0];

        data = this.checkMake(data);
        make = data[1];
        data = data[0];

        data = this.checkModel(data);
        model = data[1];
        data = data[0];

        trim = data;
        return {
            status: status,
            year: year,
            make: make,
            model: model,
            trim: trim
        };
    }
};
