// test/vin/texasauto.html
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: ''
    };

    if(location.pathname.search(/mobile/g) > -1) {
        data.listedPrice = document.querySelector('.pricing')
    } else {
        data.listedPrice = options.listedPrice;
    }

    if(!options.model && options.trim){
        var trim = options.trim.toLowerCase();

        switch (trim) {
            case trim.indexOf('750li') > -1:
                data.model = '7 Series';
                break;

            default:
                break;
        }

        if (!data.model) {
            data.model = document.querySelector('.cardata');
            data.trim = options.trim.substr(options.trim.indexOf(" ") + 1);
        };

    } else {
        data.model = options.model;
    }

    if(!data.trim) {
        data.trim = options.trim;
    };

    // pull for cars on new site implementation
    var overviewData = [];
    var overviewPrice = [];
    var overviewArr = [];
    if(!options.listedPrice || !options.retailPrice) {
        var pricingTable = document.querySelectorAll('.ddc-row .ddc-span4 .inventory-detail-pricing .pricing .clearfix');

        if(pricingTable.length) {
            overviewPrice = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }
    };

    if(!options.mileage || !options.vin  || !options.stock) {
        var vehicleOverview = document.querySelectorAll('.quick-specs li');

        if(vehicleOverview.length) {
            overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }
    };

    if (overviewPrice.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewPrice.filter(function(row, index, array) {
            if (row.textContent) {
                var labelAndValue = row.textContent.replace(replaceRegex, '').trim().split('\n');
                if(labelAndValue[1]) {
                    var label = labelAndValue[1].toLowerCase().trim();
                    var value = labelAndValue[0].trim();

                    switch (label) {
                        case 'internet price':
                            data.listedPrice = value;
                            break;

                        default:
                            break;
                    }
                }
            }
            return true;
        });
    }

    if (overviewArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}/\n]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.textContent.indexOf(':')>-1) {
                var labelAndValue = row.textContent.replace(replaceRegex, '').trim().split(':');
                var label = labelAndValue[0].toLowerCase().trim();
                var value = labelAndValue[1].trim();

                switch (label) {
                    case 'mileage':
                        data.mileage = value;
                        break;

                    case 'stock':
                        data.stock = value;
                        break;

                    case 'vin':
                        data.vin = value;
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    if(!options.year) {
        data.year = document.querySelector('.ddc-page-title');
    };

    if(!options.make) {
        data.make = document.querySelector('.ddc-page-title');
    };

    if(!options.model) {
        data.model = document.querySelector('.ddc-page-title');
    };

    if(!options.trim) {
        data.trim = document.querySelector('.ddc-page-title');
    };

    if(!options.vehicleStatus) {
        data.vehicleStatus = document.URL;
    };

    if(!options.img) {
        data.img = document.querySelector('.imageViewer img')
    };


    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice: function(data, result) {
            if(data.listedPrice) {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            } else {
                result.listedPrice = options.listedPrice;
            }
        },
        retailPrice: function(data, result) {
            if(data.retailPrice) {
                result.retailPrice = shimUtils.getInteger(data.retailPrice);
            } else if (options.retailPrice) {
                result.retailPrice = options.retailPrice;
            }
        },
        year: function(data, result) {
            if(data.year) {
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            } else {
                result.year = options.year;
            }
        },
        make: function(data, result) {
            if(data.make) {
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            } else {
                result.make = options.make;
            }
        },
        model: function(data, result) {
            if(data.model.textContent) {
                var title = shimUtils.titleTrimmer(data.model);
                data.model = title.model;
            };

            result.model = data.model.trim();
        },
        trim: function(data, result) {
            if(data.trim) {
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            } else {
                result.trim = options.trim;
            }
        },
        vehicleStatus: function(data, result) {
            if(data.vehicleStatus) {
                result.vehicleStatus = data.vehicleStatus.toLowerCase().indexOf('new')>-1 ? 1 : 0;
            } else {
                result.vehicleStatus = options.vehicleStatus;
            }
        },
        mileage: function(data, result) {
            if(data.mileage) {
                result.mileage = shimUtils.getInteger(data.mileage);
            } else {
                result.mileage = options.mileage;
            }
        },
        vin: function(data, result) {
            if(data.vin) {
                result.vin = data.vin;
            } else {
                result.vin = options.vin;
            }
        },
        img: function(data, result) {
            if(data.img) {
                result.img = data.img.src;
            } else {
                result.img = options.img;
            }
        },
        stock: function(data, result) {
            if(data.stock) {
                result.stock = data.stock;
            } else {
                result.stock = options.stock;
            }
        }
    }

    //call continue to get data
    options = shimUtils.continue(data,tasks,options);

        //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    //set isShimmed
    options.isShimmed = true;

    return options;
}
