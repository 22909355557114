var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // site has a dataLayer object which has most of the needed options
    var siteOptions = window.dataLayer && window.dataLayer[0];

    data.year = siteOptions.year;

    data.make = siteOptions.make;

    data.model = siteOptions.model;

    data.trim = siteOptions.trim;

    data.stock = siteOptions.stock;

    data.vehicleStatus = siteOptions.condition;


    // grab data from vehicle overview table
    var vehicleOverviewLeft = document.querySelectorAll('.left-overview-data p');
    var vehicleOverviewRight = document.querySelectorAll('.right-overview-data p');
    var vehicleOverview = document.querySelectorAll('.overview .row');
    var pricingTable = document.querySelectorAll('.pricing-table tbody tr');

    var overviewArrLeft = Array.prototype.map.call(
        vehicleOverviewLeft,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArrRight = Array.prototype.map.call(
        vehicleOverviewRight,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewPrice = Array.prototype.map.call(
        pricingTable,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewData = [];

    if (overviewArrLeft.length) {
        overviewData = overviewArrLeft.concat(overviewArrRight);
    } else if (overviewArr.length) {
        overviewData = overviewArr;
    }

    if (overviewData.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        overviewData = overviewData.concat(overviewPrice);

        var siteOptionsCollector = overviewData.filter(function(row, index, array) {
            if (row.children[0]) {
                var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'msrp':
                        data.retailPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'price':
                        data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'jimmy britt price':
                        data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });

        //get img
        data.img = document.querySelector('.photos.stock-photos div a') || document.querySelector('.gallery-active-image');
        data.listedPrice = data.listedPrice || document.querySelector('[name="q_name_listedprice"]') && document.querySelector('[name="q_name_listedprice"]').value;
        data.mileage = data.mileage || document.querySelector('[name="q_name_mileage"]') || document.querySelector('[name="q_name_mileage"]').value;
        data.vin = data.vin || document.querySelector('[name="q_name_vin"]') && document.querySelector('[name="q_name_vin"]').value;
        data.stock = data.stock || document.querySelector('[name="q_name_stockno"]') || document.querySelector('[name="q_name_stockno"]').value;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if (data.listedPrice.textContent !== '') {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            } else {
                result.listedPrice = shimUtils.getInteger(data.listedPrice.value);
            }

        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = parseInt(data.year, 10);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            if (data.mileage.textContent !== '') {
                result.mileage = shimUtils.getInteger(data.mileage);
            } else {
                result.mileage = shimUtils.getInteger(data.mileage.value);
            }

        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus === 'new') {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            if (data.img.nodeName === 'A') {
                result.img = data.img.href;
            } else {
                result.img = data.img.src;
            }

        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
