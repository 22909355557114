var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        listedPrice: ''
    };

    //get listedPrice
    data.listedPrice =  document.querySelector('.highlight[itemprop="price"]') ||
                        document.querySelector('.special[itemprop="price"]') ||
                        document.querySelectorAll('.cblt-content .cblt-list .salePrice .offer-item .mathbox-price');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            var listedPrice = data.listedPrice.textContent.replace(/\D/g, '');
            result.listedPrice = parseInt(listedPrice, 10);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
