//https://makemydeal.freshdesk.com/helpdesk/tickets/5619
module.exports = function(options) {

    options = options || {};

    var addr = document.querySelector('.vdp-details-main .adr .street-address');

    if(addr && addr.textContent){
        addr=addr.textContent.trim();

        if(addr === '35200 Grand River Ave'){
            options.dealerId = 1640;
        }
    }

    return options;

}