//dealerx
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

     var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.panel .row .columns .row .columns h1')
        || document.querySelector('.card-product .category');

    //get overview box
    var vehicleOverviewLabel = document.querySelectorAll('.vehicle_information .vehicle-data dt');
    if(!vehicleOverviewLabel.length){
        vehicleOverviewLabel = document.querySelectorAll('.container .col-lg-6 .tab-content .table-responsive .table tr td:nth-child(1)');
    }
    var vehicleOverviewValue = document.querySelectorAll('.vehicle_information .vehicle-data dd');
    if(!vehicleOverviewValue.length){
        vehicleOverviewValue = document.querySelectorAll('.container .col-lg-6 .tab-content .table-responsive .table tr td:nth-child(2)');
    }

    //get price box
    var pricingTable = document.querySelector('.price-wrap') || document.querySelector('div#multiPrice');
    if(pricingTable){
        pricingTable = pricingTable.children;
    }

    if(vehicleOverviewLabel.length>0 && vehicleOverviewValue.length>0){
        var overviewLabelArr = Array.prototype.map.call(
            vehicleOverviewLabel,
            function(obj,i,arr){
                return obj;
            }
        );

        var overviewValueArr = Array.prototype.map.call(
            vehicleOverviewValue,
            function(obj,i,arr){
                return obj;
            }
        );

        var siteOptionsCollector = overviewLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~:%'"*?<>{}]/g, '').toLowerCase();
            var value = overviewValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase()
            switch(label) {
                case 'condition':
                    data.vehicleStatus = value;
                    break;

                case 'miles':
                case 'mileage':
                    data.mileage = value;
                    break;

                case 'stock':
                    data.stock = value;
                    break;

                case 'vin':
                case 'vin number':
                    data.vin = value;
                    break;

                default:
            }
            return true;
        });
    }

    if(pricingTable.length>0){
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj,i,arr){
                return obj;
            }
        )

        var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
            if(array[index].children.length !== 0) {
                var option = array[index].textContent.replace(/[&\/\\#,+():~%'"*?<>{}]/g, '').toLowerCase().split('$');
                switch(option[0].trim()) {
                    case 'internet price':
                        data.listedPrice = option[1].trim();
                        break;

                    case 'msrp':
                        data.retailPrice = option[1].trim();
                        break;

                    default:
                }
            }
            return true;
        });
    }

    if(!data.vehicleStatus){
        data.vehicleStatus = document.title;
    }

    //get img
    data.img = document.querySelector('.images-wrap .orbit-container img')
        || document.querySelector('.item img');


    //logging
    shimUtils.callValueQuerySelector(data, options);

    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus.toLowerCase().indexOf('new');
            if(status>-1){
                result.vehicleStatus = 1;
            }else{
                result.vehicleStatus = 0;
            }
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin : function(data, result) {
            result.vin = data.vin;
        },
        stock : function(data, result) {
            result.stock = data.stock;
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
