var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.vdp-title.panel-title');

    data.mileage = data.vin = document.querySelector('tr[data-cb-detail=mileage] td[data-cb-value]');
    
    data.stock = document.querySelector('tr[data-cb-detail=stockno] td[data-cb-value]');

    data.vin = document.querySelector('tr[data-cb-detail=vin] td[data-cb-value]');

    data.listedPrice = document.querySelector('.price-container .price');

    data.img = document.querySelector('img.fotorama__img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if(data.vin.textContent)
                result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            if(data.stock.textContent)
                result.stock = data.stock.textContent.trim();
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
