var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.ar_vehtitle');

    var vehicleOverview = document.querySelectorAll('.ar_vehspec');

    if(vehicleOverview.length>0){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsArr = overviewArr;

        var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
	        var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
            var label = labelAndValue[0];
            var value = labelAndValue[1];
			switch(label.trim()) {
				case 'stock':
					data.stock = value;
					break;

				case 'vin':
					data.vin = value;
					break;

				case 'mileage':
					data.mileage = value;
					break;

				case 'sale price':
					data.listedPrice = value;
                    break;

				default:
			}
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
