//ATTN: Dealerhosted desktop site, autofusion hosted mobile site.

var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    if(document.URL.indexOf('mobile')>-1) { //mobile
        //MOBILE STUFF
        var vehicleOverview = document.querySelectorAll('.mobile-result-specs tr');

        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        if (overviewArr.length) {

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if (row.children[0]) {
                    var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

                    switch (label) {
                        case 'type':
                            data.vehicleStatus = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'year':
                            data.year = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                            break;

                        case 'make':
                            data.make = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                            break;

                        case 'model':
                            data.model = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'trim':
                            data.trim = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'internet special':
                            data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'mileage':
                            data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'vin':
                            data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'stock':
                            data.stock = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        default:
                            break;
                    }
                }
                return true;
            });

            //get img
            data.img = document.querySelector('.mobile-result-image-main');
        }
    } else { //DESKTOP STUFF

        var vehicleOverview = document.querySelectorAll('.main-container .info .info-main li');

        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        if (overviewArr.length) {

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if (row.children[0]) {
                    var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

                    switch (label) {
                        case 'trim':
                            data.trim = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'list price':
                            data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'mileage':
                            data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'vin':
                            data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        case 'stock':
                            data.stock = row.children[1].textContent.trim().replace(replaceRegex, '');
                            break;

                        default:
                            break;
                    }
                }
                return true;
            });
            data.year = data.make = data.model = document.querySelector('.info-title');

            data.vehicleStatus = document.querySelector('.i-new')
                || document.URL;

            //get img
            data.img = document.querySelector('.main-container .details-wrap #slide img');
        }
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            if(!data.year.textContent){
                result.year = shimUtils.getInteger(data.year);
            }else{
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            };
        },
        make: function(data, result) {
            if(!data.make.textContent){
                result.make = data.make;
            }else{
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            };
        },
        model: function(data, result) {
            if(!data.model.textContent){
                result.model = data.model;
            }else{
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            };
        },
        trim: function(data, result) {
            if(!data.trim.textContent){
                result.trim = data.trim;
            }else{
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            };
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            if(data.vehicleStatus === document.querySelector('.i-new')){
                result.vehicleStatus = 1;
            }else{
                var vehicleStatus = data.vehicleStatus.toLowerCase();

                if (vehicleStatus === 'new') {
                    result.vehicleStatus = 1;
                } else {
                    result.vehicleStatus = 0;
                }
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
