var shimUtils = require('../utils');


module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.i05r_detailVehicleName') || document.querySelector('.i07r_vehicleTitle');  

    var vehicleOverview = document.querySelectorAll('.vehicleInfoWrap .col-md-6 p');

    if(vehicleOverview.length == 0)
        vehicleOverview = document.querySelectorAll('.i05_detailTopDetails .i05_detailTopDetailsTable tr');

    if(!vehicleOverview.length){
        vehicleOverview = document.querySelectorAll('.i05r_detailFeatures li');
    }

    if(!vehicleOverview.length){
        vehicleOverview = document.querySelectorAll('.i07r_features .col-xs-6 p');
    }

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj,i,arr){
            return obj;
        }
    );

    var siteOptionsArr = overviewArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(row,index,array){
        if(array[index].children.length!==0){
            var dataArr = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase();
            var label = dataArr.split(':',1)[0].trim();

            if (dataArr.split(':',2)[1]) {
                var value = dataArr.split(':',2)[1].trim();
            }

            switch(label){
                case 'year':
                    data.year = value;
                    break;

                case 'make':
                    data.make = value;
                    break;

                case 'model':
                    data.model = value;
                    break;

                case 'trim':
                    data.trim = value;
                    break;

                case 'mileage':
                    data.mileage = value;
                    break;

                case 'vin':
                    data.vin = value;
                    break;

                case 'stock':
                    data.stock = value;
                    break;

                default:
            }
        }
        return true;
    });

    //get listedPrice and retailPrice
    data.listedPrice = document.querySelector("span[id$='lblBox1']")
                    || document.querySelector("span[id*='TopPrice']")
                    || document.querySelector("span[id$='lblBox2']");

    //get img
    data.img = document.getElementById("mainphoto");

    //get status
    data.vehicleStatus = document.title;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year : function(data, result) {
            if(data.year.textContent){
                var title = shimUtils.titleTrimmer(data.year);
                data.year = title.year;
            };
            result.year = data.year;
        },
        make : function(data, result) {
            if(data.make.textContent){
                var title = shimUtils.titleTrimmer(data.make);
                data.make = title.make;
            };
            result.make = data.make;
        },
        model : function(data, result) {
            if(data.model.textContent){
                var title = shimUtils.titleTrimmer(data.model);
                data.model = title.model;
            };
            result.model = data.model;
        },
        trim : function(data, result) {
            if(data.trim.textContent){
                var title = shimUtils.titleTrimmer(data.trim);
                data.trim = title.trim;
            }
            result.trim = data.trim;
        },
        vehicleStatus : function(data, result) {
            data.vehicleStatus = data.vehicleStatus.toLowerCase().search(/used/g);
            result.vehicleStatus = (data.vehicleStatus > -1) ? 0 : 1;
        },
        mileage : function(data, result) {
            result.mileage = data.mileage;
        },
        vin : function(data, result) {
            result.vin = data.vin;
        },
        stock : function(data, result) {
            result.stock = data.stock;
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    //set isShimmed
    options.isShimmed = true;

    return options;

}
