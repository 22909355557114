var shimUtils = require('../utils');

module.exports = function(options) {

   options = options || {};

   shimUtils.removeContainers();

   return options;
}
