'use strict';

var shimUtils = require('../utils');

module.exports = function(options) {

	options = options || {};

	var data = {
		retailPrice : '',
		listedPrice : ''
	}

	var pricingTable = document.querySelectorAll('.priceBox .priceContainer');

	var pricingArr = Array.prototype.map.call(
		pricingTable,
		function(obj, i, arr) {
			return obj;
		}
	);

	var siteOptionsArr = pricingArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
		if(array[index].children.length !== 0) {
	        var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':')
	        	var label = labelAndValue[0];
	        	var value = labelAndValue[1];
			switch(label) {

				case 'msrp price':
					data.retailPrice = value;
					break;

				case 'sale price':
					data.listedPrice = value;
					break;

				default:
			}
		}
        return true;
    });

	//logging
	shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        retailPrice : function(data, result) {
            result.retailPrice = parseInt(data.retailPrice,10);
        },
        listedPrice : function(data, result){
        	result.listedPrice = parseInt(data.listedPrice,10);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

	options.isShimmed = true;

	return options;

}
