var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //  FD #4666
    //  override the listedPrice with the retailPrice
    if(options.vehicleStatus === 1 && options.retailPrice) {
        options.listedPrice = options.retailPrice;
    }

    return options;

}
