export const sdpFrameCSS = `
.mmd-shopper-platform-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 2147483647;
}

.mmd-shopper-platform-background .mmd-shopper-platform-container {
    width: 95%;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;
    margin-bottom: 0;
    height: calc(100% - 25px);
    position: relative;
}

.mmd-shopper-platform-background .mmd-shopper-platform-container wrapper {
    width: 100%;
    height: 100%;
}

.mmd-shopper-platform-background .mmd-shopper-platform-iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.mmd-shopper-platform-background .mmd-shopper-platform-frame-close-button {
    position: absolute;
    right: 30px;
    top: 0px;
    background-color: hsla(0, 0%, 100%, .8);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #444;
    opacity: 1;
    font-size: 1.25em;
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border-style: none;
    line-height: 20px
}

.mmd-shopper-platform-background .cross-parent {
    height: 15px;
    width: 15px;
}

.mmd-shopper-platform-background .cross-child {
    height: 15px;
    width: 2px;
    margin-left: 7px;
    background-color: #444;
    transform: rotate(45deg);
    z-index: 1;
}

.mmd-shopper-platform-background .cross-subchild {
    height: 15px;
    width: 2px;
    background-color: #444;
    transform: rotate(90deg);
    z-index: 2;
}

#mmd-shopper-platform-progress.loading {
    margin: 250px auto 0;
    width: 80px;
    text-align: center;
}

#mmd-shopper-platform-progress.loading > div {
    width: 18px;
    height: 18px;
    background-color: #afafaf;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: delay 1.4s infinite ease-in-out both;
    animation: delay 1.4s infinite ease-in-out both;
}

#mmd-shopper-platform-progress.loading .bounce1 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

#mmd-shopper-platform-progress.loading .bounce2 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

@-webkit-keyframes delay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes delay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
`;
