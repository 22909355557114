/**
 * MMD-1654: Selfhosted dealer site with many MMD IDs.  Need to replicate the shim across all dealerIds.
 * ALERT: When changing this shim, be sure to test/change all the following IDs as well
 * IDs: 807, 809, 810, 811, 812, 818, 819
 */

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options && options.vin){
        return options;
    }

    if(document.URL.indexOf('performanceautomallutah') >-1 ) {
        //disable loading
        options.disableLoad = true;
        return options;
    }

    var data = {
        listedPrice: '',
        retailPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    var vehicleDetails = document.querySelectorAll('table.car-detail-table tbody tr');

    if (vehicleDetails.length > 0 ) {
        var detailsArr = Array.prototype.map.call(
            vehicleDetails,
            function(obj, i, arr) {
                return obj;
            }
        );

        var collector = detailsArr.filter(function(row, index, array) {
            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').replace(/\s/g,'').toLowerCase();
            switch(label) {
                case 'price':
                    data.listedPrice = array[index].children[1];
                    break;
                case 'msrp':
                    data.retailPrice = array[index].children[1];
                    break;
                case 'year':
                    data.year = array[index].children[1].textContent;
                    break;
                case 'make':
                    data.make = array[index].children[1].textContent;
                    break;
                case 'model':
                    data.model = array[index].children[1].textContent;
                    break;
                case 'trim':
                    data.trim = array[index].children[1].textContent;
                    break;
                case 'mileage':
                    data.mileage = array[index].children[1];
                    break;
                case 'stock':
                    data.stock = array[index].children[1];
                    break;
                case 'vin':
                    data.vin = array[index].children[1];
                    break;
            }
            return true;
        });
    } else{
        var isComplete = shimUtils.isComplete();
        if (!options.wasDeferred && !isComplete) {
            options.isDeferred = true;
            return options;
        }
    }
    if(!data.year)
        data.year = document.querySelector('.ddc-page-title');

    if(!data.make)
        data.make = document.querySelector('.ddc-page-title');

    if(!data.model)
        data.model = document.querySelector('.ddc-page-title');

    if(!data.trim)
        data.trim = document.querySelector('.ddc-page-title');

    if(!data.retailPrice)
        data.retailPrice = document.querySelector('.msrp .price');

    if(!data.dealerSavings)
        data.dealerSavings = document.querySelector('.abSub .price');

    if(!data.listedPrice)
        data.listedPrice = document.querySelector('.final-price .price');

    if(!data.stock)
        data.stock = document.querySelector('.stockNumber .value');

    if(!data.vin)
        data.vin = document.querySelector('.vin .value');

    if(!data.mileage)
        data.mileage = "1";




    data.vehicleStatus = location.pathname.toLowerCase();

    data.img = document.querySelector('div#car-carousel .carousel-inner .item img')
        || document.querySelector('.imageViewer img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        year: function(data, result) {
            if(data.year.textContent){
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            }else{
                result.year = data.year;
            };
        },
        make: function(data, result) {
            if(data.make.textContent){
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            }else{
                result.make = data.make;
            }
        },
        model: function(data, result) {
            if(data.model.textContent){
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }else{
                result.model = data.model;
            }
        },
        trim: function(data, result) {
            if(data.trim.textContent){
                var title = shimUtils.titleTrimmer(data.trim);
            }else{
                result.trim = data.trim;
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if(status && status.search(/new/g)) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        vin: function(data, result) {
            result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
};
