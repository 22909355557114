/*
* Camel case dom element attributes.
*/
module.exports = function (str) {
	if(str.indexOf('-') === -1) {
		return str;
	}

	var replaced = str.replace(/-([a-z])/gi, function(s, group1) {
	    return group1.toUpperCase();
	});
	return replaced;
}