
var qs = require('qs');

module.exports = {
    getParam: function (name) {
        var queryString = window.location.search;
        if (name && queryString) {
            var parsedQS = qs.parse(queryString.substr(1));
            return parsedQS[name] || parsedQS[name.toLowerCase()];
        }
    }
}; 