var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        img : '',
        vehicleStatus : ''
    };

    var breadcrumb = document.querySelectorAll('.breadcrumb span');

    var breadcrumbArr = Array.prototype.map.call(
        breadcrumb,
        function(obj, i , arr){
            return obj;
        }
    );

    var vehicleStatusCollector = breadcrumbArr.filter(function(row, index, array){
        if(array[index].length !== 0){
            var breadcrumbElement = array[index].textContent.trim().toLowerCase();

            if(breadcrumbElement.indexOf('used')>-1){
                data.vehicleStatus = '0';
            }else if(breadcrumbElement.indexOf('certified')>-1){
                data.vehicleStatus = '0';
            }else if(breadcrumbElement.indexOf('pre-owned')>-1){
                data.vehicleStatus = '0';
            }else if(breadcrumbElement.indexOf('new')>-1){
                data.vehicleStatus = '1';
            }
        }
    });

    data.img = document.querySelector('.images li img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    var tasks = {
        img : function(data, result) {
            result.img = data.img.src;
        },
        vehicleStatus : function(data, result) {
            result.vehicleStatus = parseInt(data.vehicleStatus);
        }
    };

    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    options.isShimmed = true;

    return options;
};
