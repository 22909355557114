var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        incentiveSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        stock: '',
        vin: '',
        mileage: '',
        img: '',
        vehicleStatus: ''
    };

    var pricingTable = document.querySelectorAll('.prc_lst tr');
    var vehicleOverview = document.querySelectorAll('.veh_dets .half_col tr');
    if(!vehicleOverview.length)
        vehicleOverview = document.querySelectorAll('.info_table tr');

    if (vehicleOverview.length) {
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );
    };

    if (pricingTable.length) {
        var pricingArr = Array.prototype.map.call(
            pricingTable,
            function(obj, i, arr) {
                return obj;
            }
        );
    };

    if (overviewArr && pricingArr) {
        overviewArr = overviewArr.concat(pricingArr);
    } else if (!overviewArr && pricingArr) {
        overviewArr = pricingArr;
    };

    if (overviewArr) {
        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (array[index].children.length == 2) {
                var label = array[index].children[0].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                switch (label.trim()) {

                    case 'arlington price':
                    case 'internet price':
                    case 'price':
                        data.listedPrice = array[index].children[1];
                        break;

                    case 'msrp':
                    case 'market value':
                        data.retailPrice = array[index].children[1];
                        break;

                    case 'you save':
                        data.incentiveSavings = array[index].children[1];
                        break;

                    case 'mileage':
                    case 'miles':
                        data.mileage = array[index].children[1];
                        break;

                    case 'stock':
                        data.stock = array[index].children[1];
                        break;

                    case 'vin':
                        data.vin = array[index].children[1];
                        break;

                    default:
                }
            }
            return true;
        });
    }

    //get img
    data.img = document.querySelector('#inv_gal .rsImg') //desktop
        || document.querySelector('#gallery_wrap img') || ''; //mobile

    data.year = data.make = data.model = data.trim = document.querySelector('.name') || document.querySelector('.pg_title div.ttl') || document.querySelector('.mn_ttl.cleared h1.name');

    data.vehicleStatus = document.title;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice, 10);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice, 10);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings, 10);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        vehicleStatus: function(data, result) {
            if (data.vehicleStatus.toLowerCase().search(/new/g) !== -1) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            };
        },
        img: function(data, result) {
            result.img = (data.img.dataset && data.img.dataset.rsbigimg) ? data.img.dataset.rsbigimg : data.img.src;
        }
    };

    // call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.url;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };


    return options;
};
