var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    //get listedPrice and retailPrice
    data.listedPrice = data.retailPrice = document.querySelector('.priceBox h2');

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.headline_area h1.entry-title');

    //get vin
    data.vin = document.querySelector('#details .details li:last-child');

    //get stock
    data.stock = document.querySelector('#details .details li:nth-child(4)');

    //get vehicleStatus
    data.vehicleStatus = document.querySelector('meta[property="article:section"]');

    //get mileage
    data.mileage = document.querySelector('#details .details li:nth-child(2)');

    //get img
    data.img = document.querySelector('#imagesExt img');

	//logging
	shimUtils.callValueQuerySelector(data, options);

	//define tasks to get data
	var tasks = {
		listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
		vehicleStatus : function(data, result) {
			var status = data.vehicleStatus.getAttribute('content');
			result.vehicleStatus = (status.search(/Used/g) > -1) ? 0 : 1;
		},
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin : function(data, result) {
            var vin = data.vin;
            result.vin = vin.textContent.split('VIN')[1].trim();
        },
        stock: function(data, result) {
            var stock = data.stock;
            result.stock = stock.textContent.split('Number')[1].trim();
        },
        img : function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
	}

	//call continue to get data
	options = shimUtils.continue(data, tasks, options);

	//set url
    options.url = document.URL;

	//set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;
}
