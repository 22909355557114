var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var telNum = document.querySelector('.contact-info .vcard .tel .value');

    if(telNum && telNum.textContent){
        telNum=shimUtils.getInteger(telNum);

        if(telNum === '8886818298'){
            options.dealerId = 1635
        }
    }

    return options;

}
