var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        mileage: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.inventory-detail-quick-specs .quick-specs');

    var overviewArr = Array.prototype.map.call(vehicleOverview[0].children,
        function(obj, i, arr) {
            return obj;
        }
    );


    var siteOptionsArr = overviewArr;

    var siteOptionsCollector = siteOptionsArr.filter(function(obj, i, arr) {

        var row = obj.textContent.replace(/(\r\n|\n|\r)/gm, ' ');
        row = row.replace(/[&\/\\#,+()$~%'":*?<>{}]/gm, '');
        row = row.trim().split(/\s+/g);

        if ('length' in row && row.length > 1) {

            var label = row[0].toLowerCase();
            var value = row[1].toLowerCase();

            if (label in data) {
                data[label] = value;
            }
        }

        return true;
    });


    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {

        mileage: function(data, result) {
            if (data.mileage) {
                result.mileage = parseInt(data.mileage);
            }
        },
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    options.isShimmed = true;

    return options;
};
