// externals
import axios from 'axios';

// config
import * as Config from '../config';

// interfaces/types
import type { FetchDealerConfigContext, FetchDealerConfigBffResponse, FetchDealerConfigResults } from './types/fetchDealerTypes';

/**
 * Fetch the Dealer Config
 * @param context dealer config params
 */
export const fetchDealerConfig = async (context: FetchDealerConfigContext): Promise<FetchDealerConfigResults> => {
    const domain = Config.getdsApiUrl('');
    const { connectionId, vin } = context;
    const url = `${domain}/sp/bff/connection/${connectionId}/vehicle/${vin}/widget-config`;

    const axiosResult = await axios.get<FetchDealerConfigBffResponse>(url);
    const apiCallResponse = axiosResult.data;

    if (!apiCallResponse.data) {
        throw new Error('Unable to retrieve dealer config');
    } else if (!apiCallResponse.data.item?.dealer) {
        throw new Error('Dealer config response does not contain expected data');
    }

    return apiCallResponse.data.item.dealer;
};
