'use strict';
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    if(options.listedPrice && options.retailPrice){
    	options.listedPrice = options.retailPrice
    }

    return options;
};
