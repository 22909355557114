var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        dealerSavings: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    // grab data from vehicle overview table
    var vehicleOverviewLabel = document.querySelectorAll('.detail-fields-prices .fields .field .field-label');

    if (vehicleOverviewLabel.length === 0) {
        vehicleOverviewLabel = document.getElementById('vi_fields')  && document.getElementById('vi_fields').querySelectorAll('.label');
    }

    var vehicleOverviewValue = document.querySelectorAll('.detail-fields-prices .fields .field .field-value');

    if (vehicleOverviewValue.length === 0) {
        vehicleOverviewValue = document.getElementById('vi_fields') && document.getElementById('vi_fields').querySelectorAll('.value');
    }

    var overviewArr = [];
    var overviewLabelArr = [];
    var overviewValueArr = [];


    if (vehicleOverviewLabel && vehicleOverviewLabel.length > 0) {

        overviewLabelArr = Array.prototype.map.call(
            vehicleOverviewLabel,
            function(obj, i, arr) {
                return obj;
            }
        );

        overviewValueArr = Array.prototype.map.call(
            vehicleOverviewValue,
            function(obj, i, arr) {
                return obj;
            }
        );


        //join arrays together into one array
        for (i = 0; i < overviewLabelArr.length; i++) {
            overviewArr.push(overviewLabelArr[i].textContent.trim().concat(' ', overviewValueArr[i].textContent.trim()));
        }
    }

    // grab pricing data from page
    var pricingTable = document.querySelectorAll('#vehicleInfo_prices div');
    if (pricingTable.length === 0) {
        pricingTable = document.querySelectorAll('.prices .price-wrap span');
    }

    var pricingTableLabel = [];
    var pricingTableValue = [];
    for (var i = 0; i < pricingTable.length; i++) {
        if (i % 2 === 0) { // index is odd
            pricingTableLabel.push(pricingTable[i]);
            pricingTableValue.push(pricingTable[i + 1]);
        }
    }

    var pricingArr = [];

    if (pricingTableValue.length > 0) {

        var pricingLabelArr = Array.prototype.map.call(
            pricingTableLabel,
            function(obj, i, arr) {
                return obj;
            }
        );

        var pricingValueArr = Array.prototype.map.call(
            pricingTableValue,
            function(obj, i, arr) {
                return obj;
            }
        );

        //join arrays together into one array;
        for (i = 0; i < pricingLabelArr.length; i++) {
            if (pricingLabelArr[i] && pricingValueArr[i]) {
                pricingArr.push(pricingLabelArr[i].textContent.trim().concat(' ', pricingValueArr[i].textContent.trim()));
            }
        }

    }

    var siteOptionsArr = null;

    //set siteOptionsArr
    if (pricingArr && overviewLabelArr) {
        siteOptionsArr = overviewArr.concat(pricingArr);
    } else if (!pricingArr && overviewArr) {
        siteOptionsArr = overviewArr;
    } else if (pricingArr && !overviewArr) {
        siteOptionsArr = pricingArr;
    }

    if (siteOptionsArr) {
        var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
            //if label exists
            if (array[index].indexOf(':') > -1) {
                var labelAndValue = array[index].trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
                var label = labelAndValue[0];
                var value = labelAndValue[1];
                switch (label.trim()) {
                    case 'sale price':
                    case 'brien difference price':
                    case 'special price':
                    case 'internet price':
                    case 'velocity price':
                    case 'wade low price':
                    case 'selling price':
                    case 'price':
                    case 'value price':
                    case 'curbside price':
                    case 'final price':
                    case 'your price':
                    case 'swag price':
                        data.listedPrice = value;
                        break;

                    case 'our discount':
                        data.dealerSavings = value;
                        break;

                    case 'msrp':
                        data.retailPrice = value;
                        break;

                    case 'stock':
                    case 'stock number':
                        data.stock = value;
                        break;

                    case 'mileage':
                        data.mileage = value;
                        break;

                    case 'vin':
                        data.vin = value;
                        break;

                    default:
                }
            }
            return true;
        });
    }

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.getElementById('vehicleInfo_title') || document.querySelector('.title-row .pull-left');

    //get status, vin
    if(!data.vehicleStatus)
        data.vehicleStatus = document.querySelector('meta[name="og:title"]');
    if(!data.vin)
        data.vin = document.querySelector('meta[name="og:title"]');

    //get img
    data.img = document.getElementById('media_image') || document.querySelector('.carousel .carousel-inner .active img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus.getAttribute('content');
            status = status.toLowerCase();
            result.vehicleStatus = (status.search(/\bnew\b/g) > -1) ? 1 : 0;
        },
        mileage: function(data, result) {
            result.mileage = parseInt(data.mileage, 10);
        },
        vin: function(data, result) {
            if(data.vin.textContent){
                var vin = data.vin.getAttribute('content');
                data.vin = vin.split(' ').pop();
            }
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    return options;

};
