var shimUtils = require('../utils');
var selectn = require('selectn');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '1',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    /*
    * DealerSocket has two data objects (daObjects and gaObjects) with relevent data for
    * Loading the widget.  Will use those two objects to get some data and then fall-back
    * normal query selectors
    */
    data.year = selectn('VehicleObject.YearFrom', window.daObjects);
    data.make = selectn('VehicleObject.Make', window.daObjects);
    data.model = selectn('VehicleObject.Model', window.daObjects);
    data.trim = selectn('VehicleObject.Trim', window.daObjects);
    data.vehicleStatus = selectn('VehicleObject.IsNew', window.daObjects) === true ? '1' : '0';
    data.vin = selectn('VehicleObject.VIN', window.gaObjects);
    data.stock = selectn('VehicleObject.StockNumber', window.gaObjects);

    // data.year = data.make = data.model = data.trim = document.getElementById('vehicle_title')
    //     || document.querySelector('.vehicle-title')
    //     || document.querySelector('[itemprop="name"]')
    //     || document.querySelector('.iui_vehicle li b');


    // grab data from vehicle overview table
    var vehicleOverview = document.getElementById('vitalsContainer') || '';
    if(vehicleOverview){
        vehicleOverview = vehicleOverview.querySelectorAll('li');
    }else{
        vehicleOverview = document.querySelectorAll('.row ul .specification-item');
        if(!vehicleOverview.length){
            vehicleOverview = document.querySelectorAll('li table tbody tr');
        }
    }

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );



    if (overviewArr.length) {

        var replaceRegex1 = /[&\/\\#,+()$~%'"*?<>{}]/g;
        var replaceRegex2 = /[&\/\\#,+()$~%'"*:?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {

            if(row.textContent.indexOf(':')>-1){
                var labelAndValue = row.textContent.trim().replace(replaceRegex1, '').toLowerCase().split(':');
                var label = labelAndValue[0].trim();
                var value = labelAndValue[1];

                switch (label) {
                    case 'mileage':
                        data.mileage = value.trim().replace(replaceRegex1, '');
                        break;

                    // case 'stock':
                    //     data.stock = value.replace(replaceRegex1, '').toLowerCase();
                    //     break;

                    // case 'vin':
                    //     data.vin = value.replace(replaceRegex1, '').toLowerCase();
                    //     break;

                    default:
                        break;

                }
                return true;
            }else if(row.children.length>1){
                var label = row.children[0].textContent.trim().replace(replaceRegex2, '').toLowerCase();

                switch(label.trim()) {
                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex2, '');
                        break;

                    // case 'stock':
                    //     data.stock = row.children[1].textContent.trim().replace(replaceRegex2, '');
                    //     break;

                    // case 'vin':
                    //     data.vin = row.children[1].textContent.trim().replace(replaceRegex2, '');
                    //     break;

                    default:
                        break;
                }
            }

        });
    }

    var listedPrice = document.getElementById('priceContainer') || '';
    if(listedPrice){
        data.listedPrice = listedPrice.querySelector('.price_line_1');
    }else{
        data.listedPrice = document.querySelector('[itemprop="price"]')
            || document.querySelector('.ecl_vehicle_price_pri h3');
    }

    data.retailPrice = document.querySelector('.price-old [itemprop="price"]')

    var img = document.getElementById('vehicle_mainPhoto') || document.querySelector('.images');
    if(img){
        data.img = img.querySelector('img')
            ||document.querySelector('.iui_vehicle li img');
    }

    // data.vehicleStatus = document.URL;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if(typeof data.listedPrice === 'object' && data.listedPrice.content){
                data.listedPrice = data.listedPrice.content;
            }
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            // var title = shimUtils.titleTrimmer(data.year);
            result.year = data.year;
        },
        make: function(data, result) {
            // var title = shimUtils.titleTrimmer(data.make);
            result.make = data.make;
        },
        model: function(data, result) {
            // var title = shimUtils.titleTrimmer(data.model);
            result.model = data.model;
        },
        trim: function(data, result) {
            // var title = shimUtils.titleTrimmer(data.trim);
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage)
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            // var vehicleStatus = data.vehicleStatus.toLowerCase();

            // if (vehicleStatus === 'new') {
            //     result.vehicleStatus = 1;
            // } else {
            //     result.vehicleStatus = 0;
            // }
            result.vehicleStatus = shimUtils.getInteger(data.vehicleStatus);
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
