var shimUtils = require('../utils');

module.exports = function(options) {

    if(options.stock.indexOf(' ')> -1){
        options.stock = options.stock.split(' ')[0];
    };

    return options;

}
