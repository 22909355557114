//dealeractive.js
var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    data.listedPrice = document.querySelector('.pricebox .bigheading');
    var retailPrice = document.querySelectorAll('.pricebox .small_price .bigheading');

    if(retailPrice.length > 0) {
        data.retailPrice = retailPrice[retailPrice.length-1];
    }
    else {
        data.retailPrice = '';
    }

    data.mileage = data.stock = data.vin = document.querySelectorAll('.vehicleinfo .rightarea span');

    //grab data from vehicle overview
    var vehicleOverview = document.querySelectorAll('.vehicleinfo .rightarea span');
    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj,i,arr){
            return obj;
        }
    );

    var siteOptionsCollector = overviewArr.filter(function(row,index,array){
        var label = array[index].textContent.split(':')[0].trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
        switch(label){
            case 'mileage':
            case 'odometer':
                data.mileage = array[index];
                break;

            case 'stock':
            case 'stock number':
                data.stock = array[index];
                break;

            case 'vin':
                data.vin = array[index];
                break;

            default:
        }
        return true;
    })

    //get year make model trim
    data.year = data.make = data.model = data.trim = document.querySelector('.veh_title_box .big_title');

    //get vehicleStatus
    data.vehicleStatus = location.href;

    //get img
    data.img = document.querySelector('.slides img');

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
        retailPrice: function(data,result){
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        listedPrice: function(data,result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year: function(data,result){
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data,result){
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data,result){
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data,result){
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data,result){
            var status = data.vehicleStatus.search(/\b(used|preownedinv|certified|detail_certified|usedvehicle|detail_usedvehicle)\b/gi);

            if(status >= 0){
                result.vehicleStatus = 0;
            }else{
                result.vehicleStatus = 1;
            }

        },
        mileage: function(data,result){
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock: function(data,result){
            result.stock = data.stock.textContent.split(':')[1].trim();
        },
        vin: function(data,result){
            result.vin = data.vin.textContent.split(':')[1].trim();
        },
        img: function(data,result){
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function(){
        console.log('widget not showing');
    };

    return options;
}
