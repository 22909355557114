/*
 This widget will be used to form a SDK within the host client to be able to open
 the SDP directly via ConnectionId/VIN.  It will also provide an SDK to be able to
 save an offer into our collections returning the ConnectionId/VIN.  This will allow
 us to take an offer from another source and open SDP.
 */

// interfaces/types
import type { SaveOffer, SaveOfferResults, VerifySaveOfferResults } from './types/saveOfferTypes';
import type { SdpWidgetInitialization, SdpWidgetInitializationCADR, SupportedOfferTypes } from './types/initializationInterfaces';
import type { IAccelerateWindow } from '../models/IAccelerateWindow';
import type { SDPRoute } from './getSdpUrl';

// utils
import { saveOffer } from './saveOffer';
import { SDPFrame } from './sdpFrame';
import { openSdp } from './openSdp';
import { isDealerInitialization, isSdpWidgetInitialization, isValidDealerInitialization } from './typeGuards/initialization';
import { isSaveOffer, isSaveOfferResults } from './typeGuards/saveOffer';
import { SDPLogger } from './utils/sdpLogger';
import { convertCadrToSdpInit } from './convertCadrToSaveOffer';
import { VdpService } from '../services/vdp.service';
import { getOfferPayment } from './getOfferPayment';
import { GetOfferPayment, GetOfferPaymentResult } from './types/offerPaymentInterfaces';
import { verifyData } from '../widget_modules/core.accelerate';
import { isFetchOfferDealXg } from './typeGuards/fetchOfferDealXg';
import { fetchOfferDealXg } from './fetchOfferDealXg';
import { getQueryParams } from './utils/querystring';
import { isEmailLandingRoutes } from './typeGuards/utils';
import { fetchDealerConfig } from './dealerConfigFetcher';

declare var window: IWindowSdk;

// this will manage the iframe and other dom elements..
export const iframeManager = new SDPFrame();

type DealerInformation = {
    dealerBrandingUrl?: string;
};

// define the interface for our SDK
export interface IWindowSdk extends Window {
    accelerate: {
        /**
         * Saves an offer into accelerate
         */
        saveOffer: (offer: SaveOffer) => Promise<SaveOfferResults>;

        /**
         * Will take an existing vin/connectionId combo and open ShopperPlatform or save
         * the offer and then open shopper platform
         */
        openShopperPlatform: (context: SdpWidgetInitialization) => Promise<void>;

        /**
         * This will use the existing CADR Object on the page to open the SDP widget without needing
         * to specify the context. Optional context can be passed in to override CADRO
         */
        openShopperPlatformCADR: (context?: SdpWidgetInitializationCADR) => Promise<void>;

        /**
         * This an async call will return payment information for the given vehicle, shopper, payment and dealer configuration. (init object)
         * Return includes apr, term, mileage, monthly payment, disclaimer
         *
         *  Optional: init
         *  Usage: await window.accelerate.getPaymentInformation(init)
         */
        getPaymentInformation: (options: SdpWidgetInitialization) => Promise<GetOfferPaymentResult>;

        /**
         * This call will return dealer information for the given vehicle, shopper, payment and dealer configuration (init object).
         *
         *  Optional: init
         *  Usage: await window.accelerate.getDealerInformation(init)
         */
        getDealerInformation: () => Promise<DealerInformation>;
        /**
         * This call will read supported query paramters on host page and create init object payload.
         * If no supported query params are found throws an error.
         *
         * Supported parameters:
         *  DealXG:
         *      dealXgId - required;
         *      dealXgVersion - required;
         *      source - optional; Values: VIN | SPONSOR;
         *  Accelerate:
         *      connectionId - required;
         *      vin - required;
         *      snapshotId - optional;
         * Usage: const payload = await window.accelerate.createPayload()
         */
        createPayload: () => SdpWidgetInitialization;
        /**
         * This call will read supported query parameters on host page and create init object payload.
         * If no supported query params are found the call to open shopper platform will not be made.
         *
         * Supported query parameters:
         *  Accelerate:
         *      dsConnectionId - required;
         *      dsVin - required;
         *      dsRoute - required;
         * Usage: window.accelerate.enableAutoOpenSDP(hooks)
         */
        enableAutoOpenSDP: (hooks) => void;
    };
}

const getSaveOfferResultsOrAsyncFn = (
    offer: SupportedOfferTypes,
    includeDealerConfig: boolean | undefined
): SaveOfferResults | (() => Promise<VerifySaveOfferResults>) => {
    if (isSaveOffer(offer)) {
        return async () => await saveOffer(offer);
    } else if (isFetchOfferDealXg(offer)) {
        return async () => await fetchOfferDealXg(offer);
    } else if (isSaveOfferResults(offer) && includeDealerConfig) {
        return async () => {
            const dealerConfigResult = await fetchDealerConfig({
                connectionId: offer.connectionId,
                vin: offer.vin
            });
            return {
                success: true,
                error: undefined,
                offer,
                verify: {
                    config: {},
                    dealerId: dealerConfigResult.dealerId,
                    dnaAccountId: dealerConfigResult.dnaAccountId,
                    experience: dealerConfigResult.experience,
                    isRedesignEnabled: dealerConfigResult.isRedesignEnabled,
                    result: true,
                    ok: true
                }
            };
        };
    }

    return offer;
};

export const initializeOpenSdp = (options: SdpWidgetInitialization) => {
    const logger = SDPLogger.getLogger();

    if (!isSdpWidgetInitialization(options)) {
        logger.updateContext({ context: options });
        logger.error(options, 'opensdp_invalid_initialization');
        return Promise.reject(new Error('Invalid Initialization'));
    }

    if (isDealerInitialization(options)) {
        /**
         * the isSaveOffer check here prevents us from failing to open the sdp for an existing offer
         * isValidDealerInitialization checks that shopper data is passed in and fails the operation if not
         * but passing the shopper data this way to an existing offer won't have any effect, so bypass the check
         */
        if (isSaveOffer(options.offer) && !isValidDealerInitialization(options)) {
            logger.updateContext({ context: options });
            logger.error(options, 'opensdp_invalid_dealer_initialization');
            return Promise.reject(new Error('Invalid Initialization'));
        }
    }
    // set the hooks from the initialization
    iframeManager.hooks = options.hooks;
    const { offer, includeDealerConfig } = options;
    const sdpOpenPayloadOrCallback = getSaveOfferResultsOrAsyncFn(offer, includeDealerConfig);
    return openSdp(options, iframeManager, sdpOpenPayloadOrCallback);
};

const getSdpInitObject = (): SdpWidgetInitialization => {
    const vdpService = new VdpService((window as unknown) as IAccelerateWindow);
    const qs = vdpService.getQueryParams();
    const bootStrapData = vdpService.getBootstrapData();
    return convertCadrToSdpInit(bootStrapData, qs);
};

export const initializeOpenSdpCadr = async (options: SdpWidgetInitializationCADR = {}): Promise<void> => {
    const init = {
        ...getSdpInitObject(),
        ...options
    };
    return initializeOpenSdp(init);
};

export const initializeSaveOffer = async (offer: SaveOffer): Promise<SaveOfferResults> => {
    const result = await saveOffer(offer);
    return result.offer;
};

const initializeGetPayment = async (options?: GetOfferPayment): Promise<GetOfferPaymentResult> => {
    // skip the step in deal setup api for saving the offer object in mongo
    options.skipSavingOffer = true;
    // fetch offer object from the deal setup api
    const result = await getOfferPayment(options);
    return result;
};

export const initializeGetPaymentCadr = async (options?: SdpWidgetInitialization): Promise<GetOfferPaymentResult> => {
    const logger = SDPLogger.getLogger();
    try {
        // consume sp init object or convert CADR as default
        const { offer } = options || getSdpInitObject();
        // extract payment information from the offer object
        return initializeGetPayment(offer as GetOfferPayment);
    } catch (e) {
        logger.error(e);
        return {};
    }
};

const getDealerInfo = async (offerObject): Promise<DealerInformation> => {
    const { dealer, vehicle } = offerObject;

    const verifyPayload = {
        dealer: {
            id: dealer.id,
            refId: '0',
            dnaAccountId: dealer.dnaAccountId,
            sponsor: dealer.sponsor
        },
        vehicle: {
            year: vehicle.year,
            listedPrice: vehicle.listedPrice,
            mileage: vehicle.mileage,
            vehicleStatus: vehicle.condition
        }
    };

    const verifyResponse = await verifyData(verifyPayload);
    return {
        dealerBrandingUrl: verifyResponse?.config?.dealerBrandingUrl
    };
};

export const getDealerInfoCADR = async (options?: SdpWidgetInitialization): Promise<DealerInformation> => {
    const logger = SDPLogger.getLogger();
    try {
        // consume sp init object or convert CADR as default
        const { offer } = options || getSdpInitObject();
        // extract dealer information from the offer object
        return getDealerInfo(offer);
    } catch (e) {
        logger.error(e);
        return {};
    }
};
export const createPayload = (): SdpWidgetInitialization => {
    const logger = SDPLogger.getLogger();
    let payload = {};

    const query = getQueryParams();
    const { dealXgId, dealXgVersion, source, connectionId, vin, snapshotId, sponsor } = query;

    if (dealXgId) {
        payload = { dealXgId, dealXgVersion, source, sponsor };
    } else if (connectionId && vin) {
        payload = { connectionId, vin, snapshotId, source };
    } else {
        throw new Error(
            'Cannot create Accelerate payload.  Unable to extract (vin, connectionId) or (dealXgId) from url parameters'
        );
    }

    logger.info('Creating SDP Widget initialization object');
    return {
        version: '1.0.0',
        offer: payload as any
    };
};

export const enableAutoOpenSDP = (hooks): void => {
    const query = getQueryParams();
    const { dsRoute, dsVin, dsConnectionId } = query;
    const route = dsRoute as SDPRoute;
    const openSpWithOfferFromEmail = isEmailLandingRoutes(route) && dsVin && dsConnectionId;
    if (openSpWithOfferFromEmail) {
        initializeOpenSdp({
            version: '1.0.0',
            route,
            offer: {
                vin: dsVin as string,
                connectionId: dsConnectionId as string
            },
            hooks
        });
    }
};

export const handleSdpMessage = (event: any) => {
    if (event.data.target === 'widget') {
        if (event.data.message === 'close-sdp') {
            iframeManager.enableVdpScroll();
            iframeManager.hide();
        } else if (event.data.message === 'add-widget-background-click-event') {
            iframeManager.addBackgroundClickEvent(event.source);
            iframeManager.disableVdpScroll();
            iframeManager.removeCloseButton();
        }
    }
};

window.accelerate = {
    saveOffer: initializeSaveOffer,
    openShopperPlatform: initializeOpenSdp,
    openShopperPlatformCADR: initializeOpenSdpCadr,
    getPaymentInformation: initializeGetPaymentCadr,
    getDealerInformation: getDealerInfoCADR,
    createPayload,
    enableAutoOpenSDP
};

window.addEventListener('message', handleSdpMessage, false);
