//get requested price for Oxmoor Toyota

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice : '',
        retailPrice : ''
    }

    //get listed and retail price
    data.retailPrice = document.querySelector('.prices .initial span');
    data.listedPrice = document.querySelector('.prices .single span')
                    || document.querySelector('.prices .final .span')
                    || document.querySelector('.real-price')
                    || data.retailPrice;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
