var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var vehicleTitle = document.querySelector('.ddc-page-title');

    //switching locations depending on vehicle title on VDP for 745 and 746
    if(vehicleTitle){
        var vehicleTitleTextContent = vehicleTitle.textContent.trim();
        var locationPosition = vehicleTitleTextContent.search(/\bin\b/gi);

        if (locationPosition) {
            var vehicleLocation = vehicleTitleTextContent.substring(locationPosition+2, 
                vehicleTitleTextContent.length).toLowerCase().trim();

            if(vehicleLocation === 'prosser wa'){
                options.dealerId = 746;
            }else if(vehicleLocation === 'hermiston or'){
                options.dealerId = 745;
            }else{
                options.dealerId = 745;
            }
        };
    }

    return options;
}
