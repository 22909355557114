var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        retailPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        stock : '',
        vin : '',
        mileage : '',
        img : ''
    };

    var vehicleOverview = document.querySelectorAll('[data-tab-content="specs"] table tr');
    if(!vehicleOverview.length){
        vehicleOverview = document.querySelectorAll('.details table.specs tr');
    };

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    if(overviewArr.length){
        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            if (row.children[0]) {
                var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase();

                switch (label.trim()) {
                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
    }

    //retailPrice
    data.retailPrice = document.getElementById('car_price');

    //year
    data.year = document.getElementById('car_year');

    //make
    data.make = document.getElementById('car_make');

    //Model
    data.model = document.getElementById('car_model');

    //trim
    data.trim = document.getElementById('car_trim');

    //img
    data.img = document.querySelector('.owl-item [data-slide-id="0"] .aspect-inner img')
                || document.querySelector('.owl-item .aspect-box .aspect-inner img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        retailPrice : function(data, result){
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result){
            result.year = shimUtils.getInteger(data.year);
        },
        make : function(data, result){
            if(data.make.textContent){
                data.make = data.make.textContent;
            };
            result.make = data.make.trim();
        },
        model : function(data, result){
            if(data.model.textContent){
                data.model = data.model.textContent;
            }
            result.model = data.model.trim();
        },
        trim : function(data, result){
            if(data.trim.textContent){
                data.trim = data.trim.textContent;
            };
            result.trim = data.trim.trim();
        },
        stock : function(data, result){
            if(data.stock.textContent){
                data.stock = data.stock.textContent;
            };
            result.stock = data.stock.trim();
        },
        vin : function(data, result){
            if(data.vin.textContent){
                data.vin = data.vin.textContent
            };
            result.vin = data.vin.trim();
        },
        mileage : function(data, result){
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vehicleStatus : function(data, result){
            if(result.mileage>1){
                result.vehicleStatus = 0;
            }else{
                result.vehicleStatus = 1;
            }
        },
        img : function(data, result){
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //get URL
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
}
