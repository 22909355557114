// MWO-2034
// This utilities contains the rules for all O'Daniel Automotive shims
// to convert dealerIds appropriately based on vehicleStatus, make and location from the page.
// Pertains to dealers: 867, 884, 886, 887, 908, 911 (group store)

var _ = require('underscore');


var convertDealerIdForNewVehicles = function(options) {
    // For New vehicles only
    // use the make from options to determine which ID to switch to for new vehicles.

    var makesByDealerId = {
        867: ['ford'],
        884: ['audi'],
        886: ['mazda'],
        887: ['chrysler', 'dodge', 'jeep', 'ram'],
        908: ['mitsubishi']
    };

    if (options.make) {
        var convertedDealerId = '';
        var make = options.make.toLowerCase();

        // Porsche is not a MMD customer so we should not load the MMD on Porsche
        // vehicles
        if(make === 'porsche') {
            options.disableLoad = true;
        } else {
            convertedDealerId = _.findKey(makesByDealerId, function(makeArr) {
                return _.indexOf(makeArr, make) !== -1;
            });
        }
    }
    return convertedDealerId || options.dealerId;
};

var convertDealerIdForUsedVehicles = function(options) {
    var convertedDealerId = '';

    var dealersByStreetAddress = {
        '631lincolnhighwayw': 867,
        '631lincolnhwywest': 867,
        '4200illinoisrd': 886,
        '5715illinoisrd': 884,
        '5715illinoisroad': 884,
        '5611illinoisrd': 887
    };

    var dealersByVINDealerId = {
        4935: 886,
        4936: 887,
        4937: 867
    };

    // WTD-125: 2016.01.12 - O'Daniel updated mobile sites to have location or provided information
    // for location data.  Either the address selector should work or it be a VIN site with
    // DealerId in the BehindAuto javascript object on the page
    var addressSelector = document.querySelector('[data-widget-name="contact-info"] .street-address')
        || document.querySelector('.atcui-schemaAutodealerVDP .address1');

    if(addressSelector && addressSelector.textContent !== '') {
        var locationAddress = addressSelector.textContent
            .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
            .replace(/\s/g, '')
            .toLowerCase()
            .trim();

        convertedDealerId = dealersByStreetAddress[locationAddress];
    }

    if(window.BehindAuto && window.BehindAuto.DealerID) {    // Assumes on a page where BehindAuto object is present.
        var providerDealerId = window.BehindAuto.DealerID;

        convertedDealerId = dealersByVINDealerId[providerDealerId];
    } else {
        options.isDeferred = true;
    }

    return convertedDealerId || options.dealerId;
};

var convertDealerId = function (options) {
    options = options || {};

    if(options.hasOwnProperty('vehicleStatus')) {
        var vehicleStatus = options.vehicleStatus;

        if(vehicleStatus === 1) {
            options.dealerId = convertDealerIdForNewVehicles(options);
        } else if (vehicleStatus === 0) {
            options.dealerId = convertDealerIdForUsedVehicles(options);
        }
    }

    return options;
};

module.exports = convertDealerId;
