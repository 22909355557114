var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var vehicleOverview = document.querySelectorAll('.vehicleInfo li');
    var address;

    if(vehicleOverview.length){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
            var label;

            if(row.children[1]){
                label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'location':
                        address = row.children[1].textContent.toLowerCase().replace(replaceRegex, '');
                        break;

                    default:
                        break;
                }
            }
            return true;
        });
        if(address){
            if(address.indexOf('prosser')>-1){
                options.dealerId = 746;
            }else{
                switch(address){
                    case 'toms country chrysler jeep dodge ram':
                    case 'toms country chrysler dodge jeep ram':
                        options.dealerId = 743;
                        break;

                    default:
                        options.dealerId = 745;
                        break;
                }
            }
        }
    }

    return options;
}
