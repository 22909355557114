//WTD-114 Change dealerIds based on address
//Original Id of 943

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var address = document.querySelector('.contact-info .street-address');

    if(address.textContent){
        address = address.textContent.toLowerCase();

        if(address.search('7360 roswell') > -1)
            options.dealerId = 952;
        else if(address.search('6740 oakley industrial') > -1)
            options.dealerId = 942;
        else if(address.search('1090 industrial park') > -1)
            options.dealerId = 941;

    }

    //set url
    options.url = document.URL;

        //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

        //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
