var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        img : '',
        vehicleStatus: ''
    };

    var vehicleData = window.inventory_localization;

    if(vehicleData && vehicleData.vehicle){
        data.vehicleStatus = vehicleData.vehicle.type;
    }

    //get img
    data.img = document.querySelector('meta[property="og:image"]');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        img : function(data,result){
            result.img = data.img.getAttribute('content');
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();
            result.vehicleStatus = (vehicleStatus.indexOf('used') > -1) ? 0 : 1;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data,tasks,options);

    return options;

}
