//dealer 581 DCA
var shimUtils = require('../utils');
var _ = require('underscore');

module.exports = function(options) {

    options = options || {};

    var data = {
        year : '',
        make : '',
        model : '',
        trim : ''
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.ddc-container .ddc-page-title')
        || document.querySelector('.ddc-page-title');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        year : function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make : function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model : function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim : function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

   return options;

}
