// MWO-1922
// Need to send deals to different stores based on the
// store location.  Location is found in a dataLayer object
// on the dealers website

module.exports = function(options) {

    options = options || {};

    // Assume a stock number and vehicleStatus was passed from DDC shim. If no stock number, no need
    // collect it again from the same location. Same with vehicleStatus.
    if (options.stock) {
        var dealership = options.stock.replace(/[^a-zA-Z]+/g, '').toLowerCase()[0];

        if (options.vehicleStatus) {
            switch (dealership) {
                case 't':
                    options.dealerId = 256;
                    break;

                case 'n':
                    options.dealerId = 851;
                    break;

                case 'c':
                    options.dealerId = 853;
                    break;

                default:
                    break;

            }
        }
    }

    return options;
}
