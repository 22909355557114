import * as Config from '../config';
import { INewRelicEvent, NewRelicClient } from './new-relic-client';
import { ICADigitalRetailObject, IWidgetHooks } from '../models/ICADigitalRetailObject';
import { uuidv4 } from './uuid';
import { IAccelerateWindow } from '../models/IAccelerateWindow';
import { WidgetEvent } from './widgetEvent';

declare let window: IAccelerateWindow;
declare let navigator: any;

export abstract class LoggerBase {
    protected readonly WIDGET_STARTED_EVENT: WidgetEvent = 'widget_started';
    protected readonly WIDGET_FINISHED_EVENT: WidgetEvent = 'widget_finished';
    protected readonly WIDGET_DURATION: WidgetEvent = 'widget_duration';

    protected newRelicClient: NewRelicClient;
    protected context: IWidgetContext;
    protected sessionId: string;
    protected vdpSessionId: string; // Needs to be set by calling createNewVdpVisit method
    protected hooks: IWidgetHooks;
    protected contextUpdateCount: number;
    protected eventMap: { [key: string]: IWidgetNewRelicEvent }; // to track events across the VDP Session
    protected isPublished: { [key: string]: boolean }; // to check if already published durations

    protected constructor(public name = 'AccelerateWidgetLogger') {
        this.newRelicClient = new NewRelicClient(Config.getNewRelicConfig());
        this.sessionId = uuidv4(); // set the 1st time logger initialized
        this.contextUpdateCount = 0;
        this.eventMap = {};
        this.isPublished = {};
    }

    public error = (error: any = {}, type?: ErrorType) => {
        // Hook to send messages to VDP
        fireErrorHook(this.hooks, error, type);
        const msg = error.message ? error.message : JSON.stringify(error);
        console.error(this.name + ' Error: ', msg, type);
        this.logEvent({ event: 'error', message: msg, errorType: type });
    };

    public info = (msg: any) => {
        console.log(this.name + ' Info: ', msg);
    };

    public createNewVdpVisit = () => {
        this.vdpSessionId = uuidv4(); // new vdp session id for each update context
        // to reset logger state on VDP vists
        this.contextUpdateCount++;
        this.isPublished = {};
        this.eventMap = {}; // reset events per visit
    };

    public logEvent(event: IWidgetLogEvent) {
        const timestamp = new Date().getTime();
        const nrEvent: IWidgetNewRelicEvent = { ...event, ...this.context, eventType: 'WidgetEvent', timestamp };
        // add events for duration calculations
        this.eventMap = {
            ...this.eventMap,
            [nrEvent.event]: nrEvent
        };
        this.publishDurationsToNewRelic(this.eventMap);
        this.newRelicClient.publishEvent(nrEvent);
    }

    protected getBrowserContext(): any {
        let context = {};
        if (navigator) {
            context = {
                userAgent: navigator.userAgent
            };
        }

        return context;
    }

    protected publishDurationEvents(duration: WidgetEvent, started?: IWidgetNewRelicEvent, finished?: IWidgetNewRelicEvent) {
        if (finished && started && !this.isPublished[duration]) {
            const timestamp = new Date().getTime(); // NR needs this when publishing the event.
            const widgetDuration = finished.timestamp - started.timestamp;

            // Publishes time taken from preloader started to widget started
            this.newRelicClient.publishEvent({
                ...this.context,
                eventType: 'WidgetEvent',
                event: duration,
                timestamp,
                duration: widgetDuration
            } as IWidgetNewRelicEvent);

            this.isPublished[duration] = true;
        }
    }

    protected publishDurationsToNewRelic(eventMap: { [key: string]: IWidgetNewRelicEvent }) {
        const widgetStarted = eventMap[this.WIDGET_STARTED_EVENT];
        const widgetFinished = eventMap[this.WIDGET_FINISHED_EVENT];
        this.publishDurationEvents(this.WIDGET_DURATION, widgetStarted, widgetFinished);
    }

    public setContext(context: {
        accountId?: string;
        dealerId: string;
        version: string;
        isMobile?: string;
        refId?: string | number;
        vehicleLocationId?: string;
        websiteProviderId: string;
        vehicleData?: {
            vdpUrl?: string;
            ownerId?: string;
            sponsor?: string;
        };
        hooks?: IWidgetHooks;
        vin?: string;
        connectionId?: string;
    }): void {
        const {
            accountId,
            dealerId,
            version,
            isMobile,
            refId,
            vehicleLocationId,
            websiteProviderId,
            vehicleData,
            hooks,
            vin,
            connectionId
        } = context;

        const vdpUrl = vehicleData && vehicleData.vdpUrl;
        const ownerId = vehicleData && vehicleData.ownerId;
        const sponsor = vehicleData && vehicleData.sponsor;
        const contextUpdateCount = this.contextUpdateCount;
        this.hooks = hooks;
        this.context = {
            ...this.getBrowserContext(),
            accountId,
            dealerId,
            dnaAccountId: accountId,
            version,
            sessionId: this.sessionId,
            vdpSessionId: this.vdpSessionId,
            sponsor,
            ownerId,
            vdpUrl,
            isMobile,
            refId,
            vehicleLocationId,
            websiteProviderId,
            contextUpdateCount,
            hasCADigitalRetailObject: hasCADigitalRetailObject(window),
            hasMMD: hasMMD(window),
            hooks,
            vin,
            connectionId
        };
    }

    updateContext(options: { context: any }) {
        if (!this.vdpSessionId) {
            this.createNewVdpVisit();
        }
        this.setContext({
            ...options.context
        });
    }

    addToContext(options) {
        this.context = {
            ...this.context,
            ...options
        };
    }
}

export class Logger extends LoggerBase {
    protected readonly DEALSTARTER_DURATION: WidgetEvent = 'dealstarter_duration';
    protected readonly DEALSTARTER_FINISHED_INITIALIZATION: WidgetEvent = 'dealstarter_finished_initialization';

    public static getLogger(): Logger {
        let logger: Logger;

        if (window.mmdLogger) {
            // Checks if logger already initialized
            logger = window.mmdLogger;
        } else {
            logger = new Logger();
            window.mmdLogger = logger; // for global access
        }

        return logger;
    }

    public static clearCache(): void {
        window.mmdLogger = undefined;
    }

    public updateContext(options: { context: ICADigitalRetailObject }) {
        super.updateContext(options);
    }

    protected publishDurationsToNewRelic(eventMap: { [key: string]: IWidgetNewRelicEvent }) {
        super.publishDurationsToNewRelic(eventMap);

        const started = eventMap[this.WIDGET_STARTED_EVENT];
        const finished = eventMap[this.DEALSTARTER_FINISHED_INITIALIZATION];

        this.publishDurationEvents(this.DEALSTARTER_DURATION, started, finished);
    }
}

/**
 *
 * @param hooks - bootstrapData.hooks - make global fn available from atc;
 * will call onLoadedFunction to send back the error message
 * @param error - description of error
 * @param errorType - network, bad request etc
 */
export const fireErrorHook = (hooks, error, errorType: ErrorType = 'Generic') => {
    const err = {
        error,
        errorType
    };
    return hooks && hooks.onLoadedFunction ? hooks.onLoadedFunction(err) : '';
};

export const hasCADigitalRetailObject = (root: IAccelerateWindow) => {
    let exists = 'false';
    if (root && root.CADigitalRetailObject) {
        exists = 'true';
    }
    return exists;
};
export const hasMMD = (root: IAccelerateWindow) => {
    let exists = 'false';
    if (root && root.mmd) {
        exists = 'true';
    }
    return exists;
};

interface IWidgetLogEvent {
    event: WidgetEvent;
    message?: string; // for error
    errorType?: string; // for error
    duration?: number; // for duration
}
interface IWidgetContext {
    // Fields to identify session
    sessionId: string;
    // To identify if unique page visit
    vdpSessionId: string;
    contextUpdateCount: number;
    hasCADigitalRetailObject?: boolean;
    hasMMD?: boolean;
    // Fields to identify widget
    version?: string;

    // Fields to identify the dealer
    dealerId?: string | number;
    accountId?: string;
    dnaAccountId?: string;
    ownerId?: string | number;
    sponsor?: string;
    vdpUrl?: string;
    vehicleLocationId?: string;
    websiteProviderId?: string;
    refId: string;
    connectionId?: string;
    vin?: string;
    // Other fields
    isMobile: string;
    isRedesignEnabled?: boolean;
}

export interface IWidgetNewRelicEvent extends INewRelicEvent, IWidgetContext, IWidgetLogEvent {
    eventType: 'WidgetEvent';
}

type ErrorType =
    | 'verify-service-unavailable'
    | 'verify-failed'
    | 'verify-invalid'
    | 'verify-no-experience'
    | 'widget_failed'
    | 'Network'
    | 'Generic'
    | 'deal360-invalid'
    | 'opensdp_failed'
    | 'opensdp_save_offer_failed'
    | 'opensdp_open_modal_failed'
    | 'opensdp_sdp_load_failed'
    | 'opensdp_offer_details_invalid'
    | 'opensdp_invalid_initialization'
    | 'opensdp_invalid_dealer_initialization';
