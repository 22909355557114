//dealer doesnt' pass retailPrice
//for new vehicles

var shimUtils = require('../utils');

module.exports = function(options) {

	options = options || {};

	var data = {
		retailPrice : '',
        listedPrice : ''
	}

    data.listedPrice = document.querySelector('h7')
                    || document.querySelector('.pricing h4');
    data.retailPrice = document.querySelector('.pricing h3:first-child');

	//logging
	shimUtils.callValueQuerySelector(data,options);

	//define tasks to get data
	var tasks = {
		retailPrice : function(data, result){
			result.retailPrice = shimUtils.getInteger(data.retailPrice);
		},
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        }
	}

	//call continue to get data
	options = shimUtils.continue(data, tasks, options);

	return options;

}
