var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: '',
    };

    //stop here

    if (document.URL.indexOf('mobile') > -1 || document.URL.indexOf('iua') > -1) {
        var pricingTable = document.querySelectorAll('.iui_vehicle table tbody tr td table tbody tr td .ecl_vehicle_price');

        if (pricingTable.length > 0) {

            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var label = array[index].querySelector('p').textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'internet price':
                            data.listedPrice = array[index].querySelector('h3');
                            break;

                        default:
                    }
                }
                return true;
            });

        }

        var vehicleOverview = document.querySelectorAll('.iui_vehicle table tbody tr');

        if (vehicleOverview.length > 0) {

            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0 && array[index].children[0].textContent.indexOf('price') < 0) {
                    var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'mileage':
                            data.mileage = array[index].children[1];
                            break;

                        case 'vin':
                            data.vin = array[index].children[1];
                            break;

                        case 'stock ':
                            data.stock = array[index].children[1];
                            break;

                        case 'stock':
                            data.stock = array[index].children[1];
                            break;

                        default:
                    }
                }
                return true;
            });
        }

        data.img = document.querySelector('.iui_vehicle li img');

        data.year = data.make = data.model = data.trim = document.querySelector('.iui_vehicle li b');


    } else {
        // grab data from vehicle overview table
        var pricingTable = document.getElementById('price');
        if (pricingTable) {
            pricingTable = pricingTable.querySelectorAll('li');
        }


        if (pricingTable && pricingTable.length > 0) {

            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsArr = pricingArr;

            var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var value = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase(),
                        label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'internet price':
                            data.listedPrice = value;
                            break;

                        case 'retail price':
                            data.retailPrice = value;
                            break;

                        default:
                    }
                }
                return true;
            });
        };

        if (!data.listedPrice) {
            data.listedPrice = document.querySelector("#priceContainer .price_line_1")
                || document.querySelector('#dealerPriceBig')
                || document.querySelector('.vehicle-price-default-price')
                || document.querySelector("#price .price_line_1");
        }

        if(!data.retailPrice){
            var price = document.querySelector('.vehicle-price-default-label');
            if(price){
                if(price.textContent.toLowerCase().indexOf('msrp')>-1){
                    data.retailPrice = document.querySelector('.vehicle-price-default-price');
                }
            }
        }

        //get year, make, model, trim
        data.year = data.make = data.model = data.trim = document.querySelector("#vehicle_title")
            || document.querySelector('#carTitle')
            || document.getElementById('titleVdp')
            || document.querySelector('.vehicle-title');

        //get img
        data.img = document.querySelector("#carimg")
            || document.querySelector('#player #main_picture')
            || document.querySelector('.hero-shot-frame img');

        //get mileage
        data.mileage = document.querySelector('#vitalsInfo tr:first-child .vitalsColumnRight .textBlack') 
            || document.querySelector('#vitalsInfo tr:first-child .vitalsColumnRight') 
            || document.querySelector('#vitalsRight li:nth-child(4)');

        //get vin
        data.vin = document.querySelector('#vitalsInfo .vitalsColumnRightBottom .vitalsLink')
            || document.querySelector('#vitalsRight li:first-child');

        //get vin
        data.stock = document.querySelector('#vitalsInfo .vitalsColumnLeftBottom')
            || document.querySelector('#vitalsLeft li:nth-child(2)');
    }

    if(!data.stock || !data.vin){
        var vehicleOverviewLabel = document.querySelectorAll('dl#stock-and-vin dt');
        var vehicleOverviewValue = document.querySelectorAll('dl#stock-and-vin dd');

        if(vehicleOverviewLabel.length){
            var overviewLabel = Array.prototype.map.call(
                vehicleOverviewLabel,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if(vehicleOverviewValue.length){
            var overviewValue = Array.prototype.map.call(
                vehicleOverviewValue,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        if(overviewLabel && overviewValue){
            var siteOptionsCollector = overviewLabel.filter(function(row, index, array) {

                var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();
                var value = overviewValue[index].textContent.replace(replaceRegex, '').toLowerCase().trim();

                switch (label) {
                    case 'stk':
                        data.stock = value;
                        break;

                    case 'vin':
                        data.vin = value;
                        break;

                    default:
                        break;
                }
                return true;
            });
        }
    };

    if(!data.mileage){
        var vehicleOverview = document.querySelectorAll('.vehicle-detail-list span');

        if(vehicleOverview.length){
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        if(overviewArr){
            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if(row.children[0]){
                    var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

                    switch (label) {
                        case 'mileage':
                            data.mileage = row.children[1].textContent.replace(replaceRegex, '').toLowerCase().trim();
                            break;

                        default:
                            break;
                    }
                }
                return true;
            });
        }
    };

    //get status
    data.vehicleStatus = location.pathname.search(/new/g);

    //logging
    shimUtils.callValueQuerySelector(data, options);

    var bmwSeries = {
        '1 series': true,
        '2 series': true,
        '3 series': true,
        '4 series': true,
        '5 series': true,
        '6 series': true,
        '7 series': true
    };

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if(data.listedPrice.textContent){
                if(data.listedPrice.innerHTML.indexOf('sup') > -1) {
                    data.listedPrice = data.listedPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                }else{
                    data.listedPrice = data.listedPrice.textContent;
                }
            }
            result.listedPrice = parseInt(data.listedPrice.replace(/[^0-9.]/g, '').trim(), 10);
        },
        retailPrice: function(data, result) {
            if(data.retailPrice.textContent){
                if(data.retailPrice.innerHTML.indexOf('sup') > -1) {
                    data.retailPrice = data.retailPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                }else{
                    data.retailPrice = data.retailPrice.textContent;
                }
            }
            result.retailPrice = parseInt(data.retailPrice.replace(/[^0-9.]/g, '').trim(), 10);
        },
        year: function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make: function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model: function(data, result) {
            var model = data.model;
            model = model.textContent;
            model = model.trim().toLowerCase().split(' ');
            if (bmwSeries[model[2] + " " + model[3]]) {
                result.model = model.splice(2, 2).join(' ');
            } else {
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }
        },
        trim: function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().toLowerCase().split(' ');
            if (bmwSeries[trim[2] + " " + trim[3]]) {
                result.trim = trim.splice(4, trim.length).join(' ');
            } else {
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);

        },
        vin: function(data, result) {
            var vin = data.vin;
            if(vin.textContent){
                vin = vin.textContent;
            };
            if (vin.search(/VIN/g) > -1) {
                result.vin = vin.split(':')[1].trim();
            } else {
                result.vin = vin;
            }
        },
        stock: function(data, result) {
            var stock = data.stock;
            if(stock.textContent){
                stock = stock.textContent;
            };
            if (stock.search(/Stock/gi) > -1 && stock.search(/:/gi)>-1) {
                stock = stock.split(':')[1].trim();
            } else if(stock.search(/number/gi)>-1){
                stock = stock.split(/\n/).filter(function(n) {
                    return /\S/.test(n);
                });
                stock = stock.pop().trim();
            } else if (stock.search(/\n/g) > -1) {
                stock = stock.split(' ').filter(function(n) {
                    return n != '';
                }).pop().trim();
            } else {
                if (stock.search(/Interior/g) > -1) {
                    stock = document.querySelector('#vitalsLeft li:nth-child(3)');
                    stock = stock.textContent.split(':')[1].trim();
                } 
            }
            result.stock = stock
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;
}
