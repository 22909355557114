// tslint:disable: trailing-comma
import { SaveOffer, VerifySaveOfferResults } from './types/saveOfferTypes';
import { getQueryParams } from './utils/querystring';
import * as Config from '../config';
import axios from 'axios';

/**
 * Save the offer
 * @param context CADR object
 */
export const saveOffer = async (context: SaveOffer): Promise<VerifySaveOfferResults> => {
    // if we are mocking get this result
    if (isMockSaveOffer()) {
        return mockSaveOffer();
    }

    if (!context.vehicle.urls) {
        context.vehicle.urls = {};
    }

    if (!context.vehicle.urls.vdpUrl) {
        context.vehicle.urls.vdpUrl = window.location.href;
    }

    // get the URL for the api
    // tslint:disable-next-line: no-commented-code
    const domain = Config.getdsApiUrl(context.dealer.sponsor || '');
    const url = `${domain}/sp/bff/deal/setup`;

    // now make the axios call with the offer as the payload
    const result = await axios.post<VerifySaveOfferResults>(url, context);

    // if we get a problem, then we need to throw the error and stop
    if (!result.data.success) {
        const { error } = result.data;
        const { message = 'Could not save the offer.' } = error || {};

        throw new Error(message);
    }

    // now we have the result, we can transform it here
    return result.data;
};

export const isMockSaveOffer = (): boolean => {
    const qs = getQueryParams();
    return qs && qs.mockSaveOffer !== undefined && ['yes', 'true'].indexOf(qs.mockSaveOffer as string) >= 0;
};

/**
 * Mock call to saveOffer so we can test without the API
 */
export const mockSaveOffer = (): VerifySaveOfferResults => {
    return {
        success: true,
        offer: {
            connectionId: '5fb68b69f1c164bdab9abf59',
            vin: '1N4BL4BV3KC210549'
        },
        verify: {
            ok: true,
            result: true,
            dealerId: 1606,
            config: {
                theme: '',
                themeRedesign: '',
                sdpModalZIndex: 10000
            },
            experience: 'dealStarter',
            isRedesignEnabled: true
        }
    };
};
