//shim:1720
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    data.listedPrice = document.querySelector('.vehicle-price .price-value')

    var vehicleOverviewLabel = document.querySelectorAll('#overview .mod-vehicle-info .row .specification li .title');
    var vehicleOverviewValue = document.querySelectorAll('#overview .mod-vehicle-info .row .specification li .value');

    var overviewArrLabel = Array.prototype.map.call(
        vehicleOverviewLabel,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewArrValue = Array.prototype.map.call(
        vehicleOverviewValue,
        function(obj, i, arr) {
            return obj;
        }
    );

    if (overviewArrLabel.length && overviewArrValue.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewArrLabel.filter(function(row, index, array) {
            var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'mileage':
                    data.mileage = overviewArrValue[index].textContent.replace(replaceRegex, '');
                    break;
                case 'vin':
                    data.vin = overviewArrValue[index].textContent.replace(replaceRegex, '');
                    break;
                case 'stock':
                    data.stock = overviewArrValue[index].textContent.replace(replaceRegex, '');
                    break;

                default:
                    break;
            }
            return true;
        });
    }

    //get img
    data.img = document.querySelector('.mod-vehicle-gallery img');

    //get status, year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-title');

    data.vehicleStatus = document.URL;
    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if(status.toLowerCase().indexOf('new') > -1){
                result.vehicleStatus = 1;
            }
            else result.vehicleStatus =0;
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
