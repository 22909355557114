var shimUtils = require('../utils');

module.exports = function(options) {

    // Currently stubbing this in but it is not being used currently.
    // Will need to be implemented going forward.
    options = options || {};

     var data = {
            retailPrice : '',
            listedPrice: '',
            year : '',
            make : '',
            model : '',
            trim : '',
            vehicleStatus : '',
            img : '',
            stock: '',
            vin: ''
    };

     //get vehicle status
    data.vehicleStatus = document.querySelector('#NewUsedCertTitle') ||
                         document.querySelector('[itemprop="itemcondition"]');

    //get retailPrice
    data.retailPrice =  document.querySelectorAll('[itemprop="price"]');

    data.listedPrice = document.querySelectorAll('[itemprop="price"]');

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('[itemprop="name"]');

    //get img
    data.img = document.getElementById('PhotosBox') || document.querySelector('.vdp-image-container');

    data.stock = document.querySelector('[itemprop="sku"]');

    data.vin = document.querySelector('[itemprop="productid"]') || document.querySelector('[itemprop="productID"]');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //defiine tasks to get data
    var tasks = {
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus.textContent.toLowerCase();
            result.vehicleStatus = (status.search(/\b(used|certified|pre-owned)\b/g) > -1) ? 0 : 1;
        },
        year : function(data, result) {
            var year = data.year.textContent.trim().split(' ');
            result.year = year.shift();
        },
        make : function(data, result) {
            var make = data.make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model : function(data, result) {
            var model = data.model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim.textContent.trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        retailPrice : function(data,result){
            if(data.retailPrice.length > 1){
                result.retailPrice = data.retailPrice[0].textContent;
            }
        },
        listedPrice : function(data,result){
            if(data.listedPrice.length > 1){
                result.listedPrice = data.listedPrice[1].textContent;
            }else{
                result.listedPrice = data.listedPrice[0].textContent;
            }
        },
        stock: function(data, result){
            var stock = data.stock.textContent.trim();
            result.stock = stock;
        },
        vin: function(data, result){
            var vin = data.vin.textContent.trim();
            result.vin = vin;
        },
        img : function(data,result){
            var img = data.img.querySelector('img');
            result.img = img.src;
        }
    };

    options.isShimmed = true;

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

};
