var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: '',
    };

    var vehicleData = window.inventory_localization;

    if(vehicleData && vehicleData.vehicle){
        data.year = vehicleData.vehicle.year;
        data.make = vehicleData.vehicle.make;
        data.model = vehicleData.vehicle.model;
        data.trim = vehicleData.vehicle.trim;
        data.vehicleStatus = vehicleData.vehicle.type;
        data.stock = vehicleData.vehicle.stock;
        data.vin = vehicleData.vehicle.vin;
    }

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.details-container dl');
	var pricingTable = document.querySelector('.mainPriceContainer');
    var pricingTable2 = document.querySelectorAll('div#vitalsContainer li');

    if(vehicleOverview.length>0 || pricingTable){
        if(vehicleOverview.length>0){
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if(array[index].children.length !== 0) {
                    var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
                    var label = labelAndValue[0];
                    var value = labelAndValue[1];

                    switch(label) {
                        case 'mileage':
                            data.mileage = value;
                            break;

                        default:
                    }
                }
                return true;
            });
        };
        if(pricingTable){
            var pricingTableLabel = [];
            var pricingTableValue = [];

            //pulls out and separates labels and values
            // FIX LOGIC
            for(var i=0;i<pricingTable.children.length;i++){
              if(pricingTable.children[i].classList[0]){
                if(pricingTable.children[i].classList[0].toLowerCase().indexOf('label')>-1){
                    pricingTableLabel.push(pricingTable.children[i])
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('value')>-1){
                    pricingTableValue.push(pricingTable.children[i]);
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('text')>-1){
                    pricingTableValue.push(pricingTable.children[i]);
                }else if(pricingTable.children[i].classList[0].toLowerCase().indexOf('originalpricecontainer')>-1){
                    pricingTableLabel.push(pricingTable.children[i].children[0]);
                    pricingTableValue.push(pricingTable.children[i].children[1]);
                }
              }
            }

            var pricingLabelArr = Array.prototype.map.call(
                pricingTableLabel,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var pricingValueArr = Array.prototype.map.call(
                pricingTableValue,
                function(obj, i, arr){
                    return obj;
                }
            );

            var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
                var label = array[index].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
                var value = pricingValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                switch(label) {
                    case '':
                    case 'pre-owned':
                    case 'eprice':
                    case 'no hassle price':
                    case 'price':
                    case 'value price':
                    case 'your price':
                    case 'selling price':
                    case 'sale price':
                    case 'internet price':
                    case 'market price':
                    case 'market value':
                    case 'lowest published price':
                    case 'one simple price':
                    case 'tamaroff family price':
                    case 'new sale price':
                    case 'our price':
                    case 'internet value price':
                    case 'internet special':
                    case 'price before rebates':
                        data.listedPrice = value;
                        break;

                    case 'msrp':
                    case 'market price':
                    case 'original selling price':
                    case 'retail':
                    case 'hq value price':
                    case 'kbb retail value':
                    case 'was':
                        data.retailPrice = value;
                        break;

                    default:
                }
                return true;
            });
        };
    }

    if(pricingTable2.length){
        var pricingTableArr2 = Array.prototype.map.call(
            pricingTable2,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = pricingTableArr2.filter(function(row, index, array) {
            var labelAndValue = array[index].textContent.replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
            var label = labelAndValue[0].trim();
            var value = labelAndValue[1];
            switch(label) {
                case 'stock':
                    data.stock = value.trim();
                    break;

                case 'vin':
                    data.vin = value.trim();
                    break;

                case 'mileage':
                    data.mileage = value.trim();
                    break;

                default:
            }
            return true;
        });
    }

    //get msrp if it appears later on page
    if(!data.retailPrice){
        if(document.querySelector('.vehicle-description-text p:nth-child(3)')){
            data.retailPrice = document.querySelector('.vehicle-description-text p:nth-child(3)');
            if(data.retailPrice.textContent === "") data.retailPrice = '';
        }
        if(document.querySelector('.realPriceLabel')){
            if(document.querySelector('.realPriceLabel').textContent.toLowerCase().indexOf('msrp')>-1 || document.querySelector('.realPriceLabel').textContent.toLowerCase().indexOf('retail')>-1){
                data.retailPrice = document.querySelector('.realPriceText');
            }
        }
        if(!data.retailPrice) {
            data.retailPrice = document.querySelector('.original-price .ctabox-price');
        }
    }

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('.price_line_1')
        || document.querySelector('.realPriceText')
        || document.querySelector('.real-price .ctabox-price');
    }

    if(!data.dealerSavings){
        data.dealerSavings = document.querySelector('.savingsAmount');
    }

    //get stock, vin
    if(!data.stock){
        data.stock = document.querySelector('.vehiclenumber');
    }
    if(!data.vin){
        data.vin = document.querySelector('.vehiclenumber');
    }

    //get year, make, model, trim
    if(!data.year){
        data.year = document.querySelector('.vehicle-title')
            || document.querySelector('h1#vehicle_title');
    }
    if(!data.make){
        data.make = document.querySelector('.vehicle-title')
            || document.querySelector('h1#vehicle_title');
    }
    if(!data.model){
        data.model = document.querySelector('.vehicle-title')
            || document.querySelector('h1#vehicle_title');
    }
    if(!data.trim){
        data.trim = document.querySelector('.vehicle-title')
            || document.querySelector('h1#vehicle_title');
    }

    //get status
    if(!data.vehicleStatus){
        data.vehicleStatus = document.querySelector('link[itemprop="itemCondition"]')
            || document.URL;
    }

    //get mileage
    if(!data.mileage){
        data.mileage = document.querySelector('.vehiclenumber')
            || document.querySelector('.details-container .lefthalf dl:nth-child(2) dd');
    }

    //get img
    data.img = document.querySelector('meta[property="og:image"]') ||
        document.querySelector('.owl-wrapper img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            var listedPrice = shimUtils.getInteger(data.listedPrice,10);
            if(isNaN(listedPrice)){
                //use retailPrice if listedPrice not given (eg: please call for price instead of number)
                result.listedPrice = shimUtils.getInteger(data.retailPrice,10);
            }else{
                result.listedPrice = shimUtils.getInteger(data.listedPrice,10);
            }
        },
        dealerSavings: function(data, result){
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings,10);
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus;
            if(vehicleStatus.href){
                vehicleStatus = vehicleStatus.href;
            }else if(vehicleStatus.textContent){
                vehicleStatus = vehicleStatus.textContent;
            }
            vehicleStatus = vehicleStatus.toLowerCase();
            result.vehicleStatus = (vehicleStatus.indexOf('used') > -1) ? 0 : 1;
        },
        retailPrice: function(data, result) {
            if(!!data.retailPrice.textContent){
                if(data.retailPrice.textContent.indexOf('price' || 'msrp')>-1){
                    result.retailPrice = parseInt(data.retailPrice.textContent.replace(/[^0-9.]/g, ''),10);
                }
                else {
                    result.retailPrice = shimUtils.getInteger(data.retailPrice,10);
                }
            }else if(!data.retailPrice.textContent){
                result.retailPrice = parseInt(data.retailPrice.replace(/[^0-9.]/g, ''),10);
            }
        },
        year: function(data, result) {
            if(data.year.textContent){
                data.year = data.year.textContent;
            };
            if(data.year.split(' ').length>3){
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            }else{
                result.year = parseInt(data.year,10);
            }
        },
        make: function(data, result) {
            if(data.make.textContent){
                data.make = data.make.textContent;
            };
            if(data.make.split(' ').length>3){
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            }else{
                result.make = data.make.trim();
            }
        },
        model: function(data, result) {
            if(data.model.textContent){
                data.model = data.model.textContent;
            };
            if(data.model.split(' ').length>3){
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }else{
                result.model = data.model.trim();
            }
        },
        trim: function(data, result) {
            if(data.trim.textContent){
                data.trim = data.trim.textContent;
            };
            if(data.trim.split(' ').length>3){
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            }else{
                result.trim = data.trim.trim();
            }
        },
        mileage: function(data, result) {
            if(data.mileage.textContent){
                if(data.mileage.textContent.indexOf('|')>-1 && data.mileage.textContent.indexOf('ileage')>-1){
                    var mileageArr = data.mileage.textContent.split('|');

                    if(mileageArr.length > 2){
                        result.mileage = mileageArr[2].split(':').pop().trim();
                    }
                }
            }else if(!result.vehicleStatus){
                result.mileage = shimUtils.getInteger(data.mileage);
            };
        },
        vin: function(data, result) {
            var vin = data.vin;
            if(vin.textContent){
                vin = vin.textContent.split('|').shift();
                vin = vin.split(':').pop();
            }
            result.vin = vin.trim();
        },
        stock: function(data, result) {
            var stock = data.stock;
            if(stock.textContent){
                var stockArr = stock.textContent.split('|');

                if(stockArr.length > 1){
                    result.stock = stockArr[1].split(':').pop().trim();
                }
            }
            else {
                result.stock = stock.trim();
            }
        },
        img: function(data, result) {
            result.img = data.img.getAttribute('content');
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
