var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        vehicleStatus : ''
    };

    //get listedPrice & retailPrice
    data.retailPrice = document.querySelector('dd.vehicle-price-base-price')
                    || document.querySelector('dd.vehicle-price-default-price');

    //get status
    data.vehicleStatus = location.pathname.toLowerCase().search(/\bnew\b/g);

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        retailPrice : function(data, result) {
            var retailPrice = data.retailPrice;
            if(retailPrice.innerHTML.indexOf('sup') > -1) {
                retailPrice = retailPrice.innerHTML.split('<').shift().replace(/\D/g, '');
                result.retailPrice = parseInt(retailPrice, 10);
            } else {
                result.retailPrice = shimUtils.getInteger(retailPrice);
            }
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set isShimmed
    options.isShimmed = true;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;
};
