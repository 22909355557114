var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        incentiveSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    var pricingLabelTable = document.querySelectorAll('.veh_pricing_container dt');
    if(pricingLabelTable.length<1)
        pricingLabelTable = document.querySelectorAll('#details-highlights .details-price dt');
    var pricingValueTable = document.querySelectorAll('.veh_pricing_container dd');
    if(pricingValueTable.length<1)
        pricingValueTable = document.querySelectorAll('#details-highlights .details-price dd');

    if (pricingLabelTable.length > 0 && pricingValueTable.length > 0) {

        var pricingLabelArr = Array.prototype.map.call(
            pricingLabelTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var pricingValueArr = Array.prototype.map.call(
            pricingValueTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            var value = pricingValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch (label) {
                case 'msrp':
                case 'list price':
                    data.retailPrice = value;
                    break;

                case 'price':
                case 'integrity price':
                case 'internet price':
                case 'internet special':
                case 'your price':
                    data.listedPrice = value;
                    break;
                case 'special price':
                    if(!data.listedPrice)
                        data.listedPrice = value;
                    break;
                case 'dealer discount':
                    data.dealerSavings = value;
                    break;

                case 'toyota rebate':
                    data.incentiveSavings = value;
                    break;

                default:
            }
            return true;
        });


    } else {
        //get retailPrice
        data.retailPrice = document.querySelector('.vehicle_price.price_tp-msrp');

        //get listedPrice
        data.listedPrice = document.querySelector('.vehicle_price.price_tp-selling') || data.retailPrice;
    }

    //get status, year, make, model, trim, vin
    if (window.DEP_EVENT_PUBLISHER) {
        var dataObj = window.DEP_EVENT_PUBLISHER.getPage();
        data.make = dataObj.vehicle_make[0];
        data.model = dataObj.vehicle_model[0];
        data.trim = dataObj.vehicle_trim[0];
        data.year = dataObj.vehicle_year[0];
        data.vin = dataObj.vehicle_vin[0];
        data.vehicleStatus = dataObj.vehicle_type[0];
    } else {
        var overviewTable = document.querySelectorAll('.details-overview_table table tr');

        if(overviewTable.length){

            var overviewArr = Array.prototype.map.call(
                overviewTable,
                function(obj, i, arr) {
                    return obj;
                }
            );

            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                var label = array[index].children[0].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim().toLowerCase();
                var value = array[index].children[1].textContent.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim().toLowerCase();
                switch (label) {
                    case 'stock':
                        data.stock = value;
                        break;

                    case 'vin':
                        data.vin = value;
                        break;

                    case 'mileage':
                        data.mileage = value;
                        break;

                    default:
                }
                return true;
            });
        }

        data.year = data.make = data.model = data.trim = document.querySelector('.vdp_vehicle_title_container')
            || document.querySelector('div#details-header h1');

        data.vehicleStatus = document.URL;
    }

    if (document.body.className != 'mobile') { //desktop

        //get mileage
        if(!data.mileage){
            data.mileage = document.querySelector('#details_table-Mileage .details-overview_data') || document.querySelector('.details-overview_table tr:nth-child(6) .details-overview_data');
        }

        //get stock
        if(!data.stock){
            data.stock = document.querySelector('#details_table-Stock .details-overview_data') || document.querySelector('.details-overview_table tr:nth-child(2) .details-overview_data');
        }

        //get img
        if(!data.img){
            data.img = document.querySelector('#details-media_box .details-vehicle_img.modal_link_active img') || document.querySelector('li.magnify img');
        }

    } else { //mobile

        //get mileage
        if(!data.mileage){
            data.mileage = document.getElementById('Mileage');
        }

        //get stock
        if(!data.stock){
            data.stock = document.getElementById('Stock');
        }

        //get vin
        if(!data.vin){
            data.vin = document.getElementById('Vin');
        }

        //get img
        if(!data.img){
            data.img = document.querySelector('.vehicle-photo img');
        }
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        dealerSavings: function(data, result) {
            result.dealerSavings = shimUtils.getInteger(data.dealerSavings);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;

            if (status.search(/new/) === -1) {
                result.vehicleStatus = 0;
            } else result.vehicleStatus = 1;
        },
        year: function(data, result) {
            if(!!data.year.innerHTML){
                var title = shimUtils.titleTrimmer(data.year);
                result.year = title.year;
            }else{
                result.year = parseInt(data.year);
            }
        },
        make: function(data, result) {
            if(!!data.make.innerHTML){
                var title = shimUtils.titleTrimmer(data.make);
                result.make = title.make;
            }else{
                result.make = data.make;
            }
        },
        model: function(data, result) {
            if(!!data.make.innerHTML){
                var title = shimUtils.titleTrimmer(data.model);
                result.model = title.model;
            }else{
                result.model = data.model;
            }
        },
        trim: function(data, result) {
            if(!!data.make.innerHTML){
                var title = shimUtils.titleTrimmer(data.trim);
                result.trim = title.trim;
            }else{
                result.trim = data.trim;
            }
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = typeof data.vin === 'object' ? data.vin.textContent : data.vin;
        },
        stock: function(data, result) {
            if(data.stock.textContent){
                data.stock = data.stock.textContent;
            }
            result.stock = data.stock;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

};
