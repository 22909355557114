var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : '',
    };

    //get vin
    data.vin = document.querySelector(".location-stock-no .stock-no span.sn-right") ||
        document.querySelector(".vi-tab-desktop-more-info-block .vif-vin") ||
        document.querySelector('.vif-vin');

    //get stock
    data.stock = document.querySelector(".location-stock-no .stock-no span.sn-left") ||
        document.querySelector(".vi-tab-desktop-more-info-block .vif-stocknumber") ||
        document.querySelector('.vif-stocknumber');

    data.mileage = document.querySelector(".vif-miles") ||
        document.querySelector(".location-stock-no .stock-no span.sn-middle") ||
        document.querySelector('.vif-miles');

    //get listedPrice & retailPrice
    data.retailPrice = document.querySelector(".price-list li[itemprop='offerDetails'] .dollars");
    data.listedPrice = document.querySelector('.marketValue .dollars') ||
                document.querySelector('.price-list .final .dollars');

    //get img
    data.img = document.querySelector("#carousel .item.active img");

    //get year
    data.year = data.make = data.model = data.trim = document.querySelector(".custom-vdp-template-one") ||
        document.querySelector("#vdp-wrapper");

    //get vehicleStatus
    data.vehicleStatus = document.querySelector(".chicago-bar h1 span.condition") ||
        document.querySelector(".vi-inventory-description-tag");
    if(!data.vehicleStatus){
        data.vehicleStatus = document.URL;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to collect data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            result.year = parseInt(data.year.getAttribute('data-year'), 10);
        },
        make : function(data, result) {
            var make = data.make;
            result.make = make.getAttribute('data-carmake')
                        || make.getAttribute('data-make');
        },
        model : function(data, result) {
            var model = data.model;
            result.model = model.getAttribute('data-carmodel')
                        || model.getAttribute('data-model');
        },
        trim : function(data, result) {
            var trim = data.trim.getAttribute('data-trim');
            result.trim = (trim) ? trim : " ";
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            if(status.textContent){
                status = status.textContent;
            }
            result.vehicleStatus = (status.toLowerCase().indexOf('new') > -1) ? 1 : 0;
        },
        mileage : function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin : function(data, result) {
            var vin = data.vin;
            vin = (vin.textContent.search(/:/g) > 0) ? vin.textContent.split(":")[1].trim() : vin.textContent;
            result.vin = vin;
        },
        stock : function(data, result) {
            var stock = data.stock;
            stock = (stock.textContent.search(/#/g) > 0) ? stock.textContent.split(":")[1].trim() : stock.textContent;
            result.stock = stock;
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;
}
