const _ = require('lodash');

export const prepareDataFromLegacyObjects = (inputData) => {
    const version = inputData?.version;
    const drWindowObj = inputData?.CADigitalRetailObject;
    const scriptAttrObject = inputData?.scriptAttrObject;

    let bootstrapObject = {};

    if (version == '4.0.0') {
        // If scriptAttrObject exists, then assume dealerId and sponsor pass via this method
        // otherwise it will be on the the window object
        if (!_.isEmpty(scriptAttrObject)) {
            const selectedScriptAttrObj: any = {
                dealerId: scriptAttrObject.dealerId,
                websiteProviderId: scriptAttrObject.sponsor
            };

            if (scriptAttrObject?.chromeStyleId) {
                selectedScriptAttrObj.vehicleData = {
                    chromeStyleId: scriptAttrObject.chromeStyleId
                };
            }

            bootstrapObject = _.extend(bootstrapObject, selectedScriptAttrObj);
        }

        if (!_.isEmpty(drWindowObj)) {
            _.mixin({
                deeply(map) {
                    return function (obj, fn) {
                        return map(
                            _.mapValues(obj, function (v) {
                                return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
                            }),
                            fn
                        );
                    };
                }
            });

            const legacyFieldMap = {
                sponsor: 'websiteProviderId',
                vehicleStatus: 'condition',
                img: 'imgUrl',
                url: 'vdpUrl',
                stock: 'stockNumber',
                widgetData: 'vehicleData'
            };

            // TODO: clean-up when all backend DCA plus validators are executing from new fields
            // This keeps backwards compatibility by replicating the fields
            let legacyFields = {};

            const mappedDRKeys = _.deeply(_.mapKeys)(drWindowObj, function (value, key) {
                if (legacyFieldMap[key]) {
                    if (key != 'widgetData') {
                        const tmpObject = {};
                        tmpObject[key] = value;
                        legacyFields = _.extend(legacyFields, tmpObject);
                    }
                    return legacyFieldMap[key];
                }

                return key;
            });

            // Add the legacy fields back to new object vehicleData
            mappedDRKeys.vehicleData = _.extend(mappedDRKeys.vehicleData, legacyFields);

            // TODO - optimize this later
            // Need to change some fields from the mappedDRKeys object
            // Doing this manually now

            // Handle hooks
            const onLoadedFunction = _.get(mappedDRKeys, 'vehicleData.onLoadedFunction');
            const onHidingFunction = _.get(mappedDRKeys, 'vehicleData.onHidingFunction');
            const hooks: any = {};

            if (onLoadedFunction) {
                hooks.onLoadedFunction = onLoadedFunction;
                delete mappedDRKeys.vehicleData.onLoadedFunction;
            }

            if (onHidingFunction) {
                hooks.onHidingFunction = onHidingFunction;
                delete mappedDRKeys.vehicleData.onHidingFunction;
            }

            bootstrapObject = _.extend(bootstrapObject, { hooks });

            // Handle condition mapping
            const conditionMap = {
                0: 'used',
                1: 'new'
            };

            const condition = mappedDRKeys?.vehicleData?.condition;

            if (condition === 1 || condition === 0) {
                mappedDRKeys.vehicleData.condition = conditionMap[condition];
            }

            // merge with bootstrapObject
            bootstrapObject = _.extend(bootstrapObject, mappedDRKeys);
        }
    }

    return bootstrapObject;
};
