// WTD-255
// This utility contains the special rules for the Glockner Auto Group shims
// to convert dealerIds to proper location based on vehicleStatus and vin.
// DealerIds involved: 834, 835, 836, 837, 839

var convertDealerId = module.exports = {
    convertDealerId: function(options) {
        var dealerId;
        var vehicleStatus;
        var firstElementOfStock;
        var make;

        if(options.dealerId){
            dealerId = options.dealerId;
        };

        if(options.vehicleStatus===0 || options.vehicleStatus===1){
            vehicleStatus = options.vehicleStatus;
        };

        if(options.stock){
            firstElementOfStock = options.stock.toString().substr(0,1).toLowerCase();
        };

        if(options.make){
            make = options.make.toLowerCase();
        }

        if((vehicleStatus===0 || vehicleStatus===1) && !!dealerId && !!firstElementOfStock){
            dealerId = vehicleStatus===1 ? this.convertDealerIdForNewVehicles(dealerId,firstElementOfStock) : this.convertDealerIdForUsedVehicles(dealerId,firstElementOfStock,make);
        }

        return dealerId;
    },

    convertDealerIdForNewVehicles: function(dealerId,firstElementOfStock) {

        switch(firstElementOfStock) {
            case 'h':
                dealerId = '834';
                break;

            case 't':
                dealerId = '835';
                break;

            case 'c':
                dealerId = '837';
                break;

            case 'f':
                dealerId = '839';
                break;

            default:
                dealerId = '836';
                break;
        }

        return dealerId;
    },

    convertDealerIdForUsedVehicles: function(dealerId,firstElementOfStock,make) {

        switch(firstElementOfStock){
            case '3':
                dealerId = '836';
                break;

            case 'c':
                dealerId = '837';
                break;

            case 'f':
                dealerId = '839';
                break;

            default:
                break;
        }


        if(firstElementOfStock==='9' && make==='honda'){
             dealerId = '834';
        }else if(firstElementOfStock==='9' && make==='toyota'){
             dealerId = '835';
        }

        return dealerId;
    }
};
