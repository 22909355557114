// tslint:disable: trailing-comma
import {
    SaveOffer,
    SaveOfferDealer,
    SaveOfferPayment,
    SaveOfferResults,
    SaveOfferShopper,
    SaveOfferTrade,
    SaveOfferVehicleData
} from '../types/saveOfferTypes';
import { checkRequiredFieldsAnd, checkRequiredFieldsOr } from './utils';

export const isNumber = (a: any) => typeof a === 'number';

/**
 * Is the data of type SaveOfferVehicleData
 * @param data Object to check
 */
export function isSaveOfferVehicleData(data: any): data is SaveOfferVehicleData {
    // confirm that it has all the required fields
    const requiredOk = checkRequiredFieldsAnd(data, ['condition', 'listedPrice', 'mileage', 'retailPrice', 'year']);
    if (requiredOk) {
        // check for either vin or chromeStyleId
        return checkRequiredFieldsOr(data, ['vin', 'chromeStyleId']);
    }

    return false;
}

/**
 * Is the data of type SaveOfferDealer
 * @param data Object to check
 */
export function isSaveOfferDealer(data: any): data is SaveOfferDealer {
    return checkRequiredFieldsOr(data, ['dnaAccountId', 'ownerId', 'id']);
}

/**
 * Is the data of type SaveOfferShopper
 * @param data Object to check
 */
export function isSaveOfferShopper(data: any): data is SaveOfferShopper {
    return true; // always true because no required fields
}

/**
 * Is the data of type SaveOfferPayment
 * @param data Object to check
 */
export function isSaveOfferPayment(data: any): data is SaveOfferPayment {
    let isOk = true;

    if (data) {
        if (isOk && 'selectedOfferType' in data && data.selectedOfferType) {
            isOk = ['cash', 'finance', 'lease'].indexOf(data.selectedOfferType.toLowerCase()) >= 0;
        }

        if (isOk && 'creditScore' in data && data.creditScore) {
            isOk = isNumber(data.creditScore);
        }
    }

    return isOk;
}

const isTradeByUserValid = (data: SaveOfferTrade): boolean => {
    const fields: (keyof SaveOfferTrade)[] = ['value', 'source'];
    return checkRequiredFieldsAnd(data, fields);
};
const isTradeByKBBValid = (data: SaveOfferTrade): boolean => {
    const fields: (keyof SaveOfferTrade)[] = [
        'amountOwed',
        'condition',
        'isNetTradeAppliedToFinancing',
        'make',
        'mileage',
        'model',
        'source',
        'trim',
        'year',
        'value'
    ];
    return checkRequiredFieldsAnd(data, fields);
};
const isTradeByICOValid = (data: SaveOfferTrade): boolean => {
    const fields: (keyof SaveOfferTrade)[] = [
        'amountOwed',
        'condition',
        'isNetTradeAppliedToFinancing',
        'make',
        'mileage',
        'model',
        'source',
        'trim',
        'year',
        'value',
        'status'
    ];
    return checkRequiredFieldsAnd(data, fields);
};
export function isSaveOfferTrade(data: SaveOfferTrade): boolean {
    if (!data) return true; // Trade is optional

    switch (data.source) {
        case 'KBB':
            return isTradeByKBBValid(data);
        case 'ICO':
            return isTradeByICOValid(data);
        case 'USER':
            return isTradeByUserValid(data);
        default:
            return false;
    }
}

/**
 * Is the data of type SaveOffer
 * @param data Object to check
 */
export function isSaveOffer(data: any): data is SaveOffer {
    return (
        data &&
        checkRequiredFieldsAnd(data, ['dealer', 'vehicle']) &&
        isSaveOfferDealer(data.dealer) &&
        isSaveOfferVehicleData(data.vehicle) &&
        isSaveOfferShopper(data.shopper) &&
        isSaveOfferPayment(data.payment) &&
        isSaveOfferTrade(data.trade)
    );
}

/**
 * Is the data of type SaveOfferResults
 * @param data Object to check
 */
export function isSaveOfferResults(data: any): data is SaveOfferResults {
    return checkRequiredFieldsAnd(data, ['vin', 'connectionId']);
}
