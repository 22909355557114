/**
 * DealerOn first desktop shim
 */

var shimUtils = require('../../utils');

module.exports = function(opts) {

    var data = opts.data;
    var options = opts.options;

    //get listedPrice & retailPrice
    data.retailPrice = document.querySelector('.price .msrpPrice span:not([class="priceTagAndColon"])');
    var internetPrice = document.querySelector('.price .internetPrice span:not([class="priceTagAndColon"])');
    var finalPrice = document.querySelector('.price .finalPrice span:not([class="priceTagAndColon"])');

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.getElementById('vehicleInfo');

    //get mileage
    data.mileage = document.querySelector('#mileageValue strong');

    //get vin
    data.vin = document.querySelector('div[data-content-theme="d"] ul li:nth-child(9) strong');

    //get stock
    data.stock = document.querySelector('div[data-content-theme="d"] ul li:nth-child(10) strong');

    //get img
    data.img = document.getElementById('car_pic');

    //set listedPrice
    //based on available element
    if (!finalPrice && internetPrice) {
        data.listedPrice = internetPrice;
    } else if (!internetPrice && !finalPrice) {
        data.listedPrice = data.retailPrice
    } else {
        data.listedPrice = finalPrice;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        incentiveSavings: function(data, result) {
            result.incentiveSavings = shimUtils.getInteger(data.incentiveSavings);
        },
        year: function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make: function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model: function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.model = model.splice(2, 1)[0];
        },
        trim: function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ').filter(function(n){ return n != "";});
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status >= 0) ? 0 : 1;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        stock: function(data, result) {
            var stock = data.stock.textContent;
            if (stock.search(/:/g) > -1) {
                result.stock = stock.split(':')[1].trim();
            } else {
                result.stock = stock.trim();
            }
        },
        vin: function(data, result) {
            var vin = data.vin.textContent;
            if (vin.search(/:/g) > -1) {
                result.vin = vin.split(':')[1].trim();
            } else {
                result.vin = vin.trim();
            }
        },
        img: function(data, result) {
            var img = data.img;
            result.img = img.src;
        }
    };

    // call continue to get data
    options = shimUtils.continue(data, tasks, options);

};
