var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        dealerSavings: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    data.listedPrice = document.querySelector('meta[itemprop="price"]');//document.querySelector('#vdp-price .vdp-price-final strong')

    var pricingTableLabel = document.querySelectorAll('div#vdp-price dl dt');
    var pricingTableValue = document.querySelectorAll('div#vdp-price dl dd');

    if (pricingTableLabel.length > 0 && pricingTableValue.length > 0) {
        var pricingLabelArr = Array.prototype.map.call(
        pricingTableLabel,

        function(obj, i, arr) {
            return obj;
        });

        var pricingValueArr = Array.prototype.map.call(
        pricingTableValue,

        function(obj, i, arr) {
            return obj;
        });
        //750 - odgen lincoln is applying a loyalty price that doesn't apply to all customers
        //We are getting the price without the owner loyalty
        //604 - Williamsburgford we are pulling price without the Ford Credit Retail Bonus Customer Cash
        var pricingOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            var value = pricingValueArr[index];

            switch (label) {
                case 'retail':
                case 'msrp':
                    data.retailPrice = value;
                    break;

                case 'savings':
                    data.dealerSavings = value;
                    break;

                default:
            }
            return true;
        });
    }

    if(!data.retailPrice){
        data.retailPrice = document.querySelector('.msrp');
    };

    var vehicleTableLabel = document.querySelectorAll('div#vdp-detail dl dt');
    var vehicleTableValue = document.querySelectorAll('div#vdp-detail dl dd');


    if (vehicleTableLabel.length > 0 && vehicleTableValue.length > 0) {
        var vehicleLabelArr = Array.prototype.map.call(
            vehicleTableLabel,

            function(obj, i, arr) {
                return obj;
            });

        var vehicleValueArr = Array.prototype.map.call(
            vehicleTableValue,

            function(obj, i, arr) {
                return obj;
            });

        var vehicleOptionsCollector = vehicleLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase().trim();
            var value = vehicleValueArr[index];

            switch (label) {
                case 'stock':
                    data.stock = value;
                    break;
                case 'miles':
                    data.mileage = value;
                    break;
                case 'vin':
                    data.vin = value;
                    break;

                default:
            }
            return true;
        });
    }

    data.year = data.make = data.model = data.trim = document.querySelector('div.clear.vdp-header h1');

    //get img
    data.img = document.querySelector('#vdp-photo img');

    //get status
    data.vehicleStatus = location.pathname.toLowerCase().search(/\bnew\b/g);


    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            var listedPrice = data.listedPrice;
            result.listedPrice = shimUtils.getInteger(listedPrice.content);
        },
        retailPrice: function(data, result) {
            var retailPrice = data.retailPrice;
            result.retailPrice = shimUtils.getInteger(retailPrice);
        },
        dealerSavings: function(data,result) {
            var dealerSavings = data.dealerSavings;
            result.dealerSavings = shimUtils.getInteger(dealerSavings);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        mileage: function(data, result) {
            var mileage = data.mileage;
            result.mileage = shimUtils.getInteger(data.mileage,10);
        },
        vin: function(data, result) {
            var vin = data.vin.textContent;
            result.vin = vin;
        },
        stock: function(data, result) {
            var stock = data.stock;

            if(typeof stock === 'string'){
                result.stock = stock.trim();
            }else{
                result.stock = stock.textContent.trim();
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set isShimmed
    options.isShimmed = true;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

};
