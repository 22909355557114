var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.vehicle-params .row');

    if(vehicleOverview.length){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );

        var overviewData = overviewArr;

        if (overviewData.length) {

            var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;;

            var siteOptionsCollector = overviewData.filter(function(row, index, array) {

                var label = row.children[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label.trim()) {
                    case 'price':
                        data.listedPrice = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'mileage':
                        data.mileage = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'stock':
                        data.stock = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'vin':
                        data.vin = row.children[1].textContent.trim().replace(replaceRegex, '').toLowerCase();
                        break;

                    case 'year':
                        data.year = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'make':
                        data.make = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'model':
                        data.model = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    case 'trim':
                        data.trim = row.children[1].textContent.trim().replace(replaceRegex, '');
                        break;

                    default:
                        break;

                }
                return true;
            });
        }
    }

    data.img = document.querySelector('.additional-images img');

    if(!data.listedPrice){
        data.listedPrice = document.querySelector('.price');
    }

    if(!data.stock){
        data.stock = document.querySelector('.stock');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = shimUtils.getInteger(data.year);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            if(data.stock.textContent){
                data.stock = data.stock.textContent;
            };
            if(data.stock.indexOf('#') > -1){
                var stock = data.stock.split('#');
                data.stock = stock[1].trim();
            }
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            result.vehicleStatus = 0;
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
