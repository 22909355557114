//dealer 581 DCA
var shimUtils = require('../utils');
var _ = require('underscore');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice : ''
    };

    // MWO-862 - Fix for listedPrice choosing the incorrect value between new/used listings.
    // Using map function to convert details list into array and then choosing index based on conditions
    var details = document.querySelector('.veh_pricing_inner_container.details-price dl');
    var detailsArr = Array.prototype.map.call(
        details.children,
        function(obj, i, arr) {
            return obj.textContent;
        }
    );

    var listedPriceIndex;
    if(_.indexOf(detailsArr, 'Net Price*:') != -1 && _.indexOf(detailsArr, 'Asking Price:') != -1) {
        listedPriceIndex = _.indexOf(detailsArr, 'Net Price*:');
    } else if(_.indexOf(detailsArr, 'Asking Price:') != -1 && _.indexOf(detailsArr, 'Special Offer:') != -1) {
        listedPriceIndex = _.indexOf(detailsArr, 'Special Offer:');
    } else {
        listedPriceIndex = _.indexOf(detailsArr, 'Asking Price:');
    }

    //get listedPrice
    data.listedPrice = detailsArr[listedPriceIndex+1];

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            var listedPrice = data.listedPrice;
            listedPrice = listedPrice.replace(/\D/g, '');
            result.listedPrice = parseInt(listedPrice, 10);
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

   return options;

}
