

module.exports = function(options) {

	options = options || {};

	//get listedPrice
	var price = document.querySelector('.price span[itemprop="price"]');
	price = price.textContent.replace(/\D/g, '');
	options.listedPrice = parseInt(price, 10);

	//get year
	var year = document.querySelector('meta[itemprop="releaseDate"]').getAttribute('content');
	options.year = parseInt(year, 10);

	//get make
	options.make = document.querySelector('meta[itemprop="brand"]').getAttribute('content');

	//get model
	options.model = document.querySelector('meta[itemprop="model"]').getAttribute('content');

	//get stock
	options.stock = document.querySelector('meta[itemprop="sku"]').getAttribute('content');

	//get vin
	options.vin = document.querySelector('meta[itemprop="serialNumber"]').getAttribute('content');

	//get data from overview block
	var keys = document.querySelectorAll('#myTab1Content .row ul li.specification-item .title');
	var values = document.querySelectorAll('#myTab1Content .row ul li.specification-item .value');
	var overview = {};

	for(var i = 0; i < keys.length; i++) {
		var key = keys[i].textContent.replace(/[:#*]/g, '').toLowerCase().trim();
		var value = values[i].textContent;
		overview[key] = value;
	}

	//set trim
	options.trim = overview.trim;

	//get mileage
	if(overview.hasOwnProperty('mileage')) {
		options.mileage = parseInt(overview.mileage, 10);
	} else {
		var mileage = document.querySelector('.info').textContent.trim();
		mileage = mileage.split(',')[3].replace(/\D/g, '');
		options.mileage = parseInt(mileage, 10);
	}

	//get status
	options.vehicleStatus = (overview.type == "New") ? 1 : 0;

	//get img
	var img = document.querySelector('.images ul li img').src;
	options.img = img;

	//set loaded function
   	options.onLoadedFunction = function() { console.log("widget loaded"); };

   	//set hiding function
   	options.onHidingFunction = function() { console.log("widget not showing"); };

	//set url
	options.url = document.URL;

	return options;

}
