// externals
import axios from 'axios';

// config
import * as Config from '../config';

// interfaces/types
import type { GetOfferPayment, GetOfferPaymentResult, GetOfferPaymentResponse } from './types/offerPaymentInterfaces';

/**
 * Retrieve payment information from offer object
 * @param context Offer from SDP Init Object
 */
export const getOfferPayment = async (context: GetOfferPayment): Promise<GetOfferPaymentResult> => {
    const domain = Config.getdsApiUrl(context.dealer.sponsor || '');
    const url = `${domain}/sp/bff/widget/payment`;

    const result = await axios.post<GetOfferPaymentResponse>(url, context);

    if (!result.data.success) {
        const { error } = result.data;
        const { message = 'Could not fetch payment information.' } = error || {};

        throw new Error(message);
    }

    return result.data.payment;
};
