var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        listedPrice: ''
    };

    var pricingLabelTable = document.querySelectorAll('.veh_pricing_container dt');
    if(pricingLabelTable.length<1)
        pricingLabelTable = document.querySelectorAll('#details-highlights .details-price dt');
    var pricingValueTable = document.querySelectorAll('.veh_pricing_container dd');
    if(pricingValueTable.length<1)
        pricingValueTable = document.querySelectorAll('#details-highlights .details-price dd');

    if (pricingLabelTable.length > 0 && pricingValueTable.length > 0) {

        var pricingLabelArr = Array.prototype.map.call(
            pricingLabelTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var pricingValueArr = Array.prototype.map.call(
            pricingValueTable,
            function(obj, i, arr) {
                return obj;
            }
        );

        var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            var value = pricingValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
            switch (label) {
                case 'price':
                case 'integrity price':
                case 'internet price':
                case 'internet special':
                case 'your price':
                case 'special price':
                    data.listedPrice = value;
                    break;
            }
            return true;
        });


    }
    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

};
