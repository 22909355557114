var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    //only display on page if "Presented by Stephen Wade Honda or Mazda" exists on page
    var stephenWade = document.getElementById('printInfo') || document.querySelector('.dealer-info h4:nth-child(1)');

    var honda = 'presented by stephen wade honda';
    var mazda = 'presented by stephen wade mazda';

    if(stephenWade) {
        stephenWade = stephenWade.textContent.trim().toLowerCase();

        if(!(stephenWade === honda || stephenWade === mazda)) {
            options.disableLoad = true;
        }
    }

    return options;
};