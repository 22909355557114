//dealer 1214
var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        listedPrice: '',
        retailPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: 1,
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.listedPrice = document.querySelector('#vehicle-detail-basic-info-container dd #vehicle-detail-price');
    data.retailPrice = data.listedPrice = document.querySelector('#vehicle-detail-basic-info-container dd .strike-price');

    data.year = data.make = data.model = data.trim = document.querySelector('div h1 [itemprop="name"]');

    data.img = document.querySelector('#carousel canvas');

    // grab data from vehicle overview table
    var vehicleOverviewLabel = document.querySelectorAll('#vehicle-detail-basic-info-container dt');

    var vehicleOverviewValue = document.querySelectorAll('#vehicle-detail-basic-info-container dd');

    if(vehicleOverviewLabel.length>0 && vehicleOverviewValue.length>0){
        var overviewLabelArr = Array.prototype.map.call(
            vehicleOverviewLabel,
            function(obj,i,arr){
                return obj;
            }
        );

        var overviewValueArr = Array.prototype.map.call(
            vehicleOverviewValue,
            function(obj,i,arr){
                return obj;
            }
        );

        var siteOptionsCollector = overviewLabelArr.filter(function(row, index, array) {
            var label = array[index].textContent.trim().replace(/[&\/\\#,+()$~:%'"*?<>{}]/g, '').toLowerCase().trim();
            var value = overviewValueArr[index].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase()
            switch(label) {
                case 'price':
                    data.listedPrice = value;
                    break;

                case 'condition':
                    data.vehicleStatus = value;
                    break;

                case 'miles':
                case 'mileage':
                    data.mileage = value;
                    break;

                case 'stock':
                    data.stock = value;
                    break;

                case 'vin':
                case 'vin vehicle identification number':
                    data.vin = value;
                    break;

                default:
            }
            return true;
        });
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            if(data.retailPrice) {
                 result.retailPrice = shimUtils.getInteger(data.retailPrice);
            }
            else {
                result.retailPrice = shimUtils.getInteger(data.listedPrice);
            }
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.trim();
        },
        vehicleStatus: function(data, result) {
            data.vehicleStatus = data.vehicleStatus.toLowerCase().trim();
            result.vehicleStatus = data.vehicleStatus.indexOf('new')>-1 ? 1 : 0;
        },
        img: function(data, result) {
            result.img = data.img.getAttribute('data-url');;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
