var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.resulttext');

    var overviewData = Array.prototype.map.call(
        vehicleOverview ,
        function(obj, i, arr) {
            return obj;
        }
    );


    if (overviewData.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}.\s]/g;

        var siteOptionsCollector = overviewData.filter(function(row, index, array) {

            if(row.children.length) {

                var label = row.childNodes[0].textContent.trim().replace(replaceRegex, '').toLowerCase();

                switch (label) {
                    case 'msrp':
                        data.retailPrice = row.childNodes[1];
                        break;
                    
                    case 'price':
                        data.listedPrice = row.childNodes[1];
                        break;

                    case 'year':
                        data.year = row.childNodes[1];
                        break;

                    case 'make':
                        data.make = row.childNodes[1];
                        break;

                    case 'model':
                        data.model = row.childNodes[1];
                        break;

                    case 'trim':
                        data.trim= row.childNodes[1];
                        break;

                    case 'mileage':
                        data.mileage = row.childNodes[1];
                        break;

                    case 'stock':
                        data.stock = row.childNodes[1];
                        break;

                    case 'vin':
                        data.vin = row.childNodes[1];
                        break;

                    default:
                        break;

                }
            }
            return true;
        });

        //get img
        data.img = document.querySelector('.pikachoose img');
        data.vehicleStatus = document.URL;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            if (data.listedPrice.textContent !== '') {
                result.listedPrice = shimUtils.getInteger(data.listedPrice);
            } else {
                result.listedPrice = shimUtils.getInteger(data.listedPrice.value);
            }

        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = parseInt(data.year.textContent.trim(), 10);
        },
        make: function(data, result) {
            result.make = data.make.textContent.trim();
        },
        model: function(data, result) {
            result.model = data.model.textContent.trim();
        },
        trim: function(data, result) {
            result.trim = data.trim.textContent.trim();
        },
        mileage: function(data, result) {
            if (data.mileage.textContent !== '') {
                result.mileage = shimUtils.getInteger(data.mileage)
            } else {
                result.mileage = shimUtils.getInteger(data.mileage.value);
            }

        },
        vin: function(data, result) {
            result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus.search('/type=N/') === -1) {
                result.vehicleStatus = 0;
            } else {
                result.vehicleStatus = 1;
            }
        },
        img: function(data, result) {
            if(data.img.nodeName === 'A'){
                result.img = data.img.href;
            }else{
                result.img = data.img.src;
            }
            
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};