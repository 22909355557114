//get correct price

module.exports = function(options) {

    options = options || {};

    var msrp = document.querySelector('.retailValue .price')
            || document.querySelector('.msrp .price')
            || document.querySelector('.askingPrice .price')
            || document.querySelector('.customMsrp .price');
	var finalPrice = document.querySelector('.final-price .price');

    var discountLabel = document.querySelector('.final-price .label').textContent.toLowerCase();

    if(discountLabel.indexOf('discount') > -1) {
        msrp = msrp.textContent.replace(/\D/g, '');
        options.listedPrice = parseInt(msrp, 10);
    }

    options.isShimmed = true;

    return options;

}
