'use strict';

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    data.year = data.make = data.model = data.trim = document.querySelector('.title');

    data.vehicleStatus = document.querySelector('h1 .category');

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelectorAll('.specifications li');
	var pricingTable = document.querySelectorAll('.mathbox_specialsContainer ul');

    if(pricingTable.length===0){
        data.listedPrice = document.querySelector('.featuredPrice span');
    }

    if(vehicleOverview.length>0 || pricingTable.length>0){
        if(vehicleOverview.length>0){
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if(pricingTable.length>0){
            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if(overviewArr && pricingArr){
            var siteOptionsArr = overviewArr.concat(pricingArr);
        }else if(!overviewArr && pricingArr){
            var siteOptionsArr = pricingArr;
        }else if(overviewArr && !pricingArr){
            var siteOptionsArr = overviewArr;
        }
    }


    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {

		if(array[index].children.length !== 0) {

            var label = array[index].children[0].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
			var value = row.children[1];

            switch(label) {
				case 'msrp':
					data.retailPrice = value;
					break;

				case "toycen's sale price":
					data.listedPrice = value;
					break;

                case "toycen discount price":
                    data.listedPrice = value;
                    break;

				case 'vin':
					data.vin = value;
					break;

				case 'stock number':
					data.stock = value;
                    break;

                case 'mileage':
                    data.mileage = value;
                    break;

				default:
			}
		}
        return true;
    });

    //get img
    data.img = document.getElementById('media_placeholder').querySelector('img')
    || document.querySelector('.media img')
    || '';

	//logging
	shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            var listedPrice = data.listedPrice.textContent.replace(/\D/g, '');
            result.listedPrice = parseInt(listedPrice, 10);
        },
        retailPrice: function(data, result) {
            var retailPrice = data.retailPrice.textContent.replace(/\D/g, '');
            result.retailPrice = parseInt(retailPrice, 10);
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var title = shimUtils.titleTrimmer(data.make);
            result.make = title.make;
        },
        model: function(data, result) {
            var title = shimUtils.titleTrimmer(data.model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var title = shimUtils.titleTrimmer(data.trim);
            result.trim = title.trim;
        },
        mileage: function(data, result) {
			if(data.mileage) {
				result.mileage = parseInt(data.mileage.textContent.replace(/\D/g, ''),10);
			}
        },
        vin: function(data, result) {
            result.vin = data.vin.textContent.trim();
        },
        stock: function(data, result) {
            result.stock = data.stock.textContent.trim();
        },
        vehicleStatus: function(data, result) {
			if(data.vehicleStatus.textContent.trim().toLowerCase() === 'new') {
				result.vehicleStatus = 1;
			}else{
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

}
