//test/dealerinspire/sportsmazdaorlando
//sponsor passing incorrect price
//get correct price
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
      listedPrice : '',
      retailPrice : '',
    };

    data.retailPrice = document.querySelector('.mainPriceContainer .originalPriceContainer .originalPrice');
    data.listedPrice = document.querySelector('.mainPriceContainer .real-price');

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
      listedPrice : function(data,result){
       result.listedPrice = shimUtils.getInteger(data.listedPrice);
      },
      retailPrice : function(data,result){
        result.listedPrice = shimUtils.getInteger(data.listedPrice);
      }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    return options;

}
