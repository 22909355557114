var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('.vehicle-title');

    //get prices
    data.listedPrice = document.querySelector('.price-block .price');

    data.retailPrice = document.querySelector('.price-block .price-old');

    //get status, mileage, vin, stock
    data.vehicleStatus = data.mileage = data.vin = data.stock = document.querySelectorAll('.specification-item');

    //get img
    data.img = document.querySelector('.images .active img');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice : function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            for(var i = 0; i < status.length; i++) {
                var title = status[i].querySelector('.title').textContent.trim().toLowerCase();
                if(title === 'type:') {
                    status = status[i].querySelector('.value').textContent.trim().toLowerCase();
                    result.vehicleStatus = (status === 'new') ? 1 : 0;
                    break;
                }
            }
        },
        mileage : function(data, result) {
            var mileage = data.mileage;
            for(var i = 0; i < mileage.length; i++) {
                var title = mileage[i].querySelector('.title').textContent.trim().toLowerCase();
                if(title === 'mileage:') {
                    mileage = mileage[i].querySelector('.value');
                    result.mileage = shimUtils.getInteger(mileage);
                    break;
                }
            }
        },
        vin : function(data, result) {
            var vin = data.vin;
            for(var i = 0; i < vin.length; i++) {
                var title = vin[i].querySelector('.title').textContent.trim().toLowerCase();
                if(title === 'vin #:') {
                    result.vin = vin[i].querySelector('.value').textContent.trim();
                    break;
                }
            }
        },
        stock : function(data, result) {
            var stock = data.stock;
            for(var i = 0; i < stock.length; i++) {
                var title = stock[i].querySelector('.title').textContent.trim().toLowerCase();
                if(title === 'stock#:') {
                    result.stock = stock[i].querySelector('.value').textContent.trim();
                    break;
                }
            }
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
	options.url = document.URL;

	//set loaded function
	options.onLoadedFunction = function() { console.log("widget loaded"); };

	//set hiding function
	options.onHidingFunction = function() { console.log("widget not showing"); };

	return options;

}
