module.exports = function(options) {

    options = options || {};

    if (options.retailPrice && options.vehicleStatus == '1') {
        options.listedPrice = options.retailPrice;
    }

    return options;

}
