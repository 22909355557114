var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        stock: ''
    };
    
    var widgetData = window.mmd.widgetData;

    if(widgetData.stock && typeof widgetData.stock === 'number') {
        data.stock = widgetData.stock.toString();
    }

    var tasks = {
        stock: function(data, result) {
            result.stock = data.stock.trim();
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;

}
