var titleTrimmer = require('./titleTrimmer');
var getInteger = require('./getInteger');
var valueQuerySelector = require('./valueQuerySelector');
var moveAppFrame = require('./moveAppFrame');

var shimUtils = module.exports = {

    titleTrimmer : function(data){
        var result = titleTrimmer.titleTrimmer(data);
        return result;
    },

    removeLocationFromVehicleTitle: function(selector) {
        var result = titleTrimmer.removeLocationFromVehicleTitle(selector);
        return result;
    },

    getInteger : function(data){
        var result = getInteger.getInteger(data);
        return result;
    },

    isElementHidden : function(data){
        var result = moveAppFrame.isElementHidden(data);
        return result;
    },

    removeContainers : function(){
        var result = moveAppFrame.removeContainers();
        return result;
    },

    moveDealerOnAppFrame : function(data){
        var result = moveAppFrame.moveDealerOnAppFrame(data);
        return result;
    },

    moveDDCAppFrame : function(){
        var result = moveAppFrame.moveDDCAppFrame();
        return result;
    },

    valueQuerySelectorNew : function(query, options, searchValue, required){
        var result = valueQuerySelector.valueQuerySelectorNew(query, options, searchValue, required);
        return result;
    },

    valueQuerySelector : function(query, options, searchValue){
        var result = valueQuerySelector.valueQuerySelector(query, options, searchValue);
        return result;
    },

    callValueQuerySelector : function(data, options){
        var result = valueQuerySelector.callValueQuerySelector(data, options);
        return result;
    },

    isComplete : function() {
        var result = valueQuerySelector.isComplete();
        return result;
    },

    continue: function(data, tasks, context) {
        var result = context || {};

        //if isDeferred or hasError
        //return to display error
        if (context.isDeferred || context.hasError) {
            return result;
        }

        //loop through tasks and run if the element exists
        for (var task in tasks) {
            var curData = data[task];
            //check if element exists
            if (!curData) {
                continue;
            }

            //run task
            if (tasks.hasOwnProperty(task)) {
                tasks[task](data, result);
            }
        }
        return result;
    },

    isVisible: function(element, count, limit = 5, _isVisible = true) {
        count = count || 0;
        element = element || document.querySelector('#mmd-widget');

        function hidden(el) {
            return window.getComputedStyle(el).display === 'none' || window.getComputedStyle(el).visibility === 'hidden';
        }

        if (count === limit) {
            return _isVisible;
        }

        if (element !== null && element.nodeName === 'BODY') {
            return _isVisible;
        }

        _isVisible &= !hidden(element);

        if (_isVisible && count < limit) {
            element = element.parentNode || null;

            if (element) {
                return this.isVisible(element, count + 1, limit, _isVisible);
            }
        }

        return _isVisible;
    }
};
