var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleLabelOverview = document.querySelectorAll('table tbody tr .ndr');
    var vehicleValueOverview = document.querySelectorAll('table tbody tr .ndl');

    var overviewLabelArr = Array.prototype.map.call(
        vehicleLabelOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    var overviewValueArr = Array.prototype.map.call(
        vehicleValueOverview,
        function(obj, i, arr) {
            return obj;
        }
    );

    if (overviewLabelArr.length && overviewValueArr.length) {

        var replaceRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;

        var siteOptionsCollector = overviewLabelArr.filter(function(row, index, array) {
            var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();
            var value = overviewValueArr[index];

            switch (label) {
                case 'year':
                    data.year = value.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'make':
                    data.make = value.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'model':
                    data.model = value.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'body':
                    data.trim = value.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'condition':
                    data.vehicleStatus = value.textContent.replace(replaceRegex, '').trim();
                    break;

                case 'mileage':
                    data.mileage = value.textContent.replace(replaceRegex, '').trim();
                    break;

                default:
                    break;
            }
            return true;
        });

        data.vin = document.querySelector('table tbody tr .topnd span:nth-child(1)');
        data.stock = document.querySelector('table tbody tr .topnd span:nth-child(2)');
        data.listedPrice = document.querySelector('.infotable1 td h2');
        data.img = document.querySelector('tbody tr .body div#picture_viewer .picture img');
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = parseInt(data.year, 10);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            if(data.vin.textContent){
                data.vin = data.vin.textContent.trim();
            };
            result.vin = data.vin;
        },
        stock: function(data, result) {
            if(data.stock.textContent)
                data.stock = data.stock.textContent.trim();
            if(data.stock.indexOf(':')>-1)
                data.stock = data.stock.split(':')[1].trim();
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus === 'new') {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
