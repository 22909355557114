var shimUtils = require('../utils');

module.exports = function(options){

    options = options || {};

    var data = {
        listedPrice : '',
        retailPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    var vehicleTitle = document.querySelector('.carheading');

    if(vehicleTitle){
        //get listedPrice
        data.listedPrice = document.querySelector('.new-price') || document.querySelector('.sundanceprice');

        //get retailPrice
        data.retailPrice = document.querySelector('.msrp');

        //get year, make, model, trim
        data.year = data.make = data.model = data.trim = vehicleTitle;

        //get vehicleStatus
        data.vehicleStatus = location.href;

        //get mileage
        data.mileage = document.querySelector('.Info tbody tr:nth-child(7)');

        //get stock
        data.stock = document.querySelector('.Info tbody tr:nth-child(9) table tbody tr:nth-child(7)');

        //get VIN
        data.vin = document.querySelector('.carvin');

        //get img
        data.img = document.getElementById('vehimg');
    }
    
    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
        retailPrice : function(data,result){
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        listedPrice : function(data,result){
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        year : function(data,result){
            var year = data.year;
            year = year.textContent.trim().split(' ').shift();
            result.year = parseInt(year,10);
        },
        make : function(data,result){
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1,1)[0];
        },
        model : function(data,result){
            var model = data.model;
            model = model.innerText.trim().split('\n');
            result.model = model[0].split(' ')[2];
        },
        trim : function(data, result) {
            var trim = data.trim;
            trim =  trim.innerText.trim().split('\n');
            result.trim = trim[1];
        },
        mileage : function(data,result){
            var mileage = data.mileage && data.mileage.textContent ? data.mileage.textContent.match(/mileage:/gi) : '';
            if(mileage){
                result.mileage = shimUtils.getInteger(data.mileage);
            }
        },
        vehicleStatus : function(data,result){
            var status = data.vehicleStatus.match(/new/gi)
            result.vehicleStatus = status && status.length ? 1 : 0;
        },
        vin : function(data,result){
            var vin = data.vin;
            result.vin = vin.textContent.trim().split(':')[1].trim();
        },
        stock : function(data, result){
            var stock = data.stock;
            result.stock =  stock.textContent.split(':')[1].trim();
        },
        img : function(data,result){
            result.img = data.img.src;
        }
    };

    //call continue to collect data
    options = shimUtils.continue(data,tasks,options);

    //set URL
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log('widget loaded');
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log('widget not showing');
    };

    options.isShimmed = true;

    return options;
};
