var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

     var data = {
        retailPrice : '',
        listedPrice : '',
        year : '',
        make : '',
        model : '',
        trim : '',
        vehicleStatus : '',
        mileage : '',
        stock : '',
        vin : '',
        img : ''
    };

    var href = location.href;

    //get status
    data.vehicleStatus = location.pathname.toLowerCase().search(/\bnew\b/g);

    //get overview box
    var vehicleOverview = document.querySelectorAll('.vdp-vehicle-details li');

    //get price box
    var pricingTable = document.querySelectorAll('.vdp-vehicle-pricing .columns');

    var overviewArr = Array.prototype.map.call(
        vehicleOverview,
        function(obj,i,arr){
            return obj;
        }
    );

    var pricingArr = Array.prototype.map.call(
        pricingTable,
        function(obj,i,arr){
            return obj;
        }
    );

    var siteOptionsArr = overviewArr.concat(pricingArr);

    var siteOptionsCollector = siteOptionsArr.filter(function(row, index, array) {
		if(array[index].children.length !== 0) {
	        var option = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}\u00ae]/g, '').toLowerCase().split(':');
			switch(option[0]) {
				case 'mileage':
					data.mileage = option[1];
					break;

				case 'msrp':
					data.retailPrice = option[1];
					break;

				case 'demontrond price':
					data.listedPrice = option[1];
					break;

                case 'price':
                    data.listedPrice = option[1];
                    break;

				case 'vin':
					data.vin = option[1];
                    break;

                case 'stock ':
                    data.stock = option[1];
                    break;

				default:
			}
		}
        return true;
    });

    //get year, make, model, trim
    data.year = data.make = data.model = data.trim = document.querySelector('#title h2')
        || document.querySelector('.vehicle-title h1');

    //get img
    data.img = document.querySelector('#photo_one img')
        || document.querySelector('.rg-image img');


    //logging
    shimUtils.callValueQuerySelector(data, options);

    var tasks = {
        listedPrice : function(data, result) {
            result.listedPrice = parseInt(data.listedPrice, 10);
        },
        retailPrice : function(data, result) {
            result.retailPrice = parseInt(data.retailPrice, 10);
        },
        year : function(data, result) {
            var year = data.year;
            year = year.textContent.trim().split(' ');
            result.year = parseInt(year.shift(), 10);
        },
        make : function(data, result) {
            var make = data.make;
            make = make.textContent.trim().split(' ');
            result.make = make.splice(1, 1)[0];
        },
        model : function(data, result) {
            var model = data.model;
            model = model.textContent.trim().split(' ');
            result.model = model.splice(2, 1)[0];
        },
        trim : function(data, result) {
            var trim = data.trim;
            trim = trim.textContent.trim().split(' ');
            result.trim = trim.splice(3, trim.length).join(' ');
        },
        vehicleStatus : function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 1 : 0;
        },
        mileage : function(data, result) {
            result.mileage = parseInt(data.mileage,10);
        },
        vin : function(data, result) {
            result.vin = data.vin.trim();
        },
        stock : function(data, result) {
            result.stock = data.stock.trim();
        },
        img : function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set prependTo
    options.prependTo = '#vdetails div[data-role="content"]';

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() { console.log("widget loaded"); };

    //set hiding function
    options.onHidingFunction = function() { console.log("widget not showing"); };

    return options;

}
