var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        stock: '',
        vin: '',
        img: ''
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.getElementById('vitalsContainer') || '';
    if (vehicleOverview.children) {
        vehicleOverview = vehicleOverview.querySelectorAll('li');
    }
    var pricingTable = document.getElementById('priceContainer') || '';
    if (pricingTable.children) {
        pricingTable = pricingTable.querySelectorAll('ul');
    }

    if (vehicleOverview.length > 0 || pricingTable.length > 0) {
        if (vehicleOverview.length > 0) {
            var overviewArr = Array.prototype.map.call(
                vehicleOverview,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (pricingTable.length > 0) {
            var pricingArr = Array.prototype.map.call(
                pricingTable,
                function(obj, i, arr) {
                    return obj;
                }
            );
        }

        if (overviewArr) {
            var siteOptionsCollector = overviewArr.filter(function(row, index, array) {
                if (array[index].textContent.indexOf(':') > -1) {
                    var labelAndValue = array[index].textContent.trim().replace(/[&\/\\#,+()$~%'"*?<>{}]/g, '').toLowerCase().split(':');
                    var label = labelAndValue[0];
                    var value = labelAndValue[1];

                    switch (label) {
                        case 'mileage':
                            data.mileage = value;
                            break;

                        case 'vin':
                            data.vin = value;
                            break;

                        case 'vin ':
                            data.vin = value;
                            break;

                        case 'stock':
                            data.stock = value;
                            break;

                        case 'stock ':
                            data.stock = value;
                            break;

                        default:
                    }
                }
                return true;
            });
        }

        if (pricingArr) {
            var siteOptionsCollector = pricingArr.filter(function(row, index, array) {
                if (array[index].children.length !== 0) {
                    var label = array[index].children[1].textContent.trim().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase();
                    switch (label) {
                        case 'sale price':
                            data.listedPrice = array[index].children[0];
                            break;

                        default:
                    }
                }
                return true;
            });
        }

    }

    //get year, make, model, trim and msrp
    data.year = data.make = data.model = data.trim = document.querySelector('#vehicle_title')
        || document.querySelector('#titleVdp');

    //get listedPrice
    if (data.listedPrice === '') {
        data.listedPrice = document.querySelector('#priceContainer .price_line_1');
    }

    //get stock and mileage and vin
    if (data.stock === '') {
        data.stock = document.querySelectorAll("#vitalsContainer ul li");
    };
    if (data.mileage === '') {
        data.mileage = document.querySelectorAll("#vitalsContainer ul li");
    };
    if (data.vin === '') {
        data.vin = document.querySelectorAll("#vitalsContainer ul li");
    };

    //get vehicleStatus
    data.vehicleStatus = location.pathname.search(/used/g);

    //get img
    data.img = document.getElementById('carimg');

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            if (data.make.textContent.trim().search(/MSRP/g) > -1) {
                var retailPrice = data.make.textContent.trim().substring(0, retailPrice.search(/MSRP/) - 1).split(' ').pop();
                result.retailPrice = parseInt(retailPrice.replace(/\D/g, ''), 10);
            } else {
                result.retailPrice = parseInt(data.retailPrice, 10);
            }
        },
        year: function(data, result) {
            var title = shimUtils.titleTrimmer(data.year);
            result.year = title.year;
        },
        make: function(data, result) {
            var make = data.make.textContent.trim();
            if (make.search(/MSRP/g) > -1) {
                make = make.split(' MSRP, ')[0].split(' ').splice(0, make.length - 1);
            }
            var title = shimUtils.titleTrimmer(make);
            result.make = title.make;;
        },
        model: function(data, result) {
            var model = data.model.textContent.trim();
            if (model.search(/MSRP/g) > -1) {
                model = model.split(' MSRP, ')[0].split(' ').splice(0, model.length - 1);
            }
            var title = shimUtils.titleTrimmer(model);
            result.model = title.model;
        },
        trim: function(data, result) {
            var trim = data.trim.textContent.trim();
            if (trim.search(/MSRP/g) > -1) {
                trim = trim.split(' MSRP, ')[0].split(' ').splice(0, trim.length - 1);
            }
            var title = shimUtils.titleTrimmer(trim);
            result.trim = title.trim;
        },
        vehicleStatus: function(data, result) {
            var status = data.vehicleStatus;
            result.vehicleStatus = (status > -1) ? 0 : 1;
        },
        mileage: function(data, result) {
            var mileage = data.mileage;
            if (!!mileage.textContent) {
                for (var i = 0; i < mileage.length; i++) {
                    if (i == 4 || i == 7) {
                        continue;
                    }
                    var title = mileage[i].querySelector('strong').textContent.trim().toLowerCase();
                    if (title === 'mileage:') {
                        result.mileage = shimUtils.getInteger(mileage[i].querySelector('strong').nextSibling);
                        break;
                    }
                }
            } else {
                result.mileage = parseInt(mileage, 10);
            }
        },
        vin: function(data, result) {
            var vin = data.vin;
            if (!!vin.textContent) {
                for (var i = 0; i < vin.length; i++) {
                    if (i == 4 || i == 7) {
                        continue;
                    }
                    var title = vin[i].querySelector('strong').textContent.trim().toLowerCase();
                    if (title === 'vin #:') {
                        result.vin = vin[i].querySelector('strong').nextSibling.textContent.trim();
                        break;
                    }
                }
            } else {
                result.vin = vin.trim();
            }
        },
        stock: function(data, result) {
            var stock = data.stock;
            if (!!stock.textContent) {
                for (var i = 0; i < stock.length; i++) {
                    if (i == 4 || i == 7) {
                        continue;
                    }
                    var title = stock[i].querySelector('strong').textContent.trim().toLowerCase();
                    if (title === 'stock #:') {
                        result.stock = stock[i].querySelector('strong').nextSibling.textContent.trim();
                        break;
                    }
                }
            } else {
                result.stock = stock.trim();
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

}
