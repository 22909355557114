'use strict';
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: ''
    };

    if(!options.retailPrice){
    	data.retailPrice = document.querySelector('.single-stacked-price .strike-through') ||
    		document.querySelector('.double-stacked-price .strike-through');

	    //logging
	    shimUtils.callValueQuerySelector(data, options);

	    //define tasks to get data
	    var tasks = {
	        retailPrice: function(data, result) {
	            result.retailPrice = shimUtils.getInteger(data.retailPrice);
	        }
	    };

	    //call continue to get data
	    options = shimUtils.continue(data, tasks, options);

	    //set loaded function
	    options.onLoadedFunction = function() {
	        console.log("widget loaded");
	    };

	    //set hiding function
	    options.onHidingFunction = function() {
	        console.log("widget not showing");
	    };

	    //set url
	    options.url = document.URL;
    }

    return options;
};
