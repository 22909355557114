var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    // collection the car location from the site
    // Desktop: [attr] data-click-to-call-phone
    // Mobile:  [attr] data-click-to-call-phone
    var phoneNumberElem = document.querySelector('[data-click-to-call-phone]');
    
    // Assume that one of these elements will be on the page
    var isComplete = shimUtils.isComplete();
    if (!phoneNumberElem && !options.wasDeferred && !isComplete) {
        options.isDeferred = true;
        return options;
    }

    if (phoneNumberElem) {
        var phoneNumber = '';

        // Extract and Sanitize Phone Number
        phoneNumber = phoneNumberElem.getAttribute('data-click-to-call-phone')
                        .replace(/[&\/\\#,+()$~%'":*?<>{}-\s]/g, '')
                        .trim();

        // Perform logic to override dealerId or set to not load
        if (phoneNumber === '7198885863') {
            // Mike Maroone Chevrolet North Colorado Springs
            options.dealerId = 2033;
        } else if (phoneNumber === '7198882921') {
            // Mike Maroone Chevrolet South Colorado Springs
            options.dealerId = 2037;
        } else if (phoneNumber === '8669591023') {
            // Mike Maroone Honda
            options.dealerId = 2038;
        // Uncomment when Mike Maroone Group adds Volkswagon Dealership to DealStarter
        /* } else if (phoneNumber === '8884802004') {
            // Mike Maroone Volkswagon
            options.dealerId = ????; */
        } else {
            options.disableLoad = true;
        }
    }

    return options;
}
