// tslint:disable: trailing-comma no-duplicate-string max-line-length
import { sdpFrameCSS } from './css/sdpFrame.css';
import { AnalyticsRelay } from './messaging/analyticsRelay';
import { SDPWidgetHooks } from './types/SDPWidgetHooks';
import { SDPLogger } from './utils/sdpLogger';

export class SDPFrame {
    private readonly IFRAME_ID = 'mmd-shopper-platform-iframe';
    private readonly BACKGROUND_ID = 'mmd-shopper-platform-background';
    private readonly CONTAINER_ID = 'mmd-shopper-platform-container';
    private readonly CLOSE_BUTTON_ID = 'mmd-shopper-platform-frame-close-button';
    private readonly PROGRESS_ID = 'mmd-shopper-platform-progress';
    private readonly IFRAME_WRAPPER_ID = 'mmd-shopper-platform-wrapper';
    private _analyticsRelay: AnalyticsRelay;
    private _domElementCache: { [key: string]: HTMLElement } = {};
    public hooks?: SDPWidgetHooks;

    constructor() {
        this.createFrame();
    }

    public get analyticsRelay(): AnalyticsRelay {
        if (!this._analyticsRelay) {
            this._analyticsRelay = new AnalyticsRelay();
        }

        return this._analyticsRelay;
    }

    /**
     * This will create the iframe if needed and then show it
     */
    public show(url: string) {
        const logger = SDPLogger.getLogger();
        logger.logEvent({
            event: 'opensdp_sdp_load_started'
        });

        this.createFrame();
        this.iframe.src = url;
        this.wrapper.style.display = 'none'; // will show once document is loaded
        this.progress.className = 'loading';
        this.background.style.display = 'block';

        this.analyticsRelay.init(
            this.iframe,
            (success: boolean) => {
                // if we do not get ack confirmation from SP, we can shut this down
                if (success) {
                    this.loaded();
                } else {
                    this.onError();
                }
            },
            this.hooks?.onTrack
        );
    }

    public showProgress() {
        this.createFrame();
        this.wrapper.style.display = 'none'; // make sure the wrapper is gone
        this.progress.className = 'loading';
        this.background.style.display = 'block';
    }

    private onError() {
        const logger = SDPLogger.getLogger();
        logger.error({
            event: 'opensdp_sdp_load_failed'
        });

        this.hide();
    }

    private loaded() {
        // this is done to keep the iframe loading event from firing when we clear
        // the src (when we hide the page).  Its also done in case the actual page has a
        // hash # at the end of the url.  In chrome, it seems as though the iframe,
        // when cleared assumes the url of the page.
        if (this.iframe.src !== '' && !window.location.href.startsWith(this.iframe.src)) {
            this.wrapper.style.display = 'block';
            this.progress.className = '';

            if (this.button) {
                this.button.style.display = 'flex';
            }

            const logger = SDPLogger.getLogger();
            logger.logEvent({
                event: 'opensdp_sdp_load_finished'
            });

            this.hooks?.onLoadedFunction?.();
        }
    }

    /**
     * Hide the iframe
     */
    public hide() {
        if (this.doesFrameExist()) {
            this.background.style.display = 'none';
            this.progress.className = '';
            this.wrapper.style.display = 'none';
            this.iframe.src = '';

            this.hooks?.onHidingFunction?.();

            this.analyticsRelay.close();
        }
    }

    /**
     * Add click event listener to widget background for closig SDP (auto-open SDP flow)
     */
    public addBackgroundClickEvent(sdpWindow: any) {
        this.background.addEventListener('click', () => {
            sdpWindow.postMessage({ message: 'widget-background-clicked', target: 'sdp' }, '*');
        });
    }

    public enableVdpScroll() {
        const body = document.querySelector('body');
        body.style.overflow = null;
    }

    public disableVdpScroll() {
        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
    }

    // TODO: To be removed once the old DealStarter confirm-popup functionality gets deprecated
    public removeCloseButton() {
        this.wrapper.removeChild(this.button);
    }

    /**
     * This will construct the iframe and the surrounding DOM elements.
     * It will only do so if it hasn't already been constructed and the body is ready.
     */
    public createFrame() {
        if (this.canConstruct()) {
            // add styles
            const css = this.createElement('style', { type: 'text/css' }) as HTMLStyleElement;
            css.innerHTML = sdpFrameCSS;
            document.getElementsByTagName('head')[0].appendChild(css);

            // create a div for the background
            const divBackground = this.createElement('div', { id: this.BACKGROUND_ID, class: this.BACKGROUND_ID });
            document.body.appendChild(divBackground);

            // create frame container
            const divContainer = this.createElement('div', { id: this.CONTAINER_ID, class: this.CONTAINER_ID });
            divBackground.appendChild(divContainer);

            const divIFrameContainer = this.createElement('div', { id: this.IFRAME_WRAPPER_ID, class: 'wrapper' });
            divContainer.appendChild(divIFrameContainer);

            // create the loading indicator
            const loadingIndicator = this.createElement('div', { id: this.PROGRESS_ID });
            loadingIndicator.appendChild(this.createElement('div', { class: 'bounce1' }));
            loadingIndicator.appendChild(this.createElement('div', { class: 'bounce2' }));
            loadingIndicator.appendChild(this.createElement('div', { class: 'bounce3' }));
            divContainer.appendChild(loadingIndicator);

            // create the iframe
            const frame = this.createElement('iframe', {
                id: this.IFRAME_ID,
                allow: 'camera;microphone',
                class: this.IFRAME_ID
            });
            divIFrameContainer.appendChild(frame);

            // close button
            const btn = this.createElement('button', {
                id: this.CLOSE_BUTTON_ID,
                type: 'button',
                title: 'Close',
                class: this.CLOSE_BUTTON_ID
            });

            // add to the button to make the button look softer
            const crossParent = this.createElement('div', { class: 'cross-parent' });
            btn.appendChild(crossParent);

            const crossChild = this.createElement('div', { class: 'cross-child' });
            crossParent.appendChild(crossChild);

            const crossSubChild = this.createElement('div', { class: 'cross-subchild' });
            crossChild.appendChild(crossSubChild);

            divIFrameContainer.appendChild(btn);

            // add button event onclick
            this.button.addEventListener('click', () => {
                this.hide();
            });
        }
    }

    public getElementById(id: string): HTMLElement {
        return this._domElementCache[id];
    }

    public get iframe(): HTMLIFrameElement {
        return this.getElementById(this.IFRAME_ID) as HTMLIFrameElement;
    }

    public get background(): HTMLDivElement {
        return this.getElementById(this.BACKGROUND_ID) as HTMLDivElement;
    }

    public get progress(): HTMLDivElement {
        return this.getElementById(this.PROGRESS_ID) as HTMLDivElement;
    }

    public get wrapper(): HTMLDivElement {
        return this.getElementById(this.IFRAME_WRAPPER_ID) as HTMLDivElement;
    }

    public get button(): HTMLButtonElement {
        return this.getElementById(this.CLOSE_BUTTON_ID) as HTMLButtonElement;
    }

    public doesFrameExist(): boolean {
        const iframe = this.iframe;
        const background = this.background;

        return iframe !== undefined && iframe !== null && background !== undefined && background !== null;
    }

    public canConstruct(): boolean {
        return document && document.body && !this.doesFrameExist();
    }

    /**
     *
     * @param type type of element
     * @param attributes object of attributes that need to be set
     * @param styles object of styles to set
     */
    private createElement(type: string, attributes?: { [key: string]: string }): any {
        const el = document.createElement(type);

        if (attributes) {
            Object.keys(attributes).forEach((attrib) => {
                el.setAttribute(attrib, attributes[attrib]);
            });

            if (attributes.id) {
                this._domElementCache[attributes.id] = el;
            }
        }

        return el;
    }
}
