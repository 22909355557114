var shimUtils = require('../utils');

module.exports = function(options) {

    if(options.retailPrice && options.listedPrice){
        options.retailPrice = options.listedPrice
    }

    return options;
};
