//MWO-2041
//Dealer wants to change the My Offer price from Internet Price to MSRP.

var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
        retailPrice: '',
        listedPrice: ''
    };

    //Only new vehicles have MSRP
    if(window._di.auto.MSRP > 0){
        data.retailPrice = data.listedPrice = window._di.auto.MSRP;
    }

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
    };

    //call continue to collect data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    return options;

};
