var shimUtils = require('../utils');

module.exports = function(options) {

    var data = {
        retailPrice: '',
        listedPrice: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        vehicleStatus: '',
        mileage: '',
        vin: '',
        img: '',
        stock: '',
    };

    // grab data from vehicle overview table
    var vehicleOverview = document.querySelector('.adui-widget-content .features .adui-clearfix');
    if(vehicleOverview){
        vehicleOverview = vehicleOverview.querySelectorAll('li');
    }

    var vehicleOverview2 = document.querySelectorAll('.details-list li');

    var pricingLabel = document.querySelectorAll('.adui-clearfix .price-label');
    var pricingValue = document.querySelectorAll('.adui-clearfix .price-value');

    if(vehicleOverview){
        var overviewArr = Array.prototype.map.call(
            vehicleOverview,
            function(obj, i, arr) {
                return obj;
            }
        );
    }

    if(vehicleOverview2.length){
        var overviewArr2 = Array.prototype.map.call(
            vehicleOverview2,
            function(obj, i, arr){
                return obj;
            }
        );
    }

    if(pricingLabel.length){
        var pricingLabelArr = Array.prototype.map.call(
            pricingLabel,
            function(obj, i, arr) {
                return obj;
            }
        );
    }

    if(pricingValue.length){
        var pricingValueArr = Array.prototype.map.call(
            pricingValue,
            function(obj, i, arr) {
                return obj;
            }
        );
    }

    if (overviewArr) {

        var replaceRegex = /[&\/\\#,+()$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewArr.filter(function(row, index, array) {

            var labelAndValue = row.textContent.replace(replaceRegex, '').toLowerCase().split(':');
            var label = labelAndValue[0].trim();
            var value = labelAndValue[1];

            switch (label) {
                case 'stock':
                case 'stock number':
                    data.stock = value.trim();
                    break;

                case 'vin':
                case 'vin number':
                    data.vin = value.trim();
                    break;

                case 'make':
                    data.make = value.trim();
                    break;

                case 'model':
                    data.model = value.trim();
                    break;

                case 'trim':
                case 'vehicle trim':
                    data.trim = value.trim();
                    break;

                case 'year':
                case 'model year':
                    data.year = value.trim();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if (overviewArr2) {

        var replaceRegex = /[&\/\\#,+():$~%'"*?<>{}]/g;

        var siteOptionsCollector = overviewArr2.filter(function(row, index, array) {

            var label = row.children[0].textContent.replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'mileage':
                    data.mileage = row.children[1].textContent.replace(replaceRegex, '').toLowerCase();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    if (pricingLabelArr && pricingValueArr) {

        var replaceRegex = /[&\/\\#,+():$~%'"*?<>{}]/g;

        var siteOptionsCollector = pricingLabelArr.filter(function(row, index, array) {

            var label = row.textContent.replace(replaceRegex, '').toLowerCase().trim();

            switch (label) {
                case 'internet price':
                    data.listedPrice = pricingValueArr[index].textContent.replace(replaceRegex, '').toLowerCase();
                    break;

                case 'msrp':
                case 'original price':
                    data.retailPrice = pricingValueArr[index].textContent.replace(replaceRegex, '').toLowerCase();
                    break;

                default:
                    break;

            }
            return true;
        });
    }

    data.img = document.querySelector('.carousel .image-wrapper img');

    data.vehicleStatus = document.URL;

    //logging
    shimUtils.callValueQuerySelector(data, options);

    //define tasks to get data
    var tasks = {
        listedPrice: function(data, result) {
            result.listedPrice = shimUtils.getInteger(data.listedPrice);
        },
        retailPrice: function(data, result) {
            result.retailPrice = shimUtils.getInteger(data.retailPrice);
        },
        year: function(data, result) {
            result.year = parseInt(data.year, 10);
        },
        make: function(data, result) {
            result.make = data.make;
        },
        model: function(data, result) {
            result.model = data.model;
        },
        trim: function(data, result) {
            result.trim = data.trim;
        },
        mileage: function(data, result) {
            result.mileage = shimUtils.getInteger(data.mileage);
        },
        vin: function(data, result) {
            result.vin = data.vin;
        },
        stock: function(data, result) {
            result.stock = data.stock;
        },
        vehicleStatus: function(data, result) {
            var vehicleStatus = data.vehicleStatus.toLowerCase();

            if (vehicleStatus.indexOf('new')) {
                result.vehicleStatus = 1;
            } else {
                result.vehicleStatus = 0;
            }
        },
        img: function(data, result) {
            result.img = data.img.src;
        }
    };

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set loaded function
    options.onLoadedFunction = function() {
        console.log("widget loaded");
    };

    //set hiding function
    options.onHidingFunction = function() {
        console.log("widget not showing");
    };

    //set url
    options.url = document.URL;

    return options;
};
