var shimUtils = require('../utils');

module.exports = function(options) {
   
    var data = {
        vehicleStatus: '',
        certifiedStatus : '',
        test_name: '',
        vdpTrackingPhoneNumber: ''
    };

    var atcDI = document.querySelector("[data-birf-role='dataisland']");

    if (atcDI) {
        // Obtain the dataIsland data from data-birf-extra attribute (seems to only be on speedy pages)
        var birfExtra = JSON.parse(atcDI.dataset.birfExtra);
        if(birfExtra) {
            data.vehicleStatus = birfExtra.page.vehicle.Car_Type.toLowerCase();
            data.test_name = birfExtra.page.BIRF.test_name;
            
            if(data.vehicleStatus === 'certified') {
                data.certifiedStatus = 'true';
            }
        }
    }

    if(data.vdpTrackingPhoneNumber === '') {
        var phoneNumber = document.querySelector("[data-cmp='phoneNumber']");
        try {
            data.vdpTrackingPhoneNumber =  typeof phoneNumber === 'object' ?  phoneNumber.textContent.trim() : '';
        }catch(error){ }
    }

    // only run the tasks and validate if we have set values otherwise continue with the options that were sent in
    if (data.vehicleStatus || data.certifiedStatus || data.test_name) {

        //logging
        shimUtils.callValueQuerySelector(data, options);

        //define tasks to get data
        var tasks = {
            vehicleStatus : function(data, result) {
                if(data.vehicleStatus === 'new'){
                    result.vehicleStatus = 1;
                }
                else {
                     result.vehicleStatus = 0;
                }
            },
            certifiedStatus: function(data, result) {
                if (data.certifiedStatus !== '') {
                    result.certifiedStatus = data.certifiedStatus;
                }
            },
            'test_name': function(data, result) {
                if(data.test_name !== '') {
                    result.test_name = data.test_name
                }
            },
            vdpTrackingPhoneNumber: function(data, result) {
                if(data.vdpTrackingPhoneNumber !== '') {
                    result.vdpTrackingPhoneNumber = data.vdpTrackingPhoneNumber;
                }
            }
        };

        //call continue to get data
        options = shimUtils.continue(data, tasks, options);
    }

    return options;
};
