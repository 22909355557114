import { LoggerBase } from '../../utils/logger';
import { partial } from 'lodash';

export const ACTIV_ENGAGE_QS_PARAM = 'activEngageId';

let isActivEngageUp = false;

export interface IActivEngageCreateSessionTokenPromise {
    done(fn: (token: string) => void): IActivEngageCreateSessionTokenPromise;
    fail(fn: () => void): IActivEngageCreateSessionTokenPromise;
}

export interface IActivEngage {
    tracking?: {
        createSessionToken?(): IActivEngageCreateSessionTokenPromise;
    };
    events?: {
        onAvailable?: (fn: () => void) => void;
        onUnavailable?: (fn: () => void) => void;
    };
}

export interface IActivEngageWindow extends Window {
    ActivEngage?: IActivEngage;
}

export interface IActivEngageSyncPayload {
    aeSessionToken: string;
}

export const makeAETrackingCall = (logger: LoggerBase): Promise<string | undefined> => {
    const promise = new Promise<string | undefined>((resolve, reject) => {
        const aeWindow = window as IActivEngageWindow;

        if (aeWindow.ActivEngage && aeWindow.ActivEngage.tracking && aeWindow.ActivEngage.tracking.createSessionToken) {
            aeWindow.ActivEngage.tracking
                .createSessionToken()
                .done((token) => {
                    resolve(token);
                })
                .fail(() => {
                    logger.error('Unable to acquire ActivEngage Session Token');
                    resolve(undefined);
                });
        } else {
            logger.info('ActivEngage is not present on the VDP');
            resolve(undefined);
        }
    });

    return promise;
};

export const connectAEEvent = () => {
    const aeWindow = window as IActivEngageWindow;

    aeWindow?.ActivEngage?.events?.onAvailable(() => {
        isActivEngageUp = true;
    });

    // I'm including this because I only ever see onUnavailable trigger
    // even when I'm looking at the widget loaded on DDC
    // Seems like could potentially be a bug on their side so including both of these
    // in case they fix it
    aeWindow?.ActivEngage?.events?.onUnavailable(() => {
        isActivEngageUp = true;
    });
};

const poll = async (fn, fnCondition, ms) => {
    let keepPolling = true;
    const timeoutId = setTimeout(() => {
        keepPolling = false
    }, 500);
    while (fnCondition() && keepPolling) {
        await wait(ms);
    }
    clearTimeout(timeoutId);
    return await fn();
}

function wait(ms = 1000) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export const getActivEngageId = async (logger: LoggerBase, isActivEngageEnabled?: boolean): Promise<any> => {
    if (isActivEngageEnabled) {
        const aeWindow = window as IActivEngageWindow;
        // it's gross but there's no getting around it
        // we have to wait for AE to show up on the window
        // and then we have to wait for them to finish initializing
        // before their createSessionToken will work
        // first poll waits for AE existence
        await poll(connectAEEvent, () => typeof aeWindow.ActivEngage === 'undefined', 100);

        // this one waits for a variable that will be updated after their callback triggers
        return await poll(partial(makeAETrackingCall, logger), () => !isActivEngageUp, 100);
    }
};
