
// MWO-1922
// Need to send deals to different stores based on the
// store location.  Location is found in a dataLayer object
// on the dealers website

module.exports = function(options) {

    options = options || {};
    var siteOptions = null;

    // site has a dataLayer object which has the location value
    siteOptions = window.dataLayer && window.dataLayer[0];

    if(siteOptions !== null && siteOptions.hasOwnProperty('location')) {
        var location = siteOptions.location.toLowerCase().replace(/\s/g,'');

        switch(location) {
            case 'jerrydamsonhondahuntsville':
                options.dealerId = 508;
                break;

            case 'jerrydamsonacurahuntsville':
                options.dealerId = 512;
                break;

            case 'hondaofdecatur':
                options.dealerId = 728;
                break;

        }
    }

    return options;
}
