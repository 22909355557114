var dealerShims = require('./dealer.shims');
var sponsorShims = require('./sponsor.shims');
var Flags = require('./flags');
var _ = require('underscore');
var shimUtils = require('./utils');
var selectn = require('selectn');
const { Logger } = require('../lib/utils/logger');

var Scrappy = function(options, next) {
    const logger = Logger.getLogger();
    var id = options.id || '';
    var sponsor = options.sponsor || '';
    var version = selectn('version', options);
    var data = options.data || {};
    var flag = Flags[id] || {};
    var dealerShim = dealerShims[id] || null;
    var sponsorShim = sponsorShims[sponsor] || null;
    var err = null;

    //add dealerId to data to do dealer checks in sponsorShims
    data.dealerId = options.id;

    // need to add sponsor to the data object so that it can be checked in the shims if required
    data.sponsor = options.sponsor;

    data.ddcAccountId = options.ddcAccountId;
    data.pixallId = options.pixallId || null;
    data.zipCode = options.zipCode;
    
    var dealerDataIslandProvided = options.data.hasOwnProperty('dealerDataIsland'); // Also called vehicleData, contains ownerId

    data.version = version;

    // version 5.0.0 or greater mandates that flags be enabled to run shims
    // instead of defaulting to running shims
    if (version >= '5.0.0') {
        var runDealerShim = flag.hasOwnProperty('dealer') ? !!flag.dealer : false;
        var runSponsorShim = (flag.hasOwnProperty('sponsor') ? !!flag.sponsor : false) || data.sponsor == 'dealeron';
    } else {
        var runDealerShim = flag.hasOwnProperty('dealer') ? !!flag.dealer : true;
        var runSponsorShim = flag.hasOwnProperty('sponsor') ? !!flag.sponsor : true;
        if (data.sponsor == 'atc') runDealerShim = false;
    }

    var scrape = function() {

        if (dealerDataIslandProvided) {     
            logger.logEvent({ event: 'dataislandprovider_started' });
            data = _.extend(options.data.dealerDataIsland, _.pick(data, 'dealerId', 'sponsor', 'additionalData', 'version', 'zipCode'));
        }

        if (!data.testMode && runSponsorShim && sponsorShim) {
            logger.logEvent({ event: 'sponsorshim_started' });
            data = sponsorShim(data);
        }

        if (!data.testMode && runDealerShim && dealerShim && !data.isDeferred) {
            logger.logEvent({ event: 'dealershim_started' });
            data = dealerShim(data);
        }

        return data;
    };

    var setError = function() {
        if (data.hasError) {
            err = new Error(data.error.message);
        }
    };

    data = scrape();

    // set err if error returned from DCA
    setError();
    var isComplete = shimUtils.isComplete();
    if (!data.isDeferred || isComplete) {
        return next(err, data);
    }

    document.addEventListener("DOMContentLoaded", function(event) {
        data.wasDeferred = true;
        data.isDeferred = false;
        data = scrape();
        setError();

        next(err, data);
    });
};

module.exports = Scrappy;