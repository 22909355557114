//get correct msrp for Tindol Ford
var shimUtils = require('../utils');

module.exports = function(options) {

    options = options || {};

    var data = {
      retailPrice : '',
    }

    //get retailPrice
    data.retailPrice = document.querySelector('.pricing .initial span');

    //logging
    shimUtils.callValueQuerySelector(data,options);

    //define tasks to get data
    var tasks = {
      retailPrice : function(data,result){
       result.retailPrice = shimUtils.getInteger(data.retailPrice);
      }
    }

    //call continue to get data
    options = shimUtils.continue(data, tasks, options);

    //set url
    options.url = document.URL;

    return options;

}
