//dealer DCA

module.exports = function(options) {

	options = options || {};

	//get listedPrice
	var listedPrice = document.querySelector('#overview .singlePrice dd span')
					|| document.querySelector('#overview .price.used dd span');
	if(!listedPrice) {
		options.isDeferred = true;
		return options;
	}
	listedPrice = listedPrice.textContent.replace(/\D/g, '');
	options.listedPrice = parseInt(listedPrice, 10);

	//get status, year, make, model, trim
	var condYMMT = document.querySelector('.title h1');
	condYMMT = condYMMT.textContent.trim().split(' ');

		//get status
		var status = condYMMT.shift();
		options.vehicleStatus = (status.toLowerCase() == 'new') ? 1 : 0;

		//get year
		var year = condYMMT.shift();
		options.year = parseInt(year, 10);

		//set make
		options.make = condYMMT.shift();

		//set model
		options.model = condYMMT.shift();

		//set trim
		options.trim = condYMMT.join(' ').trim();

	//get vin, mileage, status
	if(options.vehicleStatus == 1) {

		//set mileage
		options.mileage = 1;

		//get vin
		var vin = document.querySelector('#overview .widgetWrap dl:nth-child(2) dd:last-child');
		options.vin = vin.textContent.trim();

		//get stock
		var stock = document.querySelector('#overview .widgetWrap dl:nth-child(2) dd:nth-child(16)');
		options.stock = stock.textContent.trim();

	} else {

		//set mileage
		var mileage = document.querySelector('#overview .widgetWrap dl:nth-child(2) dd.mileageValue span');
		options.mileage = mileage.textContent.replace(/\D/g, '');

		//get vin
		var vin = document.querySelector('#overview .widgetWrap dl:last-child dd:last-child');
		options.vin = vin.textContent.trim();

		//get stock
		var stock = document.querySelector('#overview .widgetWrap dl:nth-child(2) dd:nth-child(18)');
		options.stock = stock.textContent.trim();

	}

	//get img
	var img = document.querySelector('.photo img');
	options.img = img.src;

	//set url
	options.url = document.URL;

	//set loaded function
	options.onLoadedFunction = function() { console.log("widget loaded"); };

	//set hiding function
	options.onHidingFunction = function() { console.log("widget not showing"); };

	//set shimmed true
	options.isShimmed = true;

	return options;

}
